import { gql, useLazyQuery } from '@apollo/client';

import {
  ValidateSendCryptoPayloadQuery,
  ValidateSendCryptoPayloadQueryVariables,
} from '../gqlTypes';

const QUERY = gql`
  query validateSendCryptoPayload(
    $amount: String!
    $address: String!
    $currency: CryptoCurrency
    $unlistedCurrency: String
    $onBehalfOfMyself: Boolean
    $isLegalEntity: Boolean
    $beneficiaryData: NotabenePayload
    $beneficiaryVasp: BeneficiaryVasp
  ) {
    withdrawal {
      validateSendCryptoPayload(
        amount: $amount
        address: $address
        currency: $currency
        unlistedCurrency: $unlistedCurrency
        onBehalfOfMyself: $onBehalfOfMyself
        isLegalEntity: $isLegalEntity
        beneficiaryData: $beneficiaryData
        beneficiaryVasp: $beneficiaryVasp
      ) {
        addressValid
        isValid
        notabeneErrors
        vaspMissing
      }
    }
  }
`;

export function useValidateSendCryptoPayload() {
  const [validateSendCryptoPayloadQuery, { data, error, loading }] = useLazyQuery<
    ValidateSendCryptoPayloadQuery,
    ValidateSendCryptoPayloadQueryVariables
  >(QUERY, { fetchPolicy: 'no-cache', notifyOnNetworkStatusChange: true });

  return {
    validateSendCryptoPayloadQuery,
    data,
    loading,
    error,
  };
}
