import { gql, QueryHookOptions, useQuery } from '@apollo/client';

import {
  GetAvailableVaspsQuery,
  GetAvailableVaspsQueryVariables,
  PopularWithdrawDestinationsQuery,
} from '../gqlTypes';

const FETCH_AVAILABLE_VASPS = gql`
  query GetAvailableVasps($query: String, $limit: Int) {
    withdrawal {
      listAvailableVASPs(q: $query, limit: $limit) {
        did
        displayName
        popularDestination
      }
    }
  }
`;

const FETCH_POPULAR_WITHDRAW_DESTINATIONS = gql`
  query PopularWithdrawDestinations {
    withdrawal {
      popularWithdrawDestinations {
        did
        displayName
      }
    }
  }
`;

export function useAvailableVasps(
  opts?: QueryHookOptions<GetAvailableVaspsQuery, GetAvailableVaspsQueryVariables>,
) {
  return useQuery<GetAvailableVaspsQuery, GetAvailableVaspsQueryVariables>(
    FETCH_AVAILABLE_VASPS,
    opts,
  );
}

export function usePopularWithdrawDestinations(
  opts?: QueryHookOptions<PopularWithdrawDestinationsQuery>,
) {
  return useQuery<PopularWithdrawDestinationsQuery>(FETCH_POPULAR_WITHDRAW_DESTINATIONS, opts);
}
