/*eslint-disable*/ export const messages = {
  '1 day': '1 day',
  '1 month': '1 month',
  '1 mth': '1 mth',
  '1 week': '1 week',
  '1 y': '1 y',
  '1 year': '1 year',
  '1-2 banking days': '1-2 banking days',
  '1D': '1D',
  '1M': '1M',
  '1W': '1W',
  '1Y': '1Y',
  '24H': '24H',
  '24h change': '24h change',
  '3 months': '3 months',
  '3 mth': '3 mth',
  '3M': '3M',
  '404 - Page not found': '404 - Page not found',
  '6 digit code': '6 digit code',
  '7 days': '7 days',
  '7D': '7D',
  '919 932 465': '919 932 465',
  'A "Destination tag" identifies the recipient of a transaction. It is optional to enter the Destination tag, but some recipients require it.':
    'A "Destination tag" identifies the recipient of a transaction. It is optional to enter the Destination tag, but some recipients require it.',
  'A "Destination tag" is a unique number that identifies the recipient of a transaction. It is optional to enter the Destination tag, but some recipients require it.':
    'A "Destination tag" is a unique number that identifies the recipient of a transaction. It is optional to enter the Destination tag, but some recipients require it.',
  'A bank account was verified': 'A bank account was verified',
  'A description of you the source of your assets, e.g bank statement or sales statement':
    'A description of you the source of your assets, e.g bank statement or sales statement',
  'A description of your investment and which bank account has the assets been deducted from':
    'A description of your investment and which bank account has the assets been deducted from',
  'A fee of 3.9% is added to the amount when you make a deposit with a card. You will receive the money immediately.':
    'A fee of 3.9% is added to the amount when you make a deposit with a card. You will receive the money immediately.',
  'A fee of 3.9% is added to the amount when you use Apple Pay to make a deposit. You will receive the money immediately.':
    'A fee of 3.9% is added to the amount when you use Apple Pay to make a deposit. You will receive the money immediately.',
  'A fee of 3.9% is added to the amount when you use Google Pay to make a deposit. You will receive the money immediately.':
    'A fee of 3.9% is added to the amount when you use Google Pay to make a deposit. You will receive the money immediately.',
  'A fee of 3.9% is added to the amount when you use MobilePay to make a deposit. You will receive the money immediately.':
    'A fee of 3.9% is added to the amount when you use MobilePay to make a deposit. You will receive the money immediately.',
  'A fee of 3.9% is added to the amount when you use Vipps to make a deposit. You will receive the money immediately.':
    'A fee of 3.9% is added to the amount when you use Vipps to make a deposit. You will receive the money immediately.',
  'A fee of {0}% is added to the amount when you make a deposit with a card. You will receive the money immediately.':
    [
      'A fee of ',
      ['0'],
      '% is added to the amount when you make a deposit with a card. You will receive the money immediately.',
    ],
  'A fee of {0}% is added to the amount when you use MobilePay to make a deposit. You will receive the money immediately.':
    [
      'A fee of ',
      ['0'],
      '% is added to the amount when you use MobilePay to make a deposit. You will receive the money immediately.',
    ],
  'A fee of {0}% is added to the amount when you use Vipps to make a deposit. You will receive the money immediately.':
    [
      'A fee of ',
      ['0'],
      '% is added to the amount when you use Vipps to make a deposit. You will receive the money immediately.',
    ],
  'A fixed fee of {0} will be deducted from the withdrawal amount to cover our costs.': [
    'A fixed fee of ',
    ['0'],
    ' will be deducted from the withdrawal amount to cover our costs.',
  ],
  'A key reason why cryptocurrency has become so popular is the pioneering blockchain technology. Blockchain technology allows digital interactions to be recorded, tracked and made publicly visible. It opens up a whole new way of creating secure, distributed services that are accessible to all and governed by the community.':
    'A key reason why cryptocurrency has become so popular is the pioneering blockchain technology. Blockchain technology allows digital interactions to be recorded, tracked and made publicly visible. It opens up a whole new way of creating secure, distributed services that are accessible to all and governed by the community.',
  'A lot is happening here! These crypto have had the biggest change in price the last 24 hours':
    'A lot is happening here! These crypto have had the biggest change in price the last 24 hours',
  'A lot is happening with': 'A lot is happening with',
  'A market order is executed immediately and at the best available price on the Firi market when you confirm your order.':
    'A market order is executed immediately and at the best available price on the Firi market when you confirm your order.',
  'A new hatch is opened every day at 8 am': 'A new hatch is opened every day at 8 am',
  "A premium user experience is part of Firi's DNA, so with this update, you will experience a cleaner, tidier, and faster app 🥇 Swipe around a bit to get to know everything we have to offer!":
    "A premium user experience is part of Firi's DNA, so with this update, you will experience a cleaner, tidier, and faster app 🥇 Swipe around a bit to get to know everything we have to offer!",
  API: 'API',
  'API Key': 'API Key',
  'API Key credentials': 'API Key credentials',
  'API documentation': 'API documentation',
  'API key permission': 'API key permission',
  'Aave is a decentralized financial platform built on the Ethereum blockchain. It enables users to borrow, lend and earn interest on various cryptocurrencies, using smart contracts.':
    'Aave is a decentralized financial platform built on the Ethereum blockchain. It enables users to borrow, lend and earn interest on various cryptocurrencies, using smart contracts.',
  "Aave's associated cryptocurrency is called AAVE and is used for governance and security within the smart contracts on the Aave DeFi platform.":
    "Aave's associated cryptocurrency is called AAVE and is used for governance and security within the smart contracts on the Aave DeFi platform.",
  'About "Travel Rule"': 'About "Travel Rule"',
  'About Aave': 'About Aave',
  'About Avalanche': 'About Avalanche',
  'About BNB': 'About BNB',
  'About Bitcoin': 'About Bitcoin',
  'About Cardano': 'About Cardano',
  'About Chainlink': 'About Chainlink',
  'About DAI': 'About DAI',
  'About Decentraland (MANA)': 'About Decentraland (MANA)',
  'About Dogecoin': 'About Dogecoin',
  'About Ethereum': 'About Ethereum',
  'About Firi': 'About Firi',
  'About Litecoin': 'About Litecoin',
  'About Matic': 'About Matic',
  'About Polkadot': 'About Polkadot',
  'About Polygon': 'About Polygon',
  'About Solana': 'About Solana',
  'About Stellar': 'About Stellar',
  'About Tether USDt': 'About Tether USDt',
  'About The Sandbox (SAND)': 'About The Sandbox (SAND)',
  'About USD Coin': 'About USD Coin',
  'About Uniswap': 'About Uniswap',
  'About XRP': 'About XRP',
  'About me': 'About me',
  'About savings account': 'About savings account',
  'About us': 'About us',
  Accept: 'Accept',
  'Accept and continue': 'Accept and continue',
  'Accept everything': 'Accept everything',
  'Accept the updated terms by December 31, 2024, to continue using your account.':
    'Accept the updated terms by December 31, 2024, to continue using your account.',
  'Access to your account is temporarily blocked.':
    'Access to your account is temporarily blocked.',
  Account: 'Account',
  'Account activities': 'Account activities',
  'Account level 4 reached': 'Account level 4 reached',
  'Account number': 'Account number',
  'Account number belongs to a bank that is not supported by Firi':
    'Account number belongs to a bank that is not supported by Firi',
  'Account number belongs to a bank that is not supported by Firi. Please change or delete this account number.':
    'Account number belongs to a bank that is not supported by Firi. Please change or delete this account number.',
  Accounts: 'Accounts',
  'Activate 2FA': 'Activate 2FA',
  'Activate staking and earn rewards': 'Activate staking and earn rewards',
  'Activate the saving agreement in your bank': 'Activate the saving agreement in your bank',
  'Activate two-factor authentication': 'Activate two-factor authentication',
  'Activate two-factor authentication (2FA)': 'Activate two-factor authentication (2FA)',
  'Activated!': 'Activated!',
  'Activity log': 'Activity log',
  Add: 'Add',
  'Add additional information': 'Add additional information',
  'Add as favorite': 'Add as favorite',
  'Add bank account': 'Add bank account',
  'Add configuration key': 'Add configuration key',
  'Add funds': 'Add funds',
  'Add funds to trade crypto': 'Add funds to trade crypto',
  'Add manually': 'Add manually',
  'Add more': 'Add more',
  'Add more information': 'Add more information',
  'Add new key': 'Add new key',
  'Add or remove cryptos from your favorites': 'Add or remove cryptos from your favorites',
  'Add or update purchase price': 'Add or update purchase price',
  'Add purchase price': 'Add purchase price',
  'Add receiver': 'Add receiver',
  'Add your friends referralcode when signing up': 'Add your friends referralcode when signing up',
  'Add your own destination': 'Add your own destination',
  'Added as favorite! You\'ll find "My favorites" in the overview.':
    'Added as favorite! You\'ll find "My favorites" in the overview.',
  Address: 'Address',
  'Advanced trade': 'Advanced trade',
  'Advent calendar': 'Advent calendar',
  Affiliate: 'Affiliate',
  'Affiliate bonus': 'Affiliate bonus',
  'Affiliate commision': 'Affiliate commision',
  'Affiliate program': 'Affiliate program',
  'Affiliate program guidelines': 'Affiliate program guidelines',
  All: 'All',
  'All accounts': 'All accounts',
  'All articles': 'All articles',
  'All crypto': 'All crypto',
  'All crypto on Firi': 'All crypto on Firi',
  'All currencies': 'All currencies',
  'All payouts': 'All payouts',
  'All trades with cryptocurrency will always be done from your main account. This is where your assets for your buying and selling exist.':
    'All trades with cryptocurrency will always be done from your main account. This is where your assets for your buying and selling exist.',
  'All types': 'All types',
  Alphabetical: 'Alphabetical',
  'Already have a user?': 'Already have a user?',
  'Always double check that the copied wallet address is correct before making a deposit':
    'Always double check that the copied wallet address is correct before making a deposit',
  'Amnesty International Norge 2021': 'Amnesty International Norge 2021',
  'Among other things, we consider mining and staking as income. Remember to list the value when you acquired this crypto':
    'Among other things, we consider mining and staking as income. Remember to list the value when you acquired this crypto',
  Amount: 'Amount',
  'Amount ({0})': ['Amount (', ['0'], ')'],
  'Amount exceeds available funds': 'Amount exceeds available funds',
  'Amount exceeds the deposit limit. Try a smaller amount.':
    'Amount exceeds the deposit limit. Try a smaller amount.',
  'Amount is too large': 'Amount is too large',
  'Amount is too low': 'Amount is too low',
  'Amount must be more than 0': 'Amount must be more than 0',
  'Amount to high. Exceeds available funds': 'Amount to high. Exceeds available funds',
  'Amount to high. Exceeds withdraw limit': 'Amount to high. Exceeds withdraw limit',
  'Amount to high. Maximum {0}': ['Amount to high. Maximum ', ['0']],
  'Amount to low. Minimum {0}': ['Amount to low. Minimum ', ['0']],
  'Amount too high': 'Amount too high',
  'Amount too high. Maximum withdraw is {0} {1}': [
    'Amount too high. Maximum withdraw is ',
    ['0'],
    ' ',
    ['1'],
  ],
  'Amount too high. Maximum withdraw is {0} {currency}': [
    'Amount too high. Maximum withdraw is ',
    ['0'],
    ' ',
    ['currency'],
  ],
  'Amount too low': 'Amount too low',
  'Amount too low. Minimum withdrawal is {0} {currency} ≈ {1} {2}': [
    'Amount too low. Minimum withdrawal is ',
    ['0'],
    ' ',
    ['currency'],
    ' ≈ ',
    ['1'],
    ' ',
    ['2'],
  ],
  'Amount too low. Minimum withdrawal is {minLimit} {0}': [
    'Amount too low. Minimum withdrawal is ',
    ['minLimit'],
    ' ',
    ['0'],
  ],
  'Amount too low. Minimum {0}': ['Amount too low. Minimum ', ['0']],
  'An app you can trust': 'An app you can trust',
  "An error has occurred. We're looking into it right away. Your funds are completely safe with us. Please try again in a little while.":
    "An error has occurred. We're looking into it right away. Your funds are completely safe with us. Please try again in a little while.",
  'An error occured. Please try again!': 'An error occured. Please try again!',
  'An error occurred while changing your password.':
    'An error occurred while changing your password.',
  'Annual return is an estimate and may vary over time.':
    'Annual return is an estimate and may vary over time.',
  'Annual yield is an estimate and will vary over time.':
    'Annual yield is an estimate and will vary over time.',
  Appearance: 'Appearance',
  'Apple Pay': 'Apple Pay',
  'Apple Pay fee': 'Apple Pay fee',
  'Apps built on the blockchain need external data to function. Chainlink was founded to serve as a bridge between the blockchain world and the real world by connecting so-called smart contracts, such as those on Ethereum, with reliable and verified data sources outside the blockchain. Chainlink achieves this through a network of so-called "oracles".':
    'Apps built on the blockchain need external data to function. Chainlink was founded to serve as a bridge between the blockchain world and the real world by connecting so-called smart contracts, such as those on Ethereum, with reliable and verified data sources outside the blockchain. Chainlink achieves this through a network of so-called "oracles".',
  'Are you experiencing problems?': 'Are you experiencing problems?',
  'Are you not receiving the email?': 'Are you not receiving the email?',
  'Are you ready to invest in your future, {0}?': [
    'Are you ready to invest in your future, ',
    ['0'],
    '?',
  ],
  'Are you sure that you want to cancel this transaction':
    'Are you sure that you want to cancel this transaction',
  'Are you sure the account number is correct?': 'Are you sure the account number is correct?',
  'Are you sure you want to delete the file?': 'Are you sure you want to delete the file?',
  'Are you sure you want to enter a lower rate than the best available price in our market? We do not have a stop-loss which means that the order is executed instantly at our best price.':
    'Are you sure you want to enter a lower rate than the best available price in our market? We do not have a stop-loss which means that the order is executed instantly at our best price.',
  'Are you sure you want to remove the bank account?':
    'Are you sure you want to remove the bank account?',
  'Are you sure?': 'Are you sure?',
  'Are you transferring to an account you own?': 'Are you transferring to an account you own?',
  Article: 'Article',
  Articles: 'Articles',
  'As of April 9th it will not be possible to redeem gift cards.':
    'As of April 9th it will not be possible to redeem gift cards.',
  'As of March 15, 2022, we will no longer offer commission for new referrals. You will of course retain commission on the trading fees of those you have already referred and will continue to earn from this.':
    'As of March 15, 2022, we will no longer offer commission for new referrals. You will of course retain commission on the trading fees of those you have already referred and will continue to earn from this.',
  'At the same time, you help Firi make the crypto world even brighter and safer for everyone.':
    'At the same time, you help Firi make the crypto world even brighter and safer for everyone.',
  'Author: {0}. Read articles about crypto. Buy and sell bitcoin and other cryptocurrencies for {1} at Firi.':
    [
      'Author: ',
      ['0'],
      '. Read articles about crypto. Buy and sell bitcoin and other cryptocurrencies for ',
      ['1'],
      ' at Firi.',
    ],
  'Automatic purchase with crypto-save': 'Automatic purchase with crypto-save',
  Available: 'Available',
  'Available balance': 'Available balance',
  'Available for staking': 'Available for staking',
  'Available for withdrawal': 'Available for withdrawal',
  'Avalanche Contract Chain (C-Chain)': 'Avalanche Contract Chain (C-Chain)',
  "Avalanche is a blockchain platform that focuses on high transaction speed, low fees, and environmental friendliness. Avalanche's associated cryptocurrency is called AVAX.":
    "Avalanche is a blockchain platform that focuses on high transaction speed, low fees, and environmental friendliness. Avalanche's associated cryptocurrency is called AVAX.",
  "Avalanche is a relatively new blockchain and cryptocurrency. It was launched in 2020, and AVAX has quickly become one of the world's largest cryptocurrencies based on market capitalization.":
    "Avalanche is a relatively new blockchain and cryptocurrency. It was launched in 2020, and AVAX has quickly become one of the world's largest cryptocurrencies based on market capitalization.",
  'Average price': 'Average price',
  'Awesome that you like our crypto news!': 'Awesome that you like our crypto news!',
  'BNB (BNB) is the cryptocurrency of Binance and is currently the fourth largest cryptocurrency in the world.':
    'BNB (BNB) is the cryptocurrency of Binance and is currently the fourth largest cryptocurrency in the world.',
  Back: 'Back',
  'Back to logged in': 'Back to logged in',
  'Back to login': 'Back to login',
  Balance: 'Balance',
  'Bank ID': 'Bank ID',
  'Bank account added!': 'Bank account added!',
  'Bank accounts': 'Bank accounts',
  'Bank deposit': 'Bank deposit',
  'Bank transfers': 'Bank transfers',
  BankID: 'BankID',
  'Based on the transactions that are carried out and the risk associated with cryptocurrency, we sometimes need information about the origin of the funds. We accept various forms of documentation such as a bank statement, sales statement, inheritance or gift certificate that documents the origin of the funds.':
    'Based on the transactions that are carried out and the risk associated with cryptocurrency, we sometimes need information about the origin of the funds. We accept various forms of documentation such as a bank statement, sales statement, inheritance or gift certificate that documents the origin of the funds.',
  'Based on the transactions that are carried out and the risk associated with cryptocurrency, we sometimes need information about the origin of the funds. We accept various forms of documentation such as closing note, settlement statement, inheritance settlement, loan documents or gift certificates documenting the origin of the funds.':
    'Based on the transactions that are carried out and the risk associated with cryptocurrency, we sometimes need information about the origin of the funds. We accept various forms of documentation such as closing note, settlement statement, inheritance settlement, loan documents or gift certificates documenting the origin of the funds.',
  'Based on the type of documentation, there may be several rounds of questions, answers and the need for additional documentation. Therefore, we can also request the latest available Tax Report which gives us a secure and comprehensive overview of your funds and financial situation.':
    'Based on the type of documentation, there may be several rounds of questions, answers and the need for additional documentation. Therefore, we can also request the latest available Tax Report which gives us a secure and comprehensive overview of your funds and financial situation.',
  'Be aware of fraud!': 'Be aware of fraud!',
  'Be aware that investing in and staking crypto involves risk.':
    'Be aware that investing in and staking crypto involves risk.',
  'Because of its fast transactions and low fees, Doge is often used for online donations and small payments.':
    'Because of its fast transactions and low fees, Doge is often used for online donations and small payments.',
  'Because of this, meme coins are highly volatile and prices can change dramatically in a short period of time. It is important to be aware of the risks when choosing to invest in meme coins.':
    'Because of this, meme coins are highly volatile and prices can change dramatically in a short period of time. It is important to be aware of the risks when choosing to invest in meme coins.',
  'Become a customer of Firi': 'Become a customer of Firi',
  'Beginner crypto investor': 'Beginner crypto investor',
  'Being paid out on': 'Being paid out on',
  'Belongs to the categories': 'Belongs to the categories',
  'Belongs to the category': 'Belongs to the category',
  'Best regards': 'Best regards',
  'Binance Smart Chain (BEP20)': 'Binance Smart Chain (BEP20)',
  'Bitcoin is a digital currency that you can store and transfer directly to anyone, anywhere in the world, without going through third parties such as banks or other central institutions.':
    'Bitcoin is a digital currency that you can store and transfer directly to anyone, anywhere in the world, without going through third parties such as banks or other central institutions.',
  'Bitcoin is, in addition to being a cryptocurrency, also a digital payment network. The Bitcoin network is decentralized, and is based on blockchain technology. Being decentralized means you can transfer bitcoin directly to others without third-party interference.':
    'Bitcoin is, in addition to being a cryptocurrency, also a digital payment network. The Bitcoin network is decentralized, and is based on blockchain technology. Being decentralized means you can transfer bitcoin directly to others without third-party interference.',
  'Bitcoin network': 'Bitcoin network',
  Bonus: 'Bonus',
  Bought: 'Bought',
  'Bought {0}/3 cryptocurrencies for {1} {2}': [
    'Bought ',
    ['0'],
    '/3 cryptocurrencies for ',
    ['1'],
    ' ',
    ['2'],
  ],
  'Built on the Ethereum network, DAI makes it easy to gain exposure to a more stable cryptocurrency that is also based on blockchain technology.':
    'Built on the Ethereum network, DAI makes it easy to gain exposure to a more stable cryptocurrency that is also based on blockchain technology.',
  "Bummer! Unfortunately, Firi does not support this crypto. Feel free to let us know if it's on your wish list.":
    "Bummer! Unfortunately, Firi does not support this crypto. Feel free to let us know if it's on your wish list.",
  Buy: 'Buy',
  'Buy & Sell': 'Buy & Sell',
  'Buy and sell bitcoin and other cryptocurrencies for NOK, safely and easily. We are registered with Finanstilsynet, have a Vipps login, use BankID and a Norwegian bank for deposits and withdrawals.':
    'Buy and sell bitcoin and other cryptocurrencies for NOK, safely and easily. We are registered with Finanstilsynet, have a Vipps login, use BankID and a Norwegian bank for deposits and withdrawals.',
  'Buy and sell bitcoin and other cryptocurrencies for NOK. We are registered as an exchange and custodian for cryptocurrency in Norway.':
    'Buy and sell bitcoin and other cryptocurrencies for NOK. We are registered as an exchange and custodian for cryptocurrency in Denmark.',
  'Buy bitcoin | Firi - Norwegian cryptocurrency exchange':
    'Buy bitcoin | Firi - Norwegian cryptocurrency exchange',
  'Buy bitcoin | Firi - The largest cryptocurrency exchange in the Nordics':
    'Buy bitcoin | Firi - The largest cryptocurrency exchange in the Nordics',
  'Buy completed': 'Buy completed',
  'Buy completed and staking activated!': 'Buy completed and staking activated!',
  'Buy crypto': 'Buy crypto',
  'Buy crypto in seconds with Vipps or card. This is suitable for those who want to make quick trades.':
    'Buy crypto in seconds with Vipps or card. This is suitable for those who want to make quick trades.',
  'Buy crypto in seconds with card. This is suitable for those who want to make quick trades.':
    'Buy crypto in seconds with card. This is suitable for those who want to make quick trades.',
  'Buy crypto now': 'Buy crypto now',
  'Buy crypto to give away': 'Buy crypto to give away',
  'Buy crypto to stake': 'Buy crypto to stake',
  'Buy for total amount': 'Buy for total amount',
  'Buy more crypto': 'Buy more crypto',
  'Buy not completed': 'Buy not completed',
  'Buy {0} to stake': ['Buy ', ['0'], ' to stake'],
  'Buy {currency} and activate staking': ['Buy ', ['currency'], ' and activate staking'],
  'Buy {name}': ['Buy ', ['name']],
  'Buying and selling is done from your main account':
    'Buying and selling is done from your main account',
  'By clicking "continue" I give Firi consent to store my information in accordance with the <0>privacy policy.</0>':
    'By clicking "continue" I give Firi consent to store my information in accordance with the <0>privacy policy.</0>',
  'By clicking on "Confirm", I agree to Firi AS terms of use':
    'By clicking on "Confirm", I agree to Firi AS terms of use',
  'By completing the transfer, you agree to securely share your information (name, wallet address, and Firi-ID) with the recipient.':
    'By completing the transfer, you agree to securely share your information (name, wallet address, and Firi-ID) with the recipient.',
  "By continuing, you agree to Firi's user terms for":
    "By continuing, you agree to Firi's user terms for",
  'By entering the Christmas calendar, you agree that we will store your answers and contact you by email if you win.':
    'By entering the Christmas calendar, you agree that we will store your answers and contact you by email if you win.',
  'By keeping all your information up to date, you will get the very best user experience Firi has to offer. This includes shorter processing time at our customer service.':
    'By keeping all your information up to date, you will get the very best user experience Firi has to offer. This includes shorter processing time at our customer service.',
  'By pressing "Confirm", I agree to Firi AS\'s terms of use':
    'By pressing "Confirm", I agree to Firi AS\'s terms of use',
  'By pressing "I agree" I agree to Firi AS\'s terms of use for staking.':
    'By pressing "I agree" I agree to Firi AS\'s terms of use for staking.',
  'By sharing anonymous statistics about how you use our app, we can improve our functionalities and your customer experience.':
    'By sharing anonymous statistics about how you use our app, we can improve our functionalities and your customer experience.',
  'By signing i give my consent for Firi to store information according to the <0>privacy policy</0>':
    'By signing i give my consent for Firi to store information according to the <0>privacy policy</0>',
  'By staking, you receive a reward because you contribute to securing and maintaining the blockchain that the specific cryptocurrency belongs to.':
    'By staking, you receive a reward because you contribute to securing and maintaining the blockchain that the specific cryptocurrency belongs to.',
  'CPR must be 10 digits': 'CPR must be 10 digits',
  'CPR-number': 'CPR-number',
  "CPR-number doesn't match MitID. Try again.": "CPR-number doesn't match MitID. Try again.",
  'CSV - Transaction history': 'CSV - Transaction history',
  'Calculating tax...': 'Calculating tax...',
  'Camera permission required': 'Camera permission required',
  'Campaign! Only from {0} to {1}': ['Campaign! Only from ', ['0'], ' to ', ['1']],
  'Can be staked later this year': 'Can be staked later this year',
  'Can be staked this fall': 'Can be staked this fall',
  "Can't find the crypto you are looking for?": "Can't find the crypto you are looking for?",
  "Can't find your bank?": "Can't find your bank?",
  Cancel: 'Cancel',
  'Cancel transaction': 'Cancel transaction',
  Cancelled: 'Cancelled',
  'Cannot redeem own gift card': 'Cannot redeem own gift card',
  'Cannot redeem your own giftcard': 'Cannot redeem your own giftcard',
  'Capital value': 'Capital value',
  Card: 'Card',
  'Card fee': 'Card fee',
  'Cardano is also a flexible blockchain that makes it possible to create decentralized applications.':
    'Cardano is also a flexible blockchain that makes it possible to create decentralized applications.',
  'Cardano is one of the largest cryptocurrencies in the world based on market capitalization. The associated cryptocurrency of Cardano is called ADA.':
    'Cardano is one of the largest cryptocurrencies in the world based on market capitalization. The associated cryptocurrency of Cardano is called ADA.',
  'Cardano network': 'Cardano network',
  Categories: 'Categories',
  "Chainlink is a leading cryptocurrency and a decentralized network. Chainlink's cryptocurrency is called LINK and is among the largest cryptocurrencies based on market value.":
    "Chainlink is a leading cryptocurrency and a decentralized network. Chainlink's cryptocurrency is called LINK and is among the largest cryptocurrencies based on market value.",
  Change: 'Change',
  'Change <0><1/></0>': 'Change <0><1/></0>',
  'Change bank': 'Change bank',
  'Change bank account': 'Change bank account',
  'Change in prices': 'Change in prices',
  'Change password': 'Change password',
  'Change payment method': 'Change payment method',
  'Change purchase price': 'Change purchase price',
  'Change savings agreement': 'Change savings agreement',
  'Check for update': 'Check for update',
  'Check it out now!': 'Check it out now!',
  'Check out': 'Check out',
  'Check out staking': 'Check out staking',
  'Check out taxes': 'Check out taxes',
  'Check that you entered the code correctly or resend code':
    'Check that you entered the code correctly or resend code',
  'Choose Vipps or cards for lightning-fast purchases, or use Norwegian kroner you already have in your Firi account.':
    'Choose Vipps or cards for lightning-fast purchases, or use Norwegian kroner you already have in your Firi account.',
  'Choose account you want to transfer to': 'Choose account you want to transfer to',
  'Choose amount you want to stake': 'Choose amount you want to stake',
  'Choose amount you want to withdraw': 'Choose amount you want to withdraw',
  'Choose cards for lightning-fast purchases, or use Danish kroner you already have in your Firi account.':
    'Choose cards for lightning-fast purchases, or use Danish kroner you already have in your Firi account.',
  'Choose currency and amount': 'Choose currency and amount',
  'Choose from library': 'Choose from library',
  'Choose how many percent of your savings amount should be placed in the different currencies.':
    'Choose how many percent of your savings amount should be placed in the different currencies.',
  'Choose max {maxAllowedAnswers} alternativer': [
    'Choose max ',
    ['maxAllowedAnswers'],
    ' alternativer',
  ],
  'Choose one option': 'Choose one option',
  'Choose one or more cryptocurrencies': 'Choose one or more cryptocurrencies',
  'Choose period': 'Choose period',
  'Choose the correct answer to continue': 'Choose the correct answer to continue',
  'Choose the options that apply most, maximum three options.':
    'Choose the options that apply most, maximum three options.',
  'Choose the options that apply most, possible with all':
    'Choose the options that apply most, possible with all',
  'Choose the options that apply most, possible with all.':
    'Choose the options that apply most, possible with all.',
  'Choose where you want to upload file from': 'Choose where you want to upload file from',
  'Choose your bank': 'Choose your bank',
  'Choose {crypto} price for buy?': ['Choose ', ['crypto'], ' price for buy?'],
  'Choose {crypto} price for sell?': ['Choose ', ['crypto'], ' price for sell?'],
  'Christmas Bonus': 'Christmas Bonus',
  'Christmas giftcard': 'Christmas giftcard',
  City: 'City',
  Claim: 'Claim',
  'Claim reward': 'Claim reward',
  'Click here to export account statement': 'Click here to export account statement',
  'Click here to export annual statement': 'Click here to export annual statement',
  'Click here to see how you do it': 'Click here to see how you do it',
  'Click on virtual currency/cryptocurrency': 'Click on virtual currency/cryptocurrency',
  'Client ID': 'Client ID',
  Close: 'Close',
  'Close my account': 'Close my account',
  'Close section': 'Close section',
  'Closing slip': 'Closing slip',
  'Code from giftcard': 'Code from giftcard',
  'Code has expired': 'Code has expired',
  'Code is not valid': 'Code is not valid',
  'Code not found or expired': 'Code not found or expired',
  'Code sent': 'Code sent',
  Coins: 'Coins',
  'Collection of customer behavior': 'Collection of customer behavior',
  'Come back on December 1 to find out what Firi Santa has hidden behind the first hatch.':
    'Come back on December 1 to find out what Firi Santa has hidden behind the first hatch.',
  'Come back tomorrow': 'Come back tomorrow',
  'Coming soon': 'Coming soon',
  Commission: 'Commission',
  'Commission on old referrals': 'Commission on old referrals',
  'Company name': 'Company name',
  Completed: 'Completed',
  Confirm: 'Confirm',
  'Confirm and pay': 'Confirm and pay',
  'Confirm gift card': 'Confirm gift card',
  'Confirm order': 'Confirm order',
  'Confirm sell': 'Confirm sell',
  'Confirm your personal code': 'Confirm your personal code',
  'Confirmation code': 'Confirmation code',
  Confirmations: 'Confirmations',
  'Congratulations with savings account': 'Congratulations with savings account',
  'Congratulations!': 'Congratulations!',
  'Congratulations! Which crypto currency will you invest in first?':
    'Congratulations! Which crypto currency will you invest in first?',
  'Congratulations! You found an easter egg!': 'Congratulations! You found an easter egg!',
  'Congratulations! You have received <0/> so that you can test out Firi. Which cryptocurrency do you want to invest in first?':
    'Congratulations! You have received <0/> so that you can test out Firi. Which cryptocurrency do you want to invest in first?',
  'Congratulations! You have received {0} so that you can test out Firi. Which cryptocurrency will you invest in first?':
    [
      'Congratulations! You have received ',
      ['0'],
      ' so that you can test out Firi. Which cryptocurrency will you invest in first?',
    ],
  'Congratulations! You now have a staking account.':
    'Congratulations! You now have a staking account.',
  'Congratulations, you have collected {amountTickets} tickets for the big prize! Each lottery ticket gives you an extra chance to win the top prize on Christmas Eve. The more lottery tickets, the greater the chance.':
    [
      'Congratulations, you have collected ',
      ['amountTickets'],
      ' tickets for the big prize! Each lottery ticket gives you an extra chance to win the top prize on Christmas Eve. The more lottery tickets, the greater the chance.',
    ],
  'Connect to': 'Connect to',
  'Connect your account to Vipps for easier login':
    'Connect your account to Vipps for easier login',
  Consent: 'Consent',
  Contact: 'Contact',
  'Contact our support': 'Contact our support',
  'Contact our support on chat!': 'Contact our support on chat!',
  'Contact our support to change your contact information':
    'Contact our support to change your contact information',
  'Contact us': 'Contact us',
  Content: 'Content',
  Continue: 'Continue',
  'Continue with bank deposit': 'Continue with bank deposit',
  'Control company name': 'Control company name',
  'Cookies give you a personalized experience': 'Cookies give you a personalized experience',
  Copied: 'Copied',
  Copy: 'Copy',
  'Copy address': 'Copy address',
  'Copy code': 'Copy code',
  'Copy link': 'Copy link',
  'Copy referral link': 'Copy referral link',
  'Copy the configuration key and add it to your 2FA app':
    'Copy the configuration key and add it to your 2FA app',
  'Corporate account': 'Corporate account',
  'Correct network: <0>{0}</0>': ['Correct network: <0>', ['0'], '</0>'],
  'Correct wallet address': 'Correct wallet address',
  'Could not create contact': 'Could not create contact',
  'Could not find any currencies': 'Could not find any currencies',
  'Could not open application for your payment.': 'Could not open application for your payment.',
  'Could this be the crypto Christmas present of all time?':
    'Could this be the crypto Christmas present of all time?',
  Country: 'Country',
  'Country manager Denmark': 'Country manager Denmark',
  Create: 'Create',
  'Create a fixed transfer/payment to the account information below:':
    'Create a fixed transfer/payment to the account information below:',
  'Create a personal six digit code to unlock the Firi app':
    'Create a personal six digit code to unlock the Firi app',
  'Create a recurring transfer/payment to the account information below:':
    'Create a recurring transfer/payment to the account information below:',
  'Create a savings account in the Firi-app': 'Create a savings account in the Firi-app',
  'Create a savings account with us today.': 'Create a savings account with us today.',
  'Create a savings agreement with automatic purchases of crypto currency.':
    'Create a savings agreement with automatic purchases of crypto currency.',
  'Create a transfer/payment to the account information below:':
    'Create a transfer/payment to the account information below:',
  'Create account with Vipps': 'Create account with Vipps',
  'Create account with your email': 'Create account with your email',
  'Create an account with Firi with Vipps in just a few minutes!':
    'Create an account with Firi with Vipps in just a few minutes!',
  'Create code': 'Create code',
  'Create gift card': 'Create gift card',
  'Create invoice': 'Create invoice',
  'Create new gift card': 'Create new gift card',
  'Create savings account': 'Create savings account',
  'Create savings agreement': 'Create savings agreement',
  'Creating an API private key provides access to markets and real-time trading services on Firi via a third-party site or application.':
    'Creating an API private key provides access to markets and real-time trading services on Firi via a third-party site or application.',
  'Credited from a deposit': 'Credited from a deposit',
  'Crypto I earn rewards on': 'Crypto I earn rewards on',
  'Crypto Talk of the Week': 'Crypto Talk of the Week',
  'Crypto currency tax': 'Crypto currency tax',
  'Crypto for fun': 'Crypto for fun',
  'Crypto for newbies': 'Crypto for newbies',
  'Crypto games allow you to earn cryptocurrency, own digital assets, and participate in decision-making within the games.':
    'Crypto games allow you to earn cryptocurrency, own digital assets, and participate in decision-making within the games.',
  'Crypto investor novice': 'Crypto investor novice',
  'Crypto news': 'Crypto news',
  'Crypto saving': 'Crypto saving',
  'Crypto savings with automatic purchases of crypto. This is suitable for those who want to invest in cryptocurrency for the long-term.':
    'Crypto savings with automatic purchases of crypto. This is suitable for those who want to invest in cryptocurrency for the long-term.',
  'Crypto tax': 'Crypto tax',
  'Crypto tax has never been easier': 'Crypto tax has never been easier',
  'Crypto that are used in DeFi projects': 'Crypto that are used in DeFi projects',
  'Crypto that can be mined': 'Crypto that can be mined',
  'Crypto that earns rewards': 'Crypto that earns rewards',
  'Crypto that follows the dollar value': 'Crypto that follows the dollar value',
  'Crypto that newbies choose': 'Crypto that newbies choose',
  'Crypto trading is a significant source of income for me':
    'Crypto trading is a significant source of income for me',
  'Crypto withdrawal is unavailable until we have received information and processed the case. This is standard practice.':
    'Crypto withdrawal is unavailable until we have received information and processed the case. This is standard practice.',
  "Crypto withdrawal is unavailable until we have received your information and processed the case. This is standard practice, and we will do our utmost to make sure that you can use all of Firi's services as soon as possible.":
    "Crypto withdrawal is unavailable until we have received your information and processed the case. This is standard practice, and we will do our utmost to make sure that you can use all of Firi's services as soon as possible.",
  'Crypto you can earn more on': 'Crypto you can earn more on',
  Cryptocurrency: 'Cryptocurrency',
  'Cryptocurrency and prices': 'Cryptocurrency and prices',
  'Cryptocurrency is a digital currency built on blockchain technology.':
    'Cryptocurrency is a digital currency built on blockchain technology.',
  'Cryptocurrency:': 'Cryptocurrency:',
  Currencies: 'Currencies',
  Currency: 'Currency',
  'Current deposit method': 'Current deposit method',
  'Current fortune': 'Current fortune',
  'Current income': 'Current income',
  'Current loss': 'Current loss',
  'Current payment method': 'Current payment method',
  'Current profit': 'Current profit',
  'Current tax calculation': 'Current tax calculation',
  'Current tax calculation for {0}': ['Current tax calculation for ', ['0']],
  'Current {0}': ['Current ', ['0']],
  'Current {0} price': ['Current ', ['0'], ' price'],
  Customize: 'Customize',
  'Customize cookies': 'Customize cookies',
  'Customize preferences': 'Customize preferences',
  'Customize settings': 'Customize settings',
  'DAI can be used in a number of decentralized applications, and is mainly used to borrow and lend cryptocurrency without the need for an intermediary.':
    'DAI can be used in a number of decentralized applications, and is mainly used to borrow and lend cryptocurrency without the need for an intermediary.',
  'DAI is a so-called stable cryptocurrency that has the purpose of keeping the same 1:1 value of a US dollar.':
    'DAI is a so-called stable cryptocurrency that has the purpose of keeping the same 1:1 value of a US dollar.',
  Daily: 'Daily',
  Danish: 'Danish',
  Dark: 'Dark',
  'Dark mode': 'Dark mode',
  Date: 'Date',
  Days: 'Days',
  DeFi: 'DeFi',
  'Deactivate staking': 'Deactivate staking',
  'Deactivate staking on': 'Deactivate staking on',
  'Deactivation registered': 'Deactivation registered',
  'Dear {0}! {1} has given you a gift card from Firi 💙 {2}Redeem the gift card by following the link below. {3}We at Firi hope this gift in cryptocurrency never stops giving! {4}{landingUrl}/giftcard/?code={5}':
    [
      'Dear ',
      ['0'],
      '! ',
      ['1'],
      ' has given you a gift card from Firi 💙 ',
      ['2'],
      'Redeem the gift card by following the link below. ',
      ['3'],
      'We at Firi hope this gift in cryptocurrency never stops giving! ',
      ['4'],
      ['landingUrl'],
      '/giftcard/?code=',
      ['5'],
    ],
  'Decentralized finance': 'Decentralized finance',
  'Decline everything': 'Decline everything',
  'Dedicated crypto investor': 'Dedicated crypto investor',
  'Deductible loss': 'Deductible loss',
  'Delete file': 'Delete file',
  Denmark: 'Denmark',
  Deposit: 'Deposit',
  'Deposit completed!': 'Deposit completed!',
  'Deposit crypto': 'Deposit crypto',
  'Deposit from bank': 'Deposit from bank',
  'Deposit money': 'Deposit money',
  'Deposit of {0} not available': ['Deposit of ', ['0'], ' not available'],
  'Deposit of {currency} not available': ['Deposit of ', ['currency'], ' not available'],
  'Deposit on': 'Deposit on',
  'Deposit over {0} {currency} into your Firi account.': [
    'Deposit over ',
    ['0'],
    ' ',
    ['currency'],
    ' into your Firi account.',
  ],
  'Deposit registered': 'Deposit registered',
  'Deposit with Apple Pay': 'Deposit with Apple Pay',
  'Deposit with Google Pay': 'Deposit with Google Pay',
  'Deposit with MobilePay': 'Deposit with MobilePay',
  'Deposit with Vipps': 'Deposit with Vipps',
  'Deposit with card': 'Deposit with card',
  'Deposit {0}': ['Deposit ', ['0']],
  Deposits: 'Deposits',
  'Deposits from online banking or Open Banking are free. It usually takes 1-2 banking days before you receive the money.':
    'Deposits from online banking or Open Banking are free. It usually takes 1-2 banking days before you receive the money.',
  'Deposits to your savings account are automatically converted to cryptocurrency through Firi':
    'Deposits to your savings account are automatically converted to cryptocurrency through Firi',
  Destination: 'Destination',
  'Destination Tag': 'Destination Tag',
  'Destination tag': 'Destination tag',
  'Detail on your purchases': 'Detail on your purchases',
  Details: 'Details',
  'Details about your order': 'Details about your order',
  Development: 'Development',
  'Did not receive the email? Feel free to check the spam as well, sometimes it hides there.':
    'Did not receive the email? Feel free to check the spam as well, sometimes it hides there.',
  'Did you know that the word staking comes from what are called proof-of-stake blockchain? Ethereum and Cardano are examples of such blockchains.':
    'Did you know that the word staking comes from what are called proof-of-stake blockchain? Ethereum and Cardano are examples of such blockchains.',
  'Did you know that you can earn rewards when you shop and learn more about crypto?':
    'Did you know that you can earn rewards when you shop and learn more about crypto?',
  'Did you start staking this week? You will then receive the reward next week. Do you want us to notify you?':
    'Did you start staking this week? You will then receive the reward next week. Do you want us to notify you?',
  'Did you start staking this week? You will then receive the reward next week. We will let you know!':
    'Did you start staking this week? You will then receive the reward next week. We will let you know!',
  'Direct deposit': 'Direct deposit',
  'Direct deposit fee is required to cover our costs.':
    'Direct deposit fee is required to cover our costs.',
  'Direct purchase': 'Direct purchase',
  Disable: 'Disable',
  'Disable two-factor': 'Disable two-factor',
  Distribution: 'Distribution',
  'Distribution is too low': 'Distribution is too low',
  'Do you have an affiliate code?': 'Do you have an affiliate code?',
  'Do you trade crypto on other exchanges/platforms besides Firi?':
    'Do you trade crypto on other exchanges/platforms besides Firi?',
  'Do you want to earn more on your crypto?': 'Do you want to earn more on your crypto?',
  'Do you want to include {0} when we show the value of your holdings?': [
    'Do you want to include ',
    ['0'],
    ' when we show the value of your holdings?',
  ],
  'Do you want to log out of Firi?': 'Do you want to log out of Firi?',
  'Do you want to receive notifications about your account activities, new products and the latest crypto news?':
    'Do you want to receive notifications about your account activities, new products and the latest crypto news?',
  'Do you want us to let you know when the hatch opens?':
    'Do you want us to let you know when the hatch opens?',
  'Do you want us to notify you when the transfer is completed?':
    'Do you want us to notify you when the transfer is completed?',
  Documentation: 'Documentation',
  'Does Firi have any shortcomings today, or is there something we can do better?':
    'Does Firi have any shortcomings today, or is there something we can do better?',
  'Dogecoin (DOGE) was launched in 2013 and started as a humorous internet phenomenon. It is the leading cryptocurrency in the category of "meme coins" and is known for its logo featuring the Shiba Inu dog from the "Doge" meme.':
    'Dogecoin (DOGE) was launched in 2013 and started as a humorous internet phenomenon. It is the leading cryptocurrency in the category of "meme coins" and is known for its logo featuring the Shiba Inu dog from the "Doge" meme.',
  'Dogecoin network': 'Dogecoin network',
  "Don't have a user?": "Don't have a user?",
  "Don't have an account": "Don't have an account",
  Donate: 'Donate',
  Donation: 'Donation',
  Donations: 'Donations',
  Done: 'Done',
  'Done!': 'Done!',
  'Don’t miss our new features': 'Don’t miss our new features',
  'Double bonus when you invite a friend ✨': 'Double bonus when you invite a friend ✨',
  'Dowload app in App Store or Google Play': 'Dowload app in App Store or Google Play',
  Download: 'Download',
  'Download 2FA-app': 'Download 2FA-app',
  'Download Google Authenticator': 'Download Google Authenticator',
  'Download PDF': 'Download PDF',
  'Download and save “Skattemelding 2020.pdf”': 'Download and save “Skattemelding 2020.pdf”',
  'Download failed': 'Download failed',
  'Download here': 'Download here',
  'Download our app': 'Download our app',
  'Download our free app and start your crypto journey, safe and easy.':
    'Download our free app and start your crypto journey, safe and easy.',
  'Download tax attachment': 'Download tax attachment',
  'Download tax documentation': 'Download tax documentation',
  'Download the Firi-app on mobile': 'Download the Firi-app on mobile',
  'Download the Firi-app to get started and get {referralAmount} {0} to buy your first cryptocurrency with Firi and start your investments.':
    [
      'Download the Firi-app to get started and get ',
      ['referralAmount'],
      ' ',
      ['0'],
      ' to buy your first cryptocurrency with Firi and start your investments.',
    ],
  'Download the Firi-app!': 'Download the Firi-app!',
  'Download the app and register easily with Vipps.':
    'Download the app and register easily with Vipps.',
  'Download the app now': 'Download the app now',
  'Download the necessary attachments from the tax calculator. You will find all the documents you need as pdf files.':
    'Download the necessary attachments from the tax calculator. You will find all the documents you need as pdf files.',
  'Download the new version of the app': 'Download the new version of the app',
  'Downloadable content': 'Downloadable content',
  'Downloads are also available on web': 'Downloads are also available on web',
  'Drag & drop or': 'Drag & drop or',
  'Earn crypto rewards by being active, such as depositing money or making your first purchase.':
    'Earn crypto rewards by being active, such as depositing money or making your first purchase.',
  'Earn even more on your crypto': 'Earn even more on your crypto',
  'Earn more on your crypto': 'Earn more on your crypto',
  'Earn more on your crypto with staking': 'Earn more on your crypto with staking',
  'Earn rewards': 'Earn rewards',
  'Earn rewards by staking your crypto': 'Earn rewards by staking your crypto',
  'Earn rewards now': 'Earn rewards now',
  'Earn rewards on': 'Earn rewards on',
  'Earn rewards on {0}': ['Earn rewards on ', ['0']],
  'Earn rewards with Firi Fordel': 'Earn rewards with Firi Fordel',
  'Earn tickets': 'Earn tickets',
  'Earned rewards': 'Earned rewards',
  'Easter egg hunt 2022': 'Easter egg hunt 2022',
  Edit: 'Edit',
  'Edit permissions': 'Edit permissions',
  'Edit purchase price': 'Edit purchase price',
  Email: 'Email',
  'Email address': 'Email address',
  'Email sent': 'Email sent',
  'Enable notifications': 'Enable notifications',
  English: 'English',
  'Enter gift card code:': 'Enter gift card code:',
  'Enter or scan the address of the external wallet to which you want to send {currencyName} ({currency}).':
    [
      'Enter or scan the address of the external wallet to which you want to send ',
      ['currencyName'],
      ' (',
      ['currency'],
      ').',
    ],
  'Enter the code generated by your authenticator application':
    'Enter the code generated by your authenticator application',
  'Enter the code on your gift card.': 'Enter the code on your gift card.',
  'Enter the price for 1 {0} at the time of the purchase': [
    'Enter the price for 1 ',
    ['0'],
    ' at the time of the purchase',
  ],
  'Enter the price for {0} {1} at the time of the purchase': [
    'Enter the price for ',
    ['0'],
    ' ',
    ['1'],
    ' at the time of the purchase',
  ],
  'Enter the value <0/> in box {0}': ['Enter the value <0/> in box ', ['0']],
  'Enter the value <0>{0} kr</0> in box 20.': ['Enter the value <0>', ['0'], ' kr</0> in box 20.'],
  'Enter the value <0>{0} kr</0> in box 58.': ['Enter the value <0>', ['0'], ' kr</0> in box 58.'],
  'Enter you social security number': 'Enter you social security number',
  'Enter your CPR number': 'Enter your CPR number',
  'Enter your email address below and we will help you reset your password.':
    'Enter your email address below and we will help you reset your password.',
  'Enter your total gains and income.': 'Enter your total gains and income.',
  'Enter your total losses.': 'Enter your total losses.',
  'Error message:': 'Error message:',
  'Essential information': 'Essential information',
  'Essentially, cryptocurrency is decentralized and digital money or assets that can be used by anyone and sent freely and directly between individuals.':
    'Essentially, cryptocurrency is decentralized and digital money or assets that can be used by anyone and sent freely and directly between individuals.',
  'Estimated staking value': 'Estimated staking value',
  'Estimated time for withdraw': 'Estimated time for withdraw',
  'Estimated time for withdraw: {time}': ['Estimated time for withdraw: ', ['time']],
  'Estimated value': 'Estimated value',
  'Ethereum is a protocol built on blockchain technology which is also best known for its ten-digit cryptocurrency called ETH, Ether or Ethereum.':
    'Ethereum is a protocol built on blockchain technology which is also best known for its ten-digit cryptocurrency called ETH, Ether or Ethereum.',
  'Ethereum is also a so-called smart-contract platform with its own programming language that enables people to create decentralized applications on the Ethereum blockchain.':
    'Ethereum is also a so-called smart-contract platform with its own programming language that enables people to create decentralized applications on the Ethereum blockchain.',
  'Ethereum network (ERC20)': 'Ethereum network (ERC20)',
  'Even more rewards': 'Even more rewards',
  'Every day': 'Every day',
  'Every day from December 1st to 24th, you can win between 500 and 1000 kroner in crypto, or exclusive Firi merch. 🎁 For each correct answer, you get a chance to win the grand prize on Christmas Eve. ✨':
    'Every day from December 1st to 24th, you can win between 500 and 1000 kroner in crypto, or exclusive Firi merch. 🎁 For each correct answer, you get a chance to win the grand prize on Christmas Eve. ✨',
  'Every friday': 'Every friday',
  'Every month': 'Every month',
  'Every other week': 'Every other week',
  'Every two weeks': 'Every two weeks',
  'Every week': 'Every week',
  'Every {0}. day': ['Every ', ['0'], '. day'],
  Everything: 'Everything',
  'Everything is in place!': 'Everything is in place!',
  'Example from Google Authenticator': 'Example from Google Authenticator',
  'Exchange rate': 'Exchange rate',
  'Expected annual yield': 'Expected annual yield',
  'Expected annual yield is an estimate and will vary over time.':
    'Expected annual yield is an estimate and will vary over time.',
  'Expected annual yield on staked crypto is an estimate and may vary over time.':
    'Expected annual yield on staked crypto is an estimate and may vary over time.',
  'Expected annual yield refers to the increase in value your staked investment is expected to give over time.':
    'Expected annual yield refers to the increase in value your staked investment is expected to give over time.',
  'Expected yield per year': 'Expected yield per year',
  Expenses: 'Expenses',
  Expired: 'Expired',
  Expires: 'Expires',
  Explore: 'Explore',
  'Export CSV': 'Export CSV',
  'Export account statement': 'Export account statement',
  'Export annual statement': 'Export annual statement',
  'Export your transactions e.g for tax decleration':
    'Export your transactions e.g for tax decleration',
  'Extra transfer': 'Extra transfer',
  FAQ: 'FAQ',
  'Facial recognition': 'Facial recognition',
  'Failed to create user': 'Failed to create user',
  'Failed to decode lightning invoice': 'Failed to decode lightning invoice',
  Fastest: 'Fastest',
  Favorites: 'Favorites',
  Fee: 'Fee',
  'Fee for direct purchase is 3.9%.': 'Fee for direct purchase is 3.9%.',
  'Fee for direct purchase is {0} percent.': ['Fee for direct purchase is ', ['0'], ' percent.'],
  'Fee will be added': 'Fee will be added',
  'Feeback bonus': 'Feeback bonus',
  Fees: 'Fees',
  'Fees on trading': 'Fees on trading',
  Files: 'Files',
  'Fill in the confirmation code': 'Fill in the confirmation code',
  'Fill in the information in the tax return and attach the documentation that you have received from the tax calculator':
    'Fill in the information in the tax return and attach the documentation that you have received from the tax calculator',
  'Fill out your profile': 'Fill out your profile',
  Filter: 'Filter',
  'Finally...': 'Finally...',
  Firi: 'Firi',
  'Firi + Staking = 💙': 'Firi + Staking = 💙',
  'Firi - Nordic largest crypto exchange': 'Firi - Nordic largest crypto exchange',
  'Firi AS': 'Firi AS',
  'Firi Affiliate': 'Firi Affiliate',
  'Firi Blue': 'Firi Blue',
  'Firi Christmas': 'Firi Christmas',
  'Firi Fordel': 'Firi Fordel',
  'Firi Grey': 'Firi Grey',
  'Firi Reward': 'Firi Reward',
  'Firi Reward gives you the opportunity to earn crypto rewards by completing various tasks, such as:':
    'Firi Reward gives you the opportunity to earn crypto rewards by completing various tasks, such as:',
  'Firi White': 'Firi White',
  'Firi current posts': 'Firi current posts',
  'Firi guest author': 'Firi guest author',
  "Firi is Norway's largest service for buying and selling cryptocurrencies. With us you will find, among other things, bitcoin, ethereum and cardano. We make the cryptic easy by discovering new and better ways to save, invest and learn about digital currency.":
    "Firi is Norway's largest service for buying and selling cryptocurrencies. With us you will find, among other things, bitcoin, ethereum and cardano. We make the cryptic easy by discovering new and better ways to save, invest and learn about digital currency.",
  'Firi is a Norwegian cryptocurrency exchange, registered by Finanstilsynet':
    'Firi is a Norwegian cryptocurrency exchange, registered by Finanstilsynet',
  'Firi is a Norwegian cryptocurrency exchange. With us you can sell and buy cryptocurrencies, such as bitcoin, ethereum and cardano. We make the cryptic easy by finding new and better ways to save, invest and learn about digital currency.':
    'Firi is a Norwegian cryptocurrency exchange. With us you can sell and buy cryptocurrencies, such as bitcoin, ethereum and cardano. We make the cryptic easy by finding new and better ways to save, invest and learn about digital currency.',
  'Firi is registered as a service provider for exchanging and storing virtual currency in Norway.':
    'Firi is registered as a service provider for exchanging and storing virtual currency in Norway.',
  'Firi is registered as an exchange with Finanstilsynet, the Financial Supervisory Authority (FSA). We are required to gather information about our users, the same way that banks, real estate agents and the rest of the financial industry must do.':
    'Firi is registered as an exchange with Finanstilsynet, the Financial Supervisory Authority (FSA). We are required to gather information about our users, the same way that banks, real estate agents and the rest of the financial industry must do.',
  'Firi is registered with Finanstilsynet': 'Firi is registered with Finanstilsynet',
  'Firi is required by law to obtain information about you as a customer and carry out ongoing follow-up of customer relationships. This is stated, among other things, in our <0>terms of use</0> and the <1>privacy policy</1>':
    'Firi is required by law to obtain information about you as a customer and carry out ongoing follow-up of customer relationships. This is stated, among other things, in our <0>terms of use</0> and the <1>privacy policy</1>',
  'Firi periodically has to ask all our customers to update their information. This is a part of our obligations as a service provider for exchanging and depositing virtual currency in Norway.':
    'Firi periodically has to ask all our customers to update their information. This is a part of our obligations as a service provider for exchanging and depositing virtual currency in Norway.',
  'Firi takes no responsibility for any loss of money.':
    'Firi takes no responsibility for any loss of money.',
  'Firi’s website, firi.com, may include links to third-party websites, plug-ins, and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you.':
    'Firi’s website, firi.com, may include links to third-party websites, plug-ins, and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you.',
  'First deposit': 'First deposit',
  'First name': 'First name',
  'First saving': 'First saving',
  'First trade': 'First trade',
  'Follow system settings': 'Follow system settings',
  'For a faster review, double-check that your information is correct.':
    'For a faster review, double-check that your information is correct.',
  'For security and regulatory reasons, we must confirm where your money is going. This helps ensure a safe and legal transfer.':
    'For security and regulatory reasons, we must confirm where your money is going. This helps ensure a safe and legal transfer.',
  'Forgot password?': 'Forgot password?',
  Fortune: 'Fortune',
  'Free crypto? Yes.': 'Free crypto? Yes.',
  From: 'From',
  'From (year)': 'From (year)',
  'From account': 'From account',
  'From start': 'From start',
  'From the next app update we only support iOS 15+. Update your iOS to get the latest updates and the best possible experience.':
    'From the next app update we only support iOS 15+. Update your iOS to get the latest updates and the best possible experience.',
  'From {0} to {1}': ['From ', ['0'], ' to ', ['1']],
  'Funds processing': 'Funds processing',
  'Funds under processing': 'Funds under processing',
  'Gambling without permission': 'Gambling without permission',
  'Gaming & Web3': 'Gaming & Web3',
  'Gaming/Web3': 'Gaming/Web3',
  'Get full access to our services and start trading cryptocurrencies today':
    'Get full access to our services and start trading cryptocurrencies today',
  'Get notifications on crypto news': 'Get notifications on crypto news',
  'Get ready for crypto summer!': 'Get ready for crypto summer!',
  'Get started': 'Get started',
  'Get started with MitID on our web platform': 'Get started with MitID on our web platform',
  'Get started with Vipps on our web platform': 'Get started with Vipps on our web platform',
  "Get started with Vipps. With Vipps, your profile is automatically filled out. Sign with BankID and you're up and running, safe and easy!":
    "Get started with Vipps. With Vipps, your profile is automatically filled out. Sign with BankID and you're up and running, safe and easy!",
  'Get started with crypto savings!': 'Get started with crypto savings!',
  'Get started with our guide': 'Get started with our guide',
  'Get started with staking': 'Get started with staking',
  'Get up to {0}% expected annual return on crypto stored in this account.': [
    'Get up to ',
    ['0'],
    '% expected annual return on crypto stored in this account.',
  ],
  'Get welcome bonus': 'Get welcome bonus',
  'Get your bonus in app': 'Get your bonus in app',
  'Get {0} {1} in {payoutCurrencyName} and give your friend {2} {3} in welcome bonus': [
    'Get ',
    ['0'],
    ' ',
    ['1'],
    ' in ',
    ['payoutCurrencyName'],
    ' and give your friend ',
    ['2'],
    ' ',
    ['3'],
    ' in welcome bonus',
  ],
  'Gift card': 'Gift card',
  'Gift card code': 'Gift card code',
  'Gift card created': 'Gift card created',
  'Gift card is redeemed': 'Gift card is redeemed',
  'Gift card not found...': 'Gift card not found...',
  'Gift from your main account': 'Gift from your main account',
  'Giftcard is redeemed': 'Giftcard is redeemed',
  'Give access to the camera': 'Give access to the camera',
  'Give away crypto you already have. Remember that you must have enough crypto in your main account.':
    'Give away crypto you already have. Remember that you must have enough crypto in your main account.',
  'Go to box 20 (Other personal income). If you have not completed the box before, you will find it under Other income and then Deductions.':
    'Go to box 20 (Other personal income). If you have not completed the box before, you will find it under Other income and then Deductions.',
  'Go to box 58 (Other expenses). If you do not have box 58 on your annual tax assessment you should contact SKAT and request that it be opened.':
    'Go to box 58 (Other expenses). If you do not have box 58 on your annual tax assessment you should contact SKAT and request that it be opened.',
  'Go to login': 'Go to login',
  'Go to my bank accounts': 'Go to my bank accounts',
  'Go to your inbox in Altinn': 'Go to your inbox in Altinn',
  'Google Pay': 'Google Pay',
  'Google Pay fee': 'Google Pay fee',
  'Google Play Store': 'Google Play Store',
  'Google reCAPTCHA is not verified or expired': 'Google reCAPTCHA is not verified or expired',
  'Got it!': 'Got it!',
  'Green crypto': 'Green crypto',
  'Guest author': 'Guest author',
  'Guidance for filling in the tax return': 'Guidance for filling in the tax return',
  'Guide to filling out the annual tax assessment':
    'Guide to filling out the annual tax assessment',
  'Has Firi been a source for cryptocurrency education and information?':
    'Has Firi been a source for cryptocurrency education and information?',
  'Have an account?': 'Have an account?',
  'Have you been referred by a friend?': 'Have you been referred by a friend?',
  'Have you ever wondered what the average price of your investments is? Or if you hit the market at a low price? Now you get this gathered clearly under each coin you own, so you can make better evaluations around your investments.':
    'Have you ever wondered what the average price of your investments is? Or if you hit the market at a low price? Now you get this gathered clearly under each coin you own, so you can make better evaluations around your investments.',
  'Have you got ideas or thoughts on how we can improve? Take the chance to share your thoughts in our survey. It takes only 5–7 minutes!':
    'Have you got ideas or thoughts on how we can improve? Take the chance to share your thoughts in our survey. It takes only 5–7 minutes!',
  'Have you lost crypto, but are told to enter 0 in losses?':
    'Have you lost crypto, but are told to enter 0 in losses?',
  'Have you received a gift card? How nice! Enter the code here:':
    'Have you received a gift card? How nice! Enter the code here:',
  'Have you recently done a deposit': 'Have you recently done a deposit',
  'Hei, {0}!': ['Hei, ', ['0'], '!'],
  'Help med download 2FA-app': 'Help med download 2FA-app',
  'Help us to give you the best Firi-experience': 'Help us to give you the best Firi-experience',
  'Here are some other pages you could try instead:':
    'Here are some other pages you could try instead:',
  "Here we have gathered insightful articles and news, carefully curated. Whether you're an experienced investor or new to crypto, Utforsk provides you with the information you need to stay updated on the latest developments.":
    "Here we have gathered insightful articles and news, carefully curated. Whether you're an experienced investor or new to crypto, Utforsk provides you with the information you need to stay updated on the latest developments.",
  'Here you can download documentation for your tax returns':
    'Here you can download documentation for your tax returns',
  'Here you can download documentation to your tax report':
    'Here you can download documentation to your tax report',
  'Here you can upload relevant documentation. Your documentation is always safely stored with us.':
    'Here you can upload relevant documentation. Your documentation is always safely stored with us.',
  'Here you will find deposits that you have not bought from Firi. In order for the calculation of the tax return to be correct, you must enter what the value was when you bought it.':
    'Here you will find deposits that you have not bought from Firi. In order for the calculation of the tax return to be correct, you must enter what the value was when you bought it.',
  'Here you will find deposits that you have not bought from Firi. In order for the calculation of the tax return to be correct, you must enter what the value was when you bought it. This is usually found in the transaction history of the service in question.':
    'Here you will find deposits that you have not bought from Firi. In order for the calculation of the tax return to be correct, you must enter what the value was when you bought it. This is usually found in the transaction history of the service in question.',
  "Here you'll find crypto address you store when sending crypto":
    "Here you'll find crypto address you store when sending crypto",
  "Here's an <0>example</0> of what the 6 digit code might look like in Google Authenticator. Such a code must be copied and pasted into the Firi app or entered manually. Important! The code is updated periodically, so check that you enter the correct code.":
    "Here's an <0>example</0> of what the 6 digit code might look like in Google Authenticator. Such a code must be copied and pasted into the Firi app or entered manually. Important! The code is updated periodically, so check that you enter the correct code.",
  "Hi! I would like to tell you about Firi. I myself use Norway's largest cryptocurrency exchange to invest in the currency of the future. Now you get up to 265 {0} in bitcoin, ethereum and other cryptocurrencies to test out Firi through my invitation. What are you waiting for?":
    [
      "Hi! I would like to tell you about Firi. I myself use Norway's largest cryptocurrency exchange to invest in the currency of the future. Now you get up to 265 ",
      ['0'],
      ' in bitcoin, ethereum and other cryptocurrencies to test out Firi through my invitation. What are you waiting for?',
    ],
  'Hide other pairs': 'Hide other pairs',
  'High demand': 'High demand',
  'High level of knowledge': 'High level of knowledge',
  'Highest (24h)': 'Highest (24h)',
  'Highest price': 'Highest price',
  'Highest value': 'Highest value',
  'Historic yield': 'Historic yield',
  'Hm, no question found. Try to start over': 'Hm, no question found. Try to start over',
  'Ho ho, you got it right!': 'Ho ho, you got it right!',
  'Ho, nooo!': 'Ho, nooo!',
  'Ho-ho-hodl be ready for this Christmas biggest crypto happening!':
    'Ho-ho-hodl be ready for this Christmas biggest crypto happening!',
  Holding: 'Holding',
  Holdings: 'Holdings',
  Home: 'Home',
  'Hope you like our new update!': 'Hope you like our new update!',
  'How are you doing this year?': 'How are you doing this year?',
  'How do I get stared?': 'How do I get stared?',
  'How do I redeem the gift card?': 'How do I redeem the gift card?',
  'How do I stake?': 'How do I stake?',
  'How do I stay current on crypto?': 'How do I stay current on crypto?',
  'How do I trade crypto with Firi?': 'How do I trade crypto with Firi?',
  'How do i stake {currency}?': ['How do i stake ', ['currency'], '?'],
  'How do you want to buy cryptocurrency?': 'How do you want to buy cryptocurrency?',
  'How do you want to deposit money?': 'How do you want to deposit money?',
  'How do you want to get started?': 'How do you want to get started?',
  'How do you want to gift crypto?': 'How do you want to gift crypto?',
  'How do you want to stake {currency}?': ['How do you want to stake ', ['currency'], '?'],
  'How do you want to stake?': 'How do you want to stake?',
  'How does it work': 'How does it work',
  'How is the exchange rate determined?': 'How is the exchange rate determined?',
  'How much do you want to transfer?': 'How much do you want to transfer?',
  'How nice!': 'How nice!',
  'How nice! You have received a gift in crypto': 'How nice! You have received a gift in crypto',
  'How often do you want to save?': 'How often do you want to save?',
  'How the amount is deduced': 'How the amount is deduced',
  'How to deactivate staking': 'How to deactivate staking',
  'How to enter gains and income in box 20:': 'How to enter gains and income in box 20:',
  'How to enter losses in box 58:': 'How to enter losses in box 58:',
  'How to invite friends?': 'How to invite friends?',
  'How to set up the savings agreement in your bank':
    'How to set up the savings agreement in your bank',
  'How to understand rewards and development': 'How to understand rewards and development',
  'How we process your personal information': 'How we process your personal information',
  'I accept': 'I accept',
  'I already have a 2FA-app': 'I already have a 2FA-app',
  'I am buying': 'I am buying',
  'I am buying for': 'I am buying for',
  'I am selling': 'I am selling',
  'I am selling for': 'I am selling for',
  'I am sending': 'I am sending',
  'I am uncertain about the future of crypto': 'I am uncertain about the future of crypto',
  "I didn't quite understand, can I find more?": "I didn't quite understand, can I find more?",
  "I don't have the capital available to invest more":
    "I don't have the capital available to invest more",
  "I don't want to upload documentation": "I don't want to upload documentation",
  'I dont accept': 'I dont accept',
  'I have an interest in blockchain technology': 'I have an interest in blockchain technology',
  'I have created a recurring transfer from my bank':
    'I have created a recurring transfer from my bank',
  'I have downloaded a 2FA-app': 'I have downloaded a 2FA-app',
  'I have earned': 'I have earned',
  'I have read and agree to the': 'I have read and agree to the',
  'I have read the <0>privacy policy</0> and give my consent to store the information specified in the policy':
    'I have read the <0>privacy policy</0> and give my consent to store the information specified in the policy',
  'I have referred': 'I have referred',
  'I have sufficient exposure to crypto now': 'I have sufficient exposure to crypto now',
  'I lack sufficient knowledge to choose / it is overwhelming':
    'I lack sufficient knowledge to choose / it is overwhelming',
  'I perceive crypto as a risky investment': 'I perceive crypto as a risky investment',
  'I understand': 'I understand',
  'I understand that investing in meme coins involves high risk and that I am responsible for my own investments.':
    'I understand that investing in meme coins involves high risk and that I am responsible for my own investments.',
  'I want to gain short-term profits': 'I want to gain short-term profits',
  'I want to join!': 'I want to join!',
  'I want to know more': 'I want to know more',
  'I want to learn about crypto': 'I want to learn about crypto',
  'I want to save in crypto (alongside savings account/funds/stocks)':
    'I want to save in crypto (alongside savings account/funds/stocks)',
  'If the error is still occurs, please contact our support team':
    'If the error is still occurs, please contact our support team',
  "If we don't have your bank": "If we don't have your bank",
  'If you are already a user, continue login in your market':
    'If you are already a user, continue login in your market',
  'If you believe that our processing of personal information does not match what we have described here or in other ways violate privacy laws, you may appeal to The Norwegian Data Protection Authority.':
    'If you believe that our processing of personal information does not match what we have described here or in other ways violate privacy laws, you may appeal to The Norwegian Data Protection Authority.',
  'If you choose to buy for more then the size of the cheapest order, you will automatically buy from the next order in the queue. That is why a trade can consist of several orders.':
    'If you choose to buy for more then the size of the cheapest order, you will automatically buy from the next order in the queue. That is why a trade can consist of several orders.',
  'If you choose to sell for more then the cheapest order, you will automatically sell from the next order in the queue. That is why a trade can consist of several orders.':
    'If you choose to sell for more then the cheapest order, you will automatically sell from the next order in the queue. That is why a trade can consist of several orders.',
  'If you do not want to receive notifications about the calendar, you can easily turn this off under settings':
    'If you do not want to receive notifications about the calendar, you can easily turn this off under settings',
  'If you do not want to use 2FA, we recommend that you register with Vipps instead.':
    'If you do not want to use 2FA, we recommend that you register with Vipps instead.',
  'If you have a referral code you can enter it here':
    'If you have a referral code you can enter it here',
  'If you have recently made a deposit, we might still be in the process of receiving the latest information from your bank. We usually receive this information one working day after the deposit.':
    'If you have recently made a deposit, we might still be in the process of receiving the latest information from your bank. We usually receive this information one working day after the deposit.',
  'If you use the app, you can redeem the gift card by going to More and then Gift card. If you are logged in to the web, then you can click on your name and then Gift card.':
    'If you use the app, you can redeem the gift card by going to More and then Gift card. If you are logged in to the web, then you can click on your name and then Gift card.',
  'If you want documentation of your tax calculation, you can download it by clicking "Download" in the upper right corner of our tax calculation page.':
    'If you want documentation of your tax calculation, you can download it by clicking "Download" in the upper right corner of our tax calculation page.',
  'If you want to download documentation for the tax return from Firi, you can do this by clicking on the "Download" button at the top right of the tax calculation page. The tax authorities require you to submit this together with the tax return.':
    'If you want to download documentation for the tax return from Firi, you can do this by clicking on the "Download" button at the top right of the tax calculation page. The tax authorities require you to submit this together with the tax return.',
  'If you want to download documentation for the tax return from Firi, you do this by clicking on the "Download" button at the top right of the tax calculation page. The tax authorities require you to submit this together with the tax return.':
    'If you want to download documentation for the tax return from Firi, you do this by clicking on the "Download" button at the top right of the tax calculation page. The tax authorities require you to submit this together with the tax return.',
  'If you want to save and print the gift card, follow the link that you have received by e-mail.':
    'If you want to save and print the gift card, follow the link that you have received by e-mail.',
  'If you want to use Firis crypto tax, you need to enter the price you purchased this crypto for. The purchase price can be found at the service you used.':
    'If you want to use Firis crypto tax, you need to enter the price you purchased this crypto for. The purchase price can be found at the service you used.',
  'If you withdraw your consent to store your BankID information your account will be\ndowngraded to an unverified account and you can no longer trade or make transactions.\nThe stored information will be deleted without undue delay in accordance with our legal\nobligations. Are you sure?':
    'If you withdraw your consent to store your BankID information your account will be\ndowngraded to an unverified account and you can no longer trade or make transactions.\nThe stored information will be deleted without undue delay in accordance with our legal\nobligations. Are you sure?',
  'If you withdraw your consent to store your KYC-form information your account will be\ndowngraded to an unverified account and you can no longer trade or make transactions.\nThe stored information will be deleted without undue delay in accordance with our legal\nobligations. Are you sure?':
    'If you withdraw your consent to store your KYC-form information your account will be\ndowngraded to an unverified account and you can no longer trade or make transactions.\nThe stored information will be deleted without undue delay in accordance with our legal\nobligations. Are you sure?',
  'If you withdraw your consent to store your profile information your account will be\nsuspended as we require this information to provide you services. The stored information\nwill be deleted without undue delay in accordance with our legal obligations. Are you\nsure?':
    'If you withdraw your consent to store your profile information your account will be\nsuspended as we require this information to provide you services. The stored information\nwill be deleted without undue delay in accordance with our legal obligations. Are you\nsure?',
  Images: 'Images',
  Immediately: 'Immediately',
  'Important information': 'Important information',
  'Important information!': 'Important information!',
  'Important update': 'Important update',
  'In order to meet the requirements of the authorities, we must also verify your identity with facial recognition. Thank you for helping us secure your account!':
    'In order to meet the requirements of the authorities, we must also verify your identity with facial recognition. Thank you for helping us secure your account!',
  'In some cases a withdrawal may require longer processing time and it will be labeled "requested" in your overview until it has been sent.':
    'In some cases a withdrawal may require longer processing time and it will be labeled "requested" in your overview until it has been sent.',
  'In the tax return field': 'In the tax return field',
  'In the tax return field<0> Capital value </0> enter the value <1>{0}kr</1>.': [
    'In the tax return field<0> Capital value </0> enter the value <1>',
    ['0'],
    'kr</1>.',
  ],
  'In the tax return field<0> Deductible loss </0> enter the value <1>{0} kr</1>.': [
    'In the tax return field<0> Deductible loss </0> enter the value <1>',
    ['0'],
    ' kr</1>.',
  ],
  'In the tax return field<0> Other income from virtual assets / crypto currency </0> enter the value <1>{0} kr</1>.':
    [
      'In the tax return field<0> Other income from virtual assets / crypto currency </0> enter the value <1>',
      ['0'],
      ' kr</1>.',
    ],
  'In the tax return field<0> Taxable gain </0> enter the value <1>{0} kr</1>.': [
    'In the tax return field<0> Taxable gain </0> enter the value <1>',
    ['0'],
    ' kr</1>.',
  ],
  'In your tax return, find the topic "Finance" and the item "Management costs". In the "Securities management" field, enter the amount of {0} {1}':
    [
      'In your tax return, find the topic "Finance" and the item "Management costs". In the "Securities management" field, enter the amount of ',
      ['0'],
      ' ',
      ['1'],
    ],
  'Include {0} in balance': ['Include ', ['0'], ' in balance'],
  Income: 'Income',
  Incoming: 'Incoming',
  Insight: 'Insight',
  'Insufficient funds': 'Insufficient funds',
  'Insured with Firi': 'Insured with Firi',
  'Internal transfer': 'Internal transfer',
  'Introducing the gaming/web3 category!': 'Introducing the gaming/web3 category!',
  'Invalid account number': 'Invalid account number',
  'Invalid address': 'Invalid address',
  'Invalid code': 'Invalid code',
  'Invalid distribution': 'Invalid distribution',
  'Invalid email': 'Invalid email',
  'Invalid referral code': 'Invalid referral code',
  'Invalid session': 'Invalid session',
  'Invalid tag': 'Invalid tag',
  'Invalid verification code': 'Invalid verification code',
  'Invalid {0} address': ['Invalid ', ['0'], ' address'],
  'Invalid {currencyName} address': ['Invalid ', ['currencyName'], ' address'],
  Invest: 'Invest',
  "Invest a fixed amount in cryptocurrency at a fixed time with a direct debit from your bank. We'll take care of the rest!":
    "Invest a fixed amount in cryptocurrency at a fixed time with a direct debit from your bank. We'll take care of the rest!",
  'Invest in crypto now': 'Invest in crypto now',
  'Invest in crypto whenever you want': 'Invest in crypto whenever you want',
  'Invest in {currency}': ['Invest in ', ['currency']],
  'Invite friends': 'Invite friends',
  'Invite friends and receive {0} {referralCurrency} in {payoutCurrencyName} for each friend who verifies their account. Whoever you invite gets a welcome bonus of {1} {referralCurrency}.':
    [
      'Invite friends and receive ',
      ['0'],
      ' ',
      ['referralCurrency'],
      ' in ',
      ['payoutCurrencyName'],
      ' for each friend who verifies their account. Whoever you invite gets a welcome bonus of ',
      ['1'],
      ' ',
      ['referralCurrency'],
      '.',
    ],
  'Invite friends to Firi': 'Invite friends to Firi',
  'Invite friends to Firi and earn bitcoin ⭐': 'Invite friends to Firi and earn bitcoin ⭐',
  'Invite with link': 'Invite with link',
  'Is my crypto safe with Firi?': 'Is my crypto safe with Firi?',
  'Is there a high risk of significant loss when investing in meme coins?':
    'Is there a high risk of significant loss when investing in meme coins?',
  'Is there something that does not seem right here?':
    'Is there something that does not seem right here?',
  'Is there something that does not seem right here? Contact our support on chat!':
    'Is there something that does not seem right here? Contact our support on chat!',
  'It can take up to 1-2 days from the amount being deducted from your bank until the cryptocurrency is visible in your savings account.':
    'It can take up to 1-2 days from the amount being deducted from your bank until the cryptocurrency is visible in your savings account.',
  'It is currently high demand and few that wishes to sell {currency}. To avoid risking you buy for an expensive price, we want to inform you that the price differentiate approx. <0>{absolutePercent}%</0> in comparison to the international price index.':
    [
      'It is currently high demand and few that wishes to sell ',
      ['currency'],
      '. To avoid risking you buy for an expensive price, we want to inform you that the price differentiate approx. <0>',
      ['absolutePercent'],
      '%</0> in comparison to the international price index.',
    ],
  'It is currently low demand and few that wishes to buy {currency}. To avoid risking you selling for a cheap price, we want to inform you that the price differentiate approx. <0>{absolutePercent}%</0> in comparison to the international price index.':
    [
      'It is currently low demand and few that wishes to buy ',
      ['currency'],
      '. To avoid risking you selling for a cheap price, we want to inform you that the price differentiate approx. <0>',
      ['absolutePercent'],
      '%</0> in comparison to the international price index.',
    ],
  'It is free to withdraw funds from your staking account. Remember that the earning of rewards will stop if you deactivate staking.':
    'It is free to withdraw funds from your staking account. Remember that the earning of rewards will stop if you deactivate staking.',
  'It is important to us that your funds are safely stored. In addition to using the best and latest technology for storing cryptocurrencies, your funds are also insured with us.':
    'It is important to us that your funds are safely stored. In addition to using the best and latest technology for storing cryptocurrencies, your funds are also insured with us.',
  'It is not possible to change or recover passwords if you\nregistered with Vipps. This is because a password for your\nuser was never registered.':
    'It is not possible to change or recover passwords if you\nregistered with Vipps. This is because a password for your\nuser was never registered.',
  'It is now easier to invite friends and acquaintances to Firi! We have removed the "Firi ambassador" concept and simplified the affiliate program. Earn {0} {referralCurrency} for each friend you invite. No limit on the number of invites as long as you follow the guidelines.':
    [
      'It is now easier to invite friends and acquaintances to Firi! We have removed the "Firi ambassador" concept and simplified the affiliate program. Earn ',
      ['0'],
      ' ',
      ['referralCurrency'],
      ' for each friend you invite. No limit on the number of invites as long as you follow the guidelines.',
    ],
  'It usually takes 1-2 banking days until the money arrives in your account, depending on your bank.':
    'It usually takes 1-2 banking days until the money arrives in your account, depending on your bank.',
  'It usually takes 1-2 working days until the money arrives in your account.':
    'It usually takes 1-2 working days until the money arrives in your account.',
  "It's time to update!": "It's time to update!",
  'Join our crypto advent calendar and win crypto every day until christmas eve':
    'Join our crypto advent calendar and win crypto every day until christmas eve',
  "Join this year's Christmas calender!": "Join this year's Christmas calender!",
  'Just remembered?': 'Just remembered?',
  'Keep in mind that the most relevant documentation make it easier and quicker for us to process your case.':
    'Keep in mind that the most relevant documentation make it easier and quicker for us to process your case.',
  Language: 'Language',
  'Last available tax report': 'Last available tax report',
  'Last day': 'Last day',
  'Last deposit from': 'Last deposit from',
  'Last month': 'Last month',
  'Last name': 'Last name',
  'Last price': 'Last price',
  'Last transactions': 'Last transactions',
  'Latest articles': 'Latest articles',
  'Latest news from the crypto market': 'Latest news from the crypto market',
  "Launched in 2020, Polkadot is one of the world's largest cryptocurrencies based on market value. Polkadot is built as part of a larger vision of a decentralized internet, and wants to revolutionize the way we can use blockchain technology.":
    "Launched in 2020, Polkadot is one of the world's largest cryptocurrencies based on market value. Polkadot is built as part of a larger vision of a decentralized internet, and wants to revolutionize the way we can use blockchain technology.",
  'Lean back. We will let you know when you get a reward🤩':
    'Lean back. We will let you know when you get a reward🤩',
  'Learn about crypto': 'Learn about crypto',
  'Learn more': 'Learn more',
  'Learn more about crypto': 'Learn more about crypto',
  'Learn more about staking': 'Learn more about staking',
  Learning: 'Learning',
  'Let your crypto do the work for you and earn rewards while you sleep.':
    'Let your crypto do the work for you and earn rewards while you sleep.',
  Light: 'Light',
  Lightning: 'Lightning',
  Limits: 'Limits',
  'Listen in as we talk about the biggest news in the crypto world over the past week':
    'Listen in as we talk about the biggest news in the crypto world over the past week',
  'Litecoin is a digital currency that you can store and transfer directly to anyone, anywhere in the world, without going through third parties such as banks or other central institutions.':
    'Litecoin is a digital currency that you can store and transfer directly to anyone, anywhere in the world, without going through third parties such as banks or other central institutions.',
  'Litecoin is based on the source code of Bitcoin, and is considered to be one of the first alternative cryptocurrencies to Bitcoin.':
    'Litecoin is based on the source code of Bitcoin, and is considered to be one of the first alternative cryptocurrencies to Bitcoin.',
  'Litecoin network': 'Litecoin network',
  'Load more': 'Load more',
  Loading: 'Loading',
  Locked: 'Locked',
  'Locked in order': 'Locked in order',
  'Locked in unstaking': 'Locked in unstaking',
  'Log in': 'Log in',
  'Log in or register now': 'Log in or register now',
  'Log in to <0>Skatteetaten.no</0> and open the tax return.':
    'Log in to <0>Skatteetaten.no</0> and open the tax return.',
  'Log in to <0>https://www.altinn.no/</0>': 'Log in to <0>https://www.altinn.no/</0>',
  'Log in to Firi or create an account quickly and easily.':
    'Log in to Firi or create an account quickly and easily.',
  'Log in to TastSelv Borger': 'Log in to TastSelv Borger',
  'Log in to Tastselv at SKAT and follow this guide':
    'Log in to Tastselv at SKAT and follow this guide',
  'Log in to your Firi account.': 'Log in to your Firi account.',
  'Log in to your online bank and go for a fixed transfer/payment.':
    'Log in to your online bank and go for a fixed transfer/payment.',
  'Log in to your online bank and go to transfer/payment.':
    'Log in to your online bank and go to transfer/payment.',
  'Log in with Vipps or email. If you have registered with Vipps, you can <0>only</0> log in with Vipps.':
    'Log in with Vipps or email. If you have registered with Vipps, you can <0>only</0> log in with Vipps.',
  'Log into': 'Log into',
  'Log out': 'Log out',
  Login: 'Login',
  "Looks like we're not available in your region": "Looks like we're not available in your region",
  Loss: 'Loss',
  'Low demand': 'Low demand',
  'Lowest (24h)': 'Lowest (24h)',
  'Lowest price': 'Lowest price',
  'Lowest value': 'Lowest value',
  "MANA is linked to the crypto game Decentraland and serves as a currency within the game. Decentraland is a virtual world that players can freely explore and build their own things in. Players can buy and sell properties, services, and assets within the game, and MANA functions as the medium of payment. Therefore, the success of MANA is closely tied to Decentraland's ability to attract players.":
    "MANA is linked to the crypto game Decentraland and serves as a currency within the game. Decentraland is a virtual world that players can freely explore and build their own things in. Players can buy and sell properties, services, and assets within the game, and MANA functions as the medium of payment. Therefore, the success of MANA is closely tied to Decentraland's ability to attract players.",
  'MATIC is becoming POL as part of a major upgrade to strengthen the Polygon network. Due to this, trading, deposits, and withdrawals of MATIC are temporarily unavailable.':
    'MATIC is becoming POL as part of a major upgrade to strengthen the Polygon network. Due to this, trading, deposits, and withdrawals of MATIC are temporarily unavailable.',
  'Main account': 'Main account',
  'Maintenance is in progress from 07:00 to 08:00. Estimated time is one hour, but it can be faster. Thanks for your patience!':
    'Maintenance is in progress from 07:00 to 08:00. Estimated time is one hour, but it can be faster. Thanks for your patience!',
  'Maintenance ongoing...': 'Maintenance ongoing...',
  'Make a crypto withdrawal': 'Make a crypto withdrawal',
  'Make a deposit': 'Make a deposit',
  'Make a deposit to your savings account': 'Make a deposit to your savings account',
  'Make a deposit to your savings account, minimum {0} {currency}': [
    'Make a deposit to your savings account, minimum ',
    ['0'],
    ' ',
    ['currency'],
  ],
  'Make a withdrawal to your bank account': 'Make a withdrawal to your bank account',
  'Make more changes': 'Make more changes',
  'Make withdrawal': 'Make withdrawal',
  'Make you first investment now': 'Make you first investment now',
  'Make your first crypto deposit': 'Make your first crypto deposit',
  'Make your first investment': 'Make your first investment',
  'Manual transfer': 'Manual transfer',
  "Many choose crypto because it's an exciting alternative to stocks and funds. Crypto as part of a diversified portfolio can be a smart choice for the future, but it's important to remember that all investments involve risk.":
    "Many choose crypto because it's an exciting alternative to stocks and funds. Crypto as part of a diversified portfolio can be a smart choice for the future, but it's important to remember that all investments involve risk.",
  'Mark as read': 'Mark as read',
  Market: 'Market',
  'Market and prices': 'Market and prices',
  'Market is empty': 'Market is empty',
  Marketing: 'Marketing',
  Max: 'Max',
  'Max 18 characters': 'Max 18 characters',
  'Max filesize': 'Max filesize',
  'Maximum withdraw is {0} {currency}': ['Maximum withdraw is ', ['0'], ' ', ['currency']],
  'Maybe later': 'Maybe later',
  'Maybe not chocolate cookies, but still important for a good experience when you visiting us. We use cookies to improve your user experience, secure our website and for marketing.':
    'Maybe not chocolate cookies, but still important for a good experience when you visiting us. We use cookies to improve your user experience, secure our website and for marketing.',
  'Meme coins': 'Meme coins',
  'Meme coins are a type of cryptocurrency based on humor and internet culture. They exist primarily for amusement and, unlike traditional cryptocurrencies like Bitcoin and Ethereum, have no underlying utility value.':
    'Meme coins are a type of cryptocurrency based on humor and internet culture. They exist primarily for amusement and, unlike traditional cryptocurrencies like Bitcoin and Ethereum, have no underlying utility value.',
  'Meme coins are evenly distributed across many wallets. It takes many individual stakeholders to move the price':
    'Meme coins are evenly distributed across many wallets. It takes many individual stakeholders to move the price',
  'Merry Christmas!': 'Merry Christmas!',
  'Merry christmas!': 'Merry christmas!',
  Message: 'Message',
  Minimum: 'Minimum',
  'Minimum amount is {SAVING_AGREEMENT_MIN_AMOUNT} {0}': [
    'Minimum amount is ',
    ['SAVING_AGREEMENT_MIN_AMOUNT'],
    ' ',
    ['0'],
  ],
  'Minimum amount is {minAmount} NOK': ['Minimum amount is ', ['minAmount'], ' NOK'],
  'Minimum deposit is': 'Minimum deposit is',
  'Minimum withdrawal is': 'Minimum withdrawal is',
  'Minimum withdrawal is {0} {currency} ≈ {1} {2}': [
    'Minimum withdrawal is ',
    ['0'],
    ' ',
    ['currency'],
    ' ≈ ',
    ['1'],
    ' ',
    ['2'],
  ],
  'Minimum {VALUE_MIN_LENGTH} characters': ['Minimum ', ['VALUE_MIN_LENGTH'], ' characters'],
  'Mismatching price index': 'Mismatching price index',
  'Missing input': 'Missing input',
  'Missing purchase price': 'Missing purchase price',
  MobilePay: 'MobilePay',
  'MobilePay fee': 'MobilePay fee',
  'Moderately high level of knowledge': 'Moderately high level of knowledge',
  'Moderately low level of knowledge': 'Moderately low level of knowledge',
  'Money laundering': 'Money laundering',
  Month: 'Month',
  'More and better market information': 'More and better market information',
  'More coins': 'More coins',
  'More information will give you a better user experience. This includes shorter processing time at our customer service.':
    'More information will give you a better user experience. This includes shorter processing time at our customer service.',
  'More options': 'More options',
  'More rewards': 'More rewards',
  'More rewards are coming': 'More rewards are coming',
  'More services (e.g., shorting or swap function)':
    'More services (e.g., shorting or swap function)',
  'Most meme coins are distributed across a few wallets. Some stakeholders can cause major fluctuations in the price themselves':
    'Most meme coins are distributed across a few wallets. Some stakeholders can cause major fluctuations in the price themselves',
  'Most popular on Firi': 'Most popular on Firi',
  'Most popular on Firi now': 'Most popular on Firi now',
  'Most popular on Firi right now': 'Most popular on Firi right now',
  'Move crypto to your Firi-wallets': 'Move crypto to your Firi-wallets',
  'Movement in the crypto market': 'Movement in the crypto market',
  'Must be 6 digits': 'Must be 6 digits',
  'Must be a valid email address': 'Must be a valid email address',
  'Must be approved': 'Must be approved',
  'Must contain 6 digits': 'Must contain 6 digits',
  'Must contain {PASSWORD_MIN_LENGTH_VARIANT_B} characters': [
    'Must contain ',
    ['PASSWORD_MIN_LENGTH_VARIANT_B'],
    ' characters',
  ],
  'Must only contain digits': 'Must only contain digits',
  'My accounts': 'My accounts',
  'My balance': 'My balance',
  'My bank accounts': 'My bank accounts',
  'My crypto': 'My crypto',
  'My destination tag': 'My destination tag',
  'My documentation': 'My documentation',
  'My favorites': 'My favorites',
  'My holdings': 'My holdings',
  'My information': 'My information',
  'My profile': 'My profile',
  'My referral code:': 'My referral code:',
  'My referrals': 'My referrals',
  'My saved addresses': 'My saved addresses',
  'My saved favorites': 'My saved favorites',
  'My savings agreement': 'My savings agreement',
  'My uploaded documents': 'My uploaded documents',
  'My wallet address': 'My wallet address',
  'My {currency} holding': ['My ', ['currency'], ' holding'],
  'NOTE: we do not support instant transfer.': 'NOTE: we do not support instant transfer.',
  Name: 'Name',
  'Name is required': 'Name is required',
  'Name of bank': 'Name of bank',
  'Name unknown': 'Name unknown',
  'Need help or have any questions? Our excellent customer service team is here to help you. Contact us through the chat in the Firi app.':
    'Need help or have any questions? Our excellent customer service team is here to help you. Contact us through the chat in the Firi app.',
  'Needs to verify using BankID': 'Needs to verify using BankID',
  'Neither nor': 'Neither nor',
  'New at Firi': 'New at Firi',
  'New crypto': 'New crypto',
  'New favorite': 'New favorite',
  'New menu item - Explore': 'New menu item - Explore',
  'New password': 'New password',
  'New products and crypto at Firi': 'New products and crypto at Firi',
  'New receiver': 'New receiver',
  'New reward! Teach others about crypto': 'New reward! Teach others about crypto',
  'New update': 'New update',
  'New update available': 'New update available',
  'New update in {storeVersion}': ['New update in ', ['storeVersion']],
  Newbie: 'Newbie',
  News: 'News',
  'News!': 'News!',
  Newsletter: 'Newsletter',
  Next: 'Next',
  No: 'No',
  'No commission for new referrals': 'No commission for new referrals',
  'No data': 'No data',
  'No fees': 'No fees',
  'No more than any other cryptocurrency': 'No more than any other cryptocurrency',
  'No results found': 'No results found',
  'No thanks': 'No thanks',
  'No transactions to show yet': 'No transactions to show yet',
  'No, I do not trade on other exchanges': 'No, I do not trade on other exchanges',
  'No, but it may take som time to process the withdraw. We will give you a time estimate when you choose to deactivate staking.':
    'No, but it may take som time to process the withdraw. We will give you a time estimate when you choose to deactivate staking.',
  'No, not now': 'No, not now',
  'No, thanks': 'No, thanks',
  'Non-custodial wallet (Argent, Metamask, Rainbow, etc)':
    'Non-custodial wallet (Argent, Metamask, Rainbow, etc)',
  Norway: 'Norway',
  Norwegian: 'Norwegian',
  'Norwegian kroner': 'Norwegian kroner',
  'Norwegian-lang': 'Norwegian',
  'Not a valid bank account number': 'Not a valid bank account number',
  'Not a valid email': 'Not a valid email',
  'Not a valid referral code': 'Not a valid referral code',
  'Not all banks support Open Banking. You can instead add a bank account manually.':
    'Not all banks support Open Banking. You can instead add a bank account manually.',
  'Not all banks support Open Banking. You can instead transfer money manually from your online bank.':
    'Not all banks support Open Banking. You can instead transfer money manually from your online bank.',
  'Not enough buy orders available': 'Not enough buy orders available',
  'Not enough sell orders available': 'Not enough sell orders available',
  'Not now': 'Not now',
  'Not supported files': 'Not supported files',
  'Not sure which crypto to invest in? Here you can find crypto by category.':
    'Not sure which crypto to invest in? Here you can find crypto by category.',
  'Not valid': 'Not valid',
  'Not valid code': 'Not valid code',
  'Note that the price may change between the time you decide to buy and when you confirm the order. The exchange rate is therefore an estimate and may vary by up to 3 percent.':
    'Note that the price may change between the time you decide to buy and when you confirm the order. The exchange rate is therefore an estimate and may vary by up to 3 percent.',
  'Note!': 'Note!',
  'Note! If you make changes to the amount and interval, you must update it in your online bank.':
    'Note! If you make changes to the amount and interval, you must update it in your online bank.',
  'Nothing here yet!': 'Nothing here yet!',
  Notifications: 'Notifications',
  'Now just sit back and get ready for the biggest crypto happening!':
    'Now just sit back and get ready for the biggest crypto happening!',
  'Now we are just waiting to receive your first bank deposit. View or change your savings agreement here':
    'Now we are just waiting to receive your first bank deposit. View or change your savings agreement here',
  'Now we need relevant documentation from you': 'Now we need relevant documentation from you',
  'Now you can get up to {0}% annual yield when staking {baseCurrency}': [
    'Now you can get up to ',
    ['0'],
    '% annual yield when staking ',
    ['baseCurrency'],
  ],
  'Now you only need som ETH to get started': 'Now you only need som ETH to get started',
  'Numbers only from 0 to 100': 'Numbers only from 0 to 100',
  'OTC Trading': 'OTC Trading',
  Off: 'Off',
  'Oh no..': 'Oh no..',
  "Oh, it looks like you're a bit lost!": "Oh, it looks like you're a bit lost!",
  Okay: 'Okay',
  'Okay!': 'Okay!',
  'Okay, I got it!': 'Okay, I got it!',
  On: 'On',
  'On the new profile screen, you can now add a personal touch to your Firi user with a profile picture. We have also moved all settings here, along with Firi Advantage and Firi Affiliate.':
    'On the new profile screen, you can now add a personal touch to your Firi user with a profile picture. We have also moved all settings here, along with Firi Advantage and Firi Affiliate.',
  'Once it has been sent, it will usually takes around 10 minutes for the first confirmation on the blockchain and normally within an hour to complete all confirmations. However, it can take longer depending on the transaction volume at the time your amount are being sent.':
    'Once it has been sent, it will usually takes around 10 minutes for the first confirmation on the blockchain and normally within an hour to complete all confirmations. However, it can take longer depending on the transaction volume at the time your amount are being sent.',
  'Once you have downloaded the 2FA app, you can come back here and move on to the next step.':
    'Once you have downloaded the 2FA app, you can come back here and move on to the next step.',
  'One of the values that stands strong in Cardano is to be a sustainable blockchain that addresses the challenges of high power consumption that many blockchains have today.':
    'One of the values that stands strong in Cardano is to be a sustainable blockchain that addresses the challenges of high power consumption that many blockchains have today.',
  'One time password must be 6 digits': 'One time password must be 6 digits',
  'One-time password is required.': 'One-time password is required.',
  'Ongoing activities': 'Ongoing activities',
  'Ongoing transfer': 'Ongoing transfer',
  'Ongoing transfers': 'Ongoing transfers',
  'Only users over 18 with BankID can participate.':
    'Only users over 18 with BankID can participate.',
  'Ooopsie, an unexpected error occurred!': 'Ooopsie, an unexpected error occurred!',
  'Open "Melding: Skattemelding for 2020 som dokumentasjon"':
    'Open "Melding: Skattemelding for 2020 som dokumentasjon"',
  'Open Google Authenticator': 'Open Google Authenticator',
  'Open a new account': 'Open a new account',
  'Open a staking account and earn rewards on your crypto.':
    'Open a staking account and earn rewards on your crypto.',
  'Open new account': 'Open new account',
  'Open savings account': 'Open savings account',
  'Open setting': 'Open setting',
  'Open staking account': 'Open staking account',
  'Open you inbox in Altinn': 'Open you inbox in Altinn',
  'Open {storeVersion}': ['Open ', ['storeVersion']],
  'Open “Message: Skattemelding for 2020 som dokumentasjon”':
    'Open “Message: Skattemelding for 2020 som dokumentasjon”',
  Optional: 'Optional',
  'Or copy the configuration key and add it to your 2FA app':
    'Or copy the configuration key and add it to your 2FA app',
  'Order book': 'Order book',
  'Order is either filled or cancelled.': 'Order is either filled or cancelled.',
  Orders: 'Orders',
  'Org nr:': 'Org nr:',
  'Originally, Binance users used BNB to get lower trading fees. The crypto is know as Binance Coin, and has multiple use cases. Such as operating and securing the BNB Chain ecosystem and being used as a payment method both online and in physical stores.':
    'Originally, Binance users used BNB to get lower trading fees. The crypto is know as Binance Coin, and has multiple use cases. Such as operating and securing the BNB Chain ecosystem and being used as a payment method both online and in physical stores.',
  Other: 'Other',
  'Other deposit methods': 'Other deposit methods',
  'Other exchanges (Binance, Coinbase, etc.)': 'Other exchanges (Binance, Coinbase, etc.)',
  'Other income from virtual assets / crypto currency':
    'Other income from virtual assets / crypto currency',
  'Other marketing': 'Other marketing',
  'Other payment methods': 'Other payment methods',
  'Other types of illegal activity': 'Other types of illegal activity',
  'Others have selected': 'Others have selected',
  'Our crypto withdrawal service is temporarily unavailable. Our service provider is working on it.':
    'Our crypto withdrawal service is temporarily unavailable. Our service provider is working on it.',
  'Our referral program will be part of the Firi rewards program, and in the new benefits program it will not be possible to earn commission on the trading fees of new friends you refer. Of course, you keep the commission on the trading fees of those you have already referred and will continue to earn from this.':
    'Our referral program will be part of the Firi rewards program, and in the new benefits program it will not be possible to earn commission on the trading fees of new friends you refer. Of course, you keep the commission on the trading fees of those you have already referred and will continue to earn from this.',
  "Our system is temporarily down and we are working to get it back up as quickly as possible. Your funds are completely safe with us. Thanks for your patience, we'll keep you updated!":
    "Our system is temporarily down and we are working to get it back up as quickly as possible. Your funds are completely safe with us. Thanks for your patience, we'll keep you updated!",
  Overview: 'Overview',
  'PDF - Annual statement': 'PDF - Annual statement',
  'PDF - Tax report': 'PDF - Tax report',
  'Paid advertising to promote the referral link is not allowed.':
    'Paid advertising to promote the referral link is not allowed.',
  'Paid out': 'Paid out',
  Pair: 'Pair',
  Password: 'Password',
  'Password is changed': 'Password is changed',
  'Passwords do not match': 'Passwords do not match',
  'Past 3 months': 'Past 3 months',
  'Past month': 'Past month',
  'Past week': 'Past week',
  'Past year': 'Past year',
  'Paste from clipboard': 'Paste from clipboard',
  'Pay special attention to:': 'Pay special attention to:',
  'Payment method': 'Payment method',
  Payouts: 'Payouts',
  Pending: 'Pending',
  'Pending payouts': 'Pending payouts',
  'Perfect!': 'Perfect!',
  Permissions: 'Permissions',
  'Personal experience': 'Personal experience',
  'Personal touch with profile picture': 'Personal touch with profile picture',
  'Phone number': 'Phone number',
  'Pink Ribbon 2021': 'Pink Ribbon 2021',
  'Please install': 'Please install',
  'Please look over and confirm that the provided information is correct':
    'Please look over and confirm that the provided information is correct',
  'Please note that it can take 1 working day until you can send the funds out of your account with us.':
    'Please note that it can take 1 working day until you can send the funds out of your account with us.',
  'Please note that we do not allow transfers to or from addresses that are linked to':
    'Please note that we do not allow transfers to or from addresses that are linked to',
  'Please sign with BankId to verify your identity quick and easy':
    'Please sign with BankId to verify your identity quick and easy',
  'Please try again': 'Please try again',
  'Please upload relevant documentation. The documents are safely and securly stored at Firi.':
    'Please upload relevant documentation. The documents are safely and securly stored at Firi.',
  Podcast: 'Podcast',
  "Polkadot is the name of Polkadot's protocol. When we talk about Polkadot's cryptocurrency, or token, it is referred to as DOT.":
    "Polkadot is the name of Polkadot's protocol. When we talk about Polkadot's cryptocurrency, or token, it is referred to as DOT.",
  'Polkadot network': 'Polkadot network',
  'Polkadot wants to unite large and unique networks, such as Bitcoin and Ethereum, so that they can collaborate across blockchains.':
    'Polkadot wants to unite large and unique networks, such as Bitcoin and Ethereum, so that they can collaborate across blockchains.',
  'Polygon is an ecosystem of various solutions to make Ethereum more scalable. Matic is the associated cryptocurrency that is used for transactions and staking, among other things.':
    'Polygon is an ecosystem of various solutions to make Ethereum more scalable. Matic is the associated cryptocurrency that is used for transactions and staking, among other things.',
  'Polygon is an ecosystem of various solutions to make Ethereum more scalable. Polygon is the associated cryptocurrency that is used for transactions and staking, among other things.':
    'Polygon is an ecosystem of various solutions to make Ethereum more scalable. Polygon is the associated cryptocurrency that is used for transactions and staking, among other things.',
  'Polygon network': 'Polygon network',
  Popular: 'Popular',
  Press: 'Press',
  'Press-release': 'Press-release',
  'Preview Buy': 'Preview Buy',
  'Preview Sell': 'Preview Sell',
  'Previously uploaded': 'Previously uploaded',
  Price: 'Price',
  'Price ({0})': ['Price (', ['0'], ')'],
  'Price details of your purchases': 'Price details of your purchases',
  'Price development last week': 'Price development last week',
  Privacy: 'Privacy',
  Prize: 'Prize',
  Processing: 'Processing',
  Profile: 'Profile',
  Profit: 'Profit',
  'Promote only your own link and be honest – no guarantees for profit.':
    'Promote only your own link and be honest – no guarantees for profit.',
  'Proof-of-stake': 'Proof-of-stake',
  'Proof-of-work': 'Proof-of-work',
  'Purchase amount': 'Purchase amount',
  'Purchase of {0} not available': ['Purchase of ', ['0'], ' not available'],
  'Purchase of {selectedCurrency} not available': [
    'Purchase of ',
    ['selectedCurrency'],
    ' not available',
  ],
  'Purchase price': 'Purchase price',
  'Pyramid games': 'Pyramid games',
  Rate: 'Rate',
  'Rating on App Store': 'Rating on App Store',
  'Rating on Google Play': 'Rating on Google Play',
  Read: 'Read',
  'Read about fees': 'Read about fees',
  'Read about insurance of your assets': 'Read about insurance of your assets',
  'Read about trades on your savings account': 'Read about trades on your savings account',
  'Read more': 'Read more',
  'Read more about': 'Read more about',
  'Read more about crypto tax here': 'Read more about crypto tax here',
  'Read more about how we work to prevent scams and investment fraud':
    'Read more about how we work to prevent scams and investment fraud',
  'Read more about insurance': 'Read more about insurance',
  'Read more about processing times here': 'Read more about processing times here',
  'Read more about rewards here.': 'Read more about rewards here.',
  'Read more about the tax report here': 'Read more about the tax report here',
  'Read more about what personal information we process here.':
    'Read more about what personal information we process here.',
  'Read more articles': 'Read more articles',
  'Read more here': 'Read more here',
  'Read more news': 'Read more news',
  'Read terms for the calendar': 'Read terms for the calendar',
  'Ready to get started?': 'Ready to get started?',
  'Recall that it can take 1-2 business days before the withdrawn from your bank reaches your savings account here at Firi.':
    'Recall that it can take 1-2 business days before the withdrawn from your bank reaches your savings account here at Firi.',
  Receive: 'Receive',
  'Receive bonus when registering with Firi | Norwegian exchange for cryptocurrency trading':
    'Receive bonus when registering with Firi | Norwegian exchange for cryptocurrency trading',
  'Receive crypto': 'Receive crypto',
  'Receive notifications to add purchase prices on new crypto deposits':
    'Receive notifications to add purchase prices on new crypto deposits',
  'Receive {0}': ['Receive ', ['0']],
  Received: 'Received',
  'Received amount': 'Received amount',
  'Received amount:': 'Received amount:',
  'Received rewards': 'Received rewards',
  Receiver: 'Receiver',
  "Receiver's address": "Receiver's address",
  "Receiver's {0}-address": ["Receiver's ", ['0'], '-address'],
  "Receiver's {currency}-address": ["Receiver's ", ['currency'], '-address'],
  'Recent articles': 'Recent articles',
  Recipient: 'Recipient',
  "Recipient's name": "Recipient's name",
  'Recurring deposits': 'Recurring deposits',
  'Recurring transfers': 'Recurring transfers',
  'Red Cross': 'Red Cross',
  Redeem: 'Redeem',
  'Redeem gift card': 'Redeem gift card',
  'Redeem your gift card in the Firi app or at Firi.no':
    'Redeem your gift card in the Firi app or at Firi.no',
  'Refer a friend': 'Refer a friend',
  'Refer a friend and get {0} {1} i {payoutCurrencyName}!': [
    'Refer a friend and get ',
    ['0'],
    ' ',
    ['1'],
    ' i ',
    ['payoutCurrencyName'],
    '!',
  ],
  'Refer at least {0} friends that trade crypto with Firi before 31. march.': [
    'Refer at least ',
    ['0'],
    ' friends that trade crypto with Firi before 31. march.',
  ],
  'Refer friends': 'Refer friends',
  'Referral bonus is paid out in bitcoin (BTC) at the end of each month. How much bitcoin you get depends on the exchange rate on the day you referred your friend.':
    'Referral bonus is paid out in bitcoin (BTC) at the end of each month. How much bitcoin you get depends on the exchange rate on the day you referred your friend.',
  'Referral bonus is paid out in {0} at the end of each month.': [
    'Referral bonus is paid out in ',
    ['0'],
    ' at the end of each month.',
  ],
  'Referral code': 'Referral code',
  'Referral code (optional)': 'Referral code (optional)',
  'Referral code is not valid': 'Referral code is not valid',
  Referrals: 'Referrals',
  'Referrals pending payment': 'Referrals pending payment',
  'Referrals with this code': 'Referrals with this code',
  Referred: 'Referred',
  'Referred {0}/{1}': ['Referred ', ['0'], '/', ['1']],
  Refund: 'Refund',
  'Reg number': 'Reg number',
  'Reg. nr. and account number': 'Reg. nr. and account number',
  'Regards {0}': ['Regards ', ['0']],
  'Regards {from}': ['Regards ', ['from']],
  Register: 'Register',
  'Register account with Firi': 'Register account with Firi',
  'Register for free': 'Register for free',
  'Register here': 'Register here',
  'Register now': 'Register now',
  'Register the transfer in your bank': 'Register the transfer in your bank',
  'Register with': 'Register with',
  'Register with Vipps': 'Register with Vipps',
  'Register with Vipps to get started and get {referralAmount} kroner to buy your first cryptocurrency with Firi and start your investments.':
    [
      'Register with Vipps to get started and get ',
      ['referralAmount'],
      ' kroner to buy your first cryptocurrency with Firi and start your investments.',
    ],
  'Register with email': 'Register with email',
  'Registered with Vipps?': 'Registered with Vipps?',
  'Registration is complete!': 'Registration is complete!',
  'Related articles': 'Related articles',
  'Related pages': 'Related pages',
  'Relevant articles': 'Relevant articles',
  'Relevant documentation can for instance be:': 'Relevant documentation can for instance be:',
  Remaining: 'Remaining',
  'Remember that the bank is closed on the weekend, but with {labels} and {scheme} you can receive the deposit within seconds.':
    [
      'Remember that the bank is closed on the weekend, but with ',
      ['labels'],
      ' and ',
      ['scheme'],
      ' you can receive the deposit within seconds.',
    ],
  'Remember that the minimum amount is {SAVING_AGREEMENT_DETAIL_MIN_AMOUNT} {0} per selected cryptocurrency.':
    [
      'Remember that the minimum amount is ',
      ['SAVING_AGREEMENT_DETAIL_MIN_AMOUNT'],
      ' ',
      ['0'],
      ' per selected cryptocurrency.',
    ],
  'Remember that you are responsible for filling in the figures in the tax return. This does not happen automatically.':
    'Remember that you are responsible for filling in the figures in the tax return. This does not happen automatically.',
  'Remember to update your payment agreement in the bank':
    'Remember to update your payment agreement in the bank',
  'Remember!': 'Remember!',
  'Remember, the recruitment must be honest and fair – we monitor the program to ensure it is not abused.':
    'Remember, the recruitment must be honest and fair – we monitor the program to ensure it is not abused.',
  Remove: 'Remove',
  'Remove as favorite': 'Remove as favorite',
  'Remove bank account': 'Remove bank account',
  'Remove photo': 'Remove photo',
  'Repeat code': 'Repeat code',
  'Repeat password': 'Repeat password',
  'Reqeust withdraw': 'Reqeust withdraw',
  'Request reset link': 'Request reset link',
  'Request {0}': ['Request ', ['0']],
  Requested: 'Requested',
  Required: 'Required',
  'Requires subscription at': 'Requires subscription at',
  'Resend code': 'Resend code',
  'Reset password': 'Reset password',
  Return: 'Return',
  Reward: 'Reward',
  'Reward claimed or not found': 'Reward claimed or not found',
  'Reward from staking': 'Reward from staking',
  'Reward on this code': 'Reward on this code',
  'Reward: {0} for {1} {2}': ['Reward: ', ['0'], ' for ', ['1'], ' ', ['2']],
  Rewards: 'Rewards',
  'Rewards (crypto)': 'Rewards (crypto)',
  'Rewards ({0})': ['Rewards (', ['0'], ')'],
  'Rewards from staking are taxable income.': 'Rewards from staking are taxable income.',
  'Rewards received': 'Rewards received',
  'Right around the corner': 'Right around the corner',
  'Right now there is a high demand to buy {currency}. We recommend you try again later.': [
    'Right now there is a high demand to buy ',
    ['currency'],
    '. We recommend you try again later.',
  ],
  'Right now there is a high demand to sell {currency}. We recommend you try again later.': [
    'Right now there is a high demand to sell ',
    ['currency'],
    '. We recommend you try again later.',
  ],
  'Ripple network': 'Ripple network',
  'Run tax calculation': 'Run tax calculation',
  'SAND functions as the currency within The Sandbox universe. Users can use SAND to buy property, play games, purchase equipment, or customize their avatars.':
    'SAND functions as the currency within The Sandbox universe. Users can use SAND to buy property, play games, purchase equipment, or customize their avatars.',
  'Safe and easy crypto trading.': 'Safe and easy crypto trading.',
  'Safe staking with Firi🔐': 'Safe staking with Firi🔐',
  'Sale completed': 'Sale completed',
  'Sale of illegal products or services': 'Sale of illegal products or services',
  'Sale of {0} not available': ['Sale of ', ['0'], ' not available'],
  'Sale of {selectedCurrency} not unavailable': [
    'Sale of ',
    ['selectedCurrency'],
    ' not unavailable',
  ],
  'Sandbox (SAND) is a cryptocurrency used within the crypto game The Sandbox, a virtual world that allows users to create, own, and monetize as well as explore the world':
    'Sandbox (SAND) is a cryptocurrency used within the crypto game The Sandbox, a virtual world that allows users to create, own, and monetize as well as explore the world',
  'Santa claus': 'Santa claus',
  Save: 'Save',
  'Save a recipient as a favorite with name and address so you can easily send crypto.':
    'Save a recipient as a favorite with name and address so you can easily send crypto.',
  'Save changes': 'Save changes',
  'Save permissions': 'Save permissions',
  Savings: 'Savings',
  'Savings & Investments': 'Savings & Investments',
  'Savings account': 'Savings account',
  'Savings agreement': 'Savings agreement',
  Scams: 'Scams',
  'Scan QR-code to download our app': 'Scan QR-code to download our app',
  'Scan QR-code to download our app and get your bonus':
    'Scan QR-code to download our app and get your bonus',
  'Scan the QR code': 'Scan the QR code',
  'Scan the QR code in Google Authenticator': 'Scan the QR code in Google Authenticator',
  'Scan the QR code to download the app and identify yourself with:':
    'Scan the QR code to download the app and identify yourself with:',
  'Scan the QR-code or copy the link below to the external wallet. It can take about 1 hour before you see the cryptocurrency on your account.':
    'Scan the QR-code or copy the link below to the external wallet. It can take about 1 hour before you see the cryptocurrency on your account.',
  'Scan the QR-code or copy the wallet address below. It can take about 1 hour before you see the cryptocurrency in your account.':
    'Scan the QR-code or copy the wallet address below. It can take about 1 hour before you see the cryptocurrency in your account.',
  'Scan the QR-code to download the app': 'Scan the QR-code to download the app',
  'Se example': 'Se example',
  Search: 'Search',
  'Search after destination': 'Search after destination',
  'Search for cryptocurrency': 'Search for cryptocurrency',
  'Search for your bank': 'Search for your bank',
  'Secret Key': 'Secret Key',
  Security: 'Security',
  See: 'See',
  'See all': 'See all',
  'See all articles': 'See all articles',
  'See all transactions': 'See all transactions',
  'See current tax for {mostRecentTaxYear}': ['See current tax for ', ['mostRecentTaxYear']],
  'See finalized tax calculations': 'See finalized tax calculations',
  'See full list': 'See full list',
  'See how to do it in the next step.': 'See how to do it in the next step.',
  'See more': 'See more',
  'See or edit your crypto saving': 'See or edit your crypto saving',
  'See whats new': 'See whats new',
  'Select "Approve".': 'Select "Approve".',
  'Select "Change your tax assessment notice/tax return".':
    'Select "Change your tax assessment notice/tax return".',
  'Select "I want to enter aggregated tax information for many virtual currency/cryptocurrency"':
    'Select "I want to enter aggregated tax information for many virtual currency/cryptocurrency"',
  'Select "Next" at the bottom of the page.': 'Select "Next" at the bottom of the page.',
  'Select CSV type to export': 'Select CSV type to export',
  'Select amount': 'Select amount',
  'Select bank account': 'Select bank account',
  'Select country': 'Select country',
  'Select crypto': 'Select crypto',
  'Select currency': 'Select currency',
  'Select deposit method': 'Select deposit method',
  'Select language': 'Select language',
  'Select payment method': 'Select payment method',
  'Select theme': 'Select theme',
  'Select time period': 'Select time period',
  'Select year': 'Select year',
  Sell: 'Sell',
  'Sell all': 'Sell all',
  'Sell completed': 'Sell completed',
  'Sell crypto': 'Sell crypto',
  'Sell everything': 'Sell everything',
  'Sell {0}': ['Sell ', ['0']],
  Send: 'Send',
  'Send all': 'Send all',
  'Send answer': 'Send answer',
  'Send crypto': 'Send crypto',
  'Send invitation': 'Send invitation',
  'Send {0}': ['Send ', ['0']],
  'Send {currency}': ['Send ', ['currency']],
  Sent: 'Sent',
  'Sent to Firi': 'Sent to Firi',
  'Set up or update the savings agreement in your bank':
    'Set up or update the savings agreement in your bank',
  'Set up savings agreement': 'Set up savings agreement',
  Settings: 'Settings',
  'Shape the future of Firi': 'Shape the future of Firi',
  'Share gift card': 'Share gift card',
  'Share your referral code or link from the Firi Affiliate section of the app. To receive rewards, the new user must register and verify their account.':
    'Share your referral code or link from the Firi Affiliate section of the app. To receive rewards, the new user must register and verify their account.',
  'Short summary': 'Short summary',
  'Should we send you a notification when the money is in your Firi account?':
    'Should we send you a notification when the money is in your Firi account?',
  'Should we send you a push notification when the cryptocurrency has been sent?':
    'Should we send you a push notification when the cryptocurrency has been sent?',
  'Should we send you a push notification when you receive crypto?':
    'Should we send you a push notification when you receive crypto?',
  Show: 'Show',
  'Show address': 'Show address',
  'Show all': 'Show all',
  'Show details': 'Show details',
  'Show less': 'Show less',
  'Show more': 'Show more',
  'Show section': 'Show section',
  'Show tax calculation': 'Show tax calculation',
  'Show transactions for': 'Show transactions for',
  'Show transactions for {year}': ['Show transactions for ', ['year']],
  'Sign In': 'Sign In',
  'Sign in': 'Sign in',
  'Sign in here': 'Sign in here',
  'Sign in with': 'Sign in with',
  'Sign in with email': 'Sign in with email',
  'Sign out': 'Sign out',
  'Sign up': 'Sign up',
  'Sign up easily and safely with crypto.': 'Sign up easily and safely with crypto.',
  'Sign up for free': 'Sign up for free',
  'Sign up here': 'Sign up here',
  'Sign with BankID': 'Sign with BankID',
  'Since you have recently made a deposit, we are still in the process of receiving the latest information from your bank.':
    'Since you have recently made a deposit, we are still in the process of receiving the latest information from your bank.',
  'Since you have recently made a deposit, we are still in the process of receiving the latest information from your bank. We usually receive this information one working day after the deposit':
    'Since you have recently made a deposit, we are still in the process of receiving the latest information from your bank. We usually receive this information one working day after the deposit',
  Skip: 'Skip',
  'So nice! You have received a gift in crypto': 'So nice! You have received a gift in crypto',
  'Social media (Reddit, Twitter, etc.)': 'Social media (Reddit, Twitter, etc.)',
  'Social security number': 'Social security number',
  "Solana is a decentralized and programmable blockchain and a cryptocurrency. Solana's blockchain is designed specifically to offer scalable, decentralized apps and smart contracts.":
    "Solana is a decentralized and programmable blockchain and a cryptocurrency. Solana's blockchain is designed specifically to offer scalable, decentralized apps and smart contracts.",
  "Solana itself claims that they are the world's fastest blockchain, and with up to 65,000 transactions per second, you should not ignore the fact that they are absolutely right.":
    "Solana itself claims that they are the world's fastest blockchain, and with up to 65,000 transactions per second, you should not ignore the fact that they are absolutely right.",
  'Solana network': 'Solana network',
  "Solana's cryptocurrency is called Solana, but is also known under the ticker SOL. Solana is one of the world's largest cryptocurrencies based on market value.":
    "Solana's cryptocurrency is called Solana, but is also known under the ticker SOL. Solana is one of the world's largest cryptocurrencies based on market value.",
  Sold: 'Sold',
  'Some money to spare? Make an extra deposit': 'Some money to spare? Make an extra deposit',
  'Something is wrong with your account. Please contact us and give error code: 3013 for further assistant: support@firi.com':
    'Something is wrong with your account. Please contact us and give error code: 3013 for further assistant: support@firi.com',
  'Something is wrong. Please contact support': 'Something is wrong. Please contact support',
  'Something went wrong': 'Something went wrong',
  'Something went wrong activating staking': 'Something went wrong activating staking',
  'Something went wrong resending code': 'Something went wrong resending code',
  'Something went wrong trying to activate staking. Try again or contact support if the issue persists':
    'Something went wrong trying to activate staking. Try again or contact support if the issue persists',
  'Something went wrong when creating gift card. Please retry or contact support for help.':
    'Something went wrong when creating gift card. Please retry or contact support for help.',
  'Something went wrong. Please try again or contact our support.':
    'Something went wrong. Please try again or contact our support.',
  'Something went wrong. Please try again or contact support.':
    'Something went wrong. Please try again or contact support.',
  'Something went wrong. Unable to place your order. Try again?':
    'Something went wrong. Unable to place your order. Try again?',
  'Something went wrong. We were not able to cancel your withdraw':
    'Something went wrong. We were not able to cancel your withdraw',
  'Sorry!': 'Sorry!',
  'Sort your crypto': 'Sort your crypto',
  'Specify the desired amount for your transfer.': 'Specify the desired amount for your transfer.',
  Spread: 'Spread',
  'Spring cleaning 🧹': 'Spring cleaning 🧹',
  Stablecoins: 'Stablecoins',
  Stake: 'Stake',
  'Stake crypto I own': 'Stake crypto I own',
  'Stake crypto now': 'Stake crypto now',
  'Stake more': 'Stake more',
  'Stake more crypto': 'Stake more crypto',
  Staked: 'Staked',
  'Staked now': 'Staked now',
  Staking: 'Staking',
  'Staking account': 'Staking account',
  'Start a savings agreement': 'Start a savings agreement',
  'Start here': 'Start here',
  'Start now': 'Start now',
  'Start or change savings from your bank': 'Start or change savings from your bank',
  'Start over': 'Start over',
  'Start saving': 'Start saving',
  'Start savings from your bank': 'Start savings from your bank',
  'Start staking': 'Start staking',
  'Start with crypto': 'Start with crypto',
  'Start with crypto today!': 'Start with crypto today!',
  'Statistics and analysis': 'Statistics and analysis',
  'Statistics shows that long term investments give better yield than buying and selling cryptocurrency based on the exchange rate':
    'Statistics shows that long term investments give better yield than buying and selling cryptocurrency based on the exchange rate',
  Status: 'Status',
  'Stellar network': 'Stellar network',
  'Store your configuration key in a safe place. You will need it if you lose your phone or data gets lost':
    'Store your configuration key in a safe place. You will need it if you lose your phone or data gets lost',
  'Strongest development': 'Strongest development',
  Submit: 'Submit',
  Summary: 'Summary',
  'Supported filetypes': 'Supported filetypes',
  Sweden: 'Sweden',
  Swedish: 'Swedish',
  'Sweet!': 'Sweet!',
  System: 'System',
  'TV campaign 2020': 'TV campaign 2020',
  'TV campaign 2021': 'TV campaign 2021',
  'Take a look around the app': 'Take a look around the app',
  'Take me back': 'Take me back',
  'Take me to': 'Take me to',
  'Take me to advanced trading': 'Take me to advanced trading',
  'Take photo': 'Take photo',
  'Take the survey': 'Take the survey',
  Tax: 'Tax',
  'Tax calculation': 'Tax calculation',
  'Tax calculation for': 'Tax calculation for',
  'Tax for': 'Tax for',
  'Tax for {mostRecentTaxYear} is ready': ['Tax for ', ['mostRecentTaxYear'], ' is ready'],
  'Tax for {year}': ['Tax for ', ['year']],
  'Taxable gain': 'Taxable gain',
  Taxes: 'Taxes',
  'Teach others about crypto!': 'Teach others about crypto!',
  'Temporary downtime': 'Temporary downtime',
  Terms: 'Terms',
  'Terms of service': 'Terms of service',
  'Terrorist financing': 'Terrorist financing',
  'Tether USDt (USDT) is a stablecoin tied to the value of the US Dollar (USD), and should therefore always be worth approximately 1 USD. Tether is one of the most widely used stablecoins in the market and was created to provide cryptocurrency users a way to maintain a stable value while still enjoying the benefits that blockchain technology offers, such as fast transfers and global accessibility.':
    'Tether USDt (USDT) is a stablecoin tied to the value of the US Dollar (USD), and should therefore always be worth approximately 1 USD. Tether is one of the most widely used stablecoins in the market and was created to provide cryptocurrency users a way to maintain a stable value while still enjoying the benefits that blockchain technology offers, such as fast transfers and global accessibility.',
  'Thank you for completing the facial recognition! We are now reviewing the information, and you will soon receive an email from us when your account is ready for use.':
    'Thank you for completing the facial recognition! We are now reviewing the information, and you will soon receive an email from us when your account is ready for use.',
  'Thank you for filling out the form! We are now checking your answers. You will soon receive an email from us so that you can start using our app.':
    'Thank you for filling out the form! We are now checking your answers. You will soon receive an email from us so that you can start using our app.',
  'Thank you for letting us get to know you, and for contributing to a safer crypto journey for everyone!':
    'Thank you for letting us get to know you, and for contributing to a safer crypto journey for everyone!',
  'Thank you for your patience and sorry for the inconvenience.':
    'Thank you for your patience and sorry for the inconvenience.',
  'Thank you very much!': 'Thank you very much!',
  'Thank you!': 'Thank you!',
  'Thank you! We are checking your information. Please wait a moment.':
    'Thank you! We are checking your information. Please wait a moment.',
  'Thank you! We will get back to you swiftly.': 'Thank you! We will get back to you swiftly.',
  'Thanks for the feedback!': 'Thanks for the feedback!',
  'That is OK': 'That is OK',
  "That's fine!": "That's fine!",
  "That's it! Your Crypto Savings will continue as usual.":
    "That's it! Your Crypto Savings will continue as usual.",
  'The account has insufficient funds': 'The account has insufficient funds',
  'The amount you give away is deducted directly from your cryptocurrency from your main account with Firi and locked until the gift card is redeemed by the recipient.':
    'The amount you give away is deducted directly from your cryptocurrency from your main account with Firi and locked until the gift card is redeemed by the recipient.',
  'The assets will be transferred to your main account.':
    'The assets will be transferred to your main account.',
  "The buy was automatically stopped. This happens, for example, when there are large price fluctuations. We don't want you to pay more than necessary for the crypto you want to buy.":
    "The buy was automatically stopped. This happens, for example, when there are large price fluctuations. We don't want you to pay more than necessary for the crypto you want to buy.",
  'The buy was not completed, but the money ({0}) has been transferred to your Firi account.': [
    'The buy was not completed, but the money (',
    ['0'],
    ') has been transferred to your Firi account.',
  ],
  'The calculations are continuously updated based on the transactions and changes made to your account. This means that this is not the final tax calculation.':
    'The calculations are continuously updated based on the transactions and changes made to your account. This means that this is not the final tax calculation.',
  'The campaign last from {0} until {1}': ['The campaign last from ', ['0'], ' until ', ['1']],
  'The christmas calendar starts 1st December - keep an eye out!':
    'The christmas calendar starts 1st December - keep an eye out!',
  'The cryptocurrency XLM, also known as Stellar Lumens, is a digital currency that operates on the Stellar network. The Stellar network is a decentralized platform designed for fast and affordable transfer of value across the globe. XLM functions as a means of payment on the Stellar network and is used to facilitate transactions across different currencies and national borders.':
    'The cryptocurrency XLM, also known as Stellar Lumens, is a digital currency that operates on the Stellar network. The Stellar network is a decentralized platform designed for fast and affordable transfer of value across the globe. XLM functions as a means of payment on the Stellar network and is used to facilitate transactions across different currencies and national borders.',
  'The cryptocurrency is usually sent immediately.':
    'The cryptocurrency is usually sent immediately.',
  'The development shows how your staking account investment develops over time.':
    'The development shows how your staking account investment develops over time.',
  'The different percentages you choose for the different cryptocurrencies must add up to 100':
    'The different percentages you choose for the different cryptocurrencies must add up to 100',
  'The distribution exceeds 100%': 'The distribution exceeds 100%',
  'The documentation you upload will of course be processed as confidential.':
    'The documentation you upload will of course be processed as confidential.',
  'The easiest explanation of staking is that you store your cryptocurrency in order to receive a reward in the form of more crypto.':
    'The easiest explanation of staking is that you store your cryptocurrency in order to receive a reward in the form of more crypto.',
  'The easiest explanation of staking is that you store, and sometimes lock, your cryptocurrency in order to receive a reward in the form of more crypto.':
    'The easiest explanation of staking is that you store, and sometimes lock, your cryptocurrency in order to receive a reward in the form of more crypto.',
  'The email registered to your Vipps has been found in our systems and cannot be reused. Please login to Firi and integrate Vipps through settings.':
    'The email registered to your Vipps has been found in our systems and cannot be reused. Please login to Firi and integrate Vipps through settings.',
  'The fees are deducted from the amount of crypto you are selling.':
    'The fees are deducted from the amount of crypto you are selling.',
  'The fees are deducted from the amount you buy crypto for.':
    'The fees are deducted from the amount you buy crypto for.',
  'The funds will be transferred to your staking account.':
    'The funds will be transferred to your staking account.',
  'The information from this survey will only be used to obtain a better picture of our customers needs and wishes regarding services and information, and will not be used in connection with marketing.':
    'The information from this survey will only be used to obtain a better picture of our customers needs and wishes regarding services and information, and will not be used in connection with marketing.',
  'The information we collect about you is treated confidentially. Read more about what information we store and why in our':
    'The information we collect about you is treated confidentially. Read more about what information we store and why in our',
  'The largest Nordic crypto exchange': 'The largest Nordic crypto exchange',
  'The main purpose of XRP is to be a kind of broker between different currencies - both cryptocurrency and fiat. XRP is built on the blockchain technology XRP Ledger.':
    'The main purpose of XRP is to be a kind of broker between different currencies - both cryptocurrency and fiat. XRP is built on the blockchain technology XRP Ledger.',
  "The majority of all cryptocurrencies and decentralized applications in the world are built on Ethereum's blockchain.":
    "The majority of all cryptocurrencies and decentralized applications in the world are built on Ethereum's blockchain.",
  'The minimum amount for withdrawal is {0}. In order to withdraw money, you must sell crypto.': [
    'The minimum amount for withdrawal is ',
    ['0'],
    '. In order to withdraw money, you must sell crypto.',
  ],
  'The minimum amount to stake {currency} is {0} {currency}. You can buy {currency} immediately': [
    'The minimum amount to stake ',
    ['currency'],
    ' is ',
    ['0'],
    ' ',
    ['currency'],
    '. You can buy ',
    ['currency'],
    ' immediately',
  ],
  'The minimum amount you can save in a cryptocurrency is {SAVING_AGREEMENT_DETAIL_MIN_AMOUNT} {0}':
    [
      'The minimum amount you can save in a cryptocurrency is ',
      ['SAVING_AGREEMENT_DETAIL_MIN_AMOUNT'],
      ' ',
      ['0'],
    ],
  'The money is being transferred to us': 'The money is being transferred to us',
  'The money is on its way from your bank to our bank. This usually takes 1-2 business days, but it can also be transferred in minutes.':
    'The money is on its way from your bank to our bank. This usually takes 1-2 business days, but it can also be transferred in minutes.',
  'The money may have been deducted from your account.':
    'The money may have been deducted from your account.',
  'The most common destinations': 'The most common destinations',
  'The one-time password has already been used.': 'The one-time password has already been used.',
  'The price differentiate more than approx. <0>{absolutePercent}%</0> compared to the international price index. The reason is high demand and few people want to sell {currency} right now. That is why we have a blockage to avoid you buying for an expensive price.':
    [
      'The price differentiate more than approx. <0>',
      ['absolutePercent'],
      '%</0> compared to the international price index. The reason is high demand and few people want to sell ',
      ['currency'],
      ' right now. That is why we have a blockage to avoid you buying for an expensive price.',
    ],
  'The price differentiate more than approx. <0>{absolutePercent}%</0> compared to the international price index. The reason is low demand and few people want to buy {currency} right now. That is why we have a blockage to avoid you selling too cheap.':
    [
      'The price differentiate more than approx. <0>',
      ['absolutePercent'],
      '%</0> compared to the international price index. The reason is low demand and few people want to buy ',
      ['currency'],
      ' right now. That is why we have a blockage to avoid you selling too cheap.',
    ],
  'The price is locked in during the trade.': 'The price is locked in during the trade.',
  'The price is too high': 'The price is too high',
  'The price is too low': 'The price is too low',
  'The price is way above the index': 'The price is way above the index',
  'The processing time is dependent on the amount you are unstaking and how many others that are wanting to deactivate staking.':
    'The processing time is dependent on the amount you are unstaking and how many others that are wanting to deactivate staking.',
  'The products of their Ripple Labs are used for payment settlements, asset exchanges and transfer systems - including by several large banks.':
    'The products of their Ripple Labs are used for payment settlements, asset exchanges and transfer systems - including by several large banks.',
  'The purpose of this reward is simply to share knowledge about cryptocurrency and Firi. You get a regular referral bonus in addition 🤯':
    'The purpose of this reward is simply to share knowledge about cryptocurrency and Firi. You get a regular referral bonus in addition 🤯',
  'The purpose of this reward is to share knowledge about crypto and invite others to discover a new way of investing.':
    'The purpose of this reward is to share knowledge about crypto and invite others to discover a new way of investing.',
  'The recipient is a business': 'The recipient is a business',
  'The reward is what you have received from the blockchain as a thank you for staking. Note that the value changes with the crypto prices.':
    'The reward is what you have received from the blockchain as a thank you for staking. Note that the value changes with the crypto prices.',
  'The tax calculation for {0} is ready!': ['The tax calculation for ', ['0'], ' is ready!'],
  'The tax calculation for {mostRecentTaxYear} is ready!': [
    'The tax calculation for ',
    ['mostRecentTaxYear'],
    ' is ready!',
  ],
  "The tax calculation will be available when it's time to calculate the tax for 2022.":
    "The tax calculation will be available when it's time to calculate the tax for 2022.",
  'The total price is too low': 'The total price is too low',
  'The trading fee will be deducted from the amount.':
    'The trading fee will be deducted from the amount.',
  'The transaction is usually sent immediately.': 'The transaction is usually sent immediately.',
  'The transaction was not completed.': 'The transaction was not completed.',
  'The value of meme coins comes from viral spread on the internet, hype culture, and humor. Initially, they do not aim to innovate, develop, or improve current financial systems':
    'The value of meme coins comes from viral spread on the internet, hype culture, and humor. Initially, they do not aim to innovate, develop, or improve current financial systems',
  'The value of meme coins follows market cycles': 'The value of meme coins follows market cycles',
  'The value of meme coins is driven by viral posts on the internet':
    'The value of meme coins is driven by viral posts on the internet',
  'The winner will be contacted by our support!. See you tomorrow!':
    'The winner will be contacted by our support!. See you tomorrow!',
  'The withdraw is processing': 'The withdraw is processing',
  'The withdrawal fee is': 'The withdrawal fee is',
  'The withdrawal limit is renewed 24 hours from the withdrawal was made.':
    'The withdrawal limit is renewed 24 hours from the withdrawal was made.',
  'The word "crypto" comes from the concept of cryptography, which is central to blockchain technology.':
    'The word "crypto" comes from the concept of cryptography, which is central to blockchain technology.',
  Theme: 'Theme',
  'There are different ways of calculating profit and loss. We use the most common method, which excludes deposits and withdrawals from the calculation. In short, we divide the calculation period into intervals (minutes, days etc.), calculate the rate of return for each intervals, and chain these together to find the total rate of return for the entire calculation period.':
    'There are different ways of calculating profit and loss. We use the most common method, which excludes deposits and withdrawals from the calculation. In short, we divide the calculation period into intervals (minutes, days etc.), calculate the rate of return for each intervals, and chain these together to find the total rate of return for the entire calculation period.',
  'There are many people buying and selling cryptocurrencies right now, which is leading to a constant change in price. To be certain that you order is correct, we ask you to place the order again.':
    'There are many people buying and selling cryptocurrencies right now, which is leading to a constant change in price. To be certain that you order is correct, we ask you to place the order again.',
  'There are no orders in this market': 'There are no orders in this market',
  'There are two reasons to buy and hold the cryptocurrency MANA. One is as a means for transactions within the game. The other is if one wants to invest in MANA because they believe Decentraland will be a major success or because they want to speculate in the crypto-gaming niche.':
    'There are two reasons to buy and hold the cryptocurrency MANA. One is as a means for transactions within the game. The other is if one wants to invest in MANA because they believe Decentraland will be a major success or because they want to speculate in the crypto-gaming niche.',
  'There are two reasons to buy and hold the cryptocurrency SAND. One is as a means for transactions within the game. The other is if one wants to invest in SAND because they believe The Sandbox will be a major success or because they want to speculate in the crypto-gaming niche.':
    'There are two reasons to buy and hold the cryptocurrency SAND. One is as a means for transactions within the game. The other is if one wants to invest in SAND because they believe The Sandbox will be a major success or because they want to speculate in the crypto-gaming niche.',
  'There is a new update!': 'There is a new update!',
  'There is an age limit of 18 to create an account with Firi. We warmly welcome you back when you turn 18!':
    'There is an age limit of 18 to create an account with Firi. We warmly welcome you back when you turn 18!',
  "There is one thing that many Firi customers have asked for in the app, and that's an easy and intuitive way to see their rate of return; profits and losses on their crypto investment.":
    "There is one thing that many Firi customers have asked for in the app, and that's an easy and intuitive way to see their rate of return; profits and losses on their crypto investment.",
  'These are crypto based on memes and internet culture':
    'These are crypto based on memes and internet culture',
  'These are crypto that are considered more environmentally friendly than other cryptos':
    'These are crypto that are considered more environmentally friendly than other cryptos',
  'These are crypto that are used in decentralized finance':
    'These are crypto that are used in decentralized finance',
  'These are crypto that use proof-of-work consensus mechanism and therefore can be mined':
    'These are crypto that use proof-of-work consensus mechanism and therefore can be mined',
  'These are crypto that you can stake and earn rewards on with Firi':
    'These are crypto that you can stake and earn rewards on with Firi',
  'These are cryptos that follow the value of a regular currency, and therefore have a more stable price':
    'These are cryptos that follow the value of a regular currency, and therefore have a more stable price',
  'These are the crypto most people choose to start with on Firi':
    'These are the crypto most people choose to start with on Firi',
  'These cookies collect information about how you use our websites and what that is interesting to you. In this way, we can provide you with relevant content and personalized marketing.':
    'These cookies collect information about how you use our websites and what that is interesting to you. In this way, we can provide you with relevant content and personalized marketing.',
  'These cookies collect statistics about how our websites are used. This gives us valuable insight that allows us to improve and your customer experience.':
    'These cookies collect statistics about how our websites are used. This gives us valuable insight that allows us to improve and your customer experience.',
  'This account has Vipps integration.': 'This account has Vipps integration.',
  'This account number is used by a bank not supported by Firi.':
    'This account number is used by a bank not supported by Firi.',
  'This content is for information purposes only and should not be taken as financial advice.':
    'This content is for information purposes only and should not be taken as financial advice.',
  'This deposit is a payment from mining, staking or other income':
    'This deposit is a payment from mining, staking or other income',
  'This is a market order.': 'This is a market order.',
  'This is a trade executed directly with Firi.': 'This is a trade executed directly with Firi.',
  "This is because you have more in gain than loss. We've figured this out for you.":
    "This is because you have more in gain than loss. We've figured this out for you.",
  'This is cryptocurrency that has not been purchased on Firi. We need this information to be able to give you a correct tax calculation.':
    'This is cryptocurrency that has not been purchased on Firi. We need this information to be able to give you a correct tax calculation.',
  'This is how the development of the portfolio is displayed':
    'This is how the development of the portfolio is displayed',
  'This is often compared to putting money in the bank to receive interest, but there are still big, fundamental differences.':
    'This is often compared to putting money in the bank to receive interest, but there are still big, fundamental differences.',
  'This is the information you have provided to Firi. Verify that these settings match with the recurring transfer you have set up in you bank.':
    'This is the information you have provided to Firi. Verify that these settings match with the recurring transfer you have set up in you bank.',
  'This is your total crypto fortune at Firi as of 31.12.{year}': [
    'This is your total crypto fortune at Firi as of 31.12.',
    ['year'],
  ],
  'This is your total crypto income at Firi as of 31.12.{year}': [
    'This is your total crypto income at Firi as of 31.12.',
    ['year'],
  ],
  'This is your total crypto loss at Firi as of 31.12.{year}': [
    'This is your total crypto loss at Firi as of 31.12.',
    ['year'],
  ],
  'This is your total crypto profit at Firi as of 31.12.{year}': [
    'This is your total crypto profit at Firi as of 31.12.',
    ['year'],
  ],
  'This is your total crypto<0/> at Firi per': 'This is your total crypto<0/> at Firi per',
  "This is your {0} balance. Expected annual yield is {1}% and hence <0/> at today's {2} exchange rate.":
    [
      'This is your ',
      ['0'],
      ' balance. Expected annual yield is ',
      ['1'],
      "% and hence <0/> at today's ",
      ['2'],
      ' exchange rate.',
    ],
  'This month': 'This month',
  'This security measure is necessary.': 'This security measure is necessary.',
  'This version of the Firi-app is no longer available. You can download the newest version of the app in {store} now':
    [
      'This version of the Firi-app is no longer available. You can download the newest version of the app in ',
      ['store'],
      ' now',
    ],
  'This vipps account is already registered to Firi.':
    'This vipps account is already registered to Firi.',
  'This will be quick - only a few minutes. Thank you for helping out!':
    'This will be quick - only a few minutes. Thank you for helping out!',
  'Thuc and the rest of the Firi team': 'Thuc and the rest of the Firi team',
  'Time to update your information, {0}!': ['Time to update your information, ', ['0'], '!'],
  'Tips for the best Firi-experience': 'Tips for the best Firi-experience',
  To: 'To',
  'To (year)': 'To (year)',
  'To access the meme coins category and the opportunity to invest in {currency}, you first need to know what this entails.':
    [
      'To access the meme coins category and the opportunity to invest in ',
      ['currency'],
      ', you first need to know what this entails.',
    ],
  'To account': 'To account',
  'To activate 2FA, you need to have an app that generates 2FA codes. We recommend Google Authenticator, but all 2FA apps work with Firi.':
    'To activate 2FA, you need to have an app that generates 2FA codes. We recommend Google Authenticator, but all 2FA apps work with Firi.',
  'To bank account': 'To bank account',
  'To become a customer with us, all information must be correct and verifiable. Therefore, it is not possible to have a secret name or use pseudonyms. We require full legal names to ensure compliance with anti-money laundering regulations and protect against fraud.\\n\\nThis practice creates a safe and secure experience for all our customers. Thank you for your understanding, and we look forward to welcoming you.':
    'To become a customer with us, all information must be correct and verifiable. Therefore, it is not possible to have a secret name or use pseudonyms. We require full legal names to ensure compliance with anti-money laundering regulations and protect against fraud.\\n\\nThis practice creates a safe and secure experience for all our customers. Thank you for your understanding, and we look forward to welcoming you.',
  'To comply with international regulations, we need to collect certain information when you send or receive cryptocurrency. This rule, called the "Travel Rule", ensures that both the sender\'s and recipient\'s information is secure and traceable. It is designed to protect against fraud and enhance transaction security between platforms.':
    'To comply with international regulations, we need to collect certain information when you send or receive cryptocurrency. This rule, called the "Travel Rule", ensures that both the sender\'s and recipient\'s information is secure and traceable. It is designed to protect against fraud and enhance transaction security between platforms.',
  'To comply with legal requirements and protect your account, we need your CPR number again. Although you have already provided it via MitID, we kindly ask you to enter it here.':
    'To comply with legal requirements and protect your account, we need your CPR number again. Although you have already provided it via MitID, we kindly ask you to enter it here.',
  'To comply with regulatory requirements, we need more details about the recipient before your withdrawal can be completed.':
    'To comply with regulatory requirements, we need more details about the recipient before your withdrawal can be completed.',
  "To comply with the new EU regulation (MiCA), all Danish customers will be transferred to Firi's Danish subsidiary, Firi ApS. To regain full access to your account, you must accept this change.":
    "To comply with the new EU regulation (MiCA), all Danish customers will be transferred to Firi's Danish subsidiary, Firi ApS. To regain full access to your account, you must accept this change.",
  'To disable your authenticator, please enter the two factor token from your app.':
    'To disable your authenticator, please enter the two factor token from your app.',
  'To ensure that you understand the risk involved when investing in meme coins you will have to answer a few questions.':
    'To ensure that you understand the risk involved when investing in meme coins you will have to answer a few questions.',
  'To ensure your security, we require that you activate and use two-factor authentication (2FA) when you register and log in with your username and password.':
    'To ensure your security, we require that you activate and use two-factor authentication (2FA) when you register and log in with your username and password.',
  'To include destination tag when sending {0}': [
    'To include destination tag when sending ',
    ['0'],
  ],
  'To main account': 'To main account',
  'To make a deposit to your savings account you first need to have a savings agreement, so that we know how to invest your funds':
    'To make a deposit to your savings account you first need to have a savings agreement, so that we know how to invest your funds',
  'To overview': 'To overview',
  'To proceed creating an account, the following requirements must be fullfilled:':
    'To proceed creating an account, the following requirements must be fullfilled:',
  'To receive push notifications from Firi, you need to have enabled notifications in your device settings.':
    'To receive push notifications from Firi, you need to have enabled notifications in your device settings.',
  'To receive the reward of {0} {1} in {2}, you need to refer at least <0>{3} friends</0> to Firi and they must <1>make their first investment</1> with us before January 1st.':
    [
      'To receive the reward of ',
      ['0'],
      ' ',
      ['1'],
      ' in ',
      ['2'],
      ', you need to refer at least <0>',
      ['3'],
      ' friends</0> to Firi and they must <1>make their first investment</1> with us before January 1st.',
    ],
  'To receive the reward, you need to refer at least {0} friends to Firi and they must feel comfortable enough with crypto and Firi to make their first trade.':
    [
      'To receive the reward, you need to refer at least ',
      ['0'],
      ' friends to Firi and they must feel comfortable enough with crypto and Firi to make their first trade.',
    ],
  'To savings account': 'To savings account',
  'To sell crypto from your savings account you must first make an internal transfer to your main account.':
    'To sell crypto from your savings account you must first make an internal transfer to your main account.',
  'To set the exchange rate, we use a global price index. We add a markup of 1-3 percent based on the market situation, volatility, and the amount of crypto traded on Firi.':
    'To set the exchange rate, we use a global price index. We add a markup of 1-3 percent based on the market situation, volatility, and the amount of crypto traded on Firi.',
  'To sign up': 'To sign up',
  'To sign up with MitId': 'To sign up with MitId',
  'To sign up with Vipps': 'To sign up with Vipps',
  'To staking account': 'To staking account',
  'To what extent do you agree with the following statement: "I felt I had knowledge about crypto before I started using Firi".':
    'To what extent do you agree with the following statement: "I felt I had knowledge about crypto before I started using Firi".',
  'To {storeVersion}': ['To ', ['storeVersion']],
  Today: 'Today',
  'Today, {0}': ['Today, ', ['0']],
  'Toggle rate or amount': 'Toggle rate or amount',
  'Too good to be true? No. There is a good reason why you receive this reward.':
    'Too good to be true? No. There is a good reason why you receive this reward.',
  'Top 10': 'Top 10',
  'Top 10 crypto on Firi': 'Top 10 crypto on Firi',
  'Top 10 cryptos with the highest trading volume the last 24 hours':
    'Top 10 cryptos with the highest trading volume the last 24 hours',
  'Top up Firi-account': 'Top up Firi-account',
  'Top up your account': 'Top up your account',
  Total: 'Total',
  'Total amount': 'Total amount',
  'Total balance': 'Total balance',
  'Total paid out': 'Total paid out',
  'Total pending': 'Total pending',
  'Total price': 'Total price',
  'Total referrals': 'Total referrals',
  'Total reward for staked crypto': 'Total reward for staked crypto',
  'Trade for more than {0} {currency}': ['Trade for more than ', ['0'], ' ', ['currency']],
  'Trade for more than {0} {currency} in all markets': [
    'Trade for more than ',
    ['0'],
    ' ',
    ['currency'],
    ' in all markets',
  ],
  'Trade for over {0} {currency}': ['Trade for over ', ['0'], ' ', ['currency']],
  'Trade for over {0} {currency} in three markets': [
    'Trade for over ',
    ['0'],
    ' ',
    ['currency'],
    ' in three markets',
  ],
  'Trade history': 'Trade history',
  Trades: 'Trades',
  'Trades on your savings account': 'Trades on your savings account',
  'Trading fee is 0.7 percent.': 'Trading fee is 0.7 percent.',
  'Trading fee is 0.7%.': 'Trading fee is 0.7%.',
  'Trading fee is {0} percent.': ['Trading fee is ', ['0'], ' percent.'],
  'Trading fees are required to cover our costs.': 'Trading fees are required to cover our costs.',
  'Trady history from another platform': 'Trady history from another platform',
  'Transaction ID': 'Transaction ID',
  'Transaction fee (optional)': 'Transaction fee (optional)',
  Transactions: 'Transactions',
  'Transactions cannot be reversed': 'Transactions cannot be reversed',
  Transfer: 'Transfer',
  'Transfer completed': 'Transfer completed',
  'Transfer crypto between main account and savings account':
    'Transfer crypto between main account and savings account',
  'Transfer crypto here': 'Transfer crypto here',
  'Transfer via online banking': 'Transfer via online banking',
  'Transferred and activated!': 'Transferred and activated!',
  Transfers: 'Transfers',
  'Transfers between Firi and external crypto wallets usually takes a couple of minutes. In some cases it can take a business day before the cryptocurrency arrives':
    'Transfers between Firi and external crypto wallets usually takes a couple of minutes. In some cases it can take a business day before the cryptocurrency arrives',
  'Transfers to and from savings account': 'Transfers to and from savings account',
  Trends: 'Trends',
  'Try again': 'Try again',
  'Try again later': 'Try again later',
  'Try again or contact support if the issue persists.':
    'Try again or contact support if the issue persists.',
  'Try now': 'Try now',
  'Turn on notifications': 'Turn on notifications',
  'Turn on notifications if you want us to give you a ping about the latest news in the crypto world, campaigns, when we launch new cryptos in the app and other exciting news.':
    'Turn on notifications if you want us to give you a ping about the latest news in the crypto world, campaigns, when we launch new cryptos in the app and other exciting news.',
  'Two factor authentication': 'Two factor authentication',
  'Two step verification': 'Two step verification',
  'Two-factor authentication (2FA) is an additional layer of security in addition to passwords when you log in to various services, including Firi.':
    'Two-factor authentication (2FA) is an additional layer of security in addition to passwords when you log in to various services, including Firi.',
  'Two-factor authentication disabled': 'Two-factor authentication disabled',
  'Two-factor authentication enabled': 'Two-factor authentication enabled',
  'Two-factor authentication is already set for this user':
    'Two-factor authentication is already set for this user',
  'Two-factor authentication is an important security measure. Using this makes it much harder for people to access your account by guessing your password. Creating passwords that are sufficiently unique, long and strong can be a challenge, so an extra layer of security is absolutely necessary for most people to avoid cybercrime.':
    'Two-factor authentication is an important security measure. Using this makes it much harder for people to access your account by guessing your password. Creating passwords that are sufficiently unique, long and strong can be a challenge, so an extra layer of security is absolutely necessary for most people to avoid cybercrime.',
  Type: 'Type',
  'Type here...': 'Type here...',
  'Type in the code from your authentication app': 'Type in the code from your authentication app',
  'Type in your personal code to open the Firi app. It contains six digits.':
    'Type in your personal code to open the Firi app. It contains six digits.',
  Types: 'Types',
  'UNI is the associated cryptocurrency of Uniswap. UNI serves as a "governance token" that enables users to participate in decisions regarding the future development of the Uniswap exchange.':
    'UNI is the associated cryptocurrency of Uniswap. UNI serves as a "governance token" that enables users to participate in decisions regarding the future development of the Uniswap exchange.',
  'US dollars': 'US dollars',
  'USD Coin (USDC) is a centralized cryptocurrency linked to the US dollar. That is, it aims to maintain a stable 1:1 value against the USD, and is thus a so-called "stablecoin".':
    'USD Coin (USDC) is a centralized cryptocurrency linked to the US dollar. That is, it aims to maintain a stable 1:1 value against the USD, and is thus a so-called "stablecoin".',
  'USDC is an ERC-20 token and is thus built on the Ethereum blockchain. USD Coin was launched in September 2018 as a collaboration between Circle and Coinbase. Like other stablecoins, the USDC can be defined as a tool for tokenizing US dollars. By moving US dollars to the blockchain, you can easily send them anywhere in the world in minutes and in addition it contributes to stability in a volatile crypto market.':
    'USDC is an ERC-20 token and is thus built on the Ethereum blockchain. USD Coin was launched in September 2018 as a collaboration between Circle and Coinbase. Like other stablecoins, the USDC can be defined as a tool for tokenizing US dollars. By moving US dollars to the blockchain, you can easily send them anywhere in the world in minutes and in addition it contributes to stability in a volatile crypto market.',
  'Unable to turn on notifications': 'Unable to turn on notifications',
  'Unable to verify your password reset claim. Are you sure you arrived here by following an email link?':
    'Unable to verify your password reset claim. Are you sure you arrived here by following an email link?',
  'Understand crypto': 'Understand crypto',
  "Uniswap is the world's largest and most well-known decentralized cryptocurrency exchange (also known as DEX). Uniswap operates on blockchains such as Ethereum, Polygon, BNB and more, and allows users from anywhere in the world to trade cryptocurrencies without any intermediary.":
    "Uniswap is the world's largest and most well-known decentralized cryptocurrency exchange (also known as DEX). Uniswap operates on blockchains such as Ethereum, Polygon, BNB and more, and allows users from anywhere in the world to trade cryptocurrencies without any intermediary.",
  Unknown: 'Unknown',
  'Unknown error': 'Unknown error',
  'Unknown purchase price': 'Unknown purchase price',
  'Unlock Firi': 'Unlock Firi',
  Unstake: 'Unstake',
  Unstaked: 'Unstaked',
  Unstaking: 'Unstaking',
  'Unstaking completed!': 'Unstaking completed!',
  "Until New Year's you get an extra reward for referring at least {0} friends to Firi.": [
    "Until New Year's you get an extra reward for referring at least ",
    ['0'],
    ' friends to Firi.',
  ],
  Update: 'Update',
  'Update account information': 'Update account information',
  'Update the app in Google Play Store to download your tax documents':
    'Update the app in Google Play Store to download your tax documents',
  'Update the app in the App Store to download your tax documents':
    'Update the app in the App Store to download your tax documents',
  'Update your transfer/payment with the account information below:':
    'Update your transfer/payment with the account information below:',
  'Updated terms': 'Updated terms',
  'Upload documentation': 'Upload documentation',
  'Upload file': 'Upload file',
  'Upload profile picture': 'Upload profile picture',
  'Upload relevant documentation here': 'Upload relevant documentation here',
  'Upload your tax report to Firi': 'Upload your tax report to Firi',
  'Uploaded document are stored under': 'Uploaded document are stored under',
  'Uploaded documentation': 'Uploaded documentation',
  'Upon registration, you will receive a welcome bonus. With us you can buy and sell bitcoin and other cryptocurrencies, safely and easily. We are registered with Finanstilsynet, have Vipps login, use BankID and Norwegian bank for deposits and withdrawals.':
    'Upon registration, you will receive a welcome bonus. With us you can buy and sell bitcoin and other cryptocurrencies, safely and easily. We are registered with Finanstilsynet, have Vipps login, use BankID and Norwegian bank for deposits and withdrawals.',
  'Upon registration, you will receive bitcoin as a welcome bonus. With us you can buy and sell bitcoin and other cryptocurrencies, safely and easily. We are registered with Finanstilsynet, have Vipps login, use BankID and Norwegian bank for deposits and withdrawals.':
    'Upon registration, you will receive bitcoin as a welcome bonus. With us you can buy and sell bitcoin and other cryptocurrencies, safely and easily. We are registered with Finanstilsynet, have Vipps login, use BankID and Norwegian bank for deposits and withdrawals.',
  'Use a two-factor authentication app such as': 'Use a two-factor authentication app such as',
  'Use of cookies': 'Use of cookies',
  'Use of wrong network or address kan lead to permanently loss of funds':
    'Use of wrong network or address kan lead to permanently loss of funds',
  'Use the search field to find the card for "Other financial products and virtual currency/cryptocurrency"':
    'Use the search field to find the card for "Other financial products and virtual currency/cryptocurrency"',
  Used: 'Used',
  'User cancelled the session.': 'User cancelled the session.',
  'User provided is already registered in Firi.': 'User provided is already registered in Firi.',
  'User transfer': 'User transfer',
  'Valuable insight': 'Valuable insight',
  Value: 'Value',
  'Value (crypto)': 'Value (crypto)',
  'Value ({0})': ['Value (', ['0'], ')'],
  'Value in {0}': ['Value in ', ['0']],
  'Value of 1 {0} at the time of the purchase': [
    'Value of 1 ',
    ['0'],
    ' at the time of the purchase',
  ],
  'Value you are sending': 'Value you are sending',
  Verification: 'Verification',
  'Verification failed. Are you sure you typed the correct code for Firi?':
    'Verification failed. Are you sure you typed the correct code for Firi?',
  Verify: 'Verify',
  'Verify with BankID': 'Verify with BankID',
  'Verify your account using BankID': 'Verify your account using BankID',
  'Verify your email': 'Verify your email',
  Version: 'Version',
  'Version:': 'Version:',
  'Very low level of knowledge': 'Very low level of knowledge',
  Vipps: 'Vipps',
  'Vipps fee': 'Vipps fee',
  'Vipps integration': 'Vipps integration',
  'Vipps signup not available': 'Vipps signup not available',
  "Vipps, and it's done!": "Vipps, and it's done!",
  Volume: 'Volume',
  'Volume (24h)': 'Volume (24h)',
  Voucher: 'Voucher',
  'Wallet transfers': 'Wallet transfers',
  'Want the newest version?': 'Want the newest version?',
  'Was your first crypto trade conducted on Firi?':
    'Was your first crypto trade conducted on Firi?',
  We: 'We',
  'We also ask you to familiarize yourself well with our <0>terms of use</0> before you create an savings account with Firi.':
    'We also ask you to familiarize yourself well with our <0>terms of use</0> before you create an savings account with Firi.',
  'We also ask you to familiarize yourself well with our<0>terms of use</0> before you create an account with Firi.':
    'We also ask you to familiarize yourself well with our<0>terms of use</0> before you create an account with Firi.',
  'We always store your personal information and your documentation securely. You can read more in our <0>terms of service</0> and <1>privacy policy</1>.':
    'We always store your personal information and your documentation securely. You can read more in our <0>terms of service</0> and <1>privacy policy</1>.',
  'We are always striving to improve and really appreciate you taking the time. Wishing you a lovely summer!':
    'We are always striving to improve and really appreciate you taking the time. Wishing you a lovely summer!',
  'We are currently experiencing increased activity, which may result in longer processing times for withdrawals. Thank you for your understanding':
    'We are currently experiencing increased activity, which may result in longer processing times for withdrawals. Thank you for your understanding',
  'We are discontinuing gift cards': 'We are discontinuing gift cards',
  'We are experiencing an increase in scams and investment fraud. Are you in contact with foreign people who are supposed to help you with investments? These often offer high profits and trick or exploit Norwegian people into investing in fake platforms or cryptocurrencies. If this is the case, we recommend that you end all contact with them and contact us via chat.':
    'We are experiencing an increase in scams and investment fraud. Are you in contact with foreign people who are supposed to help you with investments? These often offer high profits and trick or exploit Norwegian people into investing in fake platforms or cryptocurrencies. If this is the case, we recommend that you end all contact with them and contact us via chat.',
  'We are here to help you weekdays between 09:00 and 19:00':
    'We are here to help you weekdays between 09:00 and 19:00',
  'We are introducing Stellar (XLM)!': 'We are introducing Stellar (XLM)!',
  'We are introducing the meme coin {0} ({1})!': [
    'We are introducing the meme coin ',
    ['0'],
    ' (',
    ['1'],
    ')!',
  ],
  'We are introducing {0} ({1})!': ['We are introducing ', ['0'], ' (', ['1'], ')!'],
  'We are missing data from Vipps. Try again or contact support.':
    'We are missing data from Vipps. Try again or contact support.',
  'We are now deactivating staking on the amount.':
    'We are now deactivating staking on the amount.',
  'We are required by law to inform you if there is a data breach or any other situations where your personal information is at risk. We will do this as quickly as possible.':
    'We are required by law to inform you if there is a data breach or any other situations where your personal information is at risk. We will do this as quickly as possible.',
  'We are required by the authorities to gather updated information about you as a customer on a continual basis. As part of our follow-up we kindly ask you to answer some questions in detail and upload relevant information.':
    'We are required by the authorities to gather updated information about you as a customer on a continual basis. As part of our follow-up we kindly ask you to answer some questions in detail and upload relevant information.',
  'We are sorry, something went wrong.': 'We are sorry, something went wrong.',
  'We are waiting for a deposit': 'We are waiting for a deposit',
  'We are working on implementing a simple and user-friendly process for unstaking. Thank you for your understanding!':
    'We are working on implementing a simple and user-friendly process for unstaking. Thank you for your understanding!',
  'We ask that you familiarize yourself with all of our <0>terms of use</0> before you start staking crypto with Firi.':
    'We ask that you familiarize yourself with all of our <0>terms of use</0> before you start staking crypto with Firi.',
  'We ask you to familiarize yourself well with our <0>terms of use</0> before you create an account with Firi':
    'We ask you to familiarize yourself well with our <0>terms of use</0> before you create an account with Firi',
  'We calculate profit and losses on your crypto trades on Firis platform, and all you need to do is enter the numbers in the tax return yourself.':
    'We calculate profit and losses on your crypto trades on Firis platform, and all you need to do is enter the numbers in the tax return yourself.',
  "We can't find the page you are looking for.": "We can't find the page you are looking for.",
  "We can't process your order": "We can't process your order",
  'We charge a flat fee of {0} {currency} for each {1} withdrawal and transfer. The fee is added to the amount sent.':
    [
      'We charge a flat fee of ',
      ['0'],
      ' ',
      ['currency'],
      ' for each ',
      ['1'],
      ' withdrawal and transfer. The fee is added to the amount sent.',
    ],
  'We collect statistics on how our app is used. This gives us valuable insight that allows us to improve ourselves and your customer experience.':
    'We collect statistics on how our app is used. This gives us valuable insight that allows us to improve ourselves and your customer experience.',
  'We could not find a tax report': 'We could not find a tax report',
  'We could not find any accounts': 'We could not find any accounts',
  'We could not find the article': 'We could not find the article',
  'We could not find the news update': 'We could not find the news update',
  'We currently do not have tax calculations for your country':
    'We currently do not have tax calculations for your country',
  'We give you {referralAmount} {0} to trade for when you answer "Yes, please!" to the welcome\nbonus. Good luck with your investments!':
    [
      'We give you ',
      ['referralAmount'],
      ' ',
      ['0'],
      ' to trade for when you answer "Yes, please!" to the welcome\nbonus. Good luck with your investments!',
    ],
  'We have an 18-year age limit': 'We have an 18-year age limit',
  'We have done a thorough spring cleaning of the entire app, with several exciting updates and the launch of two new coins!':
    'We have done a thorough spring cleaning of the entire app, with several exciting updates and the launch of two new coins!',
  'We have given you <0/> so you can experience how safe and easy it is to invest in crypto with Firi.':
    'We have given you <0/> so you can experience how safe and easy it is to invest in crypto with Firi.',
  'We have made it safe and simple for all of our users to make long term investments in cryptocurrency.':
    'We have made it safe and simple for all of our users to make long term investments in cryptocurrency.',
  'We have made some changes in the tax calculation! You must therefore update to get the latest version':
    'We have made some changes in the tax calculation! You must therefore update to get the latest version',
  'We have now sent you an email with information on how to proceed to create a new password. It may take up to 10 minutes before you receive the email. Check spam folder as well.':
    'We have now sent you an email with information on how to proceed to create a new password. It may take up to 10 minutes before you receive the email. Check spam folder as well.',
  'We have obtained your information from Vipps. If you want to update your profile, contact our support.':
    'We have obtained your information from Vipps. If you want to update your profile, contact our support.',
  'We have security measures in place to prevent your personal information from being shared, altered, lost or misused. We only give employees access to your personal information if required. Employees with such access will treat the information confidentially.':
    'We have security measures in place to prevent your personal information from being shared, altered, lost or misused. We only give employees access to your personal information if required. Employees with such access will treat the information confidentially.',
  'We have sent you an email with a confirmation code':
    'We have sent you an email with a confirmation code',
  'We let you know when there are big movements crypto world.':
    'We let you know when there are big movements crypto world.',
  'We must ask all new customers some questions, as banks, real estate agents, and the rest of the financial industry are also required to do.':
    'We must ask all new customers some questions, as banks, real estate agents, and the rest of the financial industry are also required to do.',
  'We need a few more details': 'We need a few more details',
  'We need information from you': 'We need information from you',
  'We need to collect and use information for our app to work in a secure and accurate way.':
    'We need to collect and use information for our app to work in a secure and accurate way.',
  'We need your email address so we can contact you. Make sure it is correct. We will send a confirmation a little later.':
    'We need your email address so we can contact you. Make sure it is correct. We will send a confirmation a little later.',
  'We notify you when you have a reward waiting, have invited a friend or received a bonus payment.':
    'We notify you when you have a reward waiting, have invited a friend or received a bonus payment.',
  'We offer a local, safe and easy staking service!':
    'We offer a local, safe and easy staking service!',
  'We offer several different ways to buy cryptocurrency. This is what Firi customers prefer:':
    'We offer several different ways to buy cryptocurrency. This is what Firi customers prefer:',
  'We only allow withdrawals to personal bank accounts in your own name. A fee of <0/> will be deducted from the amount to cover our costs.':
    'We only allow withdrawals to personal bank accounts in your own name. A fee of <0/> will be deducted from the amount to cover our costs.',
  'We recommend that you learn the basics of cryptocurrency to form your own opinion. You can find more useful articles here in the app.':
    'We recommend that you learn the basics of cryptocurrency to form your own opinion. You can find more useful articles here in the app.',
  'We recommend you to try again later or add a custom order in "advanced trading"':
    'We recommend you to try again later or add a limit order in "advanced trading"',
  'We respect your privacy, so of course you can choose not to allow certain people types of cookies. Remember that if you choose to turn these off, it may affect your experience here with us.':
    'We respect your privacy, so of course you can choose not to allow certain people types of cookies. Remember that if you choose to turn these off, it may affect your experience here with us.',
  'We respect your privacy, so you can of course choose whether you want us to collect information that is not necessary for the app to work. Remember that if you choose not to consent to this, it can affect your experience here with us.':
    'We respect your privacy, so you can of course choose whether you want us to collect information that is not necessary for the app to work. Remember that if you choose not to consent to this, it can affect your experience here with us.',
  'We respect your privacy. Feel free to read our Privacy policy about what data we must collect and use for our app to work in a secure and correct way.':
    'We respect your privacy. Feel free to read our Privacy policy about what data we must collect and use for our app to work in a secure and correct way.',
  'We send you a notification when you have received a deposit, made a withdrawal, bought or sold crypto.':
    'We send you a notification when you have received a deposit, made a withdrawal, bought or sold crypto.',
  'We store your personal information for as long as is necessary for the purpose for which the personal information was collected.':
    'We store your personal information for as long as is necessary for the purpose for which the personal information was collected.',
  'We usually receive this information one working day after we have credited you. Therefore, you cannot withdraw these funds from us until we have received the needed information':
    'We usually receive this information one working day after we have credited you. Therefore, you cannot withdraw these funds from us until we have received the needed information',
  'We usually receive this information one working day after we have credited you. You can therefore not make a withdrawal of these funds from us until we have received the necessary information.':
    'We usually receive this information one working day after we have credited you. You can therefore not make a withdrawal of these funds from us until we have received the necessary information.',
  'We want to be better at Firi! Can you help us by answering a few questions?':
    'We want to be better at Firi! Can you help us by answering a few questions?',
  'We want to get to know you!': 'We want to get to know you!',
  'We want to get to know you, {0}!': ['We want to get to know you, ', ['0'], '!'],
  'We want to give you <0/> so that you can get to know Firi and invest in your first cryptocurrency.':
    'We want to give you <0/> so that you can get to know Firi and invest in your first cryptocurrency.',
  'We want to give you <0/> so you can experience how safe and easy it is to invest in crypto with Firi.':
    'We want to give you <0/> so you can experience how safe and easy it is to invest in crypto with Firi.',
  'We welcome the category meme coins and {currency} to Firi': [
    'We welcome the category meme coins and ',
    ['currency'],
    ' to Firi',
  ],
  'We were not able to find a user linked to you Vipps account. Register to start using Firi now!':
    'We were not able to find a user linked to you Vipps account. Register to start using Firi now!',
  'We were not able to process your payment. Try again or choose another payment method.':
    'We were not able to process your payment. Try again or choose another payment method.',
  'We were not able to remove bank account': 'We were not able to remove bank account',
  'We were not able to upload you document': 'We were not able to upload you document',
  'We were unable to complete the payment. Please try again or choose another payment method.':
    'We were unable to complete the payment. Please try again or choose another payment method.',
  'We were unable to complete the payment. Please try again or contact support if the issue persists.':
    'We were unable to complete the payment. Please try again or contact support if the issue persists.',
  'We were unable to send a password reset email': 'We were unable to send a password reset email',
  'We will not disclose your personal information to third parties unless there is a legal basis for such disclosure or it is necessary for us to fulfill our agreement with you.':
    'We will not disclose your personal information to third parties unless there is a legal basis for such disclosure or it is necessary for us to fulfill our agreement with you.',
  'We will now trade cryptocurrency on your behalf and you will receive an email when the funds are in your account.':
    'We will now trade cryptocurrency on your behalf and you will receive an email when the funds are in your account.',
  'We will send you a notification when a new calendar hatch is opened.':
    'We will send you a notification when a new calendar hatch is opened.',
  'We will send you a notification when we have promotions or want to tell you something special.':
    'We will send you a notification when we have promotions or want to tell you something special.',
  'We will send you a notification when you should update the app because we have launched new products or cryptocurrencies.':
    'We will send you a notification when you should update the app because we have launched new products or cryptocurrencies.',
  'We wish you a Merry Christmas and a Happy New Year':
    'We wish you a Merry Christmas and a Happy New Year',
  "We'll let you know when there's news.": "We'll let you know when there's news.",
  "We'll let you know when you can check what Firi Santa has hidden behind the first hatch.":
    "We'll let you know when you can check what Firi Santa has hidden behind the first hatch.",
  "We're opening the doors to two renowned crypto-gaming coins, namely Decentraland (MANA) and The Sandbox (SAND). You can find these under the new category Gaming/Web3.":
    "We're opening the doors to two renowned crypto-gaming coins, namely Decentraland (MANA) and The Sandbox (SAND). You can find these under the new category Gaming/Web3.",
  "We've created an overview of how deposits to your savings account are converted to crypto and how we find the best price.":
    "We've created an overview of how deposits to your savings account are converted to crypto and how we find the best price.",
  'Weakest development': 'Weakest development',
  'Welcome back {0}!': ['Welcome back ', ['0'], '!'],
  'Welcome bonus': 'Welcome bonus',
  'Welcome to Firi': 'Welcome to Firi',
  'Welcome to Firi Affiliate!': 'Welcome to Firi Affiliate!',
  'Welcome to Firi!': 'Welcome to Firi!',
  'Welcome to Firi. Get to know crypto and invest safely and easily.':
    'Welcome to Firi. Get to know crypto and invest safely and easily.',
  'What amount do you want to save': 'What amount do you want to save',
  'What are meme coins?': 'What are meme coins?',
  'What can we do better?': 'What can we do better?',
  'What do this overview show me?': 'What do this overview show me?',
  'What do you think about crypto news?': 'What do you think about crypto news?',
  'What is Firi?': 'What is Firi?',
  'What is crypto, really?': 'What is crypto, really?',
  'What is relevant documentation?': 'What is relevant documentation?',
  'What is staking and who does it work?': 'What is staking and who does it work?',
  'What is the primary driver behind the price of meme coins?':
    'What is the primary driver behind the price of meme coins?',
  'What is the reason you also use Firi?': 'What is the reason you also use Firi?',
  'What is your motivation for investing in crypto?':
    'What is your motivation for investing in crypto?',
  'What should your saving account be called?': 'What should your saving account be called?',
  'What stops you from investing more in crypto?': 'What stops you from investing more in crypto?',
  'What was the purchase price on this cryptocurrency?':
    'What was the purchase price on this cryptocurrency?',
  'What would you like to read more about?': 'What would you like to read more about?',
  "What's hiding behind today's hatch? 👀": "What's hiding behind today's hatch? 👀",
  When: 'When',
  'When all your information is up to date you will get the best user experience Firi has to offer.':
    'When all your information is up to date you will get the best user experience Firi has to offer.',
  'When can I expect my cryptocurrency to arrive': 'When can I expect my cryptocurrency to arrive',
  'When does my cryptocurrency get sent': 'When does my cryptocurrency get sent',
  'When does the cryptocurrency arrive': 'When does the cryptocurrency arrive',
  'When will I receive the reward?': 'When will I receive the reward?',
  'When will you receive the reward?': 'When will you receive the reward?',
  'When you buy crypto with money you have in your account with Firi, you pay a fee of 0.7%.':
    'When you buy crypto with money you have in your account with Firi, you pay a fee of 0.7%.',
  'When you buy cryptocurrency, you will fill one or several orders placed by other users. Orders have different volumes and we will always ensure that you buy from the cheapest order.':
    'When you buy cryptocurrency, you will fill one or several orders placed by other users. Orders have different volumes and we will always ensure that you buy from the cheapest order.',
  'When you sell cryptocurrency, you will fill one or several orders placed by other users. Orders have different volumes and we will always ensure that you sell from the cheapest order.':
    'When you sell cryptocurrency, you will fill one or several orders placed by other users. Orders have different volumes and we will always ensure that you sell from the cheapest order.',
  'When you use our website, create a user profile or are in contact with Firi, we will process personal information about you.':
    'When you use our website, create a user profile or are in contact with Firi, we will process personal information about you.',
  'Whenever I want': 'Whenever I want',
  'Where are you sending it?': 'Where are you sending it?',
  'Where can you find information and updates about crypto today?':
    'Where can you find information and updates about crypto today?',
  'Where do I find my tax return?': 'Where do I find my tax return?',
  'Where do i find my tax report?': 'Where do i find my tax report?',
  'Where do you live?': 'Where do you live?',
  'Which account do you want to stake from?': 'Which account do you want to stake from?',
  'Which account would you like to transfer the money to?':
    'Which account would you like to transfer the money to?',
  'Which bank account do you want the money paid out to? We only allow withdrawals to Norwegian bank accounts. Remember that the account must be in your name.':
    'Which bank account do you want the money paid out to? We only allow withdrawals to Norwegian bank accounts. Remember that the account must be in your name.',
  'Which country do you live in?': 'Which country do you live in?',
  'Which crypto can I get reward for?': 'Which crypto can I get reward for?',
  'Which currencies do you want to save in': 'Which currencies do you want to save in',
  'Which currencies do you want to save in? Choose one or more.':
    'Which currencies do you want to save in? Choose one or more.',
  'Which currency do you want to transfer?': 'Which currency do you want to transfer?',
  'Who holds most of the meme coins?': 'Who holds most of the meme coins?',
  'Who would you like to give a gift card to? Enter the name or nickname of the lucky recipient.':
    'Who would you like to give a gift card to? Enter the name or nickname of the lucky recipient.',
  Whoops: 'Whoops',
  'Why am I rewarded? 🤔': 'Why am I rewarded? 🤔',
  "Why can't I deactivate staking now?": "Why can't I deactivate staking now?",
  'Why do i need to download a 2FA-app?': 'Why do i need to download a 2FA-app?',
  'Why do you trade on other platforms/exchanges?':
    'Why do you trade on other platforms/exchanges?',
  'Why does this happen?': 'Why does this happen?',
  'Why is this required?': 'Why is this required?',
  'Why should I invest in cryptocurrency?': 'Why should I invest in cryptocurrency?',
  'Will my crypto be locked?': 'Will my crypto be locked?',
  "With Firi's tax calculation, you can quickly and easily calculate your crypto tax. Absolutely free!":
    "With Firi's tax calculation, you can quickly and easily calculate your crypto tax. Absolutely free!",
  "With Vipps we will automatically fill your profile information, and it does not cost anything. Sign with BankID and you're set!":
    "With Vipps we will automatically fill your profile information, and it does not cost anything. Sign with BankID and you're set!",
  'With email': 'With email',
  Withdraw: 'Withdraw',
  'Withdraw all': 'Withdraw all',
  'Withdraw completed!': 'Withdraw completed!',
  'Withdraw consent': 'Withdraw consent',
  'Withdraw fee': 'Withdraw fee',
  'Withdraw funds': 'Withdraw funds',
  'Withdraw limit': 'Withdraw limit',
  'Withdraw limits': 'Withdraw limits',
  'Withdraw money': 'Withdraw money',
  'Withdraw of {currency} is unavailable': ['Withdraw of ', ['currency'], ' is unavailable'],
  'Withdraw on its way!': 'Withdraw on its way!',
  'Withdraw request completed': 'Withdraw request completed',
  'Withdraw to bank': 'Withdraw to bank',
  'Withdraw {0}': ['Withdraw ', ['0']],
  Withdrawals: 'Withdrawals',
  "Withdrawals of {0} usually proceed from our account within 1-3 working days. Then it's up to your bank.":
    [
      'Withdrawals of ',
      ['0'],
      " usually proceed from our account within 1-3 working days. Then it's up to your bank.",
    ],
  'Would you look at that!': 'Would you look at that!',
  Write: 'Write',
  'Write here': 'Write here',
  'Write or paste in the confirmation code that was sent to you at {0}.': [
    'Write or paste in the confirmation code that was sent to you at ',
    ['0'],
    '.',
  ],
  'Write or paste in the confirmation code that was sent to you.':
    'Write or paste in the confirmation code that was sent to you.',
  'Wrong answer. Try again': 'Wrong answer. Try again',
  'Wrong one-time password has been entered too many times. Try again in 30 seconds or more.':
    'Wrong one-time password has been entered too many times. Try again in 30 seconds or more.',
  'Wrong username or password': 'Wrong username or password',
  "XRP is the original cryptocurrency for products developed by Ripple Labs. XRP is one of the world's largest cryptocurrencies based on market capitalization.":
    "XRP is the original cryptocurrency for products developed by Ripple Labs. XRP is one of the world's largest cryptocurrencies based on market capitalization.",
  Year: 'Year',
  Yes: 'Yes',
  'Yes, I want to be notified!': 'Yes, I want to be notified!',
  'Yes, and trade about the same on Firi as on other platforms':
    'Yes, and trade about the same on Firi as on other platforms',
  'Yes, and trade more on others': 'Yes, and trade more on others',
  'Yes, but trade less on others': 'Yes, but trade less on others',
  'Yes, turn on notification': 'Yes, turn on notification',
  'Yes, with meme coins you can lose 80-90% in a very short time':
    'Yes, with meme coins you can lose 80-90% in a very short time',
  'Yes, you can be sure that your digital assets are protected by the highest level of security on the market when using Firi.':
    'Yes, you can be sure that your digital assets are protected by the highest level of security on the market when using Firi.',
  Yesterday: 'Yesterday',
  'You absolutely do not need to be a crypto expert to stake. We at Firi take care of all the complicated things, so you can sit back and enjoy the fruits of your investment.':
    'You absolutely do not need to be a crypto expert to stake. We at Firi take care of all the complicated things, so you can sit back and enjoy the fruits of your investment.',
  'You already have a savings account': 'You already have a savings account',
  'You already have a staking account': 'You already have a staking account',
  "You answered today's question correctly and are entered into the draw for a Firi fleece and beanie 🎉. In addition, you will receive a lottery ticket for the grand prize on Christmas Eve":
    "You answered today's question correctly and are entered into the draw for a Firi fleece and beanie 🎉. In addition, you will receive a lottery ticket for the grand prize on Christmas Eve",
  "You answered today's question correctly and are entered into the draw for {0} {1} in {2} 🎉. In addition, you will receive a lottery ticket for the grand prize on Christmas Eve":
    [
      "You answered today's question correctly and are entered into the draw for ",
      ['0'],
      ' ',
      ['1'],
      ' in ',
      ['2'],
      ' 🎉. In addition, you will receive a lottery ticket for the grand prize on Christmas Eve',
    ],
  'You are a politically exposed person (PEP) if you have been entrusted with a prominent public function. We kindly ask you to respond "Yes" if you have a close family or work relationship to a PEP.':
    'You are a politically exposed person (PEP) if you have been entrusted with a prominent public function. We kindly ask you to respond "Yes" if you have a close family or work relationship to a PEP.',
  'You are invited by {0}, and we welcome you to Firi!': [
    'You are invited by ',
    ['0'],
    ', and we welcome you to Firi!',
  ],
  'You are saving': 'You are saving',
  'You are sending': 'You are sending',
  'You buy {currency} and confirm that you want to stake this.': [
    'You buy ',
    ['currency'],
    ' and confirm that you want to stake this.',
  ],
  'You can <0>only</0> transfer from an account registered in your company name. If we receive a payment from a company name other than your own, there will be major delays and we will often have to return the payment. You can not transfer from joint accounts, credit cards or Nordnet.':
    'You can <0>only</0> transfer from an account registered in your company name. If we receive a payment from a company name other than your own, there will be major delays and we will often have to return the payment. You can not transfer from joint accounts, credit cards or Nordnet.',
  'You can also continue on web with Vipps or email':
    'You can also continue on web with Vipps or email',
  'You can become a customer and buy crypto in the Firi app. The easiest way to download the Firi app is by scanning this QR code with the camera on your mobile:':
    'You can become a customer and buy crypto in the Firi app. The easiest way to download the Firi app is by scanning this QR code with the camera on your mobile:',
  'You can buy {currency} instantly with Vipps or card.': [
    'You can buy ',
    ['currency'],
    ' instantly with Vipps or card.',
  ],
  'You can buy {currency} instantly with card.': [
    'You can buy ',
    ['currency'],
    ' instantly with card.',
  ],
  'You can change this distribution in your savings agreement at any time.':
    'You can change this distribution in your savings agreement at any time.',
  'You can choose if you want to buy new {currency} or stake from you holdings.': [
    'You can choose if you want to buy new ',
    ['currency'],
    ' or stake from you holdings.',
  ],
  'You can choose to let us understand how you use our app and what interests you. This will give you relevant content via email and notifications in the app.':
    'You can choose to let us understand how you use our app and what interests you. This will give you relevant content via email and notifications in the app.',
  'You can deduct fees from trading crypto. Trading fee deductions are already included in Gain or Loss.':
    'You can deduct fees from trading crypto. Trading fee deductions are already included in Gain or Loss.',
  'You can earn more on your {0}': ['You can earn more on your ', ['0']],
  'You can easily change the name later': 'You can easily change the name later',
  'You can find this code in your 2FA-app. The code should always be 6 digits. Copy the code from your app and paste it here.':
    'You can find this code in your 2FA-app. The code should always be 6 digits. Copy the code from your app and paste it here.',
  'You can integrate Vipps to your Firi account to allow you to login with Vipps. After integrating with Vipps you can use both authentication methods. This feature is optional for existing Firi accounts.':
    'You can integrate Vipps to your Firi account to allow you to login with Vipps. After integrating with Vipps you can use both authentication methods. This feature is optional for existing Firi accounts.',
  'You can now create a savings agreement with automatic purchase of cryptocurrency.':
    'You can now create a savings agreement with automatic purchase of cryptocurrency.',
  'You can only have one savings account in Firi.':
    'You can only have one savings account in Firi.',
  'You can only have one staking account in Firi.':
    'You can only have one staking account in Firi.',
  'You can only make internal transfers to and from you savings account in Firi. If you want to transfer cryptocurrencies to or from another crypto wallet outside of Firi, you must first transfer to you main account and then to you savings account, or vice versa. It is free to make internal transfers.':
    'You can only make internal transfers to and from you savings account in Firi. If you want to transfer cryptocurrencies to or from another crypto wallet outside of Firi, you must first transfer to you main account and then to you savings account, or vice versa. It is free to make internal transfers.',
  'You can only transfer from an account registered in your company name. If we receive a payment from a company name other than your own, there will be major delays and we will often have to return the payment. You can not transfer from joint accounts, credit cards or Nordnet.':
    'You can only transfer from an account registered in your company name. If we receive a payment from a company name other than your own, there will be major delays and we will often have to return the payment. You can not transfer from joint accounts, credit cards or Nordnet.',
  'You can only transfer from an account registered in your name and address. If we receive a payment from a name or address other than your own, there will be major delays and we will often have to return the payment. You can not transfer from joint accounts, credit cards or Nordnet.':
    'You can only transfer from an account registered in your name and address. If we receive a payment from a name or address other than your own, there will be major delays and we will often have to return the payment. You can not transfer from joint accounts, credit cards or Nordnet.',
  'You can open {accountTrans} in the Firi app, but unfortunately not on the web yet': [
    'You can open ',
    ['accountTrans'],
    ' in the Firi app, but unfortunately not on the web yet',
  ],
  'You can open {accountTrans} in the Firi app, but unfortunately not on the web yet. The easiest way to download the Firi app is by scanning this QR code with the camera on your mobile:':
    [
      'You can open ',
      ['accountTrans'],
      ' in the Firi app, but unfortunately not on the web yet. The easiest way to download the Firi app is by scanning this QR code with the camera on your mobile:',
    ],
  'You can start earning rewards today! Both the funds that are staked and the reward you receive are safely stored on your staking account.':
    'You can start earning rewards today! Both the funds that are staked and the reward you receive are safely stored on your staking account.',
  'You can use the Polygon blockchain on many of the leading decentralized exchanges, as well as marketplaces for NFTs. Polygon is the most popular scaling solution for Ethereum measured by the number of daily users.':
    'You can use the Polygon blockchain on many of the leading decentralized exchanges, as well as marketplaces for NFTs. Polygon is the most popular scaling solution for Ethereum measured by the number of daily users.',
  'You cancelled a order': 'You cancelled a order',
  'You changed your password': 'You changed your password',
  'You created a voucher': 'You created a voucher',
  'You created this user account': 'You created this user account',
  'You deposited from a bank account': 'You deposited from a bank account',
  "You didn't answer today's question correctly, try again tomorrow!":
    "You didn't answer today's question correctly, try again tomorrow!",
  'You do not have a savings agreement yet, but it is easy to get started!':
    'You do not have a savings agreement yet, but it is easy to get started!',
  'You do not have any crypto deposits from {year}': [
    'You do not have any crypto deposits from ',
    ['year'],
  ],
  'You do not have any payouts yet': 'You do not have any payouts yet',
  'You do not have any transactions yet': 'You do not have any transactions yet',
  'You do not have crypto here yet, but it is easy to get started.':
    'You do not have crypto here yet, but it is easy to get started.',
  "You don't have any <0/> for {year}": ["You don't have any <0/> for ", ['year']],
  "You don't have any tickets yet, but there's still time to collect! Each ticket gives you an extra chance to win the grand prize on Christmas Eve. The more tickets, the greater the chance.":
    "You don't have any tickets yet, but there's still time to collect! Each ticket gives you an extra chance to win the grand prize on Christmas Eve. The more tickets, the greater the chance.",
  'You get a regular referral bonus in addition to this reward for everyone you refer 🔥':
    'You get a regular referral bonus in addition to this reward for everyone you refer 🔥',
  'You give': 'You give',
  'You give your consent for us to store your BankID-information.':
    'You give your consent for us to store your BankID-information.',
  'You give your consent for us to store your profile information.':
    'You give your consent for us to store your profile information.',
  'You have a number of legal rights in regards to your ownership of personal information we have stored about you and our use of this information.':
    'You have a number of legal rights in regards to your ownership of personal information we have stored about you and our use of this information.',
  'You have already opened todays hatch!': 'You have already opened todays hatch!',
  'You have already registered a user with Vipps. You can log in, or go back for more options.':
    'You have already registered a user with Vipps. You can log in, or go back for more options.',
  'You have answered {0}': ['You have answered ', ['0']],
  'You have assets that can be staked. Do you want rewards?':
    'You have assets that can be staked. Do you want rewards?',
  'You have changed the purchase price of one or more transactions. Previous tax calculations are therefore no longer correct. Click here to recalculate the tax. This may take a few minutes.':
    'You have changed the purchase price of one or more transactions. Previous tax calculations are therefore no longer correct. Click here to recalculate the tax. This may take a few minutes.',
  'You have decided to save {amount} {fiatMarketName} {0}. Enter this in the fixed transfer/payment.':
    [
      'You have decided to save ',
      ['amount'],
      ' ',
      ['fiatMarketName'],
      ' ',
      ['0'],
      '. Enter this in the fixed transfer/payment.',
    ],
  'You have enabled two-factor authentication': 'You have enabled two-factor authentication',
  'You have more ongoing transfers': 'You have more ongoing transfers',
  'You have no open orders': 'You have no open orders',
  'You have no pending payouts': 'You have no pending payouts',
  'You have no previous payments': 'You have no previous payments',
  'You have no referrals': 'You have no referrals',
  'You have no referrals being processed': 'You have no referrals being processed',
  'You have not added any accounts for withdrawals to bank':
    'You have not added any accounts for withdrawals to bank',
  'You have not referred anyone to Firi yet': 'You have not referred anyone to Firi yet',
  'You have now activated 2FA.': 'You have now activated 2FA.',
  'You have now created a savings agreement, and the last thing left is to log in to your online bank to activate it.':
    'You have now created a savings agreement, and the last thing left is to log in to your online bank to activate it.',
  'You have reached the withdrawal limit': 'You have reached the withdrawal limit',
  'You have received': 'You have received',
  'You have received <0/>!': 'You have received <0/>!',
  'You have received a gift card. Solve it in the Firi app!':
    'You have received a gift card. Solve it in the Firi app!',
  'You have received rewards from staking! This is what you have earned the last week.':
    'You have received rewards from staking! This is what you have earned the last week.',
  'You have registered with Firi and verified your identity using BankID':
    'You have registered with Firi and verified your identity using BankID',
  'You have {0} crypto deposits that are missing purchase price.': [
    'You have ',
    ['0'],
    ' crypto deposits that are missing purchase price.',
  ],
  'You have {0} crypto deposits that have not been purchased from Firi. Click here to change the purchase prices.':
    [
      'You have ',
      ['0'],
      ' crypto deposits that have not been purchased from Firi. Click here to change the purchase prices.',
    ],
  'You have {0} recent crypto deposits that are missing purchase price (acquisition value). We recommend that you add the purchase price so that your tax calculation to be correct.':
    [
      'You have ',
      ['0'],
      ' recent crypto deposits that are missing purchase price (acquisition value). We recommend that you add the purchase price so that your tax calculation to be correct.',
    ],
  'You have {count} crypto deposits that are missing the purchase price (acquisition value). This is cryptocurrency that has not been purchased from Firi. We need this information to be able to give you the correct tax calculation.':
    [
      'You have ',
      ['count'],
      ' crypto deposits that are missing the purchase price (acquisition value). This is cryptocurrency that has not been purchased from Firi. We need this information to be able to give you the correct tax calculation.',
    ],
  'You have {count} deposits of crypto currency that was not purchased at Firi': [
    'You have ',
    ['count'],
    ' deposits of crypto currency that was not purchased at Firi',
  ],
  'You have {remainingPercent}% to distribute': [
    'You have ',
    ['remainingPercent'],
    '% to distribute',
  ],
  'You might see an error indicating the distribution for some cryptocurrencies is too low. This happens when one distribution is less than {SAVING_AGREEMENT_DETAIL_MIN_AMOUNT} {0}':
    [
      'You might see an error indicating the distribution for some cryptocurrencies is too low. This happens when one distribution is less than ',
      ['SAVING_AGREEMENT_DETAIL_MIN_AMOUNT'],
      ' ',
      ['0'],
    ],
  'You must be over 18 years old': 'You must be over 18 years old',
  'You must have a valid BankID': 'You must have a valid BankID',
  'You must have a valid Norwegian national identity number':
    'You must have a valid Norwegian national identity number',
  'You must verify your email to continue to use Firi.':
    'You must verify your email to continue to use Firi.',
  'You need a 2FA app to generate codes that, in addition to your password, are used to unlock your account.':
    'You need a 2FA app to generate codes that, in addition to your password, are used to unlock your account.',
  'You need more {0}': ['You need more ', ['0']],
  'You need to activate staking to earn up to {0}% expected annual yield on your crypto.': [
    'You need to activate staking to earn up to ',
    ['0'],
    '% expected annual yield on your crypto.',
  ],
  'You need to enable notifications in device setting to receive notifications from Firi':
    'You need to enable notifications in device setting to receive notifications from Firi',
  'You need to give access to the camera in device setting to scan QR code. Reload the app after enabling permissions.':
    'You need to give access to the camera in device setting to scan QR code. Reload the app after enabling permissions.',
  'You need to give access to the camera in device setting to take a profile picture':
    'You need to give access to the camera in device setting to take a profile picture',
  'You need to update your account number and message for your recurring transfer in online banking':
    'You need to update your account number and message for your recurring transfer in online banking',
  'You now get {0} {1} in {2} for each friend you invite to Firi and becomes a customer with us.': [
    'You now get ',
    ['0'],
    ' ',
    ['1'],
    ' in ',
    ['2'],
    ' for each friend you invite to Firi and becomes a customer with us.',
  ],
  'You now have a staking account.': 'You now have a staking account.',
  'You password is changed! You will be logged out in a few seconds.':
    'You password is changed! You will be logged out in a few seconds.',
  'You privacy information are always safely stored with us.':
    'You privacy information are always safely stored with us.',
  'You received': 'You received',
  'You received a currency': 'You received a currency',
  'You sent a currency': 'You sent a currency',
  'You session is invalid. Please log out and try again.':
    'You session is invalid. Please log out and try again.',
  'You should not be here yet.': 'You should not be here yet.',
  'You supplied your name and address.': 'You supplied your name and address.',
  'You will find the code on your gift card itself. In case of problems, contact our support at support@firi.com or in the chat.':
    'You will find the code on your gift card itself. In case of problems, contact our support at support@firi.com or in the chat.',
  'You will find the cryptocurrency on your main account.':
    'You will find the cryptocurrency on your main account.',
  'You will hear from us!': 'You will hear from us!',
  'You will now be sent to your bank': 'You will now be sent to your bank',
  'You will now be sent to your payment provider': 'You will now be sent to your payment provider',
  'You will now find the gift in your main account.':
    'You will now find the gift in your main account.',
  'You will receive the referral bonus once the person you referred has registered using your code and verified their account.':
    'You will receive the referral bonus once the person you referred has registered using your code and verified their account.',
  "You're a bit too early...": "You're a bit too early...",
  "You're almost there!": "You're almost there!",
  "You're almost there! To meet the authorities requirements, we also need to verify your identity through facial recognition. Thank you for helping us secure your account!":
    "You're almost there! To meet the authorities requirements, we also need to verify your identity through facial recognition. Thank you for helping us secure your account!",
  "You're on the home stretch!": "You're on the home stretch!",
  "You're staking": "You're staking",
  "You're up to date!": "You're up to date!",
  'Your account is linked with Vipps': 'Your account is linked with Vipps',
  'Your answers are now registered': 'Your answers are now registered',
  'Your balance': 'Your balance',
  'Your bank account': 'Your bank account',
  'Your bank does not allow higher amounts then 15000 NOK per transfer':
    'Your bank does not allow higher amounts then 15000 NOK per transfer',
  'Your choosen price is lower than the best available price in our market.':
    'Your choosen price is lower than the best available price in our market.',
  'Your chosen bank has a maximum amount of {0} {shortName} per deposit.': [
    'Your chosen bank has a maximum amount of ',
    ['0'],
    ' ',
    ['shortName'],
    ' per deposit.',
  ],
  'Your configuration key': 'Your configuration key',
  'Your crypto inventory is empty or it is too low to make a withdrawal. You can either buy crypto, or make an internal transfer from your savings account.':
    'Your crypto inventory is empty or it is too low to make a withdrawal. You can either buy crypto, or make an internal transfer from your savings account.',
  'Your crypto inventory is empty or it is too low to make a withdrawal. You can solve this easily by buying crypto.':
    'Your crypto inventory is empty or it is too low to make a withdrawal. You can solve this easily by buying crypto.',
  'Your customer relationship will be transferred to Firi ApS on December 31, 2024, in accordance with the new EU regulation (MiCA). To maintain full access to your account after this date, you must accept this change.':
    'Your customer relationship will be transferred to Firi ApS on December 31, 2024, in accordance with the new EU regulation (MiCA). To maintain full access to your account after this date, you must accept this change.',
  'Your email is verified!': 'Your email is verified!',
  "Your friends don't have to spend a single penny on this - they just invest their welcome bonus.":
    "Your friends don't have to spend a single penny on this - they just invest their welcome bonus.",
  'Your gift card could not be created': 'Your gift card could not be created',
  'Your gift card from Firi | Norwegian Stock Exchange for trading cryptocurrency':
    'Your gift card from Firi | Norwegian Stock Exchange for trading cryptocurrency',
  "Your gift card have been created and you will soon receive an email where you can download your gift card. We are experiencing some technical issue so you can't see it right here":
    "Your gift card have been created and you will soon receive an email where you can download your gift card. We are experiencing some technical issue so you can't see it right here",
  'Your gift card!': 'Your gift card!',
  'Your information is handled securely at every step.':
    'Your information is handled securely at every step.',
  'Your information has now been updated, and the changes will automatically take effect on January 1, 2025.':
    'Your information has now been updated, and the changes will automatically take effect on January 1, 2025.',
  'Your latest': 'Your latest',
  'Your lightning invoice': 'Your lightning invoice',
  'Your money will usually be withdrawn from your bank account immediately, but it takes some time before we receive all required information and can approve your transaction. You will get an e-mail when the money is credited.':
    'Your money will usually be withdrawn from your bank account immediately, but it takes some time before we receive all required information and can approve your transaction. You will get an e-mail when the money is credited.',
  'Your personal information is always stored securely with us.':
    'Your personal information is always stored securely with us.',
  'Your profile is not visible to other users.': 'Your profile is not visible to other users.',
  'Your profile will be automatically completed when using Vipps. Sign in with bankID to get started, safe and simple!':
    'Your profile will be automatically completed when using Vipps. Sign in with bankID to get started, safe and simple!',
  'Your referral code:': 'Your referral code:',
  'Your referral codes': 'Your referral codes',
  'Your reward of <0/> in {0} has been paid to your main account.': [
    'Your reward of <0/> in ',
    ['0'],
    ' has been paid to your main account.',
  ],
  'Your reward of {0} {name} in {1} has been paid to your main account.': [
    'Your reward of ',
    ['0'],
    ' ',
    ['name'],
    ' in ',
    ['1'],
    ' has been paid to your main account.',
  ],
  'Your safety is important': 'Your safety is important',
  'Your session has either expired or is invalid': 'Your session has either expired or is invalid',
  'Your session has expired, please login again.': 'Your session has expired, please login again.',
  'Your text exceeds 20 characters': 'Your text exceeds 20 characters',
  'Your wallet address is currently unavailable': 'Your wallet address is currently unavailable',
  'Your withdrawal and transaction fees total is {0} {1}. You can enter this amount in your tax return under the topic "Finance", the item "Management costs" and in the field "Securities management"':
    [
      'Your withdrawal and transaction fees total is ',
      ['0'],
      ' ',
      ['1'],
      '. You can enter this amount in your tax return under the topic "Finance", the item "Management costs" and in the field "Securities management"',
    ],
  'Your withdrawal limit is <0/> per day. The amount count for a total value for every currency.':
    'Your withdrawal limit is <0/> per day. The amount count for a total value for every currency.',
  'Your withdrawal limit is {0} per day. The amount count for a total value for every currency.': [
    'Your withdrawal limit is ',
    ['0'],
    ' per day. The amount count for a total value for every currency.',
  ],
  'Your withdrawal limit is {0}{1} per day. The amount count for a total value for every currency.':
    [
      'Your withdrawal limit is ',
      ['0'],
      ['1'],
      ' per day. The amount count for a total value for every currency.',
    ],
  'Your withdrawal was not successful. It may have been a hiccup, so please try again.':
    'Your withdrawal was not successful. It may have been a hiccup, so please try again.',
  'Zip code': 'Zip code',
  about: 'about',
  'app-theme': 'app-theme',
  available: 'available',
  'change <0/>': 'change <0/>',
  collected: 'collected',
  cryptocurrencies: 'cryptocurrencies',
  cryptocurrency: 'cryptocurrency',
  'danish kroner': 'danish kroner',
  'deposit-2': 'Deposit',
  dollars: 'dollars',
  'enter the value': 'enter the value',
  'every day': 'every day',
  'every month': 'every month',
  'every other week': 'every other week',
  'every two weeks': 'every two weeks',
  'every week': 'every week',
  'every {0} days': ['every ', ['0'], ' days'],
  'expected yearly earn': 'expected yearly earn',
  for: 'for',
  friends: 'friends',
  'from {0} to {1}': ['from ', ['0'], ' to ', ['1']],
  'gift card': 'gift card',
  high: 'high',
  immediately: 'immediately',
  in: 'in',
  'in expected yield per year': 'in expected yield per year',
  'in fees': 'in fees',
  'in rewards': 'in rewards',
  'invite you to Firi!': 'invite you to Firi!',
  kr: 'kr',
  kroner: 'kroner',
  left: 'left',
  low: 'low',
  'norwegian kroner': 'norwegian kroner',
  'not verified': 'not verified',
  'on the way': 'on the way',
  'on {0}': ['on ', ['0']],
  or: 'or',
  'or copy code': 'or copy code',
  'or remote': 'or remote',
  'people have donated': 'people have donated',
  'privacy policy': 'privacy policy',
  'read-past-tense': 'Read',
  'savings account': 'savings account',
  'select files': 'select files',
  'swedish kroner': 'swedish kroner',
  'terms of services': 'terms of services',
  'the App Store': 'the App Store',
  today: 'today',
  unknown: 'unknown',
  value: 'value',
  vol: 'vol',
  'with email': 'with email',
  'withdraw-2': 'Withdraw',
  'yearly yield': 'yearly yield',
  yesterday: 'yesterday',
  '{0, select, en {https://www.firi.com} no {https://www.firi.com/no} dk {https://www.firi.com/dk}}':
    [
      [
        '0',
        'select',
        {
          en: 'https://www.firi.com',
          no: 'https://www.firi.com/no',
          dk: 'https://www.firi.com/dk',
        },
      ],
    ],
  '{0}': [['0']],
  '{0} articles': [['0'], ' articles'],
  '{0} in {1}': [['0'], ' in ', ['1']],
  '{0} is {depositFee} percent.': [['0'], ' is ', ['depositFee'], ' percent.'],
  '{0} {1}': [['0'], ' ', ['1']],
  '{0} {currency} has been transferred to your main account': [
    ['0'],
    ' ',
    ['currency'],
    ' has been transferred to your main account',
  ],
  '{0}% expected annual yield': [['0'], '% expected annual yield'],
  '{0}-value now': [['0'], '-value now'],
  '{amountTickets} tickets!': [['amountTickets'], ' tickets!'],
  '{currency} holdings': [['currency'], ' holdings'],
  '{currency} is sent over <0>{0}</0>': [['currency'], ' is sent over <0>', ['0'], '</0>'],
  '{currency}-address': [['currency'], '-address'],
  '{currency}-value now': [['currency'], '-value now'],
  '{days} days': [['days'], ' days'],
  '{referralOwnerName} welcomes you to Firi!': [['referralOwnerName'], ' welcomes you to Firi!'],
  '☑️ Choose the amount you want to stake and confirm.':
    '☑️ Choose the amount you want to stake and confirm.',
  '☑️ Choose the amount you want to unstake, i.e., deactivate staking.':
    '☑️ Choose the amount you want to unstake, i.e., deactivate staking.',
  '⚙️ We give you an estimate of the processing time of the withdrawal. This is usually very quick.':
    '⚙️ We give you an estimate of the processing time of the withdrawal. This is usually very quick.',
  '📈 Statistics shows that long term investments give better yield than buying and selling cryptocurrency based on price.':
    '📈 Statistics shows that long term investments give better yield than buying and selling cryptocurrency based on price.',
  '📩 The funds you stake will be automatically transferred to your staking account.':
    '📩 The funds you stake will be automatically transferred to your staking account.',
  '🕒 Invest a fixed amount at a fixed time with automatic deposits from your bank. This is also know as Dollar-Cost Averaging (DCA)':
    '🕒 Invest a fixed amount at a fixed time with automatic deposits from your bank. This is also know as Dollar-Cost Averaging (DCA)',
  '🙌 Once your withdrawal is processed, you will receive it in your main account.':
    '🙌 Once your withdrawal is processed, you will receive it in your main account.',
  '🙌 You will receive the reward on your staking account every Friday going forward. You will get the first reward the week after starting.':
    '🙌 You will receive the reward on your staking account every Friday going forward. You will get the first reward the week after starting.',
  '🤝 We have made it simple to make long term investments in cryptocurrency.':
    '🤝 We have made it simple to make long term investments in cryptocurrency.',
  '🧘 Less risk, less stress': '🧘 Less risk, less stress',
};
