import { gql, useQuery } from '@apollo/client';

import { FeatureFlagsQuery } from '../gqlTypes';

const FEATURE_FLAGS_QUERY = gql`
  query FeatureFlags {
    featureFlags {
      surveyGoogleForm
    }
    withdrawal {
      enforceTravelRule
    }
  }
`;

export const useFeatureFlags = () => {
  const { data, loading } = useQuery<FeatureFlagsQuery>(FEATURE_FLAGS_QUERY);

  return {
    loading,
    flags: {
      surveyGoogleForm: data?.featureFlags.surveyGoogleForm || false,
      travelRuleEnabled: data?.withdrawal?.enforceTravelRule || false,
    },
  };
};
