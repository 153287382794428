import { gql, useMutation } from '@apollo/client';

import {
  WithdrawCryptoBankIdMutation,
  WithdrawCryptoBankIdMutationVariables,
  WithdrawCryptoBankIdV2Mutation,
  WithdrawCryptoBankIdV2MutationVariables,
} from '../gqlTypes';

const mutation = gql`
  mutation WithdrawCryptoBankId($input: WithdrawCryptoBankIdInput!) {
    withdrawCryptoBankId(input: $input) {
      success
      message
    }
  }
`;

const mutationV2 = gql`
  mutation WithdrawCryptoBankIdV2($input: WithdrawCryptoBankIdV2Input!) {
    withdrawCryptoBankIdV2(input: $input) {
      success
      message
    }
  }
`;

export const useWithdrawCryptoBankId = () =>
  useMutation<WithdrawCryptoBankIdMutation, WithdrawCryptoBankIdMutationVariables>(mutation, {
    refetchQueries: ['Transaction'],
  });

export const useWithdrawCryptoBankIdV2 = () =>
  useMutation<WithdrawCryptoBankIdV2Mutation, WithdrawCryptoBankIdV2MutationVariables>(mutationV2, {
    refetchQueries: ['Transaction'],
  });
