import { gql, useMutation } from '@apollo/client';

import { SubmitConsentMoveDkMutation, SubmitConsentMoveDkMutationVariables } from '../gqlTypes';

const mutation = gql`
  mutation SubmitConsentMoveDk($consent: Boolean!) {
    submitConsentMoveDk(consent: $consent) {
      success
      message
    }
  }
`;

export const useSubmitConsentMoveDk = () =>
  useMutation<SubmitConsentMoveDkMutation, SubmitConsentMoveDkMutationVariables>(mutation);
