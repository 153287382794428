import { gql, useLazyQuery } from '@apollo/client';

import {
  ValidateAddressQuery,
  ValidateAddressQueryVariables,
  ValidateAddressV2Query,
  ValidateAddressV2QueryVariables,
} from '../gqlTypes';

const QUERY = gql`
  query ValidateAddress($address: String!, $currency: Symbol, $unlistedCurrency: String) {
    transfers {
      validateAddress(address: $address, currency: $currency, unlistedCurrency: $unlistedCurrency) {
        valid
        error
      }
    }
  }
`;

const QUERY_V2 = gql`
  query ValidateAddressV2(
    $amount: String!
    $address: String!
    $currency: Symbol
    $unlistedCurrency: String
    $onBehalfOfMyself: Boolean
    $isLegalEntity: Boolean
    $beneficiaryData: NotabenePayload
  ) {
    withdrawal {
      validateAddressV2(
        amount: $amount
        address: $address
        currency: $currency
        unlistedCurrency: $unlistedCurrency
        onBehalfOfMyself: $onBehalfOfMyself
        isLegalEntity: $isLegalEntity
        beneficiaryData: $beneficiaryData
      ) {
        status
        missingInformation
        vaspAutofilled
      }
    }
  }
`;

export const useValidateAddress = () => {
  const [validateAddress, { data, loading }] = useLazyQuery<
    ValidateAddressQuery,
    ValidateAddressQueryVariables
  >(QUERY, { fetchPolicy: 'no-cache' });

  const [validateAddressV2, { data: dataV2, loading: v2Loading }] = useLazyQuery<
    ValidateAddressV2Query,
    ValidateAddressV2QueryVariables
  >(QUERY_V2, { fetchPolicy: 'no-cache', notifyOnNetworkStatusChange: true });

  return {
    validateAddress,
    validateAddressV2,
    valid: !!data?.transfers?.validateAddress.valid,
    loading: loading,
    v2Status: dataV2?.withdrawal?.validateAddressV2?.status,
    v2Loading,
  };
};
