/*eslint-disable*/ export const messages = {
  '1 day': '1 dag',
  '1 month': '1 måned',
  '1 mth': '1 mnd',
  '1 week': '1 uke',
  '1 y': '1 år',
  '1 year': '1 år',
  '1-2 banking days': '1-2 bankdager',
  '1D': '1D',
  '1M': '1M',
  '1W': '1U',
  '1Y': '1Y',
  '24H': '24H',
  '24h change': '24h endring',
  '3 months': '3 måneder',
  '3 mth': '3 mnd',
  '3M': '3M',
  '404 - Page not found': '404 - Siden finnes ikke',
  '6 digit code': '6-sifret kode',
  '7 days': '7 dager',
  '7D': '7D',
  '919 932 465': '919 932 465',
  'A "Destination tag" identifies the recipient of a transaction. It is optional to enter the Destination tag, but some recipients require it.':
    'En "Destination tag" identifiserer mottakeren av en transaksjon. Det er i utgangspunktet valgfritt å oppgi Destination tag, men noen mottakere krever det.',
  'A "Destination tag" is a unique number that identifies the recipient of a transaction. It is optional to enter the Destination tag, but some recipients require it.':
    'En "Destination tag" er et unikt nummer som identifiserer mottakeren av en transaksjon. Det er i utgangspunktet valgfritt å oppgi Destination tag, men noen mottakere krever det.',
  'A bank account was verified': 'En bankkonto ble bekreftet',
  'A description of you the source of your assets, e.g bank statement or sales statement':
    'Beskrivelser av hvor midlene dine kommer fra, for eksempel en bankutskrift eller en salgsoppgave',
  'A description of your investment and which bank account has the assets been deducted from':
    'Beskrivelser av investeringer som er foretatt og hvilken konto disse er trukket fra',
  'A fee of 3.9% is added to the amount when you make a deposit with a card. You will receive the money immediately.':
    'Et gebyr på 3,9% legges til beløpet når du gjør innskudd med kort. Du mottar pengene umiddelbart. ',
  'A fee of 3.9% is added to the amount when you use Apple Pay to make a deposit. You will receive the money immediately.':
    'Et gebyr på 3,9% legges til beløpet når du bruker Apple Pay for å gjøre innskudd. Du mottar pengene umiddelbart.',
  'A fee of 3.9% is added to the amount when you use Google Pay to make a deposit. You will receive the money immediately.':
    'Et gebyr på 3,9% legges til beløpet når du bruker Google Pay for å gjøre innskudd. Du mottar pengene umiddelbart.',
  'A fee of 3.9% is added to the amount when you use MobilePay to make a deposit. You will receive the money immediately.':
    'Et gebyr på 3,9% legges til beløpet når du bruker MobilePay for å gjøre innskudd. Du mottar pengene umiddelbart.',
  'A fee of 3.9% is added to the amount when you use Vipps to make a deposit. You will receive the money immediately.':
    'Et gebyr på 3,9% legges til beløpet når du bruker Vipps for å gjøre innskudd. Du mottar pengene umiddelbart.',
  'A fee of {0}% is added to the amount when you make a deposit with a card. You will receive the money immediately.':
    [
      'Et gebyr på ',
      ['0'],
      '% legges til beløpet når du gjør innskudd med kort. Du mottar pengene umiddelbart. ',
    ],
  'A fee of {0}% is added to the amount when you use MobilePay to make a deposit. You will receive the money immediately.':
    [
      'Et gebyr på ',
      ['0'],
      '% legges til beløpet når du bruker MobilePay for å gjøre innskudd. Du mottar pengene umiddelbart.',
    ],
  'A fee of {0}% is added to the amount when you use Vipps to make a deposit. You will receive the money immediately.':
    [
      'Et gebyr på ',
      ['0'],
      '% legges til beløpet når du bruker Vipps for å gjøre innskudd. Du mottar pengene umiddelbart.',
    ],
  'A fixed fee of {0} will be deducted from the withdrawal amount to cover our costs.': [
    'Et fast gebyr på ',
    ['0'],
    ' vil bli trukket fra uttaksbeløpet for å dekke kostnadene våre.',
  ],
  'A key reason why cryptocurrency has become so popular is the pioneering blockchain technology. Blockchain technology allows digital interactions to be recorded, tracked and made publicly visible. It opens up a whole new way of creating secure, distributed services that are accessible to all and governed by the community.':
    'En viktig grunn til at kryptovaluta har blitt så populært er den banebrytende blokkjedeteknologien. Med blokkjedeteknologi kan man registrere, spore og offentlig synliggjøre digitale samhandlinger. Det åpner opp for en helt ny måte å skape sikre og distribuerte tjenester som er tilgjengelig for alle og styres av fellesskapet.',
  'A lot is happening here! These crypto have had the biggest change in price the last 24 hours':
    'Her skjer det mye! Disse kryptoene har hatt størst endring i pris de siste 24 timene',
  'A lot is happening with': 'Nå skjer det mye med',
  'A market order is executed immediately and at the best available price on the Firi market when you confirm your order.':
    'En markedsordre blir utført umiddelbart, og til Firi-markedets beste pris når du bekrefter ordren din.',
  'A new hatch is opened every day at 8 am': 'Ny luke åpnes hver dag kl 08',
  "A premium user experience is part of Firi's DNA, so with this update, you will experience a cleaner, tidier, and faster app 🥇 Swipe around a bit to get to know everything we have to offer!":
    'Førsteklasses brukeropplevelse er en del av DNAet til Firi, så med denne oppdateringen vil du oppleve en renere, ryddigere og raskere app 🥇 Swipe deg litt rundt for å bli kjent med alt vi har å tilby!',
  API: 'API',
  'API Key': 'API nøkkel',
  'API Key credentials': 'API nøkkel informasjon',
  'API documentation': 'API dokumentasjon',
  'API key permission': 'API nøkkel tillatelser',
  'Aave is a decentralized financial platform built on the Ethereum blockchain. It enables users to borrow, lend and earn interest on various cryptocurrencies, using smart contracts.':
    'Aave er en desentralisert finansplattform bygget på Ethereum-blokkjeden. Den gjør det mulig for brukerne å låne, låne ut og tjene renter på ulike kryptovaluta, ved hjelp av smartkontrakter.',
  "Aave's associated cryptocurrency is called AAVE and is used for governance and security within the smart contracts on the Aave DeFi platform.":
    'Aave sin tilhørende kryptovaluta kalles AAVE og brukes til styring og sikkerhet innenfor smartkontraktene på DeFi-platformen Aave.',
  'About "Travel Rule"': 'Om "Travel Rule"',
  'About Aave': 'Om Aave',
  'About Avalanche': 'Om Avalanche',
  'About BNB': 'Om BNB',
  'About Bitcoin': 'Om Bitcoin',
  'About Cardano': 'Om Cardano',
  'About Chainlink': 'Om Chainlink',
  'About DAI': 'Om DAI',
  'About Decentraland (MANA)': 'Om Decentraland (MANA)',
  'About Dogecoin': 'Om Dogecoin',
  'About Ethereum': 'Om Ethereum',
  'About Firi': 'Om Firi',
  'About Litecoin': 'Om Litecoin',
  'About Matic': 'Om Matic',
  'About Polkadot': 'Om Polkadot',
  'About Polygon': 'Om Polygon',
  'About Solana': 'Om Solana',
  'About Stellar': 'Om Stellar',
  'About Tether USDt': 'Om Tether USDt',
  'About The Sandbox (SAND)': 'Om SAND',
  'About USD Coin': 'Om USD Coin',
  'About Uniswap': 'Om Uniswap',
  'About XRP': 'Om XRP',
  'About me': 'Om meg',
  'About savings account': 'Om sparekonto',
  'About us': 'Om oss',
  Accept: 'Godta',
  'Accept and continue': 'Accept and continue',
  'Accept everything': 'Godkjenn alle',
  'Accept the updated terms by December 31, 2024, to continue using your account.':
    'Accept the updated terms by December 31, 2024, to continue using your account.',
  'Access to your account is temporarily blocked.':
    'Access to your account is temporarily blocked.',
  Account: 'Konto',
  'Account activities': 'Kontoaktiviteter',
  'Account level 4 reached': 'Kontonivå 4 nådd',
  'Account number': 'Kontonummer',
  'Account number belongs to a bank that is not supported by Firi':
    'Kontonummeret tilhører en bank som ikke støttes av Firi',
  'Account number belongs to a bank that is not supported by Firi. Please change or delete this account number.':
    'Kontonummeret tilhører en bank som ikke støttes av Firi. Vennligst endre eller slett dette kontonummeret.',
  Accounts: 'Kontoer',
  'Activate 2FA': 'Aktiver 2FA',
  'Activate staking and earn rewards': 'Aktiver staking for å få belønning',
  'Activate the saving agreement in your bank': 'Aktiver spareavtalen i nettbanken din',
  'Activate two-factor authentication': 'Aktiver tofaktorautentisering',
  'Activate two-factor authentication (2FA)': 'Aktiver tofaktorautentisering (2FA)',
  'Activated!': 'Aktivert!',
  'Activity log': 'Aktivitetslogg',
  Add: 'Legg til',
  'Add additional information': 'Legg til ytterligere informasjon',
  'Add as favorite': 'Legg til som favoritt',
  'Add bank account': 'Legg til bankkonto',
  'Add configuration key': 'Legg til konfigureringsnøkkel',
  'Add funds': 'Sett inn penger',
  'Add funds to trade crypto': 'Sett inn penger for å handle krypto',
  'Add manually': 'Legg til manuelt',
  'Add more': 'Legg til flere',
  'Add more information': 'Legg til flere opplysninger',
  'Add new key': 'Legg til ny nøkkel',
  'Add or remove cryptos from your favorites': 'Legg til eller fjerne krypto fra dine favoritter',
  'Add or update purchase price': 'Legg til eller endre innkjøpspris',
  'Add purchase price': 'Legg til innkjøpspris',
  'Add receiver': 'Lagre mottaker',
  'Add your friends referralcode when signing up': 'Bruk din venns vervekode ved registrering',
  'Add your own destination': 'Legg til egen destinasjon',
  'Added as favorite! You\'ll find "My favorites" in the overview.':
    'Lagt til som favoritt! Du finner "Mine favoritter" på oversikten',
  Address: 'Adresse',
  'Advanced trade': 'Avansert handel',
  'Advent calendar': 'Julekalender',
  Affiliate: 'Affiliate',
  'Affiliate bonus': 'Vervebonus',
  'Affiliate commision': 'Affiliatekommisjon',
  'Affiliate program': 'Verveprogram',
  'Affiliate program guidelines': 'Retningslinjer for verving',
  All: 'Alle',
  'All accounts': 'Alle kontoer',
  'All articles': 'Alle artikler',
  'All crypto': 'Alle krypto',
  'All crypto on Firi': 'Alle krypto hos Firi',
  'All currencies': 'Alle valutaer',
  'All payouts': 'Alle utbetalinger',
  'All trades with cryptocurrency will always be done from your main account. This is where your assets for your buying and selling exist.':
    'Når du handler kryptovaluta gjøres dette alltid fra hovedkontoen din. Det er her midlene dine for kjøp og salg ligger.',
  'All types': 'Alle typer',
  Alphabetical: 'Alfabetisk',
  'Already have a user?': 'Har du allerede en bruker?',
  'Always double check that the copied wallet address is correct before making a deposit':
    'Alltid dobbeltsjekk at den kopierte lommebokadressen stemmer før du sender krypotovaluta',
  'Amnesty International Norge 2021': 'Amnesty International Norge 2021',
  'Among other things, we consider mining and staking as income. Remember to list the value when you acquired this crypto':
    'Vi regner blant annet mining og staking som inntekt. Husk å føre opp verdien ved ervervelse',
  Amount: 'Beløp',
  'Amount ({0})': ['Beløp (', ['0'], ')'],
  'Amount exceeds available funds': 'Beløp overskriver tilgjengelige midler',
  'Amount exceeds the deposit limit. Try a smaller amount.':
    'Beløpet overskrider innskuddsgrensen. Prøv et mindre beløp.',
  'Amount is too large': 'Beløpet er for høyt',
  'Amount is too low': 'Beløpet er for lavt',
  'Amount must be more than 0': 'Beløp må være større enn 0',
  'Amount to high. Exceeds available funds': 'Beløp for høyt. Overskrider tilgjengelige midler',
  'Amount to high. Exceeds withdraw limit': 'Beløp for høyt. Overskrider uttaksgrense',
  'Amount to high. Maximum {0}': ['Beløpet er for høyt. Maksbeløp er ', ['0']],
  'Amount to low. Minimum {0}': ['Beløp for lavt. Minimum ', ['0']],
  'Amount too high': 'Beløpet er for høyt',
  'Amount too high. Maximum withdraw is {0} {1}': [
    'Beløpet er for høyt. Maks uttak er ',
    ['0'],
    ' ',
    ['1'],
  ],
  'Amount too high. Maximum withdraw is {0} {currency}': [
    'Beløpet er for høyt. Maks uttak er ',
    ['0'],
    ' ',
    ['currency'],
  ],
  'Amount too low': 'Beløpet er for lavt',
  'Amount too low. Minimum withdrawal is {0} {currency} ≈ {1} {2}': [
    'Beløpet er for lavt. Minimum uttak er ',
    ['0'],
    ' ',
    ['currency'],
    ' ≈  ',
    ['1'],
    ' ',
    ['2'],
  ],
  'Amount too low. Minimum withdrawal is {minLimit} {0}': [
    'Beløpet er for lavt. Minimum uttak er ',
    ['minLimit'],
    ' ',
    ['0'],
  ],
  'Amount too low. Minimum {0}': ['Beløpet er for lavt. Minimum ', ['0']],
  'An app you can trust': 'En app du kan stole på',
  "An error has occurred. We're looking into it right away. Your funds are completely safe with us. Please try again in a little while.":
    'Det har oppstått en feil. Vi ser på det med en gang. Dine midler er helt trygge hos oss. Prøv igjen om litt.',
  'An error occured. Please try again!': 'Det oppsto en feil. Vennligst prøv igjen!',
  'An error occurred while changing your password.': 'Feil ved passordbytte',
  'Annual return is an estimate and may vary over time.':
    'Årlig avkastning er et estimat og kan variere over tid.',
  'Annual yield is an estimate and will vary over time.':
    'Årlig avkastning er et estimat og vil variere over tid.',
  Appearance: 'Utseende',
  'Apple Pay': 'Apple Pay',
  'Apple Pay fee': 'Apple Pay gebyr',
  'Apps built on the blockchain need external data to function. Chainlink was founded to serve as a bridge between the blockchain world and the real world by connecting so-called smart contracts, such as those on Ethereum, with reliable and verified data sources outside the blockchain. Chainlink achieves this through a network of so-called "oracles".':
    'Apper som er bygget på blokkjeden trenger data utenfra for å fungere. Chainlink ble grunnlagt for å fungere som en brobygger mellom blockchain-verdenen og den virkelige verden ved å koble såkalte smartkontrakter, f.eks på Ethereum, med pålitelige og verifiserte datakilder utenfor blokkjeden. Dette løser Chainlink ved hjelp av et nettverk av såkalte "orakler".',
  'Are you experiencing problems?': 'Opplever du problemer?',
  'Are you not receiving the email?': 'Mottar du ikke e-posten?',
  'Are you ready to invest in your future, {0}?': [
    'Er du klar for å investere i fremtiden din, ',
    ['0'],
    '?',
  ],
  'Are you sure that you want to cancel this transaction':
    'Er du sikker på at du vil kansellere transaksjonen',
  'Are you sure the account number is correct?': 'Er du sikker på at kontonummeret er riktig?',
  'Are you sure you want to delete the file?': 'Er du sikker på at du ønsker å slette filen?',
  'Are you sure you want to enter a lower rate than the best available price in our market? We do not have a stop-loss which means that the order is executed instantly at our best price.':
    'Er du sikker på at du vil angi en lavere kurs enn beste tilgjengelig pris i markedet vårt? Vi har ikke stop-loss noe som betyr at ordren blir utført øyeblikkelig til den beste prisen vår.',
  'Are you sure you want to remove the bank account?':
    'Er du sikker på at du vil fjerne bankkontoen?',
  'Are you sure?': 'Er du sikker?',
  'Are you transferring to an account you own?': 'Overfører du til en konto du eier?',
  Article: 'Artikkel',
  Articles: 'Artikler',
  'As of April 9th it will not be possible to redeem gift cards.':
    'Fra og med 9. april vil det ikke være mulig å innløse gavekort.',
  'As of March 15, 2022, we will no longer offer commission for new referrals. You will of course retain commission on the trading fees of those you have already referred and will continue to earn from this.':
    'Fra og med 15. mars 2022 vil vi ikke lenger tilby kommisjon for nye vervinger. Du beholder selvsagt kommisjon på handelsgebyrene til de du allerede har vervet og vil fortsette å tjene på dette.',
  'At the same time, you help Firi make the crypto world even brighter and safer for everyone.':
    'Samtidig hjelper du Firi å gjøre kryptoverdenen enda lysere og tryggere for alle.',
  'Author: {0}. Read articles about crypto. Buy and sell bitcoin and other cryptocurrencies for {1} at Firi.':
    [
      'Forfatter: ',
      ['0'],
      '. Les artikler om krypto. Kjøp og selg bitcoin og andre kryptovalutaer for ',
      ['1'],
      ' hos Firi.',
    ],
  'Automatic purchase with crypto-save': 'Automatiske kjøp med kryptospar',
  Available: 'Tilgjengelig',
  'Available balance': 'Tilgjengelig saldo',
  'Available for staking': 'Tilgjengelig for staking',
  'Available for withdrawal': 'Tilgjengelig for uttak',
  'Avalanche Contract Chain (C-Chain)': 'Avalanche Contract Chain (C-Chain)',
  "Avalanche is a blockchain platform that focuses on high transaction speed, low fees, and environmental friendliness. Avalanche's associated cryptocurrency is called AVAX.":
    'Avalanche er en blokkjedeplattform som fokuserer på høy transaksjonshastighet, lave avgifter og miljøvennlighet. Avalanche sin tilhørende kryptovaluta kalles AVAX.',
  "Avalanche is a relatively new blockchain and cryptocurrency. It was launched in 2020, and AVAX has quickly become one of the world's largest cryptocurrencies based on market capitalization.":
    'Avalanche er en relativt ny blokkjede og kryptovaluta. Den ble lansert i 2020, og AVAX har på kort tid blitt en av verdens største kryptovalutaer basert på markedsverdi.',
  'Average price': 'Gjennomsnittspris',
  'Awesome that you like our crypto news!': 'Så kult at du liker kryptonyheter!',
  'BNB (BNB) is the cryptocurrency of Binance and is currently the fourth largest cryptocurrency in the world.':
    'BNB (BNB) er kryptovalutaen til Binance og er i dag den fjerde største kryptovalutaen i verden.',
  Back: 'Tilbake',
  'Back to logged in': 'Tilbake til innlogget',
  'Back to login': 'Tilbake til login',
  Balance: 'Saldo',
  'Bank ID': 'Bank ID',
  'Bank account added!': 'Bankkonto lagt til!',
  'Bank accounts': 'Bankkontoer',
  'Bank deposit': 'Bankinnskudd',
  'Bank transfers': 'Bankoverføringer',
  BankID: 'BankID',
  'Based on the transactions that are carried out and the risk associated with cryptocurrency, we sometimes need information about the origin of the funds. We accept various forms of documentation such as a bank statement, sales statement, inheritance or gift certificate that documents the origin of the funds.':
    'På bakgrunn av de transaksjoner som gjennomføres og risikoen knyttet til kryptovaluta har vi noen ganger behov for informasjon om midlenes opprinnelse. Vi aksepterer ulike former for dokumentasjon som f.eks sluttseddel, oppgjørsoppstilling, arveoppgjør, lånedokumenter eller gavebrev som dokumenterer midlenes opprinnelse.',
  'Based on the transactions that are carried out and the risk associated with cryptocurrency, we sometimes need information about the origin of the funds. We accept various forms of documentation such as closing note, settlement statement, inheritance settlement, loan documents or gift certificates documenting the origin of the funds.':
    'På bakgrunn av de transaksjonene som gjennomføres og risikoen knyttet til kryptovaluta har vi noen ganger behov for informasjon om midlenes opprinnelse. Vi aksepterer ulike former for dokumentasjon som f.eks sluttseddel, oppgjørsoppstilling, arveoppgjør, lånedokumenter eller gavebrev som dokumenterer midlenes opprinnelse.',
  'Based on the type of documentation, there may be several rounds of questions, answers and the need for additional documentation. Therefore, we can also request the latest available Tax Report which gives us a secure and comprehensive overview of your funds and financial situation.':
    'Basert på typen av dokumentasjon kan det bli flere runder med spørsmål, svar og behov for tilleggsdokumentasjon. Derfor kan vi også be om siste tilgjengelige skattemelding som gir oss en sikker og helhetlig oversikt over dine midler og økonomiske situasjon.',
  'Be aware of fraud!': 'Vær obs på svindel!',
  'Be aware that investing in and staking crypto involves risk.':
    'Vær oppmerksom på at investering og staking av krypto innebærer risiko.',
  'Because of its fast transactions and low fees, Doge is often used for online donations and small payments.':
    'På grunn av sine raske transaksjoner og lave gebyrer blir Dogecoin ofte brukt for nettdonasjoner og små betalinger.',
  'Because of this, meme coins are highly volatile and prices can change dramatically in a short period of time. It is important to be aware of the risks when choosing to invest in meme coins.':
    'På grunn av dette er meme-coins svært volatile, prisene kan endre seg enormt mye på kort tid. Det er viktig å være bevisst på risikoen når man velger å engasjere seg i meme-coins.',
  'Become a customer of Firi': 'Bli kunde hos Firi',
  'Beginner crypto investor': 'Starten på noe stort',
  'Being paid out on': 'Blir utbetalt den',
  'Belongs to the categories': 'Tilhører kategoriene',
  'Belongs to the category': 'Tilhører kategorien',
  'Best regards': 'Med vennlig hilsen',
  'Binance Smart Chain (BEP20)': 'Binance Smart Chain (BEP20)',
  'Bitcoin is a digital currency that you can store and transfer directly to anyone, anywhere in the world, without going through third parties such as banks or other central institutions.':
    'Bitcoin en digital valuta som du kan oppbevare og overføre direkte til hvem som helst, hvor som helst i verden, uten å gå via tredjeparter som banker eller andre sentrale institusjoner.',
  'Bitcoin is, in addition to being a cryptocurrency, also a digital payment network. The Bitcoin network is decentralized, and is based on blockchain technology. Being decentralized means you can transfer bitcoin directly to others without third-party interference.':
    'Bitcoin er, i tillegg til å være en kryptovaluta, også et digitalt betalingsnettverk. Bitcoin-nettverket er desentralisert, og baserer seg på blokkjedeteknologi. At det er desentralisert betyr at du kan overføre bitcoin direkte til andre uten innblanding fra en tredjepart.',
  'Bitcoin network': 'Bitcoin-nettverket',
  Bonus: 'Bonus',
  Bought: 'Kjøpt',
  'Bought {0}/3 cryptocurrencies for {1} {2}': [
    'Kjøpt ',
    ['0'],
    '/3 kryptovalutaer for ',
    ['1'],
    ' ',
    ['2'],
  ],
  'Built on the Ethereum network, DAI makes it easy to gain exposure to a more stable cryptocurrency that is also based on blockchain technology.':
    'DAI er bygd på Ethereum-nettverket, og gjør det enkelt å få eksponering til en mer stabil kryptovaluta som også er basert på blokkjedeteknologi.',
  "Bummer! Unfortunately, Firi does not support this crypto. Feel free to let us know if it's on your wish list.":
    'Så kjedelig! Firi støtter dessverre ikke denne kryptoen. Gi oss gjerne en lyd hvis du har denne på ønskelisten.',
  Buy: 'Kjøp',
  'Buy & Sell': 'Kjøp & Salg',
  'Buy and sell bitcoin and other cryptocurrencies for NOK, safely and easily. We are registered with Finanstilsynet, have a Vipps login, use BankID and a Norwegian bank for deposits and withdrawals.':
    'Kjøp og selg bitcoin og andre kryptovaluta for NOK, trygt og enkelt. Vi er registrert hos Finanstilsynet, har Vipps innlogging, benytter BankID og norsk bank for innskudd og uttak.',
  'Buy and sell bitcoin and other cryptocurrencies for NOK. We are registered as an exchange and custodian for cryptocurrency in Norway.':
    'Kjøp og selg bitcoin og andre kryptovaluta for NOK, trygt og enkelt. Vi er registrert hos Finanstilsynet, har Vipps innlogging, benytter BankID og norsk bank for innskudd og uttak.',
  'Buy bitcoin | Firi - Norwegian cryptocurrency exchange':
    'Kjøp bitcoin | Firi - norsk børs for handel av kryptovaluta',
  'Buy bitcoin | Firi - The largest cryptocurrency exchange in the Nordics':
    'Kjøp bitcoin | Firi - Nordens største kryptobørs',
  'Buy completed': 'Kjøp fullført',
  'Buy completed and staking activated!': 'Kjøp fullført og staking aktivert!',
  'Buy crypto': 'Kjøp krypto',
  'Buy crypto in seconds with Vipps or card. This is suitable for those who want to make quick trades.':
    'Kjøp krypto på sekunder med Vipps eller kort. Dette passer for deg som ønsker å gjøre raske handler.',
  'Buy crypto in seconds with card. This is suitable for those who want to make quick trades.':
    'Kjøp krypto på sekunder med kort. Dette passer for deg som ønsker å gjøre raske handler',
  'Buy crypto now': 'Kjøp krypto nå',
  'Buy crypto to give away': 'Kjøp krypto for å gi bort',
  'Buy crypto to stake': 'Kjøp ny krypto for å stake',
  'Buy for total amount': 'Kjøp for hele beløpet',
  'Buy more crypto': 'Kjøp mer krypto',
  'Buy not completed': 'Kjøp ikke fullført',
  'Buy {0} to stake': ['Kjøp ', ['0'], ' for å stake'],
  'Buy {currency} and activate staking': ['Kjøp ', ['currency'], ' og aktiver staking'],
  'Buy {name}': ['Kjøp ', ['name']],
  'Buying and selling is done from your main account': 'Kjøp og salg gjøres fra hovedkontoen',
  'By clicking "continue" I give Firi consent to store my information in accordance with the <0>privacy policy.</0>':
    'Ved å klikke "fortsett" gir du Firi samtykke til å lagre informasjonen min i samsvar med <0>personvernerklæringen.</0>',
  'By clicking on "Confirm", I agree to Firi AS terms of use':
    'Ved å trykke på "Bekreft" samtykker jeg til Firi AS sine brukervilkår',
  'By completing the transfer, you agree to securely share your information (name, wallet address, and Firi-ID) with the recipient.':
    'Ved å fullføre overføringen godkjenner du å dele din informasjon (navn, lommeboksadresse og Firi-ID) sikkert med mottakeren.',
  "By continuing, you agree to Firi's user terms for":
    'Ved å fortsette godtar du Firis brukervilkår for',
  'By entering the Christmas calendar, you agree that we will store your answers and contact you by email if you win.':
    'Ved å bli med på julekalenderen godtar du at vi lagrer svarene dine og kontakter deg på e-post dersom du vinner',
  'By keeping all your information up to date, you will get the very best user experience Firi has to offer. This includes shorter processing time at our customer service.':
    'Ved å holde alle dine opplysninger oppdaterte vil du få den aller beste brukeropplevelsen Firi har å by på. Dette inkluderer kortere behandlingstid hos vår kundeservice.',
  'By pressing "Confirm", I agree to Firi AS\'s terms of use':
    'Ved å trykke på "Bekreft" samtykker jeg til Firi AS sine brukervilkår',
  'By pressing "I agree" I agree to Firi AS\'s terms of use for staking.':
    'Ved å trykke på "Jeg godkjenner" samtykker jeg til Firi AS sine brukervilkår for staking.',
  'By sharing anonymous statistics about how you use our app, we can improve our functionalities and your customer experience.':
    'Ved å dele anonym statistikk om hvordan du bruker appen vår, så kan vi forbedre oss og kundeopplevelsen din.',
  'By signing i give my consent for Firi to store information according to the <0>privacy policy</0>':
    'Ved signering gir jeg Firi samtykke til å lagre informasjonen i samsvar med <0>personvernerklæringen</0>',
  'By staking, you receive a reward because you contribute to securing and maintaining the blockchain that the specific cryptocurrency belongs to.':
    'Ved staking mottar du belønning fordi du bidrar til å sikre og drifte blokkjeden som den spesifikke kryptovalutaen tilhører.',
  'CPR must be 10 digits': 'CPR må være 10 siffer',
  'CPR-number': 'CPR-nummer',
  "CPR-number doesn't match MitID. Try again.": 'CPR-nummeret matcher ikke MitID. Prøv igen.',
  'CSV - Transaction history': 'CSV - Transaksjonshistorikk',
  'Calculating tax...': 'Beregner skatt...',
  'Camera permission required': 'Kamera tillatelse kreves',
  'Campaign! Only from {0} to {1}': ['Kampanje! Kun fra ', ['0'], ' til ', ['1']],
  'Can be staked later this year': 'Kan stakes senere i år',
  'Can be staked this fall': 'Kan stakes til høsten',
  "Can't find the crypto you are looking for?": 'Finner du ikke kryptoen du leter etter?',
  "Can't find your bank?": 'Finner du ikke banken din?',
  Cancel: 'Avbryt',
  'Cancel transaction': 'Kanseller transaksjonen',
  Cancelled: 'Kansellert',
  'Cannot redeem own gift card': 'Kan ikke løse inn eget gavekort',
  'Cannot redeem your own giftcard': 'Kan ikke løse inn eget gavekort',
  'Capital value': 'Formuesverdi',
  Card: 'Kort',
  'Card fee': 'Kortgebyr',
  'Cardano is also a flexible blockchain that makes it possible to create decentralized applications.':
    'Cardano er også en fleksibel blokkjede som gjør det mulig å lage desentraliserte applikasjoner.',
  'Cardano is one of the largest cryptocurrencies in the world based on market capitalization. The associated cryptocurrency of Cardano is called ADA.':
    'Cardano er en av de største kryptovalutaene i verden basert på børsverdi. Den tilhørende kryptovalutaen til Cardano heter ADA.',
  'Cardano network': 'Cardano-nettverket',
  Categories: 'Kategorier',
  "Chainlink is a leading cryptocurrency and a decentralized network. Chainlink's cryptocurrency is called LINK and is among the largest cryptocurrencies based on market value.":
    'Chainlink er en ledende kryptovaluta og et desentralisert nettverk. Chainlinks kryptovaluta heter LINK og er blant de største kryptovalutaene basert på markedsverdi.',
  Change: 'Endre',
  'Change <0><1/></0>': 'Utvikling <0><1/></0> ',
  'Change bank': 'Endre bank',
  'Change bank account': 'Endre bankkonto',
  'Change in prices': 'Endring i pris på din ordre',
  'Change password': 'Endre passord',
  'Change payment method': 'Endre betalingsmetode',
  'Change purchase price': 'Endre innkjøpspris',
  'Change savings agreement': 'Endre spareavtale',
  'Check for update': 'Se etter oppdatering',
  'Check it out now!': 'Sjekk ut nå!',
  'Check out': 'Sjekk ut',
  'Check out staking': 'Sjekk ut staking',
  'Check out taxes': 'Sjekk ut skatt',
  'Check that you entered the code correctly or resend code':
    'Sjekk at du skrev inn riktig kode eller send kode på nytt',
  'Choose Vipps or cards for lightning-fast purchases, or use Norwegian kroner you already have in your Firi account.':
    'Velg Vipps eller kort for lynraskt kjøp, eller bruk norske kroner du allerede har på Firi-kontoen din.',
  'Choose account you want to transfer to': 'Velg konto du ønsker å overføre til',
  'Choose amount you want to stake': 'Velg beløp du vil stake',
  'Choose amount you want to withdraw': 'Velg beløp du vil ta ut',
  'Choose cards for lightning-fast purchases, or use Danish kroner you already have in your Firi account.':
    'Velg kort for lynraskt kjøp, eller bruk danske kroner du allerede har på Firi-kontoen din.',
  'Choose currency and amount': 'Velg valuta og beløp',
  'Choose from library': 'Velg fra biblioteket',
  'Choose how many percent of your savings amount should be placed in the different currencies.':
    'Velg hvor mange prosent av beløpet du vil spare i hvilken kryptovaluta.',
  'Choose max {maxAllowedAnswers} alternativer': [
    'Velg maks ',
    ['maxAllowedAnswers'],
    ' alternativer',
  ],
  'Choose one option': 'Velg ett alternativ',
  'Choose one or more cryptocurrencies': 'Velg én eller flere kryptovalutaer',
  'Choose period': 'Velg periode',
  'Choose the correct answer to continue': 'Velg det riktige alternativet for å gå videre',
  'Choose the options that apply most, maximum three options.':
    'Velg alternativene som stemmer mest, maks tre alternativer.',
  'Choose the options that apply most, possible with all':
    'Velg alternativene som stemmer mest, mulig med alle.',
  'Choose the options that apply most, possible with all.':
    'Velg alternativene som stemmer mest, mulig med alle.',
  'Choose where you want to upload file from': 'Velg hvor du ønsker å laste opp fil fra',
  'Choose your bank': 'Velg din bank',
  'Choose {crypto} price for buy?': ['Velg ', ['crypto'], '-kurs for kjøp'],
  'Choose {crypto} price for sell?': ['Velg ', ['crypto'], '-kurs for salg'],
  'Christmas Bonus': 'Julebonus',
  'Christmas giftcard': 'Julegavekort',
  City: 'By',
  Claim: 'Innløs',
  'Claim reward': 'Utbetal belønning nå',
  'Click here to export account statement': 'Klikk her for å eksportere kontoutskrift',
  'Click here to export annual statement': 'Klikk her for å eksportere årsoppgjør',
  'Click here to see how you do it': 'Klikk her for å se hvordan du gjør det',
  'Click on virtual currency/cryptocurrency': 'Klikk på virtuell valuta/kryptovaluta',
  'Client ID': 'Klient ID',
  Close: 'Lukk',
  'Close my account': 'Close my account',
  'Close section': 'Lukk avsnitt',
  'Closing slip': 'Sluttseddel',
  'Code from giftcard': 'Koden på gavekortet',
  'Code has expired': 'Lenken er utløpt',
  'Code is not valid': 'Koden er ikke gyldig',
  'Code not found or expired': 'Lenke ikke gyldig eller er utgått',
  'Code sent': 'Kode sendt',
  Coins: 'Coins',
  'Collection of customer behavior': 'Innsamling av kundeadferd',
  'Come back on December 1 to find out what Firi Santa has hidden behind the first hatch.':
    'Kom tilbake 1. desember for å se hva Firi-nissen har gjemt bak den første luken.',
  'Come back tomorrow': 'Kom tilbake i morgen',
  'Coming soon': 'Kommer snart',
  Commission: 'Kommisjon',
  'Commission on old referrals': 'Kommisjon på gamle vervinger',
  'Company name': 'Firmanavn',
  Completed: 'Fullført',
  Confirm: 'Bekreft',
  'Confirm and pay': 'Bekreft og betal',
  'Confirm gift card': 'Bekreft gavekort',
  'Confirm order': 'Bekreft ordre',
  'Confirm sell': 'Bekreft salg',
  'Confirm your personal code': 'Gjenta din personlige kode',
  'Confirmation code': 'Bekreftelseskode',
  Confirmations: 'Bekreftelser',
  'Congratulations with savings account': 'Gratulerer med sparekonto',
  'Congratulations!': 'Gratulerer!',
  'Congratulations! Which crypto currency will you invest in first?':
    'Gratulerer! Hvilken kryptovaluta vil du investere i først?',
  'Congratulations! You found an easter egg!': 'Gratulerer! Du fant et påskeegg!',
  'Congratulations! You have received <0/> so that you can test out Firi. Which cryptocurrency do you want to invest in first?':
    'Gratulerer! Du har mottatt <0/> slik at du kan teste ut Firi. Hvilken kryptovaluta vil du investere i først?',
  'Congratulations! You have received {0} so that you can test out Firi. Which cryptocurrency will you invest in first?':
    [
      'Gratulerer! Du har mottatt ',
      ['0'],
      ' slik at du kan teste ut Firi. Hvilken kryptovaluta vil du investere i først?',
    ],
  'Congratulations! You now have a staking account.': 'Gratulerer!  Du har nå en staking-konto.',
  'Congratulations, you have collected {amountTickets} tickets for the big prize! Each lottery ticket gives you an extra chance to win the top prize on Christmas Eve. The more lottery tickets, the greater the chance.':
    [
      'Gratulerer, du har samlet ',
      ['amountTickets'],
      ' lodd til den store premien! Hvert lodd gir deg en ekstra sjanse til å vinne hovedpremien på julaften. Jo flere lodd, jo større er sjansen.',
    ],
  'Connect to': 'Koble til',
  'Connect your account to Vipps for easier login': 'Koble kontoen din til Vipps for enkler login',
  Consent: 'Samtykke',
  Contact: 'Kontakt',
  'Contact our support': 'Kontakt vår support',
  'Contact our support on chat!': 'Ta kontakt med vår support på chat!',
  'Contact our support to change your contact information':
    'Kontakt vår support for å endre kontaktinformasjon',
  'Contact us': 'Kontakt oss',
  Content: 'Innhold',
  Continue: 'Fortsett',
  'Continue with bank deposit': 'Fortsett med bankinnskudd',
  'Control company name': 'Kontroller firmanavn',
  'Cookies give you a personalized experience': 'Cookies gir deg en personlig opplevelse',
  Copied: 'Kopiert',
  Copy: 'Kopier',
  'Copy address': 'Kopier adresse',
  'Copy code': 'Kopier kode',
  'Copy link': 'Kopier lenke',
  'Copy referral link': 'Kopier vervelenke',
  'Copy the configuration key and add it to your 2FA app':
    'Kopier konfigureringsnøkkelen og legg den til i din 2FA-app.',
  'Corporate account': 'Bedriftskonto',
  'Correct network: <0>{0}</0>': ['Riktig nettverk: <0>', ['0'], '</0>'],
  'Correct wallet address': 'Riktig wallet-adresse',
  'Could not create contact': 'Kunne ikke opprette kontakten',
  'Could not find any currencies': 'Fant ingen krypto',
  'Could not open application for your payment.': 'Kunne ikke åpne app for betalingen din.',
  'Could this be the crypto Christmas present of all time?':
    'Kan dette være tidenes krypto-julegave?',
  Country: 'Land',
  'Country manager Denmark': 'Landssjef Danmark',
  Create: 'Lag',
  'Create a fixed transfer/payment to the account information below:':
    'Opprett en fast overføring/betaling til kontoinformasjonen nedenfor:',
  'Create a personal six digit code to unlock the Firi app':
    'Opprett en personlig kode på seks siffer for å åpne Firi-appen.',
  'Create a recurring transfer/payment to the account information below:':
    'Opprett en fast overføring/betaling til kontoinformasjonen nedenfor:',
  'Create a savings account in the Firi-app': 'Åpne sparekonto i Firi-appen',
  'Create a savings account with us today.': 'Opprett sparekonto hos oss i dag.',
  'Create a savings agreement with automatic purchases of crypto currency.':
    'Opprett en spareavtale med automatiske kjøp av kryptovaluta.',
  'Create a transfer/payment to the account information below:':
    'Opprett en overføring/betaling til kontoinformasjonen nedenfor:',
  'Create account with Vipps': 'Opprett konto med Vipps',
  'Create account with your email': 'Opprett konto med e-post',
  'Create an account with Firi with Vipps in just a few minutes!':
    'Lag konto hos Firi med Vipps på bare noen få minutter!',
  'Create code': 'Opprett kode',
  'Create gift card': 'Lag gavekort',
  'Create invoice': 'Lag faktura',
  'Create new gift card': 'Lag nytt gavekort',
  'Create savings account': 'Opprett sparekonto',
  'Create savings agreement': 'Opprett spareavtale',
  'Creating an API private key provides access to markets and real-time trading services on Firi via a third-party site or application.':
    'Ved å lage en API nøkkel får du muligheten til å få tilgang til vårt marked og andre tjenester hos Firi ved hjelp av tredjeparts-websider og applikasjoner',
  'Credited from a deposit': 'Kreditert fra et innskudd',
  'Crypto I earn rewards on': 'Krypto jeg tjener belønning på',
  'Crypto Talk of the Week': 'Ukens kryptosnakk',
  'Crypto currency tax': 'Kryptoskatt',
  'Crypto for fun': 'Krypto for moro skyld',
  'Crypto for newbies': 'Krypto for nybegynnere',
  'Crypto games allow you to earn cryptocurrency, own digital assets, and participate in decision-making within the games.':
    'Krypto-spill lar deg tjene kryptovaluta, eie digitale eiendeler, og være med å bestemme inne i spillene.',
  'Crypto investor novice': 'Kryptoinvestor',
  'Crypto news': 'Kryptonyheter',
  'Crypto saving': 'Kryptosparing',
  'Crypto savings with automatic purchases of crypto. This is suitable for those who want to invest in cryptocurrency for the long-term.':
    'Kryptosparing med automatiske kjøp av krypto. Dette passer for deg som ønsker å investere langsiktig i kryptovaluta.',
  'Crypto tax': 'Kryptoskatt',
  'Crypto tax has never been easier': 'Kryptoskatt har aldri vært enklere',
  'Crypto that are used in DeFi projects': 'Krypto som brukes i DeFi-prosjekter',
  'Crypto that can be mined': 'Krypto som kan mines',
  'Crypto that earns rewards': 'Krypto som gir belønning',
  'Crypto that follows the dollar value': 'Krypto som følger verdien på dollar',
  'Crypto that newbies choose': 'Krypto som nybegynnere velger',
  'Crypto trading is a significant source of income for me':
    'Handel med krypto er en betydlig inntekstkilde for meg',
  'Crypto withdrawal is unavailable until we have received information and processed the case. This is standard practice.':
    'Vanlig prosedyre er at uttak av kryptovaluta er utilgjengelig inntil vi har mottatt informasjonen og ferdigbehandlet saken.',
  "Crypto withdrawal is unavailable until we have received your information and processed the case. This is standard practice, and we will do our utmost to make sure that you can use all of Firi's services as soon as possible.":
    'Uttak av kryptovaluta vil være utilgjengelig inntil vi har mottatt informasjonen og ferdigbehandlet saken. Dette er vanlig praksis, og vi vil gjøre vårt ytterste for at du så snart som mulig skal få bruke alle funksjoner i Firi.',
  'Crypto you can earn more on': 'Krypto du kan tjene mer på',
  Cryptocurrency: 'Kryptovaluta',
  'Cryptocurrency and prices': 'Kryptovaluta og priser',
  'Cryptocurrency is a digital currency built on blockchain technology.':
    'Kryptovaluta er digital valuta bygget på blokkjedeteknologi.',
  'Cryptocurrency:': 'Kryptovaluta',
  Currencies: 'Valutaer',
  Currency: 'Valuta',
  'Current deposit method': 'Nåværende innskuddsmetode',
  'Current fortune': 'Foreløpig formuesverdi',
  'Current income': 'Foreløpig inntekt',
  'Current loss': 'Foreløpig fradragsberettiget tap',
  'Current payment method': 'Nåværende betalingsmetode',
  'Current profit': 'Foreløpig skattepliktig gevinst',
  'Current tax calculation': 'Foreløpig skatteberegning',
  'Current tax calculation for {0}': ['Foreløpig skatteberegning for ', ['0']],
  'Current {0}': ['Foreløpig ', ['0']],
  'Current {0} price': ['Nåværende ', ['0'], '-pris'],
  Customize: 'Tilpass',
  'Customize cookies': 'Tilpass cookies',
  'Customize preferences': 'Tilpass ønsker',
  'Customize settings': 'Tilpass innstillinger',
  'DAI can be used in a number of decentralized applications, and is mainly used to borrow and lend cryptocurrency without the need for an intermediary.':
    'DAI kan anvendes i en rekke desentraliserte applikasjoner, og er hovedsakelig brukt til å låne og låne bort kryptovaluta uten behovet for et mellomledd.',
  'DAI is a so-called stable cryptocurrency that has the purpose of keeping the same 1:1 value of a US dollar.':
    'DAI er en såkalt stabil kryptovaluta som har et formål om å holde samme 1:1 verdi av en amerikansk dollar.',
  Daily: 'Daglig',
  Danish: 'Dansk',
  Dark: 'Mørkt',
  'Dark mode': 'Dark mode',
  Date: 'Dato',
  Days: 'Dager',
  DeFi: 'DeFi',
  'Deactivate staking': 'Deaktiver staking',
  'Deactivate staking on': 'Deaktiver staking på',
  'Deactivation registered': 'Deaktivering registrert',
  'Dear {0}! {1} has given you a gift card from Firi 💙 {2}Redeem the gift card by following the link below. {3}We at Firi hope this gift in cryptocurrency never stops giving! {4}{landingUrl}/giftcard/?code={5}':
    [
      'Kjære ',
      ['0'],
      '! ',
      ['1'],
      ' har gitt deg et gavekort fra Firi 💙 ',
      ['2'],
      'Løs inn gavekortet ved å følge lenken under. ',
      ['3'],
      'Vi i Firi håper denne gaven i kryptovaluta aldri slutter å gi! ',
      ['4'],
      ['landingUrl'],
      '/giftcard/?code=',
      ['5'],
    ],
  'Decentralized finance': 'Desentralisert finans',
  'Decline everything': 'Avvis alt',
  'Dedicated crypto investor': 'Ellevill kryptoinvestor',
  'Deductible loss': 'Fradragsberettiget tap',
  'Delete file': 'Slett fil',
  Denmark: 'Danmark',
  Deposit: 'Innskudd',
  'Deposit completed!': 'Innskudd gjennomført!',
  'Deposit crypto': 'Sett inn krypto',
  'Deposit from bank': 'Innskudd fra bank',
  'Deposit money': 'Sett inn norske kroner',
  'Deposit of {0} not available': ['Deposit of ', ['0'], ' not available'],
  'Deposit of {currency} not available': ['Innskudd av ', ['currency'], ' utilgjengelig'],
  'Deposit on': 'Innskudd på',
  'Deposit over {0} {currency} into your Firi account.': [
    'Sett inn over ',
    ['0'],
    ' ',
    ['currency'],
    ' på din Firi konto',
  ],
  'Deposit registered': 'Innskudd registrert',
  'Deposit with Apple Pay': 'Innskudd med Apple Pay',
  'Deposit with Google Pay': 'Innskudd med Google Pay',
  'Deposit with MobilePay': 'Innskudd med MobilePay',
  'Deposit with Vipps': 'Innskudd med Vipps',
  'Deposit with card': 'Innskudd med kort',
  'Deposit {0}': ['Sett inn ', ['0']],
  Deposits: 'Innskudd',
  'Deposits from online banking or Open Banking are free. It usually takes 1-2 banking days before you receive the money.':
    'Innskudd fra nettbanken eller Open Banking er gratis. Det tar vanligvis 1-2 bankdager før du mottar pengene.',
  'Deposits to your savings account are automatically converted to cryptocurrency through Firi':
    'Innskudd til sparekontoen veksles automatisk til kryptovaluta gjennom Firi',
  Destination: 'Destination',
  'Destination Tag': 'Destinasjonskode',
  'Destination tag': 'Destinasjonskode',
  'Detail on your purchases': 'Detaljer om dine kjøp',
  Details: 'Detaljer',
  'Details about your order': 'Detaljer om din order',
  Development: 'Utvikling',
  'Did not receive the email? Feel free to check the spam as well, sometimes it hides there.':
    'Har du ikke mottatt e-posten? Sjekk gjerne søppelposten også, av og til gjemmer den seg der.',
  'Did you know that the word staking comes from what are called proof-of-stake blockchain? Ethereum and Cardano are examples of such blockchains.':
    'Visste du at ordet staking kommer fra det som kalles proof-of-stake-blokkjeder? Ethereum og Cardano er eksempler på slike blokkjeder.',
  'Did you know that you can earn rewards when you shop and learn more about crypto?':
    'Visste du at du kan tjene belønninger når du handler og lærer mer om krypto?',
  'Did you start staking this week? You will then receive the reward next week. Do you want us to notify you?':
    'Startet du å stake denne uken? Da får du belønningen neste uke. Ønsker du at vi gir deg en lyd?',
  'Did you start staking this week? You will then receive the reward next week. We will let you know!':
    'Startet du å stake denne uken? Da får du belønningen neste uke. Vi gir deg en lyd!',
  'Direct deposit': 'Direkte innskudd',
  'Direct deposit fee is required to cover our costs.':
    'Gebyr på direkte innskudd er nødvendig for å dekke kostnadene våre.',
  'Direct purchase': 'Direktekjøp',
  Disable: 'Skru av',
  'Disable two-factor': 'Skru av to-faktor',
  Distribution: 'Fordeling',
  'Distribution is too low': 'Fordeling er for lav',
  'Do you have an affiliate code?': 'Har du en vervekode?',
  'Do you trade crypto on other exchanges/platforms besides Firi?':
    'Handler du krypto på andre børser/plattformer enn Firi?',
  'Do you want to earn more on your crypto?': 'Vil du tjene mer på kryptoen din?',
  'Do you want to include {0} when we show the value of your holdings?': [
    'Ønsker du å inkludere ',
    ['0'],
    ' når vi viser deg verdien på beholdningen din?',
  ],
  'Do you want to log out of Firi?': 'Vil du logget ut av Firi?',
  'Do you want to receive notifications about your account activities, new products and the latest crypto news?':
    'Ønsker du å motta varsler om kontoaktivitetene dine, nye produkter og de siste kryptonyhetene?',
  'Do you want us to let you know when the hatch opens?':
    'Vil du at vi skal gi deg beskjed når luken åpner?',
  'Do you want us to notify you when the transfer is completed?':
    'Ønsker du at vi gir deg en lyd når uttaket er gjennomført?',
  Documentation: 'Dokumentasjon',
  'Does Firi have any shortcomings today, or is there something we can do better?':
    'Har Firi noen mangler i dag, eller er det noe vi kan gjøre bedre?',
  'Dogecoin (DOGE) was launched in 2013 and started as a humorous internet phenomenon. It is the leading cryptocurrency in the category of "meme coins" and is known for its logo featuring the Shiba Inu dog from the "Doge" meme.':
    'Dogecoin (DOGE) ble lansert i 2013 og startet som en humoristisk internetthype. Det er den ledende kryptovalutaen innenfor kategorien “meme-coins” og er kjent for logoen sin av Shiba Inu-hunden fra "Doge"-memeen.',
  'Dogecoin network': 'Dogecoin-nettverket',
  "Don't have a user?": 'Har du ikke en bruker?',
  "Don't have an account": 'Har du ikke en bruker',
  Donate: 'Doner',
  Donation: 'Donasjon',
  Donations: 'Donasjoner',
  Done: 'Ferdig',
  'Done!': 'Ferdig!',
  'Don’t miss our new features': 'Få med deg de nyeste oppdateringene våre',
  'Double bonus when you invite a friend ✨': 'Dobbel bonus når du inviterer en venn ✨',
  'Dowload app in App Store or Google Play': 'Last ned appen i App Store eller Google Play',
  Download: 'Last ned',
  'Download 2FA-app': 'Last ned 2FA-app',
  'Download Google Authenticator': 'Last ned Google Authenticator',
  'Download PDF': 'Last ned PDF',
  'Download and save “Skattemelding 2020.pdf”': 'Last ned og lagre “Skattemelding 2020.pdf”',
  'Download failed': 'Nedlastning feilet',
  'Download here': 'Last ned her',
  'Download our app': 'Last ned appen',
  'Download our free app and start your crypto journey, safe and easy.':
    'Last ned vår gratis app og start din kryptoreise, trygt og enkelt!',
  'Download tax attachment': 'Last ned skattevedlegg',
  'Download tax documentation': 'Last ned skattevedlegg',
  'Download the Firi-app on mobile': 'Last ned Firi-appen på mobil',
  'Download the Firi-app to get started and get {referralAmount} {0} to buy your first cryptocurrency with Firi and start your investments.':
    [
      'Last ned Firi-appen  for å komme i gang nå og få ',
      ['referralAmount'],
      ' ',
      ['0'],
      ' for å kjøpe din første krypto hos Firi og starte investeringen din.',
    ],
  'Download the Firi-app!': 'Last ned Firi-appen nå!',
  'Download the app and register easily with Vipps.':
    'Last ned appen og registrer deg kjapt og enkelt med Vipps.',
  'Download the app now': 'Last ned appen nå',
  'Download the necessary attachments from the tax calculator. You will find all the documents you need as pdf files.':
    'Last ned nødvendige vedlegg fra skatteberegneren. Du finner alle dokumentene du trenger som pdf-filer.',
  'Download the new version of the app': 'Last ned ny versjon av appen',
  'Downloadable content': 'Nedlastbart innhold',
  'Downloads are also available on web': 'Nedlastninger er også tilgjengelig på web',
  'Drag & drop or': 'Drag & drop eller',
  'Earn crypto rewards by being active, such as depositing money or making your first purchase.':
    'Tjen kryptobelønninger ved å være aktiv, for eksempel ved å sette inn penger eller gjennomføre ditt første kjøp.',
  'Earn even more on your crypto': 'Tjen enda mer på kryptoen din',
  'Earn more on your crypto': 'Tjen mer på kryptoen din',
  'Earn more on your crypto with staking': 'Tjen mer på kryptoen din med staking',
  'Earn rewards': 'Tjen belønning',
  'Earn rewards by staking your crypto': 'Få belønning ved å stake din krypto nå',
  'Earn rewards now': 'Tjen belønning nå',
  'Earn rewards on': 'Tjen belønning på',
  'Earn rewards on {0}': ['Tjen belønning på ', ['0']],
  'Earn rewards with Firi Fordel': 'Tjen belønninger med Firi Fordel!',
  'Earn tickets': 'Samle lodd',
  'Earned rewards': 'Opptjente belønninger',
  'Easter egg hunt 2022': 'Påskeeggjakt 2022',
  Edit: 'Endre',
  'Edit permissions': 'Endre tillatelser',
  'Edit purchase price': 'Endre innkjøpspris',
  Email: 'E-post',
  'Email address': 'E-post adresse',
  'Email sent': 'E-post sendt',
  'Enable notifications': 'Skru på varslinger',
  English: 'Engelsk',
  'Enter gift card code:': 'Skriv inn gavekortkode',
  'Enter or scan the address of the external wallet to which you want to send {currencyName} ({currency}).':
    [
      'Skriv inn eller skann adressen til den eksterne lommeboken som du vil sende ',
      ['currencyName'],
      ' (',
      ['currency'],
      ') til.',
    ],
  'Enter the code generated by your authenticator application':
    'Oppgi koden generert av din autentiserings app',
  'Enter the code on your gift card.': 'Oppgi koden på gavekortet ditt.',
  'Enter the price for 1 {0} at the time of the purchase': [
    'Angi verdien på 1 ',
    ['0'],
    ' på kjøpstidspunktet',
  ],
  'Enter the price for {0} {1} at the time of the purchase': [
    'Angi verdien på ',
    ['0'],
    ' ',
    ['1'],
    ' på kjøpstidspunktet',
  ],
  'Enter the value <0/> in box {0}': ['Oppgi verdi <0/> i rubrikk ', ['0']],
  'Enter the value <0>{0} kr</0> in box 20.': ['Oppgi verdi <0>', ['0'], ' kr</0> i rubrikk 20.'],
  'Enter the value <0>{0} kr</0> in box 58.': ['Oppgi verdi <0>', ['0'], ' kr</0> i rubrikk 58.'],
  'Enter you social security number': 'Oppgi ditt fødselsnummer',
  'Enter your CPR number': 'Oppgi ditt CPR nr',
  'Enter your email address below and we will help you reset your password.':
    'Skriv inn e-post-adressen din under, og vi hjelper deg å tilbakestille passordet ditt.',
  'Enter your total gains and income.': 'Skriv inn din totale gevinst og inntekt.',
  'Enter your total losses.': 'Skriv inn dine totale tap.',
  'Error message:': 'Feilmelding',
  'Essential information': 'Nødvendig informasjon',
  'Essentially, cryptocurrency is decentralized and digital money or assets that can be used by anyone and sent freely and directly between individuals.':
    'I hovedsak er kryptovaluta desentraliserte og digitale penger eller eiendeler som kan brukes av alle og sendes fritt og direkte mellom personer.',
  'Estimated staking value': 'Estimert staking verdi',
  'Estimated time for withdraw': 'Estimert tid for uttak',
  'Estimated time for withdraw: {time}': ['Estimert tid for uttak: ', ['time']],
  'Estimated value': 'Estimert verdi',
  'Ethereum is a protocol built on blockchain technology which is also best known for its ten-digit cryptocurrency called ETH, Ether or Ethereum.':
    'Ethereum er en protokoll bygd på blokkjedeteknologi som også er mest kjent for sin tilhørende kryptovaluta kalt ETH, Ether eller Ethereum.',
  'Ethereum is also a so-called smart-contract platform with its own programming language that enables people to create decentralized applications on the Ethereum blockchain.':
    'Ethereum er også en såkalt smart-kontrakt plattform med sitt eget programmeringsspråk som gjør det mulig for folk å lage desentralisterte applikasjoner på Ethereum-blokkjeden.',
  'Ethereum network (ERC20)': 'Ethereum-nettverket (ERC20)',
  'Even more rewards': 'Enda flere fordeler',
  'Every day': 'Hver dag',
  'Every day from December 1st to 24th, you can win between 500 and 1000 kroner in crypto, or exclusive Firi merch. 🎁 For each correct answer, you get a chance to win the grand prize on Christmas Eve. ✨':
    'Hver dag fra 1. til 24. desember kan du vinne mellom 500 og 1000 kroner i krypto, eller eksklusiv Firi-merch. 🎁 For hvert riktige svar får du en sjanse til å vinne den store premien på julaften. ✨',
  'Every friday': 'Hver fredag',
  'Every month': 'Hver måned',
  'Every other week': 'Annenhver uke',
  'Every two weeks': 'Hver 2. uke',
  'Every week': 'Hver uke',
  'Every {0}. day': ['Hver ', ['0'], '. dag'],
  Everything: 'Alt',
  'Everything is in place!': 'Everything is in place!',
  'Example from Google Authenticator': 'Eksempel fra Google Authenticator',
  'Exchange rate': 'Kurs',
  'Expected annual yield': 'Forventet årlig avkastning',
  'Expected annual yield is an estimate and will vary over time.':
    'Forventet årlig avkastning er et estimat og vil variere over tid.',
  'Expected annual yield on staked crypto is an estimate and may vary over time.':
    'Forventet årlig avkastning på staket krypto er et estimat og kan variere over tid.',
  'Expected annual yield refers to the increase in value your staked investment is expected to give over time.':
    'Forventet årlig avkastning referer til den verdistigningen den stakede investeringen din ventes å gi over tid.',
  'Expected yield per year': 'Forventet avkastning per år',
  Expenses: 'Utgifter',
  Expired: 'Utløp',
  Expires: 'Utløper',
  Explore: 'Utforsk',
  'Export CSV': 'Eksporter CSV',
  'Export account statement': 'Eksporter kontoutskrift',
  'Export annual statement': 'Eksporter årsoppgave',
  'Export your transactions e.g for tax decleration':
    'Eksporter dine transaksjoner til f.eks skattemeldingen din',
  'Extra transfer': 'Ekstra overføring',
  FAQ: 'FAQ',
  'Facial recognition': 'Ansiktsgjenkjenning',
  'Failed to create user': 'Kunne ikke opprette bruker',
  'Failed to decode lightning invoice': 'Kunne ikke dekode lightning faktura',
  Fastest: 'Raskest',
  Favorites: 'Favoritter',
  Fee: 'Gebyr',
  'Fee for direct purchase is 3.9%.': 'Gebyr for direktekjøp er 3,9%.',
  'Fee for direct purchase is {0} percent.': ['Gebyr for direktekjøp er ', ['0'], ' prosent.'],
  'Fee will be added': 'Gebyr kommer i tillegg',
  'Feeback bonus': 'Handelsbonus',
  Fees: 'Gebyr og kurtasje',
  'Fees on trading': 'Gebyr på handel',
  Files: 'Filer',
  'Fill in the confirmation code': 'Fyll inn bekreftelseskoden',
  'Fill in the information in the tax return and attach the documentation that you have received from the tax calculator':
    'Fyll ut opplysninger i skattemeldingen og legg ved dokumentasjonen som du har fått fra skatteberegneren',
  'Fill out your profile': 'Fyll ut din profil',
  Filter: 'Filter',
  'Finally...': 'Helt til slutt...',
  Firi: 'Firi',
  'Firi + Staking = 💙': 'Firi + Staking = 💙',
  'Firi - Nordic largest crypto exchange': 'Firi - Nordens største kryptobørs',
  'Firi AS': 'Firi AS',
  'Firi Affiliate': 'Firi Affiliate',
  'Firi Blue': 'Firi Blå',
  'Firi Christmas': 'Firi Jul',
  'Firi Fordel': 'Firi Fordel',
  'Firi Grey': 'Firi Grå',
  'Firi Reward': 'Firi Fordel',
  'Firi Reward gives you the opportunity to earn crypto rewards by completing various tasks, such as:':
    'Firi Fordel gir deg muligheten til å tjene kryptobelønninger ved å fullføre ulike oppgaver, som:',
  'Firi White': 'Firi Hvitt',
  'Firi current posts': 'Firi aktuelle innlegg',
  'Firi guest author': 'Firi gjesteforfatter',
  "Firi is Norway's largest service for buying and selling cryptocurrencies. With us you will find, among other things, bitcoin, ethereum and cardano. We make the cryptic easy by discovering new and better ways to save, invest and learn about digital currency.":
    'Firi er Norges største tjeneste for kjøp og salg av kryptovaluta. Hos oss finner du blant annet bitcoin, ethereum og cardano. Vi gjør det kryptiske enkelt ved å finne nye og bedre måter å spare, investere og lære om digital valuta på.',
  'Firi is a Norwegian cryptocurrency exchange, registered by Finanstilsynet':
    'Firi er en Norsk kryptobørs, registrert hos Finanstilsynet',
  'Firi is a Norwegian cryptocurrency exchange. With us you can sell and buy cryptocurrencies, such as bitcoin, ethereum and cardano. We make the cryptic easy by finding new and better ways to save, invest and learn about digital currency.':
    'Firi er en norsk kryptovalutabørs. Hos oss kan du selge og kjøpe kryptovaluta, som blant annet bitcoin, ethereum og cardano. Vi gjør det kryptiske enkelt ved å finne nye og bedre måter å spare, investere og lære om digital valuta på.',
  'Firi is registered as a service provider for exchanging and storing virtual currency in Norway.':
    'Firi er registrert som tjenestetilbyder for veksling og oppbevaring av virtuell valuta i Norge.',
  'Firi is registered as an exchange with Finanstilsynet, the Financial Supervisory Authority (FSA). We are required to gather information about our users, the same way that banks, real estate agents and the rest of the financial industry must do.':
    'Firi er registrert hos Finanstilsynet som tjenestetilbyder for veksling og oppbevaring av virtuell valuta i Norge. Vi må innhente informasjon fra kundene våre, slik også banker, eiendomsmeglere og resten av finansnæringen er pålagt å gjøre.',
  'Firi is registered with Finanstilsynet': 'Firi er registrert hos Finanstilsynet',
  'Firi is required by law to obtain information about you as a customer and carry out ongoing follow-up of customer relationships. This is stated, among other things, in our <0>terms of use</0> and the <1>privacy policy</1>':
    'Firi er lovpålagt å innhente informasjon om deg som kunde og foreta løpende oppfølging av kundeforhold. Dette står blant annet i våre <0>bruksvilkår</0> og <1>personvernreglene</1>',
  'Firi periodically has to ask all our customers to update their information. This is a part of our obligations as a service provider for exchanging and depositing virtual currency in Norway.':
    'Firi må jevnlig be alle våre kunder om å oppdatere sin informasjon. Dette er en del av våre forpliktelser som tjenestetilbyder for veksling og oppbevaring av virtuell valuta i Norge.',
  'Firi takes no responsibility for any loss of money.': 'Firi tar ingen ansvar ved tap av penger.',
  'Firi’s website, firi.com, may include links to third-party websites, plug-ins, and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you.':
    'Firi sin internettside firi.com inneholder linker til andre websider og applikasjoner. Når du besøker disse linkene, kan eierne av disse websidene og applikasjonene samle inn data om deg.',
  'First deposit': 'Første innskudd',
  'First name': 'Fornavn',
  'First saving': 'Første sparing',
  'First trade': 'Første handel',
  'Follow system settings': 'Følg telefonens system',
  'For a faster review, double-check that your information is correct.':
    'For en raskere gjennomgang, dobbeltkontroller at informasjonen din er korrekt.',
  'For security and regulatory reasons, we must confirm where your money is going. This helps ensure a safe and legal transfer.':
    'Av sikkerhets- og regelverksgrunner må vi bekrefte hvor pengene dine skal gå. Dette bidrar til å sikre en trygg og lovlig overføring.',
  'Forgot password?': 'Glemt passord?',
  Fortune: 'Formue',
  'Free crypto? Yes.': 'Gratis krypto? Ja.',
  From: 'Fra',
  'From (year)': 'Fra (år)',
  'From account': 'Fra konto',
  'From start': 'Fra start',
  'From the next app update we only support iOS 15+. Update your iOS to get the latest updates and the best possible experience.':
    'Fra neste app-oppdatering støtter vi kun iOS 15+. Oppdater din iOS for å få de nyeste oppdateringene og en best mulig opplevelse.',
  'From {0} to {1}': ['Fra ', ['0'], ' til ', ['1']],
  'Funds processing': 'Under behandling',
  'Funds under processing': 'Midler under behandling',
  'Gambling without permission': 'Pengesspill uten tillatelse',
  'Gaming & Web3': 'Gaming & Web3',
  'Gaming/Web3': 'Gaming/Web3',
  'Get full access to our services and start trading cryptocurrencies today':
    'Få full tilgang til våre tjenester og begynn å handle kryptovaluta i dag',
  'Get notifications on crypto news': 'Få varsler om nye kryptonyheter',
  'Get ready for crypto summer!': 'Gjør deg klar for kryptosommer!',
  'Get started': 'Kom i gang',
  'Get started with MitID on our web platform': 'Kom i gang med MitID på web plattformen',
  'Get started with Vipps on our web platform': 'Kom i gang med Vipps på web plattformen',
  "Get started with Vipps. With Vipps, your profile is automatically filled out. Sign with BankID and you're up and running, safe and easy!":
    'Kom i gang med Vipps. Med Vipps blir din profil automatisk utfylt. Signer så med BankID og du er i gang, trygt og enkelt!',
  'Get started with crypto savings!': 'Kom i gang med kryptosparing!',
  'Get started with our guide': 'Kom i gang med vår guide',
  'Get started with staking': 'Kom i gang med staking',
  'Get up to {0}% expected annual return on crypto stored in this account.': [
    'Få opptil ',
    ['0'],
    '% forventet årlig avkastning på krypto som oppbevares på denne kontoen.',
  ],
  'Get welcome bonus': 'Få velkomstbonus',
  'Get your bonus in app': 'Hent bonusen din i appen',
  'Get {0} {1} in {payoutCurrencyName} and give your friend {2} {3} in welcome bonus': [
    'Få ',
    ['0'],
    ' ',
    ['1'],
    ' i ',
    ['payoutCurrencyName'],
    ' og gi din venn ',
    ['2'],
    ' ',
    ['3'],
    ' i velkomstbonus',
  ],
  'Gift card': 'Gavekort',
  'Gift card code': 'Gavekortkode',
  'Gift card created': 'Gavekort opprettet',
  'Gift card is redeemed': 'Gavekortet er allerede brukt',
  'Gift card not found...': 'Finner ikke gavekort...',
  'Gift from your main account': 'Gi bort fra din hovedkonto',
  'Giftcard is redeemed': 'Gavekortet er allerede brukt',
  'Give access to the camera': 'Gi tilgang til kamera',
  'Give away crypto you already have. Remember that you must have enough crypto in your main account.':
    'Gi bort krypto du allerede har. Husk at du må ha nok krypto på hovedkonto.',
  'Go to box 20 (Other personal income). If you have not completed the box before, you will find it under Other income and then Deductions.':
    'Gå til rubrikk 20 (Annen personlig inntekt). Hvis du ikke har fylt ut rubrikken før, finner du den under Annen inntekt og deretter Fradrag.',
  'Go to box 58 (Other expenses). If you do not have box 58 on your annual tax assessment you should contact SKAT and request that it be opened.':
    'Gå til rubrikk 58 (Andre utgifter). Hvis du ikke har rubrikken 58 på årsoppgjøret ditt, bør du kontakte SKAT og be om å få den åpnet.',
  'Go to login': 'Gå til innlogging',
  'Go to my bank accounts': 'Gå til mine bankkontoer',
  'Go to your inbox in Altinn': 'Gå til din innboks på Altinn',
  'Google Pay': 'Google Pay',
  'Google Pay fee': 'Google Pay gebyr',
  'Google Play Store': 'Google Play Store',
  'Google reCAPTCHA is not verified or expired':
    'Google reCAPTCHA er ikke verifisert eller har utgått',
  'Got it!': 'Den er grei!',
  'Green crypto': 'Grønne krypto',
  'Guest author': 'Gjesteforfatter',
  'Guidance for filling in the tax return': 'Veiledning til utfylling av skattemeldingen',
  'Guide to filling out the annual tax assessment': 'Guide for utfylling av årsoppgjøret',
  'Has Firi been a source for cryptocurrency education and information?':
    'Har Firi vært en kilde for opplæring og informasjon om kryptovaluta?',
  'Have an account?': 'Har du en konto?',
  'Have you been referred by a friend?': 'Har du blitt vervet av en venn?',
  'Have you ever wondered what the average price of your investments is? Or if you hit the market at a low price? Now you get this gathered clearly under each coin you own, so you can make better evaluations around your investments.':
    'Har du noen gang lurt på hva gjennomsnittsprisen for dine investeringer er? Eller om du traff markedet på en lav pris? Dette får du nå samlet oversiktlig under hver enkelt coin du eier, slik at du kan gjøre bedre vurderinger rundt investeringene dine',
  'Have you got ideas or thoughts on how we can improve? Take the chance to share your thoughts in our survey. It takes only 5–7 minutes!':
    'Har du ideer eller tanker om hvordan vi kan bli bedre? Del dine meninger med oss slik at vi kan bygge tjenesten bedre for deg. Det tar bare 5–7 minutter!',
  'Have you lost crypto, but are told to enter 0 in losses?':
    'Har du tapt krypto, men får beskjed om å føre opp 0 i tap?',
  'Have you received a gift card? How nice! Enter the code here:':
    'Har du fått et gavekort? Så kjekt! Skriv inn koden her:',
  'Have you recently done a deposit': 'Har du nylig gjort et innskudd',
  'Hei, {0}!': ['Hei, ', ['0'], '!'],
  'Help med download 2FA-app': 'Hjelp meg å laste ned 2FA-app',
  'Help us to give you the best Firi-experience': 'Hjelp oss å gi deg den beste Firi-opplevelsen',
  'Here are some other pages you could try instead:':
    'Her er det noen andre sider du kan prøve istedenfor:',
  "Here we have gathered insightful articles and news, carefully curated. Whether you're an experienced investor or new to crypto, Utforsk provides you with the information you need to stay updated on the latest developments.":
    'Her har vi samlet innsiktsfulle artikler og nyheter, som er nøye kuratert. Enten du er en erfaren investor eller ny til krypto, gir Utforsk deg informasjonen du trenger for å holde deg oppdatert på siste nytt',
  'Here you can download documentation for your tax returns':
    'Her kan du laste ned dokumentasjon til skattemeldingen',
  'Here you can download documentation to your tax report':
    'Her kan du laste ned dokumentasjon til skattemeldingen',
  'Here you can upload relevant documentation. Your documentation is always safely stored with us.':
    'Her kan du laste opp relevant dokumentasjon. Din dokumentasjon er alltid trygt oppbevart hos oss.',
  'Here you will find deposits that you have not bought from Firi. In order for the calculation of the tax return to be correct, you must enter what the value was when you bought it.':
    'Her finner du innskudd som du ikke har kjøpt hos Firi. For at utregningen til skattemeldingen skal bli riktig må du legge inn hva verdien var da du kjøpte den.',
  'Here you will find deposits that you have not bought from Firi. In order for the calculation of the tax return to be correct, you must enter what the value was when you bought it. This is usually found in the transaction history of the service in question.':
    'Her finner du innskudd som du ikke har kjøpt hos Firi. For at utregningen til skattemeldingen skal bli riktig må du legge inn hva verdien var da du kjøpte den. Dette finner du som regel i transaksjonshistorikken på den aktuelle tjenesten.',
  "Here you'll find crypto address you store when sending crypto":
    'Her finne du kryptoadresser du lagrer når du sender krypto',
  "Here's an <0>example</0> of what the 6 digit code might look like in Google Authenticator. Such a code must be copied and pasted into the Firi app or entered manually. Important! The code is updated periodically, so check that you enter the correct code.":
    'Her ser du et <0>eksempel</0> på hvordan koden på 6 siffer kan se ut i Google Authenticator. En slik kode må kopieres og limes inn i Firi-appen eller skrives inn manuelt. Viktig! Koden oppdateres med jevne mellomrom, så sjekk at du skriver inn riktig kode.',
  "Hi! I would like to tell you about Firi. I myself use Norway's largest cryptocurrency exchange to invest in the currency of the future. Now you get up to 265 {0} in bitcoin, ethereum and other cryptocurrencies to test out Firi through my invitation. What are you waiting for?":
    [
      'Hei! Jeg vil gjerne tipse deg om Firi. Jeg bruker selv Norges største kryptovalutabørs for å investere i fremtidens valuta. Nå får du inntil 265 ',
      ['0'],
      ' i bitcoin, ethereum og andre kryptovalutaer for å teste ut Firi gjennom min invitasjon. Hva venter du på?',
    ],
  'Hide other pairs': 'Skjul andre par',
  'High demand': 'Høy etterspørsel',
  'High level of knowledge': 'I stor grad',
  'Highest (24h)': 'Høyeste (24h)',
  'Highest price': 'Høyeste pris',
  'Highest value': 'Høyest verdi',
  'Historic yield': 'Historisk avkastning',
  'Hm, no question found. Try to start over': 'Hm, vi fant ikke spørsmålet. Prøv å start på nytt',
  'Ho ho, you got it right!': 'Hoho, her var du god!',
  'Ho, nooo!': 'Ho, nooo!',
  'Ho-ho-hodl be ready for this Christmas biggest crypto happening!':
    'Ho-ho-hodl deg klar til julens store krypto-glede!',
  Holding: 'Beholdning',
  Holdings: 'Beholdning',
  Home: 'Hjem',
  'Hope you like our new update!': 'Håper du liker vår nye oppdatering!',
  'How are you doing this year?': 'Hvordan ligger du an i år?',
  'How do I get stared?': 'Hvordan kommer jeg i gang?',
  'How do I redeem the gift card?': 'Hvordan løser jeg inn gavekortet?',
  'How do I stake?': 'Hvordan staker jeg?',
  'How do I stay current on crypto?': 'Hvordan holder jeg meg oppdatert på krypto?',
  'How do I trade crypto with Firi?': 'Hvordan handler jeg krypto hos Firi?',
  'How do i stake {currency}?': ['Hvordan staker jeg ', ['currency'], '?'],
  'How do you want to buy cryptocurrency?': 'Hvordan vil du kjøpe kryptovaluta?',
  'How do you want to deposit money?': 'Hvordan vil du sette inn penger?',
  'How do you want to get started?': 'Hvordan vil du starte?',
  'How do you want to gift crypto?': 'Hvordan vil du gi krypto?',
  'How do you want to stake {currency}?': ['Hvordan vil du stake ', ['currency'], '?'],
  'How do you want to stake?': 'Hvordan vil du stake?',
  'How does it work': 'Hvordan fungerer det',
  'How is the exchange rate determined?': 'Hvordan bestemmes vekslingskursen?',
  'How much do you want to transfer?': 'Hvor mye vil du overføre?',
  'How nice!': 'Så kjekt!',
  'How nice! You have received a gift in crypto': 'Så kjekt! Du har fått krypto i gave',
  'How often do you want to save?': 'Hvor ofte ønsker du å spare?',
  'How the amount is deduced': 'Slik blir beløpet trukket',
  'How to deactivate staking': 'Hvordan deaktivere staking',
  'How to enter gains and income in box 20:': 'Slik skriver du gevinst og inntekt i rubrikk 20:',
  'How to enter losses in box 58:': 'Slik skriver du tap i rubrikk 58:',
  'How to invite friends?': 'Hvordan verve venner?',
  'How to set up the savings agreement in your bank': 'Hvordan sette opp spareavtalen i banken din',
  'How to understand rewards and development': 'Hvordan forstå belønning og utvikling',
  'How we process your personal information': 'Slik behandler vi dine personopplysninger',
  'I accept': 'Jeg godkjenner',
  'I already have a 2FA-app': 'Jeg har allerede 2FA-app',
  'I am buying': 'Jeg kjøper',
  'I am buying for': 'Jeg kjøper for',
  'I am selling': 'Jeg selger',
  'I am selling for': 'Jeg selger for',
  'I am sending': 'Jeg sender',
  'I am uncertain about the future of crypto': 'Jeg er usikker på fremtiden til krypto',
  "I didn't quite understand, can I find more?":
    'Dette skjønte jeg ikke, kan jeg få vite litt mer?',
  "I don't have the capital available to invest more":
    'Jeg har ikke tilgjengelig kapital til å investere mer',
  "I don't want to upload documentation": 'Jeg ønsker ikke å laste opp dokumentasjon',
  'I dont accept': 'I dont accept',
  'I have an interest in blockchain technology': 'Jeg har interesse for blokkjedeteknologi',
  'I have created a recurring transfer from my bank':
    'Jeg har opprettet en fast overføring fra banken min',
  'I have downloaded a 2FA-app': 'Jeg har lastet ned 2FA-app',
  'I have earned': 'Jeg har tjent',
  'I have read and agree to the': 'Jeg har lest og godtar',
  'I have read the <0>privacy policy</0> and give my consent to store the information specified in the policy':
    'Jeg har lest <0>personvernreglene</0> og gir mitt samtykke til å lagre informasjonen spesifisert i retningslinjene',
  'I have referred': 'Jeg har vervet',
  'I have sufficient exposure to crypto now': 'Jeg har passe eksponering mot krypto nå',
  'I lack sufficient knowledge to choose / it is overwhelming':
    'Jeg mangler tilstrekkeling kunnskap til å velge / det er overveldende.',
  'I perceive crypto as a risky investment': 'Jeg opplever krypto som en risikabel investering',
  'I understand': 'Jeg forstår',
  'I understand that investing in meme coins involves high risk and that I am responsible for my own investments.':
    'Jeg forstår at investering i meme-coins innebærer høy risiko og at jeg selv er ansvarlig for investeringene mine.',
  'I want to gain short-term profits': 'Jeg ønsker å hente kortsiktig vinning',
  'I want to join!': 'Jeg vil bli med!',
  'I want to know more': 'Jeg vil vite mer',
  'I want to learn about crypto': 'Jeg ønsker å lære om krypto',
  'I want to save in crypto (alongside savings account/funds/stocks)':
    'Jeg vil spare i krypto (parallelt med sparekonto/fond/aksjer)',
  'If the error is still occurs, please contact our support team':
    'Hvis feilen fortsatt oppstår, vennligst kontakt supportteamet vårt',
  "If we don't have your bank": 'Hvis din bank ikke er på listen',
  'If you are already a user, continue login in your market':
    'Hvis du allerede er en bruker fortsett innlogging i ditt marked',
  'If you believe that our processing of personal information does not match what we have described here or in other ways violate privacy laws, you may appeal to The Norwegian Data Protection Authority.':
    'Dersom du mener at vår behandling av personopplysninger ikke stemmer med det vi har beskrevet her, eller at vi på andre måter bryter personvernlovgivningen, så kan du klage til Datatilsynet.',
  'If you choose to buy for more then the size of the cheapest order, you will automatically buy from the next order in the queue. That is why a trade can consist of several orders.':
    'Hvis du f.eks. ønsker å kjøpe for mer enn hva den billigste ordren har å tilby, kjøper du automatisk av neste ordre i køen. Derfor kan en handel bestå av flere ordrer.',
  'If you choose to sell for more then the cheapest order, you will automatically sell from the next order in the queue. That is why a trade can consist of several orders.':
    'Hvis du f.eks. ønsker å selge for mer enn hva den billigste ordren har å tilby, selger du automatisk av neste ordre i køen. Derfor kan en handel bestå av flere ordrer.',
  'If you do not want to receive notifications about the calendar, you can easily turn this off under settings':
    'Du kan enkelt skru av kalender-varslinger under innstillinger i profilen din.',
  'If you do not want to use 2FA, we recommend that you register with Vipps instead.':
    'Hvis du ikke ønsker å bruke 2FA, så anbefaler vi deg å heller registrere deg med Vipps.',
  'If you have a referral code you can enter it here':
    'Hvis du har en vervekode kan du skrive den her',
  'If you have recently made a deposit, we might still be in the process of receiving the latest information from your bank. We usually receive this information one working day after the deposit.':
    'Hvis du nylig har foretatt et innskudd er vi kanskje fortsatt i en prosess med å motta den siste informasjonen fra banken din. Denne informasjonen får vi normalt en arbeidsdag etter at du har mottatt innskuddet.',
  'If you use the app, you can redeem the gift card by going to More and then Gift card. If you are logged in to the web, then you can click on your name and then Gift card.':
    'Hvis du bruker appen, så kan du løse inn gavekortet ved å gå til Mer og deretter Gavekort.  Hvis du er innlogget på web, så kan du klikke på navnet ditt og deretter Gavekort.',
  'If you want documentation of your tax calculation, you can download it by clicking "Download" in the upper right corner of our tax calculation page.':
    'Hvis du ønsker dokumentasjon på skatteberegningen din, kan du laste den ned ved å klikke på "Last ned" øverst til høyre på skatteberegningssiden vår.',
  'If you want to download documentation for the tax return from Firi, you can do this by clicking on the "Download" button at the top right of the tax calculation page. The tax authorities require you to submit this together with the tax return.':
    'Dersom du ønsker å laste ned dokumentasjon til skattemeldingen fra Firi gjør du dette ved å trykke på knappen “Last ned” oppe til høyre på skatteberegningssiden. Skatteetaten krever at du sender inn dette sammen skattemeldingen.',
  'If you want to download documentation for the tax return from Firi, you do this by clicking on the "Download" button at the top right of the tax calculation page. The tax authorities require you to submit this together with the tax return.':
    'Dersom du ønsker å laste ned dokumentasjon til skattemeldingen fra Firi gjør du dette ved å trykke på knappen “Last ned” oppe til høyre på skatteberegningssiden. Skatteetaten krever at du sender inn dette sammen skattemeldingen.',
  'If you want to save and print the gift card, follow the link that you have received by e-mail.':
    'Ønsker du å lagre og skrive ut gavekortet? Følg lenken som du har fått på e-post.',
  'If you want to use Firis crypto tax, you need to enter the price you purchased this crypto for. The purchase price can be found at the service you used.':
    'Hvis du ønsker å bruke Firis kryptoskatt, må du oppgi prisen du kjøpte denne kryptoen for. Innkjøpsprisen finner du hos tjenesten du brukte.',
  'If you withdraw your consent to store your BankID information your account will be\ndowngraded to an unverified account and you can no longer trade or make transactions.\nThe stored information will be deleted without undue delay in accordance with our legal\nobligations. Are you sure?':
    'Hvis du trekker tilbake samtykket til å lagre BankID-opplysningene dine, vil kontoen din bli \nnedgradert til en ubekreftet konto og du kan ikke lenger handle eller foreta transaksjoner.\nDen lagrede informasjonen vil bli slettet uten unødig forsinkelse i henhold til våre juridiske \nforpliktelser. Er du sikker?',
  'If you withdraw your consent to store your KYC-form information your account will be\ndowngraded to an unverified account and you can no longer trade or make transactions.\nThe stored information will be deleted without undue delay in accordance with our legal\nobligations. Are you sure?':
    'Hvis du trekker tilbake samtykket ditt til å lagre informasjonen i KYC-skjemaet, vil kontoen din bli\nnedgradert til en ubekreftet konto og du kan ikke lenger handle eller foreta transaksjoner.\nDen lagrede informasjonen vil bli slettet uten unødig forsinkelse i samsvar med våre juridiske\nforpliktelser. Er du sikker?',
  'If you withdraw your consent to store your profile information your account will be\nsuspended as we require this information to provide you services. The stored information\nwill be deleted without undue delay in accordance with our legal obligations. Are you\nsure?':
    'Hvis du trekker tilbake samtykket ditt til å lagre profilinformasjonen din, vil kontoen din bli\nsuspendert siden vi trenger denne informasjonen for å kunne tilby deg tjenester. Den lagrede informasjonen\nvil bli slettet uten unødig forsinkelse i samsvar med våre juridiske forpliktelser. Er du\nsikker?',
  Images: 'Bilder',
  Immediately: 'Umiddelbart',
  'Important information': 'Viktig informasjon',
  'Important information!': 'Viktig informasjon!',
  'Important update': 'Important update',
  'In order to meet the requirements of the authorities, we must also verify your identity with facial recognition. Thank you for helping us secure your account!':
    'For å oppfylle myndighetenes krav må vi også verifisere din identitet med ansiktsgjenkjenning. Takk for at du hjelper oss med å sikre kontoen din!',
  'In some cases a withdrawal may require longer processing time and it will be labeled "requested" in your overview until it has been sent.':
    'I noen tilfeller kan et uttak ta lengre tid før det prosesseres. Uttaket blir merket med "forespurt" i oversikten din frem til den har blitt sendt',
  'In the tax return field': 'I skattemeldingens felt',
  'In the tax return field<0> Capital value </0> enter the value <1>{0}kr</1>.': [
    'I skattemeldingens felt<0> Formuesverdi </0> fører du opp beløpet <1>',
    ['0'],
    'kr</1>.',
  ],
  'In the tax return field<0> Deductible loss </0> enter the value <1>{0} kr</1>.': [
    'I skattemeldingens felt<0> Fradragsberettiget tap </0> fører du opp beløpet <1>',
    ['0'],
    'kr</1>.',
  ],
  'In the tax return field<0> Other income from virtual assets / crypto currency </0> enter the value <1>{0} kr</1>.':
    [
      'I skattemeldingens felt <0> Andre inntekter fra virtuelle eiendeler / kryptovaluta </0> fører du opp beløpet <1>',
      ['0'],
      'kr</1>',
    ],
  'In the tax return field<0> Taxable gain </0> enter the value <1>{0} kr</1>.': [
    'I skattemeldingens felt<0> Skattepliktig gevinst </0> fører du opp beløpet <1>',
    ['0'],
    'kr</1>.',
  ],
  'In your tax return, find the topic "Finance" and the item "Management costs". In the "Securities management" field, enter the amount of {0} {1}':
    [
      'I skattemeldingen din, finn temaet "Finans" og punktet "Forvaltningskostnader". I feltet "Verdipapirforvaltning" fører du opp beløpet ',
      ['0'],
      ' ',
      ['1'],
    ],
  'Include {0} in balance': ['Inkluder ', ['0'], ' i verdien'],
  Income: 'Inntekt',
  Incoming: 'Innkommende',
  Insight: 'Innsikt',
  'Insufficient funds': 'For liten beholdning',
  'Insured with Firi': 'Forsikret med Firi',
  'Internal transfer': 'Intern overføring',
  'Introducing the gaming/web3 category!': 'Introduserer kategorien gaming/web3!',
  'Invalid account number': 'Ugyldig kontonummer',
  'Invalid address': 'Ugyldig adresse',
  'Invalid code': 'Uguldig kode',
  'Invalid distribution': 'Ugyldig fordeling',
  'Invalid email': 'Ugyldig e-post',
  'Invalid referral code': 'Ugyldig vervekode',
  'Invalid session': 'Ugyldig sesjon',
  'Invalid tag': 'Ugyldig tag',
  'Invalid verification code': 'Ugyldig verfiseringskode',
  'Invalid {0} address': ['Ugyldig ', ['0'], ' adresse'],
  'Invalid {currencyName} address': ['Ugyldig ', ['currencyName'], ' adresse'],
  Invest: 'Invester',
  "Invest a fixed amount in cryptocurrency at a fixed time with a direct debit from your bank. We'll take care of the rest!":
    'Invester et fast beløp i kryptovaluta til fast tid med autotrekk fra banken din. Vi ordner resten!',
  'Invest in crypto now': 'Invester i krypto nå',
  'Invest in crypto whenever you want': 'Invester i krypto når du selv ønsker',
  'Invest in {currency}': ['Invester i ', ['currency']],
  'Invite friends': 'Inviter venner',
  'Invite friends and receive {0} {referralCurrency} in {payoutCurrencyName} for each friend who verifies their account. Whoever you invite gets a welcome bonus of {1} {referralCurrency}.':
    [
      'Verv venner og motta ',
      ['0'],
      ' ',
      ['referralCurrency'],
      ' i ',
      ['payoutCurrencyName'],
      ' for hver venn som verifiserer kontoen sin. Den du verver får ',
      ['1'],
      ' ',
      ['referralCurrency'],
      ' i velkomstbonus.',
    ],
  'Invite friends to Firi': 'Inviter venner til Firi',
  'Invite friends to Firi and earn bitcoin ⭐': 'Inviter venner til Firi og tjen bitcoin ⭐️',
  'Invite with link': 'Inviter med lenke',
  'Is my crypto safe with Firi?': 'Er kryptoen min trygg hos Firi?',
  'Is there a high risk of significant loss when investing in meme coins?':
    'Er det høy risiko for betydelig tap ved investering i meme-coins?',
  'Is there something that does not seem right here?': 'Er det noe som ikke stemmer her?',
  'Is there something that does not seem right here? Contact our support on chat!':
    'Er det noe som ikke stemmer her? Ta kontakt med vår support på chat!',
  'It can take up to 1-2 days from the amount being deducted from your bank until the cryptocurrency is visible in your savings account.':
    'Det kan ta opptil 1-2 dager fra beløpet trekkes fra banken din til kryptovalutaen er synlig på sparekontoen din.',
  'It is currently high demand and few that wishes to sell {currency}. To avoid risking you buy for an expensive price, we want to inform you that the price differentiate approx. <0>{absolutePercent}%</0> in comparison to the international price index.':
    [
      'Akkurat nå er det høy etterspørsel og få ønsker å selge ',
      ['currency'],
      '. For å unngå at du kjøper til høy pris, vil vi informere deg om at prisen skiller seg ca. <0>',
      ['absolutePercent'],
      '%</0> i forhold til den internasjonale pris indeksen.',
    ],
  'It is currently low demand and few that wishes to buy {currency}. To avoid risking you selling for a cheap price, we want to inform you that the price differentiate approx. <0>{absolutePercent}%</0> in comparison to the international price index.':
    [
      'Akkurat nå er det lav etterspørsel og få ønsker å kjøpe ',
      ['currency'],
      '. For å unngå at du selger til for lav pris, vil vi informere deg om at prisen skiller seg ca. <0>',
      ['absolutePercent'],
      '%</0> i forhold til den internasjonale pris indeksen.',
    ],
  'It is free to withdraw funds from your staking account. Remember that the earning of rewards will stop if you deactivate staking.':
    'Det er gratis å ta ut fra staking-konto. Husk at opptjening til belønning avsluttes hvis du deaktiverer staking.',
  'It is important to us that your funds are safely stored. In addition to using the best and latest technology for storing cryptocurrencies, your funds are also insured with us.':
    'Det er viktig for oss at midlene dine er trygt lagret. I tillegg til å benytte oss av den beste og nyeste teknologien for lagring av kryptovaluta, så er midlene dine også forsikret hos oss.',
  'It is not possible to change or recover passwords if you\nregistered with Vipps. This is because a password for your\nuser was never registered.':
    'Det er ikke mulig å endre eller gjenopprette passord hvis du registrerte deg med Vipps. Dette er fordi det aldri ble registrert et passord til brukeren din.',
  'It is now easier to invite friends and acquaintances to Firi! We have removed the "Firi ambassador" concept and simplified the affiliate program. Earn {0} {referralCurrency} for each friend you invite. No limit on the number of invites as long as you follow the guidelines.':
    [
      'Nå er det enklere å verve venner og kjente til Firi! Vi har fjernet “Firi ambassadør”-konseptet og forenklet verveprogrammet. Tjen ',
      ['0'],
      ' ',
      ['referralCurrency'],
      ' for hver venn du verver. Ingen grense på antall vervinger så lenge du følger retningslinjene.',
    ],
  'It usually takes 1-2 banking days until the money arrives in your account, depending on your bank.':
    'Det tar vanligvis 1-2 virkedager før pengene kommer inn på kontoen din, avhengig av banken din.',
  'It usually takes 1-2 working days until the money arrives in your account.':
    'Det tar vanligvis 1-2 virkedager før pengene kommer inn på kontoen din.',
  "It's time to update!": 'Det er på tide å oppdatere!',
  'Join our crypto advent calendar and win crypto every day until christmas eve':
    'Bli med i vår julekalender og pakk ut en sjanse til å vinne krypto eller eksklusiv Firi-merch hver dag frem til julaften!',
  "Join this year's Christmas calender!": 'Bli med på årets julekalender!',
  'Just remembered?': 'Husket du det?',
  'Keep in mind that the most relevant documentation make it easier and quicker for us to process your case.':
    'Husk at best mulig informasjon gjør det enklere for oss å behandle saken raskt.',
  Language: 'Språk',
  'Last available tax report': 'Din nyeste skattemelding',
  'Last day': 'Siste døgnet',
  'Last deposit from': 'Sist overført fra',
  'Last month': 'Forrige måned',
  'Last name': 'Etternavn',
  'Last price': 'Siste pris',
  'Last transactions': 'Siste transaksjoner',
  'Latest articles': 'Seneste artikler',
  'Latest news from the crypto market': 'Siste nytt fra kryptomarkedet',
  "Launched in 2020, Polkadot is one of the world's largest cryptocurrencies based on market value. Polkadot is built as part of a larger vision of a decentralized internet, and wants to revolutionize the way we can use blockchain technology.":
    'Polkadot, som ble lansert i 2020, er en av verdens største kryptovalutaer basert på markedsverdi. Polkadot er bygget som en del av en større visjon om et desentralisert internett, og ønsker å revolusjonere måten vi kan bruke blokkjedeteknologi på.',
  'Lean back. We will let you know when you get a reward🤩':
    'Len deg tilbake. Vi gir deg en lyd når du får belønning 🤩',
  'Learn about crypto': 'Lær om krypto',
  'Learn more': 'Lær mer',
  'Learn more about crypto': 'Lær deg mer om krypto',
  'Learn more about staking': 'Lær mer om staking',
  Learning: 'Lær',
  'Let your crypto do the work for you and earn rewards while you sleep.':
    'La kryptoen din gjøre jobben for deg, og tjen belønninger mens du sover.',
  Light: 'Lyst',
  Lightning: 'Lightning',
  Limits: 'Grenser',
  'Listen in as we talk about the biggest news in the crypto world over the past week':
    'Lytt med når vi snakker om de største nyhetene i kryptoverdenen den siste uken',
  'Litecoin is a digital currency that you can store and transfer directly to anyone, anywhere in the world, without going through third parties such as banks or other central institutions.':
    'Litecoin er en digital valuta som du kan oppbevare og overføre direkte til hvem som helst, hvor som helst i verden, uten å gå via tredjeparter som banker eller andre sentrale institusjoner.',
  'Litecoin is based on the source code of Bitcoin, and is considered to be one of the first alternative cryptocurrencies to Bitcoin.':
    'Litecoin er basert på Bitcoin sin kildekode, og er ansett for å være en av de første alternative kryptovalutaene til Bitcoin.',
  'Litecoin network': 'Litecoin-nettverket',
  'Load more': 'Last mer',
  Loading: 'Laster',
  Locked: 'Låst',
  'Locked in order': 'Låst i ordre',
  'Locked in unstaking': 'Låst i unstaking',
  'Log in': 'Logg inn',
  'Log in or register now': 'Logg inn eller registrer deg nå',
  'Log in to <0>Skatteetaten.no</0> and open the tax return.':
    'Logg inn på <0>Skatteetaten.no</0> og åpne skattemeldingen.',
  'Log in to <0>https://www.altinn.no/</0>': 'Logg inn på <0>https://www.altinn.no/</0>',
  'Log in to Firi or create an account quickly and easily.':
    'Logg inn på Firi eller opprett konto raskt og enkelt.',
  'Log in to TastSelv Borger': 'Logg inn på TastSelv Borger',
  'Log in to Tastselv at SKAT and follow this guide':
    'Logg inn på Tastselv på SKAT og følg denne guiden',
  'Log in to your Firi account.': 'Logg inn på din Firi konto.',
  'Log in to your online bank and go for a fixed transfer/payment.':
    'Logg inn i nettbanken din og gå til fast overføring/betaling.',
  'Log in to your online bank and go to transfer/payment.':
    'Logg inn i nettbanken din og gå til overføring/betaling.',
  'Log in with Vipps or email. If you have registered with Vipps, you can <0>only</0> log in with Vipps.':
    'Logg inn med Vipps eller e-post. Har du registrert deg med Vipps kan du kun logge inn med Vipps.',
  'Log into': 'Logg inn på',
  'Log out': 'Logg ut',
  Login: 'Logg inn',
  "Looks like we're not available in your region":
    'Det ser ut til at vi ikke er tilgjengelig i din region',
  Loss: 'Tap',
  'Low demand': 'Lav etterspørsel',
  'Lowest (24h)': 'Laveste (24h)',
  'Lowest price': 'Laveste pris',
  'Lowest value': 'Lavest verdi',
  "MANA is linked to the crypto game Decentraland and serves as a currency within the game. Decentraland is a virtual world that players can freely explore and build their own things in. Players can buy and sell properties, services, and assets within the game, and MANA functions as the medium of payment. Therefore, the success of MANA is closely tied to Decentraland's ability to attract players.":
    'MANA er knyttet til kryptospillet Decentraland, og fungerer som en valuta inne i spillet. Decentraland er en virtuell verden som spillerne fritt kan utforske og bygge egne ting i. Spillere kan kjøpe og selge eiendommer, tjenester og eiendeler inne i spillet, og MANA fungerer da som betalingsmiddel. Suksessen til MANA er derfor tett knyttet til Decentralands evne til å tiltrekke seg spillere.',
  'MATIC is becoming POL as part of a major upgrade to strengthen the Polygon network. Due to this, trading, deposits, and withdrawals of MATIC are temporarily unavailable.':
    'MATIC blir til POL som en del av en større oppgradering for å styrke Polygon-nettverket. På grunn av dette er handel, innskudd, og uttak av MATIC midlertidig utilgjengelig.',
  'Main account': 'Hovedkonto',
  'Maintenance is in progress from 07:00 to 08:00. Estimated time is one hour, but it can be faster. Thanks for your patience!':
    'Vedlikehold pågår fra 07:00 til 08:00. Estimert tid er en time, men det kan gå raskere. Takk for tålmodigheten!',
  'Maintenance ongoing...': 'Vedlikehold pågår...',
  'Make a crypto withdrawal': 'Gjør et uttak av krypto',
  'Make a deposit': 'Gjør et innskudd',
  'Make a deposit to your savings account': 'Gjør et innskudd til din sparekonto',
  'Make a deposit to your savings account, minimum {0} {currency}': [
    'Gjør et innskudd til din sparekonto, minimum ',
    ['0'],
    ' ',
    ['currency'],
  ],
  'Make a withdrawal to your bank account': 'Gjør uttak til bankkontoen din',
  'Make more changes': 'Gjøre flere endringer',
  'Make withdrawal': 'Gjør uttak',
  'Make you first investment now': 'Gjør din første investering nå',
  'Make your first crypto deposit': 'Gjør ditt første kryptoinnskudd',
  'Make your first investment': 'Gjør din første investering',
  'Manual transfer': 'Manuell overføring',
  "Many choose crypto because it's an exciting alternative to stocks and funds. Crypto as part of a diversified portfolio can be a smart choice for the future, but it's important to remember that all investments involve risk.":
    'Mange velger krypto fordi det er et spennende alternativ til aksjer og fond. Krypto som en del av en diversifisert portefølje kan være et smart valg for fremtiden, men det er viktig å huske at alle investeringer innebærer risiko.',
  'Mark as read': 'Merk som lest',
  Market: 'Marked',
  'Market and prices': 'Marked og priser',
  'Market is empty': 'Markedet er tomt',
  Marketing: 'Markedsføring',
  Max: 'Maks',
  'Max 18 characters': 'Maks 18. tegn',
  'Max filesize': 'Maksimal filstørrelse',
  'Maximum withdraw is {0} {currency}': ['Maks uttak er ', ['0'], ' ', ['currency']],
  'Maybe later': 'Kanskje senere',
  'Maybe not chocolate cookies, but still important for a good experience when you visiting us. We use cookies to improve your user experience, secure our website and for marketing.':
    'Kanskje ikke sjokoladekjeks, men likevel viktig for en god opplevelse når du besøker oss. Vi bruker informasjonskapsler for å forbedre brukeropplevelsen din, sikre nettstedet vårt og for markedsføring.',
  'Meme coins': 'Meme-coins',
  'Meme coins are a type of cryptocurrency based on humor and internet culture. They exist primarily for amusement and, unlike traditional cryptocurrencies like Bitcoin and Ethereum, have no underlying utility value.':
    'Meme-coins er en type kryptovaluta som er basert på humor og internettkultur. De eksisterer i stor grad bare for moro skyld og har i motsetning til tradisjonelle kryptovaluta, som Bitcoin og Ethereum, ingen underliggende nytteverdi.',
  'Meme coins are evenly distributed across many wallets. It takes many individual stakeholders to move the price':
    'Meme-coins er jevnt fordelt på mange wallets. Det må mange individuelle aktører til for å bevege prisen',
  'Merry Christmas!': 'Gledelig jul!',
  'Merry christmas!': 'God jul!',
  Message: 'Melding',
  Minimum: 'Minimum',
  'Minimum amount is {SAVING_AGREEMENT_MIN_AMOUNT} {0}': [
    'Minstebeløp er ',
    ['SAVING_AGREEMENT_MIN_AMOUNT'],
    ' ',
    ['0'],
  ],
  'Minimum amount is {minAmount} NOK': ['Minimumsbeløp er ', ['minAmount'], ' NOK'],
  'Minimum deposit is': 'Minimum innskudd er',
  'Minimum withdrawal is': 'Minimum uttak er',
  'Minimum withdrawal is {0} {currency} ≈ {1} {2}': [
    'Minimum uttak er ',
    ['0'],
    ' ',
    ['currency'],
    ' ≈  ',
    ['1'],
    ' ',
    ['2'],
  ],
  'Minimum {VALUE_MIN_LENGTH} characters': ['Minst ', ['VALUE_MIN_LENGTH'], ' tegn'],
  'Mismatching price index': 'Mismatchende prisindeks',
  'Missing input': 'Mangler input',
  'Missing purchase price': 'Innkjøpspris mangler',
  MobilePay: 'MobilePay',
  'MobilePay fee': 'MobilePay-gebyr',
  'Moderately high level of knowledge': 'I noe grad',
  'Moderately low level of knowledge': 'I liten grad',
  'Money laundering': 'Hvitvasking',
  Month: 'Måned',
  'More and better market information': 'Mer og bedre informasjon om markedet',
  'More coins': 'Flere coins',
  'More information will give you a better user experience. This includes shorter processing time at our customer service.':
    'Flere opplysninger vil gi deg en bedre brukeropplevelse. Dette inkluderer kortere behandlingstid hos vår kundeservice.',
  'More options': 'Flere muligheter',
  'More rewards': 'Flere fordeler',
  'More rewards are coming': 'Det åpnes flere belønninger etter hvert',
  'More services (e.g., shorting or swap function)':
    'Flere tjenester (eks. shorting eller swap-funksjon)',
  'Most meme coins are distributed across a few wallets. Some stakeholders can cause major fluctuations in the price themselves':
    'Mesteparten av meme-coins er fordelt på få wallets. Enkelte aktører kan selv forårsake store svingninger i prisen',
  'Most popular on Firi': 'Mest populære hos Firi',
  'Most popular on Firi now': 'Mest populære hos Firi nå',
  'Most popular on Firi right now': 'Mest populære hos Firi akkurat nå',
  'Move crypto to your Firi-wallets': 'Flytt krypto til dine Firi-wallets',
  'Movement in the crypto market': 'Bevegelser i kryptomarkedet',
  'Must be 6 digits': 'Må være 6 sifre',
  'Must be a valid email address': 'Må være en gyldig e-post adresse',
  'Must be approved': 'Må godkjennes',
  'Must contain 6 digits': 'Må være 6 sifre',
  'Must contain {PASSWORD_MIN_LENGTH_VARIANT_B} characters': [
    'Må inneholde ',
    ['PASSWORD_MIN_LENGTH_VARIANT_B'],
    ' tegn',
  ],
  'Must only contain digits': 'Kan bare inneholde tall',
  'My accounts': 'Mine kontoer',
  'My balance': 'Min saldo',
  'My bank accounts': 'Mine bankkontoer',
  'My crypto': 'Min krypto',
  'My destination tag': 'Min destinasjonskode',
  'My documentation': 'Min dokumentasjon',
  'My favorites': 'Mine favoritter',
  'My holdings': 'Min beholdning',
  'My information': 'Mine opplysninger',
  'My profile': 'Min profil',
  'My referral code:': 'Min vervekode:',
  'My referrals': 'Mine vervinger',
  'My saved addresses': 'Mine lagrede adresser',
  'My saved favorites': 'Mine lagrede favoritter',
  'My savings agreement': 'Min spareavtale',
  'My uploaded documents': 'Min opplastede dokumentasjon',
  'My wallet address': 'Min wallet-adresse',
  'My {currency} holding': ['Min ', ['currency'], ' beholdning'],
  'NOTE: we do not support instant transfer.': 'OBS: vi støtter ikke straks betaling.',
  Name: 'Navn',
  'Name is required': 'Navn er påkrevd',
  'Name of bank': 'Navn på bank',
  'Name unknown': 'Navn ukjent',
  'Need help or have any questions? Our excellent customer service team is here to help you. Contact us through the chat in the Firi app.':
    'Trenger du hjelp eller lurer på noe? Vårt eminente kundeservice-team er her for å hjelpe deg. Ta kontakt med oss på chat i Firi-appen.',
  'Needs to verify using BankID': 'Må verifisere seg med BankID',
  'Neither nor': 'Hverken eller',
  'New at Firi': 'Ny hos Firi',
  'New crypto': 'Ny krypto',
  'New favorite': 'Ny favoritt',
  'New menu item - Explore': 'Nytt i menyen - Utforsk',
  'New password': 'Nytt passord',
  'New products and crypto at Firi': 'Nye produkter og krypto hos Firi',
  'New receiver': 'Ny mottaker',
  'New reward! Teach others about crypto': 'Ny belønning! Lær andre om krypto',
  'New update': 'Ny oppdatering',
  'New update available': 'Du må oppdatere appen',
  'New update in {storeVersion}': ['Oppdatering i ', ['storeVersion']],
  Newbie: 'Nybegynner',
  News: 'Nyheter',
  'News!': 'Nyhet!',
  Newsletter: 'Nyhetsbrev',
  Next: 'Neste',
  No: 'Nei',
  'No commission for new referrals': 'Ingen kommisjon på nye vervinger',
  'No data': 'Ingen data',
  'No fees': 'Ingen gebyr',
  'No more than any other cryptocurrency': 'Ikke mer enn annen kryptovaluta',
  'No results found': 'Ingen resultater funnet',
  'No thanks': 'Nei takk',
  'No transactions to show yet': 'Ingen transaksjoner å vise',
  'No, I do not trade on other exchanges': 'Nei, jeg handler ikke på andre børser',
  'No, but it may take som time to process the withdraw. We will give you a time estimate when you choose to deactivate staking.':
    'Nei, men det kan ta tid å behandle uttaket. Vi gir deg et tidsestimat når du velger å deaktivere staking',
  'No, not now': 'Nei, ikke nå',
  'No, thanks': 'Avbryt',
  'Non-custodial wallet (Argent, Metamask, Rainbow, etc)':
    'Non-custodial wallet (Argent, Metamask, Rainbow, etc)',
  Norway: 'Norge',
  Norwegian: 'Norsk',
  'Norwegian kroner': 'Norske kroner',
  'Norwegian-lang': 'Norsk',
  'Not a valid bank account number': 'Ikke et gyldig kontonummer',
  'Not a valid email': 'Ikke en gyldig e-post',
  'Not a valid referral code': 'Ikke en gyldig vervekode',
  'Not all banks support Open Banking. You can instead add a bank account manually.':
    'Alle banker støtter ikke Open Banking. Du kan i stedet legge til kontonummer manuelt.',
  'Not all banks support Open Banking. You can instead transfer money manually from your online bank.':
    'Alle banker støtter ikke Open Banking. Du kan i stedet overføre penger manuelt fra nettbanken din.',
  'Not enough buy orders available': 'Ikke nok kjøpsordre tilgjengelig',
  'Not enough sell orders available': 'Ikke nok salgsordre tilgjengelig',
  'Not now': 'Ikke nå',
  'Not supported files': 'Ikke støttede filer',
  'Not sure which crypto to invest in? Here you can find crypto by category.':
    'Usikker på hvilken krypto du skal investere i? Her kan du finne krypto etter kategori.',
  'Not valid': 'Ikke gyldig',
  'Not valid code': 'Ikke gyldig kode',
  'Note that the price may change between the time you decide to buy and when you confirm the order. The exchange rate is therefore an estimate and may vary by up to 3 percent.':
    'Merk at prisen kan endre seg fra du bestemmer deg for å kjøpe og til du har bekreftet ordren. Vekslingskurs er altså et estimat, og kan variere med opptil 3 prosent.',
  'Note!': 'Obs!',
  'Note! If you make changes to the amount and interval, you must update it in your online bank.':
    'Obs! Gjør du endringer på beløp og intervall må du oppdatere det i nettbanken din.',
  'Nothing here yet!': 'Her var det tomt, gitt!',
  Notifications: 'Varsler',
  'Now just sit back and get ready for the biggest crypto happening!':
    'Nå er det bare å begynne å glede seg!',
  'Now we are just waiting to receive your first bank deposit. View or change your savings agreement here':
    'Nå venter vi bare på ditt første bankinnskudd. Se eller endre din spareavtale her',
  'Now we need relevant documentation from you': 'Nå behøver vi relevant dokumentasjon fra deg',
  'Now you can get up to {0}% annual yield when staking {baseCurrency}': [
    'Nå kan du få opp til ',
    ['0'],
    '% årlig avkastning når du staker ',
    ['baseCurrency'],
    ' ',
  ],
  'Now you only need som ETH to get started': 'Nå trenger du bare ETH for å starte',
  'Numbers only from 0 to 100': 'Tall bare mellom 0 og 100',
  'OTC Trading': 'OTC-handel',
  Off: 'Av',
  'Oh no..': 'Åh nei..',
  "Oh, it looks like you're a bit lost!": 'Oi, det ser ut til at du har gått deg vill!',
  Okay: 'Okay',
  'Okay!': 'Den er grei!',
  'Okay, I got it!': 'Da er jeg med!',
  On: 'På',
  'On the new profile screen, you can now add a personal touch to your Firi user with a profile picture. We have also moved all settings here, along with Firi Advantage and Firi Affiliate.':
    'På den nye profilskjermen kan du nå legge til et personlig preg på Firi-brukeren din med et profilbilde. Vi har også flyttet alle innstillingene her, sammen med Firi Fordel og Firi Affiliate.',
  'Once it has been sent, it will usually takes around 10 minutes for the first confirmation on the blockchain and normally within an hour to complete all confirmations. However, it can take longer depending on the transaction volume at the time your amount are being sent.':
    'Når den er sendt, tar det vanligvis rundt 10 minutter før den første bekreftelsen på blokkjeden og normalt innen en time å fullføre alle bekreftelser. Det kan imidlertid ta lengre tid avhengig av transaksjonsvolumet på tidspunktet beløpet sendes.',
  'Once you have downloaded the 2FA app, you can come back here and move on to the next step.':
    'Når du har lastet ned 2FA-appen kan du komme tilbake hit og gå videre til neste steg.',
  'One of the values that stands strong in Cardano is to be a sustainable blockchain that addresses the challenges of high power consumption that many blockchains have today.':
    'En av verdiene som står sterkt i Cardano er å være en bærekraftig blokkjede som addresserer utfordringene med høyt strømforbruk som mange blokkjeder har i dag.',
  'One time password must be 6 digits': 'Engagngskode må være 6 sifre',
  'One-time password is required.': 'Engangskode er påkrevd',
  'Ongoing activities': 'Pågående aktivitet',
  'Ongoing transfer': 'Pågående overføring',
  'Ongoing transfers': 'Pågående overføringer',
  'Only users over 18 with BankID can participate.': 'Kun brukere over 18 år med BankID kan delta.',
  'Ooopsie, an unexpected error occurred!': 'Ooopsie, en uforventet feil oppstod!',
  'Open "Melding: Skattemelding for 2020 som dokumentasjon"':
    'Åpne "Melding: Skattemelding for 2020 som dokumentasjon"',
  'Open Google Authenticator': 'Åpne Google Authenticator',
  'Open a new account': 'Åpne en ny konto',
  'Open a staking account and earn rewards on your crypto.':
    'Åpne staking-konto og tjen belønning på din krypto.',
  'Open new account': 'Åpne ny konto',
  'Open savings account': 'Åpne sparekonto',
  'Open setting': 'Åpne innstillinger',
  'Open staking account': 'Åpne staking-konto',
  'Open you inbox in Altinn': 'Gå til din innboks på Altinn',
  'Open {storeVersion}': ['Til ', ['storeVersion']],
  'Open “Message: Skattemelding for 2020 som dokumentasjon”':
    'Åpne “Melding: Skattemelding for 2020 som dokumentasjon”',
  Optional: 'Valgfritt',
  'Or copy the configuration key and add it to your 2FA app':
    'Eller kopier konfigureringsnøkkelen og legg den til i din 2FA app',
  'Order book': 'Ordrebok',
  'Order is either filled or cancelled.':
    'Ditt ordre ble nettopp fylt opp eller kansellert. Vennligst prøv igjen.',
  Orders: 'Ordre',
  'Org nr:': 'Org nr:',
  'Originally, Binance users used BNB to get lower trading fees. The crypto is know as Binance Coin, and has multiple use cases. Such as operating and securing the BNB Chain ecosystem and being used as a payment method both online and in physical stores.':
    'BNB ble opprinnelig benyttet av Binance-brukere for å få lavere handelsgebyrer. Kryptoen er kjent som Binance Coin, og har flere bruksområder. Blant annet drifter og sikrer den BNB Chain-økosystemet og brukes som betalingsmiddel både online og i fysiske butikker.',
  Other: 'Annet',
  'Other deposit methods': 'Andre innskuddsmetoder',
  'Other exchanges (Binance, Coinbase, etc.)': 'Andre børser (Binance, Coinbase, etc.)',
  'Other income from virtual assets / crypto currency':
    'Andre inntekter fra virtuelle eiendeler / kryptovaluta',
  'Other marketing': 'Annet markedsføring',
  'Other payment methods': 'Andre betalingsmetoder',
  'Other types of illegal activity': 'Andre typer ulovlig aktivitet',
  'Others have selected': 'Andre har valgt',
  'Our crypto withdrawal service is temporarily unavailable. Our service provider is working on it.':
    'Tjenesten vår for kryptouttak er midlertidig utilgjengelig. Vår leverandør jobber på spreng med saken.',
  'Our referral program will be part of the Firi rewards program, and in the new benefits program it will not be possible to earn commission on the trading fees of new friends you refer. Of course, you keep the commission on the trading fees of those you have already referred and will continue to earn from this.':
    'Verveprogramt vårt blir en del av Firi Fordel, og i det nye fordelsprogrammet vil det ikke være mulig å tjene kommisjon på handelsgebyrene til nye venner du verver. Du beholder selvsagt kommisjon på handelsgebyrene til de du allerede har vervet og vil fortsette å tjene på dette. ',
  "Our system is temporarily down and we are working to get it back up as quickly as possible. Your funds are completely safe with us. Thanks for your patience, we'll keep you updated!":
    'Systemet vårt er midlertidig nede, og vi jobber med å få det opp igjen så raskt som mulig. Dine midler er helt trygge hos oss. Takk for tålmodigheten din, vi holder deg oppdatert!',
  Overview: 'Oversikt',
  'PDF - Annual statement': 'PDF - Årsoppgave',
  'PDF - Tax report': 'PDF - Skatteberegninger',
  'Paid advertising to promote the referral link is not allowed.':
    'Betalt reklame for å promotere vervelenken er ikke tillatt.',
  'Paid out': 'Utbetalt',
  Pair: 'Par',
  Password: 'Passord',
  'Password is changed': 'Passordet er endret',
  'Passwords do not match': 'Passordene er ikke like',
  'Past 3 months': 'Siste 3 måneder',
  'Past month': 'Siste måneden',
  'Past week': 'Siste uken',
  'Past year': 'Siste året',
  'Paste from clipboard': 'Lim inn fra utklippstavle',
  'Pay special attention to:': 'Legg spesielt merke til:',
  'Payment method': 'Betalingsmetode',
  Payouts: 'Utbetalinger',
  Pending: 'Ventende',
  'Pending payouts': 'Til utbetaling',
  'Perfect!': 'Perfekt!',
  Permissions: 'Tillatelser',
  'Personal experience': 'Personlig opplevelse',
  'Personal touch with profile picture': 'Personlig preg med profilbilde',
  'Phone number': 'Telefonnummer',
  'Pink Ribbon 2021': 'Rosasløyfe 2021',
  'Please install': 'Vennligst installer',
  'Please look over and confirm that the provided information is correct':
    'Vennligst se over og bekreft at opplysningene du har oppgitt er riktige',
  'Please note that it can take 1 working day until you can send the funds out of your account with us.':
    'Vær obs på at det kan ta 1 arbeidsdag til du kan sende midlene ut fra kontoen din hos oss.',
  'Please note that we do not allow transfers to or from addresses that are linked to':
    'Vær oppmerksom på at vi ikke tillater overføringer til eller fra adresser som er knyttet til',
  'Please sign with BankId to verify your identity quick and easy':
    'Vennligst signer med BankID for å verifisere din identitet raskt og enkelt.',
  'Please try again': 'Vennligst prøv igjen.',
  'Please upload relevant documentation. The documents are safely and securly stored at Firi.':
    'Her kan du laste opp relevant dokumentasjon. Din dokumentasjon er alltid trygt oppbevart hos oss.',
  Podcast: 'Podcast',
  "Polkadot is the name of Polkadot's protocol. When we talk about Polkadot's cryptocurrency, or token, it is referred to as DOT.":
    'Polkadot er navnet på Polkadot sin protokoll. Når man snakker om Polkadot sin kryptovaluta, eller token, betegnes denne som DOT.',
  'Polkadot network': 'Polkadot-nettverket',
  'Polkadot wants to unite large and unique networks, such as Bitcoin and Ethereum, so that they can collaborate across blockchains.':
    'Polkadot ønsker å forene store og unike nettverk, som for eksempel Bitcoin og Ethereum, slik at de kan samarbeide på tvers av blokkjedene.',
  'Polygon is an ecosystem of various solutions to make Ethereum more scalable. Matic is the associated cryptocurrency that is used for transactions and staking, among other things.':
    'Polygon er et økosystem av ulike løsninger for å gjøre Ethereum mer skalerbart. Matic er den tilhørende kryptovalutaen som blant annet benyttes til transaksjoner og staking.',
  'Polygon is an ecosystem of various solutions to make Ethereum more scalable. Polygon is the associated cryptocurrency that is used for transactions and staking, among other things.':
    'Polygon er et økosystem av ulike løsninger for å gjøre Ethereum mer skalerbart. Polygon er den tilhørende kryptovalutaen som blant annet benyttes til transaksjoner og staking.',
  'Polygon network': 'Polygon-nettverket',
  Popular: 'Populære',
  Press: 'Presse',
  'Press-release': 'Pressemelding',
  'Preview Buy': 'Forhåndsvis kjøp',
  'Preview Sell': 'Forhåndsvis salg',
  'Previously uploaded': 'Tidligere opplastet dokumentasjon',
  Price: 'Pris',
  'Price ({0})': ['Pris (', ['0'], ')'],
  'Price details of your purchases': 'Prisdetaljer på dine kjøp',
  'Price development last week': 'Prisutvikling siste uken',
  Privacy: 'Personvernerklæring',
  Prize: 'Belønning',
  Processing: 'Under behandling',
  Profile: 'Profil',
  Profit: 'Gevinst',
  'Promote only your own link and be honest – no guarantees for profit.':
    'Promoter kun din egen lenke og vær ærlig – ingen garantier for fortjeneste.',
  'Proof-of-stake': 'Proof-of-stake',
  'Proof-of-work': 'Proof-of-work',
  'Purchase amount': 'Kjøpesum',
  'Purchase of {0} not available': ['Kjøp av ', ['0'], ' utilgjengelig'],
  'Purchase of {selectedCurrency} not available': [
    'Purchase of ',
    ['selectedCurrency'],
    ' not available',
  ],
  'Purchase price': 'Innkjøpspris',
  'Pyramid games': 'Pyramidespill',
  Rate: 'Kurs',
  'Rating on App Store': 'Rating på App Store',
  'Rating on Google Play': 'Rating på Google Play',
  Read: 'Lese',
  'Read about fees': 'Les om gebyrer',
  'Read about insurance of your assets': 'Les om forsikring av dine midler',
  'Read about trades on your savings account': 'Les om handler på sparekontoen din',
  'Read more': 'Les mer',
  'Read more about': 'Les mer om',
  'Read more about crypto tax here': 'Les mer om kryptoskatt',
  'Read more about how we work to prevent scams and investment fraud':
    'Les mer om hvordan vi arbeider for å forhindre svindel og investeringsbedrageri',
  'Read more about insurance': 'Les mer om forsikring',
  'Read more about processing times here': 'Les mer om behandlingstid her',
  'Read more about rewards here.': 'Les mer om belønning her.',
  'Read more about the tax report here': 'Les mer om skattemeldingen her',
  'Read more about what personal information we process here.':
    'Her kan du lese mer om hvilke personopplysninger vi behandler. ',
  'Read more articles': 'Les flere artikler',
  'Read more here': 'Les mer her',
  'Read more news': 'Les flere nyheter',
  'Read terms for the calendar': 'Les vilkårene for kalenderen',
  'Ready to get started?': 'Er du klar til å starte?',
  'Recall that it can take 1-2 business days before the withdrawn from your bank reaches your savings account here at Firi.':
    'Husk at det kan ta opptil 1-2 dager fra beløpet trekkes fra banken din til kryptovalutaen er synlig på sparekontoen din.',
  Receive: 'Motta',
  'Receive bonus when registering with Firi | Norwegian exchange for cryptocurrency trading':
    'Motta bonus ved registrering hos Firi | norsk børs for handel av kryptovaluta',
  'Receive crypto': 'Motta krypto',
  'Receive notifications to add purchase prices on new crypto deposits':
    'Motta varsler om å legge til innkjøpspriser på nye kryptoinnskudd',
  'Receive {0}': ['Motta ', ['0']],
  Received: 'Mottatt',
  'Received amount': 'Mottatt sum',
  'Received amount:': 'Mottatt sum:',
  'Received rewards': 'Mottatt belønning',
  Receiver: 'Mottaker',
  "Receiver's address": 'Mottakers adresse',
  "Receiver's {0}-address": ['Mottakers ', ['0'], '-adresse'],
  "Receiver's {currency}-address": ['Mottakers ', ['currency'], '-adresse'],
  'Recent articles': 'Nyeste artikler',
  Recipient: 'Mottaker',
  "Recipient's name": 'Mottakerens navn',
  'Recurring deposits': 'Faste overføringer',
  'Recurring transfers': 'Faste overføringer',
  'Red Cross': 'Røde Kors',
  Redeem: 'Løs inn',
  'Redeem gift card': 'Løs inn gavekort',
  'Redeem your gift card in the Firi app or at Firi.no':
    'Løs inn gavekortet ditt i Firi-appen eller på Firi.no.',
  'Refer a friend': 'Verv en venn',
  'Refer a friend and get {0} {1} i {payoutCurrencyName}!': [
    'Verv en venn og få ',
    ['0'],
    ' ',
    ['1'],
    ' i ',
    ['payoutCurrencyName'],
    '!',
  ],
  'Refer at least {0} friends that trade crypto with Firi before 31. march.': [
    'Verv minst ',
    ['0'],
    ' venner som handler krypto hos Firi innen 31. mars.',
  ],
  'Refer friends': 'Verv venner',
  'Referral bonus is paid out in bitcoin (BTC) at the end of each month. How much bitcoin you get depends on the exchange rate on the day you referred your friend.':
    'Vervebonus blir utbetalt i bitcoin (BTC) ved hvert månedsskifte. Hvor mye bitcoin du får er avhengig av kursen den dagen du vervet din venn.',
  'Referral bonus is paid out in {0} at the end of each month.': [
    'Vervebonus blir utbetalt i ',
    ['0'],
    ' ved hvert månedsskifte.',
  ],
  'Referral code': 'Vervekode',
  'Referral code (optional)': 'Vervekode (valgfritt)',
  'Referral code is not valid': 'Vervekoden er ikke gyldig',
  Referrals: 'Vervinger',
  'Referrals pending payment': 'Vervinger til utbetaling',
  'Referrals with this code': 'Vervinger med denne kodden',
  Referred: 'Vervet',
  'Referred {0}/{1}': ['Vervet ', ['0'], '/', ['1']],
  Refund: 'Refusjon',
  'Reg number': 'Reg nummer',
  'Reg. nr. and account number': 'Reg. nr. og kontonummer',
  'Regards {0}': ['Hilsen ', ['0']],
  'Regards {from}': ['Hilsen ', ['from']],
  Register: 'Registrer deg',
  'Register account with Firi': 'Registrer konto hos Firi',
  'Register for free': 'Registrer deg gratis',
  'Register here': 'Meld deg på her',
  'Register now': 'Meld deg på nå',
  'Register the transfer in your bank': 'Registrer overføringen i nettbanken din',
  'Register with': 'Registrer med',
  'Register with Vipps': 'Registrer deg med Vipps',
  'Register with Vipps to get started and get {referralAmount} kroner to buy your first cryptocurrency with Firi and start your investments.':
    [
      'Registrer med Vipps for å komme i gang og få ',
      ['referralAmount'],
      ' kroner til å kjøpe din første kryptovaluta hos Firi og starte dine investeringer.',
    ],
  'Register with email': 'Registrer deg med e-post',
  'Registered with Vipps?': 'Registrert deg med Vipps?',
  'Registration is complete!': 'Registreringen er fullført!',
  'Related articles': 'Relaterte artikler',
  'Related pages': 'Relaterte sider',
  'Relevant articles': 'Relevante artikler',
  'Relevant documentation can for instance be:': 'Relevant dokumentasjon kan være:',
  Remaining: 'Gjenstående',
  'Remember that the bank is closed on the weekend, but with {labels} and {scheme} you can receive the deposit within seconds.':
    [
      'Husk at banken er stengt i helgen, men med ',
      ['labels'],
      ' og ',
      ['scheme'],
      ' kan du motta innskuddet i løpet av sekunder.',
    ],
  'Remember that the minimum amount is {SAVING_AGREEMENT_DETAIL_MIN_AMOUNT} {0} per selected cryptocurrency.':
    'Husk at minstebeløpet er 10 kroner per valgt kryptovaluta.',
  'Remember that you are responsible for filling in the figures in the tax return. This does not happen automatically.':
    'Selv om vi regner ut kryptoskatten for deg, så er du selv ansvarlig for å fylle inn tallene i skattemeldingen. Dette skjer ikke automatisk.',
  'Remember to update your payment agreement in the bank':
    'Husk å oppdatere din betalingsavtale i banken',
  'Remember!': 'Husk!',
  'Remember, the recruitment must be honest and fair – we monitor the program to ensure it is not abused.':
    'Husk, vervingen må skje ærlig og redelig – vi kontrollerer at programmet ikke misbrukes.',
  Remove: 'Fjern',
  'Remove as favorite': 'Fjern som favoritt',
  'Remove bank account': 'Fjern bankkonto',
  'Remove photo': 'Fjern bildet',
  'Repeat code': 'Gjenta kode',
  'Repeat password': 'Gjenta passord',
  'Reqeust withdraw': 'Be om uttak',
  'Request reset link': 'Be om tilbakestilling',
  'Request {0}': ['Meld inn ønske om ', ['0']],
  Requested: 'Forespurt',
  Required: 'Påkrevd',
  'Requires subscription at': 'Krever abonnement hos',
  'Resend code': 'Send kode på nytt',
  'Reset password': 'Tilbakestill passord',
  Return: 'Avkastning',
  Reward: 'Belønning',
  'Reward claimed or not found': 'Belønning hentet eller ikke funnet',
  'Reward from staking': 'Belønning fra staking',
  'Reward on this code': 'Belønnig på denne koden',
  'Reward: {0} for {1} {2}': ['Belønning: ', ['0'], ' for ', ['1'], ' ', ['2']],
  Rewards: 'Belønninger',
  'Rewards (crypto)': 'Belønning (krypto)',
  'Rewards ({0})': ['Belønning (', ['0'], ')'],
  'Rewards from staking are taxable income.': 'Belønning fra staking er skattepliktig inntekt.',
  'Rewards received': 'Belønning mottas',
  'Right around the corner': 'Rett rundt hjørnet',
  'Right now there is a high demand to buy {currency}. We recommend you try again later.': [
    'Akkurat nå er det høy etterspørsel for å kjøpe ',
    ['currency'],
    '. Vi anbefaler deg å prøve igjen senere.',
  ],
  'Right now there is a high demand to sell {currency}. We recommend you try again later.': [
    'Akkurat nå er det høy etterspørsel for å selge ',
    ['currency'],
    '. Vi anbefaler deg å prøve igjen senere.',
  ],
  'Ripple network': 'Ripple-nettverket',
  'Run tax calculation': 'Oppdater beregningen',
  'SAND functions as the currency within The Sandbox universe. Users can use SAND to buy property, play games, purchase equipment, or customize their avatars.':
    'SAND fungerer som valutaen innenfor The Sandbox-universet. Brukere kan bruke SAND til å kjøpe eiendom, spille spill, kjøpe utstyr eller tilpasse sine avatarer.',
  'Safe and easy crypto trading.': 'Kjøp krypto trygt og enkelt.',
  'Safe staking with Firi🔐': 'Trygg staking hos Firi 🔐',
  'Sale completed': 'Salg fullført',
  'Sale of illegal products or services': 'Salg av ulovlige produkter eller tjenester',
  'Sale of {0} not available': ['Salg av ', ['0'], ' utilgjengelig'],
  'Sale of {selectedCurrency} not unavailable': [
    'Sale of ',
    ['selectedCurrency'],
    ' not available',
  ],
  'Sandbox (SAND) is a cryptocurrency used within the crypto game The Sandbox, a virtual world that allows users to create, own, and monetize as well as explore the world':
    'Sandbox (SAND) er en kryptovaluta som brukes innenfor kryptospillet The Sandbox, en virtuell verden som lar brukere skape, eie og tjene penger i tillegg til å utforske verdenen.',
  'Santa claus': 'Julenisse',
  Save: 'Lagre',
  'Save a recipient as a favorite with name and address so you can easily send crypto.':
    'Har du en favorittmottaker? Lagre wallet-adressen slik at du enkelt kan sende krypto neste gang.',
  'Save changes': 'Lagre endringer',
  'Save permissions': 'Lagre tillatelser',
  Savings: 'Sparing',
  'Savings & Investments': 'Sparing & Investering',
  'Savings account': 'Sparekonto',
  'Savings agreement': 'Spareavatale',
  Scams: 'Svindel',
  'Scan QR-code to download our app': 'Skann QR-kode for å laste ned appen',
  'Scan QR-code to download our app and get your bonus':
    'Skann QR-koden for å laste ned appen og hente bonusen din',
  'Scan the QR code': 'Skann koden',
  'Scan the QR code in Google Authenticator': 'Skann QR-koden i Google Authenticator',
  'Scan the QR code to download the app and identify yourself with:':
    'Skann QR-koden for å laste ned appen og registrer deg med:',
  'Scan the QR-code or copy the link below to the external wallet. It can take about 1 hour before you see the cryptocurrency on your account.':
    'Skann QR-koden eller kopier lenken nedenfor til den eksterne lommeboken. Det kan gå omtrent 1 time før du ser kryptovalutaen på kontoen.',
  'Scan the QR-code or copy the wallet address below. It can take about 1 hour before you see the cryptocurrency in your account.':
    'Skann QR-koden eller kopier lommebokadressen nedenfor. Det kan ta omtrent 1 time før du ser kryptovalutaen på kontoen din.',
  'Scan the QR-code to download the app': 'Skann QR-kode for å laste ned app',
  'Se example': 'Se eksempel',
  Search: 'Søk',
  'Search after destination': 'Søk etter destinasjon',
  'Search for cryptocurrency': 'Søk etter kryptovaluta',
  'Search for your bank': 'Søk etter din bank',
  'Secret Key': 'Secret Key',
  Security: 'Sikkerhet',
  See: 'Se',
  'See all': 'Se alle',
  'See all articles': 'Se alle artikler',
  'See all transactions': 'Se alle transaksjoner',
  'See current tax for {mostRecentTaxYear}': ['Se foreløpig skatt for ', ['mostRecentTaxYear']],
  'See finalized tax calculations': 'Se ferdigstilte skatteberegninger',
  'See full list': 'Se hele listen',
  'See how to do it in the next step.': 'Se hvordan du gjør det i neste steg.',
  'See more': 'Se mer',
  'See or edit your crypto saving': 'Se og endre Kryptosparing',
  'See whats new': 'Se hva som er nytt',
  'Select "Approve".': 'Velg "Godkjenn".',
  'Select "Change your tax assessment notice/tax return".':
    'Velg "Endre årsoppgjøret / opplysningsskjemaet".',
  'Select "I want to enter aggregated tax information for many virtual currency/cryptocurrency"':
    'Velg "Jeg vil legge inn summerte skatteopplysninger for mange virtuelle valuta/kryptovaluta"',
  'Select "Next" at the bottom of the page.': 'Velg "Neste" nederst på siden.',
  'Select CSV type to export': 'Velg hvilket CSV skjema du vil eksportere',
  'Select amount': 'Velg beløp',
  'Select bank account': 'Velg kontonummer',
  'Select country': 'Velg land',
  'Select crypto': 'Velg krypto',
  'Select currency': 'Velg valuta',
  'Select deposit method': 'Velg innskuddsmetode',
  'Select language': 'Velg språk',
  'Select payment method': 'Velg betalingsmetode',
  'Select theme': 'Velg tema',
  'Select time period': 'Velg tidsperiode',
  'Select year': 'Velg år',
  Sell: 'Selg',
  'Sell all': 'Selg alt',
  'Sell completed': 'Salg fullført',
  'Sell crypto': 'Selg krypto',
  'Sell everything': 'Selg alt',
  'Sell {0}': ['Selg ', ['0']],
  Send: 'Send',
  'Send all': 'Send alt',
  'Send answer': 'Send svar',
  'Send crypto': 'Send krypto',
  'Send invitation': 'Send invitasjon',
  'Send {0}': ['Send ', ['0']],
  'Send {currency}': ['Send ', ['currency']],
  Sent: 'Sendt',
  'Sent to Firi': 'Sendt til Firi',
  'Set up or update the savings agreement in your bank':
    'Sett opp eller endre spareavtalen i banken din',
  'Set up savings agreement': 'Sett opp spareavtale',
  Settings: 'Innstillinger',
  'Shape the future of Firi': 'Hjelp oss med å bli bedre!',
  'Share gift card': 'Del gavekort',
  'Share your referral code or link from the Firi Affiliate section of the app. To receive rewards, the new user must register and verify their account.':
    'Del din vervekode eller lenke fra Firi Fordel-seksjonen i appen. For å motta belønning må den nye brukeren registrere og verifisere kontoen sin.',
  'Short summary': 'Kort oppsummert',
  'Should we send you a notification when the money is in your Firi account?':
    'Skal vi gi deg en lyd når pengene er på Firi-kontoen din?',
  'Should we send you a push notification when the cryptocurrency has been sent?':
    'Skal vi gi deg beskjed når kryptovalutaen er sendt?',
  'Should we send you a push notification when you receive crypto?':
    'Skal vi gi deg en lyd når du mottar krypto?',
  Show: 'Vis',
  'Show address': 'Vis adresse',
  'Show all': 'Vis alle',
  'Show details': 'Vis detaljer',
  'Show less': 'Vis mindre',
  'Show more': 'Vis mer',
  'Show section': 'Åpne avsnitt',
  'Show tax calculation': 'Vis skatteberegning',
  'Show transactions for': 'Vis transaksjoner for',
  'Show transactions for {year}': ['Vis transaksjoner for ', ['year']],
  'Sign In': 'Logg inn',
  'Sign in': 'Logg inn',
  'Sign in here': 'Logg på her',
  'Sign in with': 'Logg inn med',
  'Sign in with email': 'Logg inn med e-post',
  'Sign out': 'Logg ut',
  'Sign up': 'Bli kunde',
  'Sign up easily and safely with crypto.': 'Kom enkelt og trygt i gang med krypto.',
  'Sign up for free': 'Bli kunde gratis',
  'Sign up here': 'Meld deg på her',
  'Sign with BankID': 'Signer med BankID',
  'Since you have recently made a deposit, we are still in the process of receiving the latest information from your bank.':
    'Siden du nylig har foretatt et innskudd er vi fortsatt i en prosess med å motta den siste informasjonen fra banken din.',
  'Since you have recently made a deposit, we are still in the process of receiving the latest information from your bank. We usually receive this information one working day after the deposit':
    'Siden du nylig har foretatt et innskudd er vi fortsatt i en prosess med å motta den siste informasjonen fra banken din. Denne informasjonen får vi normalt en arbeidsdag etter at du har mottatt innskuddet.',
  Skip: 'Hopp over',
  'So nice! You have received a gift in crypto': 'Så kjekt! Du har fått krypto i gave',
  'Social media (Reddit, Twitter, etc.)': 'Sosiale media (Reddit, Twitter, etc.)',
  'Social security number': 'Fødselsnummer',
  "Solana is a decentralized and programmable blockchain and a cryptocurrency. Solana's blockchain is designed specifically to offer scalable, decentralized apps and smart contracts.":
    'Solana er en desentralisert og programmerbar blokkjede og en kryptovaluta. Solana sin blokkjede er designet spesielt for å tilby skalerbare, desentraliserte apper og smartkontrakter.',
  "Solana itself claims that they are the world's fastest blockchain, and with up to 65,000 transactions per second, you should not ignore the fact that they are absolutely right.":
    'Solana hevder selv at de er verdens raskeste blokkjede, og med opptil 65 000 transaksjoner i sekundet skal du ikke se bort ifra at de har helt rett.',
  'Solana network': 'Solana-nettverket',
  "Solana's cryptocurrency is called Solana, but is also known under the ticker SOL. Solana is one of the world's largest cryptocurrencies based on market value.":
    'Solana sin kryptovaluta heter Solana, men er også kjent under tickeren SOL. Solana er en av verdens største kryptovalutaer basert på markedsverdi.',
  Sold: 'Solgt',
  'Some money to spare? Make an extra deposit': 'Litt penger til overs? Gjør en ekstra overføring',
  'Something is wrong with your account. Please contact us and give error code: 3013 for further assistant: support@firi.com':
    'Noen har gått galt med din bruker. Vennligst kontakt oss og nevn feilkode 3013 for videre support: support@firi.com',
  'Something is wrong. Please contact support': 'Noe galt har skjedd. Vennligst kontakt support',
  'Something went wrong': 'Noe gikk galt',
  'Something went wrong activating staking': 'Noe gikk galt under aktivering av staking',
  'Something went wrong resending code': 'Noe gikk galt ved utsending av kode',
  'Something went wrong trying to activate staking. Try again or contact support if the issue persists':
    'Noe gikk galt ved aktivering av staking. Prøv igjen eller ta kontakt med support dersom problemet vedvarer',
  'Something went wrong when creating gift card. Please retry or contact support for help.':
    'Noe gikk galt ved opprettelse av gavekort. Vennligst prøv igjen eller kontakt support for hjelp.',
  'Something went wrong. Please try again or contact our support.':
    'Noe gikk galt. Vennligst prøv igjen eller kontakt våres support',
  'Something went wrong. Please try again or contact support.':
    'Noe gikk galt. Vennligst prøv igjen eller kontakt kundestøtte.',
  'Something went wrong. Unable to place your order. Try again?':
    'Noe gikk galt. Kan ikke plassere ordren din. Prøv igjen?',
  'Something went wrong. We were not able to cancel your withdraw':
    'Noe gikk galt. Vi klarte ikke kansellere uttaket ditt',
  'Sorry!': 'Beklager!',
  'Sort your crypto': 'Sorter dine krypto',
  'Specify the desired amount for your transfer.': 'Angi valgfritt beløp for din overføring.',
  Spread: 'Spread',
  'Spring cleaning 🧹': 'Vårrengjøring 🧹',
  Stablecoins: 'Stablecoins',
  Stake: 'Stake',
  'Stake crypto I own': 'Stake krypto jeg allerede har',
  'Stake crypto now': 'Stake krypto nå',
  'Stake more': 'Stake mer',
  'Stake more crypto': 'Stake mer krypto',
  Staked: 'Staket',
  'Staked now': 'Stakes nå',
  Staking: 'Staking',
  'Staking account': 'Staking-konto',
  'Start a savings agreement': 'Start en spareavtale',
  'Start here': 'Begynn her',
  'Start now': 'Start nå',
  'Start or change savings from your bank': 'Aktiver eller endre sparing i banken din',
  'Start over': 'Prøv på nytt',
  'Start saving': 'Start sparing',
  'Start savings from your bank': 'Start sparing fra banken din',
  'Start staking': 'Begynne å stake',
  'Start with crypto': 'Start med krypto',
  'Start with crypto today!': 'Begynn med krypto i dag!',
  'Statistics and analysis': 'Statistikk og analyse',
  'Statistics shows that long term investments give better yield than buying and selling cryptocurrency based on the exchange rate':
    'Statistikk viser at langsiktig sparing gir bedre avkastning enn å kjøpe og selge kryptovaluta basert på kurs',
  Status: 'Status',
  'Stellar network': 'Stellar-nettverket',
  'Store your configuration key in a safe place. You will need it if you lose your phone or data gets lost':
    'Ta vare på konfigueringsnøkkelen din og lagre den et trygt sted. Du vil ha behov for den dersom du mister telefonen din eller data blir slettet.',
  'Strongest development': 'Sterkest utvikling',
  Submit: 'Send inn',
  Summary: 'Oppsummering',
  'Supported filetypes': 'Støttede filtyper',
  Sweden: 'Sverige',
  Swedish: 'Svensk',
  'Sweet!': 'Herlig!',
  System: 'System',
  'TV campaign 2020': 'TV-aksjonen 2020',
  'TV campaign 2021': 'TV-aksjonen 2021',
  'Take a look around the app': 'Se deg rundt i appen',
  'Take me back': 'Ta meg tilbake',
  'Take me to': 'Ta meg til',
  'Take me to advanced trading': 'Ta meg til avansert handel',
  'Take photo': 'Ta bilde',
  'Take the survey': 'Delta nå',
  Tax: 'Skatt',
  'Tax calculation': 'Skatteberegning',
  'Tax calculation for': 'Skatteberegninger for',
  'Tax for': 'Skatt for',
  'Tax for {mostRecentTaxYear} is ready': ['Skatt for ', ['mostRecentTaxYear'], ' er klar'],
  'Tax for {year}': ['Skatt for ', ['year']],
  'Taxable gain': 'Skattepliktig gevinst',
  Taxes: 'Skatteberegning',
  'Teach others about crypto!': 'Lær andre om krypto!',
  'Temporary downtime': 'Mid­ler­ti­dig nedetid',
  Terms: 'Brukervilkår',
  'Terms of service': 'Brukervilkår',
  'Terrorist financing': 'Finansiering av terror',
  'Tether USDt (USDT) is a stablecoin tied to the value of the US Dollar (USD), and should therefore always be worth approximately 1 USD. Tether is one of the most widely used stablecoins in the market and was created to provide cryptocurrency users a way to maintain a stable value while still enjoying the benefits that blockchain technology offers, such as fast transfers and global accessibility.':
    'Tether (USDT) er en stablecoin som har sin verdi knyttet opp mot amerikanske dollar (USD), og skal derfor alltid være verdt omtrent 1 USD. Tether er en av de mest brukte stablecoins på markedet, og ble opprettet for å gi kryptovaluta-brukere en måte å beholde en stabil verdi mens de fortsatt benytter seg av de fordeler som blockchain-teknologi gir, som rask overføring og global tilgjengelighet.',
  'Thank you for completing the facial recognition! We are now reviewing the information, and you will soon receive an email from us when your account is ready for use.':
    'Takk for at du fullførte ansiktsgjenkjenningen! Vi gjennomgår nå informasjonen, og du vil snart motta en e-post fra oss når kontoen din er klar til bruk.',
  'Thank you for filling out the form! We are now checking your answers. You will soon receive an email from us so that you can start using our app.':
    'Takk for at du fylte ut skjemaet! Vi sjekker nå svarene dine. Du vil snart motta en e-post fra oss slik at du kan begynne å bruke appen vår.',
  'Thank you for letting us get to know you, and for contributing to a safer crypto journey for everyone!':
    'Tusen takk for at du lar oss bli kjent med deg og bidrar til en tryggere kryptoreise for alle!',
  'Thank you for your patience and sorry for the inconvenience.':
    'Takk for tålmodigheten og beklager ulempene dette kan medføre.',
  'Thank you very much!': 'Tusen hjertelig takk!',
  'Thank you!': 'Takk!',
  'Thank you! We are checking your information. Please wait a moment.':
    'Takk! Vi sjekker informasjonen din. Vennligst vent litt.',
  'Thank you! We will get back to you swiftly.': 'Tusen takk! Vi kommer tilbake til deg snarest.',
  'Thanks for the feedback!': 'Takk for feedbacken!',
  'That is OK': 'Det er OK',
  "That's fine!": 'Den er grei!',
  "That's it! Your Crypto Savings will continue as usual.":
    'Det var alt! Din Kryptosparing vil fortsette som vanlig.',
  'The account has insufficient funds': 'Kontoen har for lav saldo',
  'The amount you give away is deducted directly from your cryptocurrency from your main account with Firi and locked until the gift card is redeemed by the recipient.':
    'Beløpet du gir bort blir trukket direkte fra din kryptowallet fra hovedkontoen din hos Firi og låst inntil gavekortet løses inn av mottaker.',
  'The assets will be transferred to your main account.':
    'Midlene blir overført til din hovedkonto.',
  "The buy was automatically stopped. This happens, for example, when there are large price fluctuations. We don't want you to pay more than necessary for the crypto you want to buy.":
    'Kjøpet ble automatisk stoppet. Dette skjer for eksempel når det er store prissvingninger. Vi ønsker ikke at du skal betale mer enn nødvendig for kryptoen du ønsker å kjøpe.',
  'The buy was not completed, but the money ({0}) has been transferred to your Firi account.': [
    'Kjøpet ble ikke fullført, men pengene (',
    ['0'],
    ') har blitt overført til din Firi-konto.',
  ],
  'The calculations are continuously updated based on the transactions and changes made to your account. This means that this is not the final tax calculation.':
    'Beregningene blir kontinuerlig oppdatert basert på de transaksjoner og endringer som gjøres på din konto. Det betyr at dette ikke er den endelige skatteberegningen.',
  'The campaign last from {0} until {1}': ['Kampanjen varer fra ', ['0'], ' til ', ['1']],
  'The christmas calendar starts 1st December - keep an eye out!':
    'Julekalenderen starter 1. desember - følg med!',
  'The cryptocurrency XLM, also known as Stellar Lumens, is a digital currency that operates on the Stellar network. The Stellar network is a decentralized platform designed for fast and affordable transfer of value across the globe. XLM functions as a means of payment on the Stellar network and is used to facilitate transactions across different currencies and national borders.':
    'Kryptovalutaen XLM, også kalt Stellar Lumen, er en digital valuta som opererer på Stellar-nettverket. Stellar-nettverket er en desentralisert plattform designet for rask og rimelig overføring av verdier over hele verden. XLM fungerer som et betalingsmiddel på Stellar-nettverket og brukes til å lette transaksjoner på tvers av forskjellige valutaer og landegrenser.',
  'The cryptocurrency is usually sent immediately.':
    'Kryptovaluta blir vanligvis sendt umiddelbart.',
  'The development shows how your staking account investment develops over time.':
    'Utviklingen viser hvordan investeringen på staking-kontoen din utvikler seg over tid.',
  'The different percentages you choose for the different cryptocurrencies must add up to 100':
    'Fordelingene må samlet være 100%',
  'The distribution exceeds 100%': 'Fordelingen overstiger 100%',
  'The documentation you upload will of course be processed as confidential.':
    'Dokumentene du laster opp hos oss behandles selvsagt konfidensielt.',
  'The easiest explanation of staking is that you store your cryptocurrency in order to receive a reward in the form of more crypto.':
    'Den enkleste forklaringen på staking er at du oppbevarer dine kryptovaluta for å motta belønning i form av mer krypto.',
  'The easiest explanation of staking is that you store, and sometimes lock, your cryptocurrency in order to receive a reward in the form of more crypto.':
    'Den enkleste forklaringen på staking er at du oppbevarer, og noen ganger låser, dine kryptovaluta for å motta belønning i form av mer krypto.',
  'The email registered to your Vipps has been found in our systems and cannot be reused. Please login to Firi and integrate Vipps through settings.':
    'E-post som er registrert på din Vipps har blitt funnet i våre systemer og kan ikke bli gjenbrukt. Vennligst logg inn på Firi og integrer Vipps under innstillinger',
  'The fees are deducted from the amount of crypto you are selling.':
    'Gebyrene trekkes fra beløpet krypto du selger.',
  'The fees are deducted from the amount you buy crypto for.':
    'Gebyrene trekkes fra beløpet du kjøper krypto for.',
  'The funds will be transferred to your staking account.':
    'Midlene blir overført til staking-kontoen din.',
  'The information from this survey will only be used to obtain a better picture of our customers needs and wishes regarding services and information, and will not be used in connection with marketing.':
    'Informasjonen fra denne undersøkelsen vil kun brukes til å skaffe et bedre bilde på våre kunders behov og ønsker rundt tjenester og informasjon, og vil ikke benyttes i forbindelse med markedsføring.',
  'The information we collect about you is treated confidentially. Read more about what information we store and why in our':
    'Informasjonen vi innhenter om deg behandles konfidensielt. Les mer om hvilken informasjon vi lagrer og hvorfor i vår',
  'The largest Nordic crypto exchange': 'Nordens største kryptobørs',
  'The main purpose of XRP is to be a kind of broker between different currencies - both cryptocurrency and fiat. XRP is built on the blockchain technology XRP Ledger.':
    'Hovedformålet til XRP er å være en slags megler mellom ulike valutaer - både kryptovaluta og fiat. XRP er bygd på blokkjedeteknologien XRP Ledger.',
  "The majority of all cryptocurrencies and decentralized applications in the world are built on Ethereum's blockchain.":
    'Majoriteten av alle kryptovalutaer og desentraliserte applikasjoner i verden er bygd på Ethereum sin blokkjede.',
  'The minimum amount for withdrawal is {0}. In order to withdraw money, you must sell crypto.': [
    'Minimumsbeløpet for uttak er ',
    ['0'],
    '. For å ta ut penger må du selge krypto.',
  ],
  'The minimum amount to stake {currency} is {0} {currency}. You can buy {currency} immediately': [
    'Minstebeløp for å stake ',
    ['currency'],
    ' er ',
    ['0'],
    ' ',
    ['currency'],
    '. Du kan kjøpe ',
    ['currency'],
    ' umiddelbart',
  ],
  'The minimum amount you can save in a cryptocurrency is {SAVING_AGREEMENT_DETAIL_MIN_AMOUNT} {0}':
    [
      'Det minste beløpet du kan spare i en kryptovaluta er ',
      ['SAVING_AGREEMENT_DETAIL_MIN_AMOUNT'],
      ' ',
      ['0'],
    ],
  'The money is being transferred to us': 'Pengene overføres til oss',
  'The money is on its way from your bank to our bank. This usually takes 1-2 business days, but it can also be transferred in minutes.':
    'Pengene er på vei fra din bank til vår bank. Dette tar vanligvis 1-2 arbeidsdager, men det kan også overføres på minutter.',
  'The money may have been deducted from your account.':
    'Pengene kan ha blitt trukket fra din konto.',
  'The most common destinations': 'De vanligste destinasjonene',
  'The one-time password has already been used.':
    'Engangspassordet er allerede brukt eller ikke lenger gyldig',
  'The price differentiate more than approx. <0>{absolutePercent}%</0> compared to the international price index. The reason is high demand and few people want to sell {currency} right now. That is why we have a blockage to avoid you buying for an expensive price.':
    [
      'Prisen skiller seg ca. <0>',
      ['absolutePercent'],
      '%</0> i forhold til den globale prisen. Dette skyldes høy etterspørsel og få som ønsker å selge ',
      ['currency'],
      ' akkurat nå. Vi har derfor satt opp en sperre, slik at du unngår å kjøpe for dyrt.',
    ],
  'The price differentiate more than approx. <0>{absolutePercent}%</0> compared to the international price index. The reason is low demand and few people want to buy {currency} right now. That is why we have a blockage to avoid you selling too cheap.':
    [
      'Prisen skiller seg ca. <0>',
      ['absolutePercent'],
      '%</0> i forhold til den globale prisen. Dette skyldes lav etterspørsel og få som ønsker å kjøpe ',
      ['currency'],
      ' akkurat nå. Vi har derfor satt opp en sperre, slik at du unngår å selge for billig.',
    ],
  'The price is locked in during the trade.': 'Prisen låses mens handelen gjennomføres.',
  'The price is too high': 'Prisen er for høy',
  'The price is too low': 'Prisen er for lav',
  'The price is way above the index':
    'Prisen du handler for er langt over den globale prisindeksen',
  'The processing time is dependent on the amount you are unstaking and how many others that are wanting to deactivate staking.':
    'Behandlingstiden avhenger av hvor stort beløpet ditt er og hvor mange andre som ønsker å deaktivere staking.',
  'The products of their Ripple Labs are used for payment settlements, asset exchanges and transfer systems - including by several large banks.':
    'Produktene til Ripple Labs deres brukes til betalingsoppgjør, aktivautveksling og overføringssystemer - deriblant av flere store banker.',
  'The purpose of this reward is simply to share knowledge about cryptocurrency and Firi. You get a regular referral bonus in addition 🤯':
    'Formålet med denne belønningen er rett og slett å dele kunnskap om kryptovaluta og om Firi. Du får vanlig vervebonus i tillegg 🤯',
  'The purpose of this reward is to share knowledge about crypto and invite others to discover a new way of investing.':
    'Formålet med denne belønningen er å dele kunnskap om krypto og å invitere andre til å oppdage en ny måte å investere på.  ',
  'The recipient is a business': 'Mottakeren er et selskap',
  'The reward is what you have received from the blockchain as a thank you for staking. Note that the value changes with the crypto prices.':
    'Belønningen er det du har fått fra blokkjedene som takk for at du staker. Merk at verdien endrer seg i takt med krypto-prisene.',
  'The tax calculation for {0} is ready!': ['Skatteberegningen for ', ['0'], ' er klar!'],
  'The tax calculation for {mostRecentTaxYear} is ready!': [
    'Skatteberegningen for ',
    ['mostRecentTaxYear'],
    ' er klar!',
  ],
  "The tax calculation will be available when it's time to calculate the tax for 2022.":
    'Skatteberegningen blir tilgjengelig når du skal regne ut skatten for 2022.',
  'The total price is too low': 'Totalprisen er for lav',
  'The trading fee will be deducted from the amount.':
    'Handelsgebyr kommer i tillegg og trekkes fra dette beløpet.',
  'The transaction is usually sent immediately.': 'Transaksjonen blir vanligvis sendt umiddelbart.',
  'The transaction was not completed.': 'Transaksjonen gikk ikke gjennom.',
  'The value of meme coins comes from viral spread on the internet, hype culture, and humor. Initially, they do not aim to innovate, develop, or improve current financial systems':
    'Verdien til meme-coins kommer fra viral spredning på internett, hypekultur og humor. De forsøker i utganspunktet ikke å innovere, utvikle eller forbedre dagens finansielle systemer.',
  'The value of meme coins follows market cycles': 'Verdien av meme-coins følger markedssykluser',
  'The value of meme coins is driven by viral posts on the internet':
    'Verdien av meme-coins er drevet av virale poster på internett',
  'The winner will be contacted by our support!. See you tomorrow!':
    'Vinneren av dagens trekning kontaktes av vår support. Sees i morgen!',
  'The withdraw is processing': 'Uttaket behandles',
  'The withdrawal fee is': 'Gebyr for uttak er',
  'The withdrawal limit is renewed 24 hours from the withdrawal was made.':
    'Uttaksgrensen fornyes 24 timer fra uttaket ble gjennomført.',
  'The word "crypto" comes from the concept of cryptography, which is central to blockchain technology.':
    'Ordet "krypto" kommer fra konseptet kryptografi som er sentralt i blokkjedeteknologien.',
  Theme: 'Tema',
  'There are different ways of calculating profit and loss. We use the most common method, which excludes deposits and withdrawals from the calculation. In short, we divide the calculation period into intervals (minutes, days etc.), calculate the rate of return for each intervals, and chain these together to find the total rate of return for the entire calculation period.':
    'Det finnes flere måter å beregne gevinst og tap på. Vi har valgt å bruke den vanligste metoden, som ser bort ifra innskudd og uttak. Kort forklart så deler man beregningsperioden opp i delperioder (minutter, dager osv.), beregner avkastningen for hver delperiode, og kjeder disse sammen for å finne avkastningen for hele beregningsperioden.',
  'There are many people buying and selling cryptocurrencies right now, which is leading to a constant change in price. To be certain that you order is correct, we ask you to place the order again.':
    'Det er mange som kjøper og selger kryptovaluta akkurat nå, noe som fører til en konstant endring i pris. For å være sikker på at alt blir riktig i handelen din, så ber vi deg legge inn ordren på nytt.',
  'There are no orders in this market': 'Det er ingen ordre tilgjengelig i dette markedet',
  'There are two reasons to buy and hold the cryptocurrency MANA. One is as a means for transactions within the game. The other is if one wants to invest in MANA because they believe Decentraland will be a major success or because they want to speculate in the crypto-gaming niche.':
    'Det er to grunner til å kjøpe og holde kryptovalutaen MANA. Den ene er som et middel for transaksjoner inne i spillet. Den andre er hvis man ønsker å investere i MANA fordi man tror at Decentraland vil bli en stor suksess eller fordi man ønsker å spekulere i krypto-gaming nisjen.',
  'There are two reasons to buy and hold the cryptocurrency SAND. One is as a means for transactions within the game. The other is if one wants to invest in SAND because they believe The Sandbox will be a major success or because they want to speculate in the crypto-gaming niche.':
    'Det er to grunner til å kjøpe og holde kryptovalutaen SAND. Den ene er som et middel for transaksjoner inne i spillet. Den andre er hvis man ønsker å investere i SAND fordi man tror at The Sandbox vil bli en stor suksess eller fordi man ønsker å spekulere i krypto-gaming nisjen.',
  'There is a new update!': 'Det er en ny oppdatering!',
  'There is an age limit of 18 to create an account with Firi. We warmly welcome you back when you turn 18!':
    'Det er 18-årsgrense for å opprette konto hos Firi. Vi ønsker deg hjertelig velkommen tilbake når du fyller 18 år!',
  "There is one thing that many Firi customers have asked for in the app, and that's an easy and intuitive way to see their rate of return; profits and losses on their crypto investment.":
    'Er det én ting Firis kunder har etterspurt så er det en god visning av avksatning på sine kryptoinvesteringer, altså gevinst og tap over tid',
  'These are crypto based on memes and internet culture':
    'Dette er krypto som er basert på memes og internettkultur',
  'These are crypto that are considered more environmentally friendly than other cryptos':
    'Dette er krypto som anses å være mer miljøvennlige enn andre krypto',
  'These are crypto that are used in decentralized finance':
    'Dette er krypto som brukes i desentralisert finans',
  'These are crypto that use proof-of-work consensus mechanism and therefore can be mined':
    'Dette er krypto som bruker proof-of-work konsensusmekanisme og derfor kan mines',
  'These are crypto that you can stake and earn rewards on with Firi':
    'Dette er de kryptoene du kan stake og tjene belønning på hos Firi',
  'These are cryptos that follow the value of a regular currency, and therefore have a more stable price':
    'Dette er krypto som skal følge verdien på vanlig valuta, og derfor være mer stabile',
  'These are the crypto most people choose to start with on Firi':
    'Dette er de kryptoene som flest velger å starte med hos Firi',
  'These cookies collect information about how you use our websites and what that is interesting to you. In this way, we can provide you with relevant content and personalized marketing.':
    'Disse cookies samler informasjon om hvordan du bruker nettsidene våre og hva som er interessant for deg. På denne måten kan vi gi deg relevant innhold og personlig tilpasset markedsføring.',
  'These cookies collect statistics about how our websites are used. This gives us valuable insight that allows us to improve and your customer experience.':
    'Disse cookies samler statistikk om hvordan nettsidene våre blir brukt. Dette gir oss verdifull innsikt som gjør at vi kan forbedre oss og kundeopplevelsen din.',
  'This account has Vipps integration.': 'Denne kontoen har Vipps integrasjon.',
  'This account number is used by a bank not supported by Firi.':
    'Dette kontonummeret brukes av en bank som ikke støttes av Firi.',
  'This content is for information purposes only and should not be taken as financial advice.':
    'Dette innholdet er kun til informasjon, og bør ikke tolkes som finansielle råd.',
  'This deposit is a payment from mining, staking or other income':
    'Dette innskuddet er en utbetaling fra mining, staking eller annen inntekt',
  'This is a market order.': 'Dette er en markedsordre.',
  'This is a trade executed directly with Firi.':
    'Dette er en handel gjennomført direkte med Firi.',
  "This is because you have more in gain than loss. We've figured this out for you.":
    'Det er fordi du har mer i gevinst enn tap. Vi har regnet ut dette for deg.',
  'This is cryptocurrency that has not been purchased on Firi. We need this information to be able to give you a correct tax calculation.':
    'Dette er kryptovaluta som ikke er kjøpt hos Firi. Vi behøver denne informasjonen for å kunne gi deg korrekt skatteberegning.',
  'This is how the development of the portfolio is displayed': 'Slik vises porteføljens utvikling',
  'This is often compared to putting money in the bank to receive interest, but there are still big, fundamental differences.':
    'Dette sammenlignes ofte med å sette penger inn i banken for å få renter, men likevel er det store, fundamentale forskjeller.',
  'This is the information you have provided to Firi. Verify that these settings match with the recurring transfer you have set up in you bank.':
    'Dette er informasjonen du har angitt hos Firi. Sjekk at disse innstillingene samsvarer med det faste trekket fra banken din.',
  'This is your total crypto fortune at Firi as of 31.12.{year}': [
    'Din totale formue hos Firi per 31.12.',
    ['year'],
  ],
  'This is your total crypto income at Firi as of 31.12.{year}': [
    'Din totale inntekt hos Firi per 31.12.',
    ['year'],
  ],
  'This is your total crypto loss at Firi as of 31.12.{year}': [
    'Ditt totale tap hos Firi per 31.12.',
    ['year'],
  ],
  'This is your total crypto profit at Firi as of 31.12.{year}': [
    'Din toalte gevinst hos Firi per 31.12.',
    ['year'],
  ],
  'This is your total crypto<0/> at Firi per': 'Dette er ditt totale krypto<0/> i Firi per',
  "This is your {0} balance. Expected annual yield is {1}% and hence <0/> at today's {2} exchange rate.":
    [
      'Dette er din ',
      ['0'],
      '-beholdning. Forventet årlig avkastning er ',
      ['1'],
      '% og dermed <0/> ved dagens ',
      ['2'],
      '-kurs.',
    ],
  'This month': 'Denne måneden',
  'This security measure is necessary.': 'Dette er et nødvendig sikkerhetstiltak som vi må gjøre.',
  'This version of the Firi-app is no longer available. You can download the newest version of the app in {store} now':
    [
      'Denne versjonen av Firi-appen er ikke lenger tilgjengelig. Du kan laste ned den nyeste versjonen av appen i ',
      ['store'],
      ' nå',
    ],
  'This vipps account is already registered to Firi.':
    'Oppgitt Vipps bruker er allerede registrert hos Firi.',
  'This will be quick - only a few minutes. Thank you for helping out!':
    'Det går fort - bare noen minutter. Takk for at du hjelper til!',
  'Thuc and the rest of the Firi team': 'Thuc og resten av Firi-teamet',
  'Time to update your information, {0}!': ['På tide å oppdatere informasjonen din, ', ['0'], '!'],
  'Tips for the best Firi-experience': 'Tips til den beste Firi-opplevelsen',
  To: 'Til',
  'To (year)': 'Til (år)',
  'To access the meme coins category and the opportunity to invest in {currency}, you first need to know what this entails.':
    [
      'For å få tilgang til meme-coins kategorien og muligheten til å investere i ',
      ['currency'],
      ' må du først vite hva dette innebærer.',
    ],
  'To account': 'Til konto',
  'To activate 2FA, you need to have an app that generates 2FA codes. We recommend Google Authenticator, but all 2FA apps work with Firi.':
    'For å aktivere 2FA må du ha en app som genererer 2FA-koder. Vi anbefaler Google Authenticator, men alle 2FA-apper fungerer med Firi.',
  'To bank account': 'Til bankkonto',
  'To become a customer with us, all information must be correct and verifiable. Therefore, it is not possible to have a secret name or use pseudonyms. We require full legal names to ensure compliance with anti-money laundering regulations and protect against fraud.\\n\\nThis practice creates a safe and secure experience for all our customers. Thank you for your understanding, and we look forward to welcoming you.':
    'For å bli kunde hos oss må alle opplysninger være korrekte og verifiserbare. Derfor kan man ikke ha et hemmelig navn eller bruke pseudonymer. Vi krever fulle, juridiske navn for å sikre overholdelse av hvitvaskingsloven og beskytte mot svindel.\\n\\nDenne praksisen skaper en trygg og sikker opplevelse for alle våre kunder. Takk for forståelsen, og vi ser frem til å ønske deg velkommen.',
  'To comply with international regulations, we need to collect certain information when you send or receive cryptocurrency. This rule, called the "Travel Rule", ensures that both the sender\'s and recipient\'s information is secure and traceable. It is designed to protect against fraud and enhance transaction security between platforms.':
    'For å overholde internasjonale regler må vi samle inn visse opplysninger når du sender eller mottar kryptovaluta. Denne regelen, kalt "Travel Rule", sikrer at både avsenderens og mottakerens informasjon er trygg og sporbar. Den er utformet for å beskytte mot svindel og forbedre transaksjonssikkerheten mellom plattformer.',
  'To comply with legal requirements and protect your account, we need your CPR number again. Although you have already provided it via MitID, we kindly ask you to enter it here.':
    'For å overholde lovkrav og beskytte kontoen din trenger vi CPR-nummeret ditt igjen. Selv om du allerede har oppgitt det via MitID, ber vi deg vennligst om å taste det inn her.',
  'To comply with regulatory requirements, we need more details about the recipient before your withdrawal can be completed.':
    'For å oppfylle regulatoriske krav trenger vi mer informasjon om mottakeren før uttaket ditt kan fullføres.',
  "To comply with the new EU regulation (MiCA), all Danish customers will be transferred to Firi's Danish subsidiary, Firi ApS. To regain full access to your account, you must accept this change.":
    "To comply with the new EU regulation (MiCA), all Danish customers will be transferred to Firi's Danish subsidiary, Firi ApS. To regain full access to your account, you must accept this change.",
  'To disable your authenticator, please enter the two factor token from your app.':
    'For å deaktivere autentiseringen, skriv inn to-faktor-koden fra appen.',
  'To ensure that you understand the risk involved when investing in meme coins you will have to answer a few questions.':
    'For å forsikre oss om at du forstår risikoen ved å investere i meme-coins må du svare på noen få spørsmål.',
  'To ensure your security, we require that you activate and use two-factor authentication (2FA) when you register and log in with your username and password.':
    'For å ivareta din sikkerhet krever vi at du aktiverer og bruker tofaktorautentisering (2FA) når du registrerer deg og logger på med brukernavn og passord hos oss.',
  'To include destination tag when sending {0}': [
    'Å inkludere destination tag når du sender ',
    ['0'],
  ],
  'To main account': 'Til hovedkonto',
  'To make a deposit to your savings account you first need to have a savings agreement, so that we know how to invest your funds':
    'For å gjøre et innskudd til din sparekonto må du først opprette en spareavtale, slik at vi vet hvordan vi skal investere midlene dine',
  'To overview': 'Til oversikt',
  'To proceed creating an account, the following requirements must be fullfilled:':
    'For å fortsatte registreringen må følgende kriterier oppfylles:',
  'To receive push notifications from Firi, you need to have enabled notifications in your device settings.':
    'For å motta varslinger fra Firi må du skru på varsling i innstillinger på enheten din.',
  'To receive the reward of {0} {1} in {2}, you need to refer at least <0>{3} friends</0> to Firi and they must <1>make their first investment</1> with us before January 1st.':
    [
      'For å motta belønningen på ',
      ['0'],
      ' ',
      ['1'],
      ' i ',
      ['2'],
      ' må du verve minst <0>',
      ['3'],
      ' venner</0> til Firi, og de må <1>gjennomføre sin første investering</1> hos oss innen 1. januar.',
    ],
  'To receive the reward, you need to refer at least {0} friends to Firi and they must feel comfortable enough with crypto and Firi to make their first trade.':
    [
      'For å motta belønningen må du verve minst ',
      ['0'],
      ' venner til Firi og de må føle seg komfortable nok med krypto og Firi til å gjennomføre sin første handel.',
    ],
  'To savings account': 'Til sparekonto',
  'To sell crypto from your savings account you must first make an internal transfer to your main account.':
    'For å selge krypto fra sparekontoen din må du først gjøre en intern overføring til hovedkonto.',
  'To set the exchange rate, we use a global price index. We add a markup of 1-3 percent based on the market situation, volatility, and the amount of crypto traded on Firi.':
    'For å sette vekslingskursen benytter vi en global prisindeks. Vi legger til et prispåslag på 1-3 prosent, basert på markedssituasjonen, volatilitet og hvor mye krypto som handles hos Firi.',
  'To sign up': 'Til registrering',
  'To sign up with MitId': 'Til registrering med MitId',
  'To sign up with Vipps': 'Til registrering med Vipps',
  'To staking account': 'Til staking-konto',
  'To what extent do you agree with the following statement: "I felt I had knowledge about crypto before I started using Firi".':
    'I hvilken grad er du enig med følgende utsagn: "Jeg følte jeg hadde kunnskap om krypto før jeg startet å bruke Firi".',
  'To {storeVersion}': ['Til ', ['storeVersion']],
  Today: 'I dag',
  'Today, {0}': ['I dag, ', ['0']],
  'Toggle rate or amount': 'Velg kurs eller kjøpesum',
  'Too good to be true? No. There is a good reason why you receive this reward.':
    'For godt til å være sant? Nei. Det er nemlig en god grunn til at du får denne belønningen.',
  'Top 10': 'Topp 10',
  'Top 10 crypto on Firi': 'Topp 10 hos Firi',
  'Top 10 cryptos with the highest trading volume the last 24 hours':
    'Topp 10 krypto med høyest handelsvolum de siste 24 timene',
  'Top up Firi-account': 'Fyll opp Firi-kontoen',
  'Top up your account': 'Fyll opp kontoen',
  Total: 'Totalt',
  'Total amount': 'Totalbeløp',
  'Total balance': 'Total beholdning',
  'Total paid out': 'Totalt utbetalt',
  'Total pending': 'Til utbetaling',
  'Total price': 'Totalpris',
  'Total referrals': 'Totalt antall vervet',
  'Total reward for staked crypto': 'Total belønning for staket krypto',
  'Trade for more than {0} {currency}': ['Handle for mer enn ', ['0'], ' ', ['currency']],
  'Trade for more than {0} {currency} in all markets': [
    'Handle for mer enn ',
    ['0'],
    ' ',
    ['currency'],
    ' i alle marked',
  ],
  'Trade for over {0} {currency}': ['Handle for mer enn ', ['0'], ' ', ['currency']],
  'Trade for over {0} {currency} in three markets': [
    'Handle for mer enn ',
    ['0'],
    ' ',
    ['currency'],
    ' i tre markeder',
  ],
  'Trade history': 'Handelshistorikk',
  Trades: 'Handler',
  'Trades on your savings account': 'Handler på din sparekonto',
  'Trading fee is 0.7 percent.': 'Handelsgebyr er på 0,7 prosent.',
  'Trading fee is 0.7%.': 'Handelsgebyr er på 0,7%.',
  'Trading fee is {0} percent.': ['Handelsgebyr er på ', ['0'], ' prosent.'],
  'Trading fees are required to cover our costs.':
    'Gebyr på handel er nødvendig for å dekke kostnadene våre.',
  'Trady history from another platform': 'Handelshistorikk fra en annen plattform',
  'Transaction ID': 'Transaksjons id',
  'Transaction fee (optional)': 'Transaksjonsgebyr (valgfritt)',
  Transactions: 'Transaksjoner',
  'Transactions cannot be reversed': 'Transaksjoner kan ikke reverses',
  Transfer: 'Overfør',
  'Transfer completed': 'Overføring fullført',
  'Transfer crypto between main account and savings account':
    'Overfør krypto mellom hovedkonto og sparekonto',
  'Transfer crypto here': 'Overfør krypto hit',
  'Transfer via online banking': 'Overføring via nettbank',
  'Transferred and activated!': 'Overført og aktivert!',
  Transfers: 'Overføringer',
  'Transfers between Firi and external crypto wallets usually takes a couple of minutes. In some cases it can take a business day before the cryptocurrency arrives':
    'Overføringer mellom Firi og eksterne wallet (kryptolommebok) tar vanligvis et par minutter. I noen tileller kan det ta en dag før kryptovaluten er inne på konto',
  'Transfers to and from savings account': 'Overføringer til og fra sparekonto',
  Trends: 'Trender',
  'Try again': 'Prøv igjen',
  'Try again later': 'Prøv igjen senere',
  'Try again or contact support if the issue persists.':
    'Prøv igjen eller kontakt kundestøtte hvis problemet vedvarer.',
  'Try now': 'Prøv nå',
  'Turn on notifications': 'Skru på varslinger',
  'Turn on notifications if you want us to give you a ping about the latest news in the crypto world, campaigns, when we launch new cryptos in the app and other exciting news.':
    'Skru på varslinger hvis du ønsker at vi gir deg en lyd om siste nytt i kryptoverden, kampanjer, når vi lanserer nye krypto i appen og andre spennende nyheter.',
  'Two factor authentication': 'Tofaktorautentisering',
  'Two step verification': 'To-faktor verifisering',
  'Two-factor authentication (2FA) is an additional layer of security in addition to passwords when you log in to various services, including Firi.':
    'Tofaktorautentisering (2FA) er et ekstra lag med sikkerhet i tillegg til passord når du skal logge deg inn på ulike tjenester, inkludert Firi.',
  'Two-factor authentication disabled': 'To-faktor autentisering ble deaktivert',
  'Two-factor authentication enabled': 'To-faktor autentisering ble aktivert',
  'Two-factor authentication is already set for this user':
    '2FA er allerede opprettet for din bruker',
  'Two-factor authentication is an important security measure. Using this makes it much harder for people to access your account by guessing your password. Creating passwords that are sufficiently unique, long and strong can be a challenge, so an extra layer of security is absolutely necessary for most people to avoid cybercrime.':
    'Tofaktorautentisering er et viktig sikkerhetstiltak. Å bruke dette gjør det mye vanskeligere for folk å komme seg inn på kontoen din ved å gjette passordet ditt. Å lage passord som er tilstrekkelig unike, lange og sterke kan være en utfordring, så et ekstra lag med sikkerhet er for de fleste helt nødvendig for å unngå nettkriminalitet.',
  Type: 'Type',
  'Type here...': 'Skriv her...',
  'Type in the code from your authentication app': 'Skriv inn koden fra din autentiseringsapp',
  'Type in your personal code to open the Firi app. It contains six digits.':
    'Skriv inn din personlige kode. Den består av seks siffer.',
  Types: 'Typer',
  'UNI is the associated cryptocurrency of Uniswap. UNI serves as a "governance token" that enables users to participate in decisions regarding the future development of the Uniswap exchange.':
    'UNI er den tilhørende kryptovalutaen til Uniswap. UNI fungerer som et "styringstoken" som lar brukere være med på å bestemme over Uniswap-børsens fremtidige utvikling.',
  'US dollars': 'US dollars',
  'USD Coin (USDC) is a centralized cryptocurrency linked to the US dollar. That is, it aims to maintain a stable 1:1 value against the USD, and is thus a so-called "stablecoin".':
    'USD Coin (USDC) er en sentralisert kryptovaluta som er knyttet til den amerikanske dollaren. Det vil si at den har som mål å opprettholde en stabil 1:1 verdi mot USD, og er dermed en såkalt "stablecoin".',
  'USDC is an ERC-20 token and is thus built on the Ethereum blockchain. USD Coin was launched in September 2018 as a collaboration between Circle and Coinbase. Like other stablecoins, the USDC can be defined as a tool for tokenizing US dollars. By moving US dollars to the blockchain, you can easily send them anywhere in the world in minutes and in addition it contributes to stability in a volatile crypto market.':
    'USDC er en ERC-20 token og er dermed bygget på Ethereum-blokkjeden. USD Coin ble lansert i september 2018 som et samarbeid mellom Circle og Coinbase. Som andre stablecoins kan USDC defineres som et verktøy for å tokenisere amerikanske dollar. Ved å flytte amerikanske dollar til blokkjeden kan man enkelt sende dem hvor som helst i verden på få minutter og i tillegg bidrar det til stabilitet i et volatilt kryptomarked.',
  'Unable to turn on notifications': 'Kunne ikke skru på varslinger',
  'Unable to verify your password reset claim. Are you sure you arrived here by following an email link?':
    'Klarte ikke å verifisere kravet om tilbakestilling av passord. Er du sikker på at du kom hit via en e-postlenke?',
  'Understand crypto': 'Forstå krypto',
  "Uniswap is the world's largest and most well-known decentralized cryptocurrency exchange (also known as DEX). Uniswap operates on blockchains such as Ethereum, Polygon, BNB and more, and allows users from anywhere in the world to trade cryptocurrencies without any intermediary.":
    'Uniswap er verdens største og mest kjente desentralisert kryptobørs (også kalt DEX). Uniswap opererer på blokkjeder som Ethereum, Polygon, BNB med flere, og lar brukere hvor som helst i verden handle krypto uten noe mellomledd',
  Unknown: 'Ukjent',
  'Unknown error': 'Ukjent feil',
  'Unknown purchase price': 'Ukjent innkjøpspris',
  'Unlock Firi': 'Lås opp Firi',
  Unstake: 'Unstake',
  Unstaked: 'Unstaket',
  Unstaking: 'Unstaker',
  'Unstaking completed!': 'Unstaking fullført!',
  "Until New Year's you get an extra reward for referring at least {0} friends to Firi.": [
    'Frem til nyttårsaften får du ekstra belønning for å verve minst ',
    ['0'],
    ' venner til Firi.',
  ],
  Update: 'Oppdater',
  'Update account information': 'Oppdater kontoinformasjon',
  'Update the app in Google Play Store to download your tax documents':
    'Oppdater appen i Google Play Store for å kunne laste ned dine skattedokumenter',
  'Update the app in the App Store to download your tax documents':
    'Oppdater appen i App Store for å kunne laste ned dine skattedokumenter',
  'Update your transfer/payment with the account information below:':
    'Oppdater overføringen/betalingen din med kontoinformasjonen nedenfor:',
  'Updated terms': 'Oppdaterte vilkår',
  'Upload documentation': 'Last opp dokumentasjon',
  'Upload file': 'Last opp fil',
  'Upload profile picture': 'Last opp profilbilde',
  'Upload relevant documentation here': 'Last opp relevant dokumentasjon her',
  'Upload your tax report to Firi': 'Last opp skattemeldingen din hos Firi',
  'Uploaded document are stored under': 'Opplastet dokumentasjon blir lagret under',
  'Uploaded documentation': 'Opplastet dokumentasjon',
  'Upon registration, you will receive a welcome bonus. With us you can buy and sell bitcoin and other cryptocurrencies, safely and easily. We are registered with Finanstilsynet, have Vipps login, use BankID and Norwegian bank for deposits and withdrawals.':
    'Ved registrering vil du motta en velkomstbonus. Hos oss kan du kjøpe og selge bitcoin og andre kryptovaluta, trygt og enkelt. Vi er registrert hos Finanstilsynet, har Vipps innlogging, benytter BankID og norsk bank for innskudd og uttak.',
  'Upon registration, you will receive bitcoin as a welcome bonus. With us you can buy and sell bitcoin and other cryptocurrencies, safely and easily. We are registered with Finanstilsynet, have Vipps login, use BankID and Norwegian bank for deposits and withdrawals.':
    'Ved registrering vil du motta bitcoin som velkomstbonus. Hos oss kan du kjøpe og selge bitcoin og andre kryptovaluta for NOK, trygt og enkelt. Vi er registrert hos Finanstilsynet, har Vipps innlogging, benytter BankID og norsk bank for innskudd og uttak.',
  'Use a two-factor authentication app such as': 'Bruk en tofaktorautentiseringsapp som f.eks',
  'Use of cookies': 'Bruk av cookies',
  'Use of wrong network or address kan lead to permanently loss of funds':
    'Bruk av feil nettverk eller adresse kan føre til permanent tap.',
  'Use the search field to find the card for "Other financial products and virtual currency/cryptocurrency"':
    'Bruk søkefeltet for å finne kortet for "Andre finansprodukter og virtuell valuta/kryptovaluta"',
  Used: 'Brukt',
  'User cancelled the session.': 'Bruker avbrøt sesjonen.',
  'User provided is already registered in Firi.': 'Oppgitt bruker er allerede registrert i Firi.',
  'User transfer': 'Overføring til bruker',
  'Valuable insight': 'Verdifull innsikt',
  Value: 'Verdi',
  'Value (crypto)': 'Verdi (krypto)',
  'Value ({0})': ['Verdi (', ['0'], ')'],
  'Value in {0}': ['Verdi i ', ['0']],
  'Value of 1 {0} at the time of the purchase': ['Verdi på 1 ', ['0'], ' på kjøpstidspunktet'],
  'Value you are sending': 'Verdi du sender',
  Verification: 'Verifisering',
  'Verification failed. Are you sure you typed the correct code for Firi?':
    'Verifisering feilet. Er du sikker på at du har trykket riktig kode for Firi?',
  Verify: 'Bekreft',
  'Verify with BankID': 'Bekreft med BankID',
  'Verify your account using BankID': 'Verifiser din konto med BankID',
  'Verify your email': 'Verifiser e-posten din',
  Version: 'Versjon',
  'Version:': 'Versjon',
  'Very low level of knowledge': 'I svært liten grad',
  Vipps: 'Vipps',
  'Vipps fee': 'Vipps-gebyr',
  'Vipps integration': 'Vipps integrasjon',
  'Vipps signup not available': 'Vipps registrering ikke tilgjengelig',
  "Vipps, and it's done!": 'Vipps, så var det gjort!',
  Volume: 'Volum',
  'Volume (24h)': 'Volum (24h)',
  Voucher: 'Gavekort',
  'Wallet transfers': 'Wallet-overføring',
  'Want the newest version?': 'Det går veldig kjapt!',
  'Was your first crypto trade conducted on Firi?':
    'Ble din første kryptohandel gjennomført hos Firi?',
  We: 'Vi',
  'We also ask you to familiarize yourself well with our <0>terms of use</0> before you create an savings account with Firi.':
    'Vi ber deg også sette deg godt inn i <0>brukervilkårene</0> våre før du oppretter en sparekonto hos Firi.',
  'We also ask you to familiarize yourself well with our<0>terms of use</0> before you create an account with Firi.':
    'Vi ber deg også sette deg godt inn i<0>brukervilkårene</0> våre før du oppretter konto hos Firi.',
  'We always store your personal information and your documentation securely. You can read more in our <0>terms of service</0> and <1>privacy policy</1>.':
    'Dine personopplysninger og din dokumentasjon er alltid trygt oppbevart hos oss. Du kan lese mer i våre <0>brukervilkår</0> og i <1>personvernerklæringen</1>.',
  'We are always striving to improve and really appreciate you taking the time. Wishing you a lovely summer!':
    'Vi strever alltid etter å bli bedre og setter stor pris på at du tok deg tid. Vi i Firi ønsker deg en nydelig sommer!',
  'We are currently experiencing increased activity, which may result in longer processing times for withdrawals. Thank you for your understanding':
    'Vi opplever for øyeblikket økt aktivitet, noe som kan føre til lengre behandlingstid for uttak. Takk for din forståelse',
  'We are discontinuing gift cards': 'Vi legger ned gavekort',
  'We are experiencing an increase in scams and investment fraud. Are you in contact with foreign people who are supposed to help you with investments? These often offer high profits and trick or exploit Norwegian people into investing in fake platforms or cryptocurrencies. If this is the case, we recommend that you end all contact with them and contact us via chat.':
    'Vi opplever en økning i svindel og investeringsbedrageri. Har du kontakt med utenlandske personer som skal hjelpe deg med investeringer? Disse tilbyr ofte høy profitt og lurer/utnytter norske personer til å investere i falske plattformer eller kryptovaluta. Da anbefaler vi at du avslutter all kontakt med disse og kontakter oss på chat.',
  'We are here to help you weekdays between 09:00 and 19:00':
    'Vi hjelper deg alle hverdager mellom 09:00 og 19:00',
  'We are introducing Stellar (XLM)!': 'Vi introduserer Stellar (XLM)!',
  'We are introducing the meme coin {0} ({1})!': [
    'Vi introduserer memecoinen - ',
    ['0'],
    ' (',
    ['1'],
    ')!',
  ],
  'We are introducing {0} ({1})!': ['Vi introduserer ', ['0'], ' (', ['1'], ')!'],
  'We are missing data from Vipps. Try again or contact support.':
    'Vi mangler data fra Vipps. Vennligst prøv igjen eller kontakt support.',
  'We are now deactivating staking on the amount.': 'Nå deaktiverer vi stakingen på valgt beløp.',
  'We are required by law to inform you if there is a data breach or any other situations where your personal information is at risk. We will do this as quickly as possible.':
    'Vi er lovpålagt å informere deg dersom det skjer et datainnbrudd eller eventuelle andre situasjoner hvor dine personopplysninger kommer på avveie. Det vil vi gjøre så raskt som overhodet mulig.',
  'We are required by the authorities to gather updated information about you as a customer on a continual basis. As part of our follow-up we kindly ask you to answer some questions in detail and upload relevant information.':
    'Vi er pålagt av myndighetene å innhente informasjon om deg som kunde, og vi må gjennomføre løpende oppfølging av kundeforholdet. Som en del av vår oppfølging ber vi deg svare utfyllende på noen spørsmål og laste opp relevant dokumentasjon.',
  'We are sorry, something went wrong.': 'Vi beklager, noe gikk galt.',
  'We are waiting for a deposit': 'Venter på innskudd',
  'We are working on implementing a simple and user-friendly process for unstaking. Thank you for your understanding!':
    'Vi jobber med å få på plass en enkel og brukervennlig prosess for unstaking. Takk for forståelsen!',
  'We ask that you familiarize yourself with all of our <0>terms of use</0> before you start staking crypto with Firi.':
    'Vi ber deg sette deg godt inn i alle <0>brukervilkårene våre</0> før du starter å stake krypto hos Firi.',
  'We ask you to familiarize yourself well with our <0>terms of use</0> before you create an account with Firi':
    'Vi ber deg sette deg godt inn i <0>brukervilkårene våre</0> før du oppretter konto hos Firi',
  'We calculate profit and losses on your crypto trades on Firis platform, and all you need to do is enter the numbers in the tax return yourself.':
    'Vi beregner gevinst og tap på dine kryptohandler på Firis plattform, og det eneste du trenger å gjøre er å føre inn tallene i skattemeldingen selv.',
  "We can't find the page you are looking for.": 'Vi kan ikke finne siden du leter etter.',
  "We can't process your order": 'Vi kan ikke gjenomføre din ordre',
  'We charge a flat fee of {0} {currency} for each {1} withdrawal and transfer. The fee is added to the amount sent.':
    [
      'Vi tar en fast avgift på ',
      ['0'],
      ' ',
      ['currency'],
      ' for hvert uttak og overføring med ',
      ['1'],
      '. Gebyret blir lagt på sendt beløp.',
    ],
  'We collect statistics on how our app is used. This gives us valuable insight that allows us to improve ourselves and your customer experience.':
    'Vi samler statistikk om hvordan appen vår blir brukt. Dette gir oss verdifull innsikt som gjør at vi kan forbedre oss og kundeopplevelsen din.',
  'We could not find a tax report': 'Kunne ikke finne skattebergningene',
  'We could not find any accounts': 'Vi fant ingen kontoer',
  'We could not find the article': 'Vi kunne ikke finne artikkelen',
  'We could not find the news update': 'Vi fant ikke nyhetsoppdateringen',
  'We currently do not have tax calculations for your country':
    'Vi har for øyeblikket ikke skatteberegning for ditt land',
  'We give you {referralAmount} {0} to trade for when you answer "Yes, please!" to the welcome\nbonus. Good luck with your investments!':
    [
      'Vi gir deg ',
      ['referralAmount'],
      ' ',
      ['0'],
      ' til å handle for når du svarer “Ja, takk!” til din velkomstbonus. Lykke til med investeringen! ',
    ],
  'We have an 18-year age limit': 'Vi har 18-års grense',
  'We have done a thorough spring cleaning of the entire app, with several exciting updates and the launch of two new coins!':
    'Vi har tatt en skikkelig vårrengjøring av hele appen, med flere spennende nyheter og lansering av to nye coins!',
  'We have given you <0/> so you can experience how safe and easy it is to invest in crypto with Firi.':
    'Vi har gitt deg <0/> slik at du kan oppleve hvor trygt og enkelt det er å investere i krypto hos Firi.',
  'We have made it safe and simple for all of our users to make long term investments in cryptocurrency.':
    'Vi har gjort det trygt og enkelt for alle våre kunder å investere langsiktig i kryptovaluta.',
  'We have made some changes in the tax calculation! You must therefore update to get the latest version':
    'Vi har gjort noen endringer i skatteberegningen! Du må derfor oppdatere for å få siste versjon',
  'We have now sent you an email with information on how to proceed to create a new password. It may take up to 10 minutes before you receive the email. Check spam folder as well.':
    'Vi har nå sendt deg en e-post med informasjon om hvordan du går videre for å opprette nytt passord. Det kan ta opptil 10 minutter før du mottar e-posten. Sjekk gjerne søppelposten.',
  'We have obtained your information from Vipps. If you want to update your profile, contact our support.':
    'Vi har hentet informasjonen din fra Vipps. Vil du oppdatere profilen din kontakt vår support.',
  'We have security measures in place to prevent your personal information from being shared, altered, lost or misused. We only give employees access to your personal information if required. Employees with such access will treat the information confidentially.':
    'Vi har sikkerhetstiltak som hindrer at dine personopplysninger blir delt, endret, går tapt eller misbrukt på annen måte. Vi gir kun ansatte som har behov for det tilgang til dine personopplysninger. Ansatte med slik tilgang vil behandle opplysningene konfidensielt.',
  'We have sent you an email with a confirmation code':
    'Vi har sendt deg en e-post med en bekreftelseskode',
  'We let you know when there are big movements crypto world.':
    'Vi gir deg beskjed når det er store bevegelser i kryptomarkedet.',
  'We must ask all new customers some questions, as banks, real estate agents, and the rest of the financial industry are also required to do.':
    'Vi må stille alle nye kunder noen spørsmål, slik også banker, eiendomsmeglere og resten av finansnæringen er pålagt å gjøre.',
  'We need a few more details': 'Vi trenger noen flere detaljer',
  'We need information from you': 'Vi trenger informasjon fra deg',
  'We need to collect and use information for our app to work in a secure and accurate way.':
    'Vi må samle inn og bruke informasjon for at appen vår skal fungere på en sikker og korrekt måte.',
  'We need your email address so we can contact you. Make sure it is correct. We will send a confirmation a little later.':
    'Vi trenger din epostadresse slik at vi kan kontakte deg. Pass på at den er riktig. Vi sender en bekreftelse litt senere.',
  'We notify you when you have a reward waiting, have invited a friend or received a bonus payment.':
    'Vi gir deg beskjed når du har en belønning som venter, har vervet en venn eller fått utbetalt bonus.',
  'We offer a local, safe and easy staking service!':
    'Vi tilbyr en lokal, trygg og enkel staking-tjeneste!',
  'We offer several different ways to buy cryptocurrency. This is what Firi customers prefer:':
    'Vi kan tilby deg flere ulike måter å kjøpe kryptovaluta på. Dette foretrekker Firi-kundene:',
  'We only allow withdrawals to personal bank accounts in your own name. A fee of <0/> will be deducted from the amount to cover our costs.':
    'Vi tillater kun uttak til bankkontoer som står i ditt navn. En avgift på <0/> vil bli trukket fra beløpet for å dekke våre kostnader.',
  'We recommend that you learn the basics of cryptocurrency to form your own opinion. You can find more useful articles here in the app.':
    'Vi anbefaler at du lærer deg det grunnleggende om kryptovaluta for å forme din egen mening. Du finner flere nyttige artikler her i appen.',
  'We recommend you to try again later or add a custom order in "advanced trading"':
    'Vi anbefaler deg å prøve igjen senere eller legge inn en egendefinert ordre i "avansert handel"',
  'We respect your privacy, so of course you can choose not to allow certain people types of cookies. Remember that if you choose to turn these off, it may affect your experience here with us.':
    'Vi respekterer ditt personvern, så du kan selvsagt velge å ikke tillatte enkelte typer av cookies. Husk at hvis du velger å skru av disse, så kan det påvirke opplevelsen din her hos oss.',
  'We respect your privacy, so you can of course choose whether you want us to collect information that is not necessary for the app to work. Remember that if you choose not to consent to this, it can affect your experience here with us.':
    'Vi respekterer ditt personvern, så du kan selvsagt velge om du ønsker å la oss samle inn informasjon som ikke er nødvendig for at appen skal fungere. Husk at hvis du velger å ikke samtykke til dette, så kan det påvirke opplevelsen din her hos oss.',
  'We respect your privacy. Feel free to read our Privacy policy about what data we must collect and use for our app to work in a secure and correct way.':
    'Vi respekterer ditt personvern. Les gjerne vår Personvernerklæring om hvilken data vi må samle inn og bruke for at appen vår skal fungere på en sikker og korrekt måte.',
  'We send you a notification when you have received a deposit, made a withdrawal, bought or sold crypto.':
    'Vi sender deg et varsel når du har mottatt et innskudd, gjort uttak, kjøpt eller solgt krypto.',
  'We store your personal information for as long as is necessary for the purpose for which the personal information was collected.':
    'Vi lagrer dine personopplysninger hos oss så lenge det er nødvendig for det formål personopplysningene ble samlet inn for.',
  'We usually receive this information one working day after we have credited you. Therefore, you cannot withdraw these funds from us until we have received the needed information':
    'Denne informasjonen får vi en arbeidsdag etter vi har kreditert deg. Du kan derfor ikke gjøre et uttak på disse midlene fra oss før vi har mottatt den nødvendige informasjonen.',
  'We usually receive this information one working day after we have credited you. You can therefore not make a withdrawal of these funds from us until we have received the necessary information.':
    'Denne informasjonen får vi en arbeidsdag etter vi har kreditert deg. Du kan derfor ikke gjøre et uttak på disse midlene fra oss før vi har mottatt den nødvendige informasjonen.',
  'We want to be better at Firi! Can you help us by answering a few questions?':
    'Vi ønsker å utvikle Firi! Kan du hjelpe oss ved å svare på noen få spørsmål?',
  'We want to get to know you!': 'Vi ønsker å bli kjent med deg!',
  'We want to get to know you, {0}!': ['Vi ønsker å bli kjent med deg, ', ['0'], '!'],
  'We want to give you <0/> so that you can get to know Firi and invest in your first cryptocurrency.':
    'Vi ønsker å gi deg <0/> slik at du kan bli kjent med Firi og investere i din første kryptovaluta',
  'We want to give you <0/> so you can experience how safe and easy it is to invest in crypto with Firi.':
    'Vi ønsker å gi deg <0/> slik at du kan oppleve hvor trygt og enkelt det er å investere i krypto hos Firi.',
  'We welcome the category meme coins and {currency} to Firi': [
    'Vi ønsker kategorien meme-coins og ',
    ['currency'],
    ' velkommen til Firi.',
  ],
  'We were not able to find a user linked to you Vipps account. Register to start using Firi now!':
    'Vi kunne ikke finne en bruker knyttet til din Vipps-konto. Registrer deg for å begynne å bruke Firi nå!',
  'We were not able to process your payment. Try again or choose another payment method.':
    'Vi fikk ikke til å gjennomføre betalingen. Prøv igjen eller velg en annen betalingsmetode.',
  'We were not able to remove bank account': 'Vi klarte ikke å fjerne bankkontoen',
  'We were not able to upload you document': 'Vi klarte ikke last opp dokumentet ditt',
  'We were unable to complete the payment. Please try again or choose another payment method.':
    'Vi fikk ikke til å gjennomføre betalingen. Prøv igjen eller velg en annen betalingsmetode.',
  'We were unable to complete the payment. Please try again or contact support if the issue persists.':
    'Betalingen din gikk ikke gjennom. Prøv igjen. Kontakt support hvis problemet vedvarer.',
  'We were unable to send a password reset email':
    'Vi klarte ikke sende ut e-posten for tilbakestilling.',
  'We will not disclose your personal information to third parties unless there is a legal basis for such disclosure or it is necessary for us to fulfill our agreement with you.':
    'Vi gir ikke personopplysningene dine videre til andre, med mindre det foreligger et lovlig grunnlag for slik utlevering eller det er nødvendig for at vi skal kunne oppfylle vår avtale med deg.',
  'We will now trade cryptocurrency on your behalf and you will receive an email when the funds are in your account.':
    'Nå vil vi utføre en handel av kryptovaluta på dine vegne, og du vil få e-post når midlene er på kontoen din.',
  'We will send you a notification when a new calendar hatch is opened.':
    'Vi sender deg et varsel når en ny luke i kalenderen er åpen.',
  'We will send you a notification when we have promotions or want to tell you something special.':
    'Vi sender deg et varsel når vi har kampanjer eller vil fortelle deg noe spesielt.',
  'We will send you a notification when you should update the app because we have launched new products or cryptocurrencies.':
    'Vi sender deg et varsel når du bør oppdatere appen fordi vi har lansert nye produkter eller kryptovalutaer.',
  'We wish you a Merry Christmas and a Happy New Year':
    'Vi ønsker deg en gledelig jul og et godt nytt år',
  "We'll let you know when there's news.": 'Vi gir deg beskjed når det har kommet en nyhet.',
  "We'll let you know when you can check what Firi Santa has hidden behind the first hatch.":
    'Vi gir deg beskjed når du kan sjekke hva Firi-nissen har gjemt bak den første luken.',
  "We're opening the doors to two renowned crypto-gaming coins, namely Decentraland (MANA) and The Sandbox (SAND). You can find these under the new category Gaming/Web3.":
    'Vi åpner dørene for to anerkjente krypto-spill coins, nemlig Decentraland (MANA) og The Sandbox (SAND). Disse finner du under den nye kategorien Gaming/Web3',
  "We've created an overview of how deposits to your savings account are converted to crypto and how we find the best price.":
    'Vi har laget en oversikt over hvordan innskudd på sparekontoen din konverteres til krypto og hvordan vi finner den beste prisen.',
  'Weakest development': 'Svakest utvikling',
  'Welcome back {0}!': ['Velkommen tilbake ', ['0'], '!'],
  'Welcome bonus': 'Velkomstbonus',
  'Welcome to Firi': 'Velkommen til Firi',
  'Welcome to Firi Affiliate!': 'Velkommen til Firi Fordel!',
  'Welcome to Firi!': 'Velkommen til Firi!',
  'Welcome to Firi. Get to know crypto and invest safely and easily.':
    'Velkommen til Firi. Bli kjent med krypto og invester trygt og enkelt.',
  'What amount do you want to save': 'Hvilket beløp vil du spare',
  'What are meme coins?': 'Hva er meme-coins?',
  'What can we do better?': 'Hva kan vi gjøre bedre?',
  'What do this overview show me?': 'Hva viser denne oversikten meg?',
  'What do you think about crypto news?': 'Hva synes du om kryptonyheter?',
  'What is Firi?': 'Hva er Firi?',
  'What is crypto, really?': 'Hva er egentlig krypto?',
  'What is relevant documentation?': 'Hva er relevant dokumentasjon?',
  'What is staking and who does it work?': 'Hva er staking og hvordan fungerer det?',
  'What is the primary driver behind the price of meme coins?':
    'Hva er den primære driveren bak prisen på meme-coins?',
  'What is the reason you also use Firi?': 'Hva er grunnen til at du også bruker Firi?',
  'What is your motivation for investing in crypto?':
    'Hva er motivasjonen din for å investere i krypto?',
  'What should your saving account be called?': 'Hva skal sparekontoen din hete?',
  'What stops you from investing more in crypto?': 'Hva stopper deg fra å investere mer i krypto?',
  'What was the purchase price on this cryptocurrency?':
    'Hva var innkjøpsprisen på denne kryptovalutaen?',
  'What would you like to read more about?': 'Hva ønsker du å lese mer om?',
  "What's hiding behind today's hatch? 👀": 'Hva skjuler seg bak dagens luke? 👀',
  When: 'Tidspunkt',
  'When all your information is up to date you will get the best user experience Firi has to offer.':
    'Når all din informasjonen er oppdatert vil du få den beste brukeropplevelsen Firi har å tilby.',
  'When can I expect my cryptocurrency to arrive': 'Når kan man forvente å motta kryptovalutaene',
  'When does my cryptocurrency get sent': 'Når blir kryptovaluten sendt',
  'When does the cryptocurrency arrive': 'Når kommer kryptovalutene',
  'When will I receive the reward?': 'Når mottar jeg belønningen?',
  'When will you receive the reward?': 'Når mottar du belønningen?',
  'When you buy crypto with money you have in your account with Firi, you pay a fee of 0.7%.':
    'Når du kjøper krypto med penger du har på konto hos Firi, betaler du et gebyr på 0,7%.',
  'When you buy cryptocurrency, you will fill one or several orders placed by other users. Orders have different volumes and we will always ensure that you buy from the cheapest order.':
    'Når du kjøper kryptovaluta, vil du fylle et eller flere ordre fra andre brukere. Ordrene har ulike volum og vi vil alltid sørge for at du kjøper fra det billigste orderet.',
  'When you sell cryptocurrency, you will fill one or several orders placed by other users. Orders have different volumes and we will always ensure that you sell from the cheapest order.':
    'Når du selger kryptovaluta, vil du fylle en eller flere ordre plassert av andre brukere. Ordre har ulike volum og vi vil alltid sørge for at du selger fra det billigste orderet.',
  'When you use our website, create a user profile or are in contact with Firi, we will process personal information about you.':
    'Når du bruker vår nettside, oppretter en brukerprofil eller er i kontakt med Firi vil vi behandle personopplysninger om deg.',
  'Whenever I want': 'Når jeg vil',
  'Where are you sending it?': 'Hvor sender du det?',
  'Where can you find information and updates about crypto today?':
    'Hvor finner du informasjon og oppdateringer om krypto i dag?',
  'Where do I find my tax return?': 'Hvor finner jeg skattemeldingen min?',
  'Where do i find my tax report?': 'Hvor finner jeg skattemeldingen?',
  'Where do you live?': 'Hvor bor du?',
  'Which account do you want to stake from?': 'Hvilken konto vil du stake fra?',
  'Which account would you like to transfer the money to?':
    'Hvilken konto vil du overføre pengene til?',
  'Which bank account do you want the money paid out to? We only allow withdrawals to Norwegian bank accounts. Remember that the account must be in your name.':
    'Hvilken bankkonto vil du ha pengene utbetalt til? Vi tillater kun uttak til norske bankkontoer. Husk at kontoen må stå i ditt navn.',
  'Which country do you live in?': 'Hvilket land bor du i?',
  'Which crypto can I get reward for?': 'Hvilken krypto kan jeg få belønning for?',
  'Which currencies do you want to save in': 'Hvilke kryptovalutaer vil du spare i',
  'Which currencies do you want to save in? Choose one or more.':
    'Hvilke kryptovaluta vil du spare i? Velg en eller flere.',
  'Which currency do you want to transfer?': 'Hvilken valuta vil du overføre?',
  'Who holds most of the meme coins?': 'Hvem holder mesteparten av meme-coins?',
  'Who would you like to give a gift card to? Enter the name or nickname of the lucky recipient.':
    'Hvem vil du gi gavekort til? Fyll inn navn eller kallenavn på den heldige mottakeren.',
  Whoops: 'Oisann',
  'Why am I rewarded? 🤔': 'Hvorfor blir jeg belønnet? 🤔',
  "Why can't I deactivate staking now?": 'Hvorfor kan jeg ikke deaktivere staking allerede nå?',
  'Why do i need to download a 2FA-app?': 'Hvorfor må jeg laste ned en 2FA-app?',
  'Why do you trade on other platforms/exchanges?':
    'Hvorfor handler du på andre plattformer/børser?',
  'Why does this happen?': 'Hvorfor skjer dette?',
  'Why is this required?': 'Hvorfor er dette nødvendig?',
  'Why should I invest in cryptocurrency?': 'Hvorfor skal jeg investere i kryptovaluta?',
  'Will my crypto be locked?': 'Blir kryptoen min låst?',
  "With Firi's tax calculation, you can quickly and easily calculate your crypto tax. Absolutely free!":
    'Med Firi sin skatteberegning kan du raskt og enkelt regne ut din kryptoskatt. Helt gratis!',
  "With Vipps we will automatically fill your profile information, and it does not cost anything. Sign with BankID and you're set!":
    'Med Vipps blir profilen din automatisk utfylt, og det koster ingenting. Signer med BankID og du er i gang.',
  'With email': 'Med e-post',
  Withdraw: 'Uttak',
  'Withdraw all': 'Ta ut alt',
  'Withdraw completed!': 'Uttak fullført!',
  'Withdraw consent': 'Trekk tilbake samtykke',
  'Withdraw fee': 'Uttaksgebyr',
  'Withdraw funds': 'Ta ut penger',
  'Withdraw limit': 'Uttaksgrense',
  'Withdraw limits': 'Uttaksgrenser',
  'Withdraw money': 'Ta ut penger',
  'Withdraw of {currency} is unavailable': ['Uttak av ', ['currency'], ' utilgjengelig'],
  'Withdraw on its way!': 'Uttak på vei!',
  'Withdraw request completed': 'Uttaksforespørsel fullført',
  'Withdraw to bank': 'Uttak til bank',
  'Withdraw {0}': ['Ta ut ', ['0']],
  Withdrawals: 'Uttak',
  "Withdrawals of {0} usually proceed from our account within 1-3 working days. Then it's up to your bank.":
    [
      'Uttak av ',
      ['0'],
      ' går vanligvis ut fra vår konto i løpet av 1-3 virkedager. Deretter er det opp til banken din.',
    ],
  'Would you look at that!': 'Neimen, har du sett!',
  Write: 'Skrive',
  'Write here': 'Skriv her',
  'Write or paste in the confirmation code that was sent to you at {0}.': [
    'Skriv eller lim inn bekreftelseskoden som ble sendt til deg på ',
    ['0'],
    '.',
  ],
  'Write or paste in the confirmation code that was sent to you.':
    'Skriv eller lim inn bekreftelseskoden som ble sendt til deg.',
  'Wrong answer. Try again': 'Feil alternativ. Prøv på nytt',
  'Wrong one-time password has been entered too many times. Try again in 30 seconds or more.':
    'Wrong one-time password has been entered too many times. Try again in 30 seconds or more.',
  'Wrong username or password': 'Feil brukernavn eller passord',
  "XRP is the original cryptocurrency for products developed by Ripple Labs. XRP is one of the world's largest cryptocurrencies based on market capitalization.":
    'XRP er den opprinnelige kryptovalutaen for produkter utviklet av Ripple Labs. XRP er en av verdens største kryptovalutaer basert på børsverdi.',
  Year: 'År',
  Yes: 'Ja',
  'Yes, I want to be notified!': 'Ja, jeg vil ha beskjed!',
  'Yes, and trade about the same on Firi as on other platforms':
    'Ja, og handler ca. likt hos Firi som hos andre plattformer',
  'Yes, and trade more on others': 'Ja, og handler mer hos andre',
  'Yes, but trade less on others': 'Ja, men handler mindre hos andre',
  'Yes, turn on notification': 'Ja, skru på varslinger',
  'Yes, with meme coins you can lose 80-90% in a very short time':
    'Ja, med meme-coins kan man på svært kort tid ta 80-90% i tap',
  'Yes, you can be sure that your digital assets are protected by the highest level of security on the market when using Firi.':
    'Ja, du kan være trygg på at dine digitale eiendeler blir beskyttet av det høyeste sikkerhetsnivået på markedet når du bruker Firi.',
  Yesterday: 'I går',
  'You absolutely do not need to be a crypto expert to stake. We at Firi take care of all the complicated things, so you can sit back and enjoy the fruits of your investment.':
    'Du trenger absolutt ikke være krypto-ekspert for å stake. Vi i Firi tar oss av alt det kompliserte, slik at du kan lene deg tilbake og nyte fruktene av din investering.',
  'You already have a savings account': 'Du har allerede sparekonto',
  'You already have a staking account': 'Du har allerede staking-konto',
  "You answered today's question correctly and are entered into the draw for a Firi fleece and beanie 🎉. In addition, you will receive a lottery ticket for the grand prize on Christmas Eve":
    'Du svarte riktig på dagens spørsmål og er med i trekningen om en Firi-fleece og en lue 🎉. I tillegg har du nå fått et lodd til hovedtrekningen på julaften.',
  "You answered today's question correctly and are entered into the draw for {0} {1} in {2} 🎉. In addition, you will receive a lottery ticket for the grand prize on Christmas Eve":
    [
      'Du svarte riktig på dagens spørsmål og er med i trekningen av ',
      ['0'],
      ' ',
      ['1'],
      ' i ',
      ['2'],
      '! 🎉 I tillegg har du nå fått et lodd til hovedtrekningen på julaften.',
    ],
  'You are a politically exposed person (PEP) if you have been entrusted with a prominent public function. We kindly ask you to respond "Yes" if you have a close family or work relationship to a PEP.':
    'Du er en politisk eksponert person hvis du har, eller har hatt, et høytstående offentlig verv eller stilling i Norge eller utlandet. Vi ber deg som er i familie med eller nærstående medarbeider til en politisk eksponert person også svare "Ja" på dette spørsmålet.',
  'You are invited by {0}, and we welcome you to Firi!': [
    'Du har blitt invitert av ',
    ['0'],
    ', og vi ønsker deg velkommen til Firi!',
  ],
  'You are saving': 'Du sparer',
  'You are sending': 'Du sender',
  'You buy {currency} and confirm that you want to stake this.': [
    'Du kjøper ',
    ['currency'],
    ' og bekrefter at du vil stake dette.',
  ],
  'You can <0>only</0> transfer from an account registered in your company name. If we receive a payment from a company name other than your own, there will be major delays and we will often have to return the payment. You can not transfer from joint accounts, credit cards or Nordnet.':
    'Du kan <0>bare</0> overføre fra en konto som er registrert i firmanavnet ditt. Dersom vi mottar betaling fra et annet firmanavn enn ditt eget, vil det oppstå store forsinkelser og vi må ofte returnere betalingen. Du kan ikke overføre fra felleskontoer, kredittkort eller Nordnet.',
  'You can also continue on web with Vipps or email':
    'Du kan også fortsette på web med Vipps eller e-post',
  'You can become a customer and buy crypto in the Firi app. The easiest way to download the Firi app is by scanning this QR code with the camera on your mobile:':
    'Du kan bli kunde og kjøpe krypto i Firi-appen. Den enkleste måten å laste ned Firi-appen på er ved å skanne denne QR-koden med kameraet på mobilen:',
  'You can buy {currency} instantly with Vipps or card.': [
    'Du kan kjøpe ',
    ['0'],
    ' umiddelbart med Vipps eller kort.',
  ],
  'You can buy {currency} instantly with card.': [
    'Du kan kjøpe ',
    ['0'],
    ' umiddelbart med kort. ',
  ],
  'You can change this distribution in your savings agreement at any time.':
    'Du kan når som helst endre denne fordelingen i spareavtalen din.',
  'You can choose if you want to buy new {currency} or stake from you holdings.': [
    'Du velger selv om du vil kjøpe ny ',
    ['currency'],
    ' eller stake fra din beholdning.',
  ],
  'You can choose to let us understand how you use our app and what interests you. This will give you relevant content via email and notifications in the app.':
    'Du kan velge å la oss forstå hvordan du bruker appen vår og hva som interesser deg. Dette vil gi deg relevant innhold på e-post eller push-varsler i app.',
  'You can deduct fees from trading crypto. Trading fee deductions are already included in Gain or Loss.':
    'Du kan fradragsføre gebyrer fra handel av krypto. Fradrag for handelsgebyr er allerede inkludert i Gevinst eller Tap.',
  'You can earn more on your {0}': ['Du kan tjene mer på din ', ['0']],
  'You can easily change the name later': 'Du kan enkelt endre navn senere',
  'You can find this code in your 2FA-app. The code should always be 6 digits. Copy the code from your app and paste it here.':
    'Du finner denne koden i 2FA-appen din. Koden skal alltid være på seks siffer. Kopier koden fra appen og lim den inn her.',
  'You can integrate Vipps to your Firi account to allow you to login with Vipps. After integrating with Vipps you can use both authentication methods. This feature is optional for existing Firi accounts.':
    'Du kan integrere Vipps til Firi-kontoen din slik at du kan logge inn med Vipps. Etter å ha integrert med Vipps kan du bruke begge autentiseringsmetodene. Denne funksjonen er valgfri for eksisterende Firi-kontoer.',
  'You can now create a savings agreement with automatic purchase of cryptocurrency.':
    'Nå kan du opprette en spareavtale med automatisk kjøp av kryptovaluta.',
  'You can only have one savings account in Firi.': 'Du kan kun ha én sparekonto hos Firi.',
  'You can only have one staking account in Firi.': 'Du kan kun ha én staking-konto hos Firi.',
  'You can only make internal transfers to and from you savings account in Firi. If you want to transfer cryptocurrencies to or from another crypto wallet outside of Firi, you must first transfer to you main account and then to you savings account, or vice versa. It is free to make internal transfers.':
    'Du kan kun gjøre interne kryptooverføringer til og fra sparekontoen din hos Firi. Hvis du ønsker å overføre kryptovaluta til eller fra en annen wallet (kryptolommebok) utenfor Firi, så må du først overføre til hovedkonto og deretter til sparekonto eller omvendt. Det er gratis å gjøre interne overføringer.',
  'You can only transfer from an account registered in your company name. If we receive a payment from a company name other than your own, there will be major delays and we will often have to return the payment. You can not transfer from joint accounts, credit cards or Nordnet.':
    'Du kan kun overføre fra en konto registrert i firmanavnet ditt. Dersom vi mottar betaling fra et annet firmanavn enn ditt eget, vil det oppstå store forsinkelser og vi må ofte returnere betalingen. Du kan ikke overføre fra felleskontoer, kredittkort eller Nordnet.',
  'You can only transfer from an account registered in your name and address. If we receive a payment from a name or address other than your own, there will be major delays and we will often have to return the payment. You can not transfer from joint accounts, credit cards or Nordnet.':
    'Du kan kun overføre fra en konto registrert i ditt navn og adresse. Hvis vi mottar en betaling fra et annet navn eller adresse enn din egen, vil det oppstå store forsinkelser og ofte må vi returnere betalingen. Du kan ikke overføre fra felleskontoer, kredittkort eller Nordnet.',
  'You can open {accountTrans} in the Firi app, but unfortunately not on the web yet': [
    'Du kan åpne ',
    ['accountTrans'],
    ' i Firi-appen, men dessverre ikke på web helt enda',
  ],
  'You can open {accountTrans} in the Firi app, but unfortunately not on the web yet. The easiest way to download the Firi app is by scanning this QR code with the camera on your mobile:':
    [
      'Du kan åpne ',
      ['accountTrans'],
      ' i Firi-appen, men dessverre ikke på web helt enda. Den enkleste måten å laste ned Firi-appen er ved å skanne denne QR-koden med kameraet på mobilen din:',
    ],
  'You can start earning rewards today! Both the funds that are staked and the reward you receive are safely stored on your staking account.':
    'Du kan starte å tjene belønning allerede i dag! Både midlene som stakes og belønningen du får oppbevares trygt på staking-kontoen din.',
  'You can use the Polygon blockchain on many of the leading decentralized exchanges, as well as marketplaces for NFTs. Polygon is the most popular scaling solution for Ethereum measured by the number of daily users.':
    'Man kan benytte Polygon-blokkjeden på mange av de ledende desentraliserte børsene, samt markedsplasser for NFTer. Polygon er den mest populære skaleringsløsningen til Ethereum målt i antall daglige brukere.',
  'You cancelled a order': 'Du kansellerte en ordre',
  'You changed your password': 'Du endret passordet ditt',
  'You created a voucher': 'Du lagde et gavekort',
  'You created this user account': 'Du opprettet denne kontoen',
  'You deposited from a bank account': 'Du har satt inn fra en bankkonto',
  "You didn't answer today's question correctly, try again tomorrow!":
    'Du svarte ikke riktig på dagens spørsmål, prøv igjen i morgen!',
  'You do not have a savings agreement yet, but it is easy to get started!':
    'Du har ikke en spareavtale enda, men det er enkelt å komme i gang!',
  'You do not have any crypto deposits from {year}': [
    'Du har ingen krypto innskudd fra ',
    ['year'],
  ],
  'You do not have any payouts yet': 'Du har ingen utbetalinger enda',
  'You do not have any transactions yet': 'Du har ingen transaksjoner ennå',
  'You do not have crypto here yet, but it is easy to get started.':
    'Du har ikke krypto her enda, men det er enkelt å komme i gang',
  "You don't have any <0/> for {year}": ['Du har ingen <0/> for ', ['year']],
  "You don't have any tickets yet, but there's still time to collect! Each ticket gives you an extra chance to win the grand prize on Christmas Eve. The more tickets, the greater the chance.":
    'Du har ingen lodd enda, men det er fortsatt tid til å samle! Hvert lodd gir deg en ekstra sjanse til å vinne hovedpremien på julaften. Jo flere lodd, jo større er sjansen.',
  'You get a regular referral bonus in addition to this reward for everyone you refer 🔥':
    'Du får vanlig vervebonus i tillegg til denne belønningen for alle du verver 🔥',
  'You give': 'Du gir',
  'You give your consent for us to store your BankID-information.':
    'Du gir ditt samtykke til at vi lagrer din BankID-informasjon',
  'You give your consent for us to store your profile information.':
    'Du gir ditt samtykke til at vi lagrer profilinformasjonen din.',
  'You have a number of legal rights in regards to your ownership of personal information we have stored about you and our use of this information.':
    'Du har flere juridiske rettigheter med tanke på ditt eierskap til personopplysninger vi har lagret hos oss og vår bruk av disse.',
  'You have already opened todays hatch!': 'Du har allerede åpnet dagens luke!',
  'You have already registered a user with Vipps. You can log in, or go back for more options.':
    'Du har allerede registrert en bruker med Vipps. Du kan logge inn, eller gå tilbake for flere valg.',
  'You have answered {0}': ['Du har svart ', ['0']],
  'You have assets that can be staked. Do you want rewards?':
    'Du har midler som kan stakes. Ønsker du belønning?',
  'You have changed the purchase price of one or more transactions. Previous tax calculations are therefore no longer correct. Click here to recalculate the tax. This may take a few minutes.':
    'Du har endret innkjøpspris på én eller flere transaksjoner. Tidligere skatteberegning er derfor ikke lenger korrekt. Klikk her for å beregne skatten på nytt. Dette kan ta et par minutter.',
  'You have decided to save {amount} {fiatMarketName} {0}. Enter this in the fixed transfer/payment.':
    [
      'Du har valgt å spare ',
      ['amount'],
      ' ',
      ['fiatMarketName'],
      ' ',
      ['0'],
      '. Angi dette i den faste overføringen/betalingen.',
    ],
  'You have enabled two-factor authentication': 'Du har aktivert tofaktorautentisering',
  'You have more ongoing transfers': 'Du har flere pågående overføringer',
  'You have no open orders': 'Du har ingen åpne ordre',
  'You have no pending payouts': 'Du har ingen ventende utbetalinger',
  'You have no previous payments': 'Du har ingen tidligere betalinger',
  'You have no referrals': 'Du har ingen vervinger',
  'You have no referrals being processed': 'Du har ingen henvisninger under behandling',
  'You have not added any accounts for withdrawals to bank':
    'Du har ikke lagt til noen bankkontoer for uttak til bank',
  'You have not referred anyone to Firi yet': 'Du har ikke invitert noen til Firi enda',
  'You have now activated 2FA.': 'Du har nå aktivert 2FA.',
  'You have now created a savings agreement, and the last thing left is to log in to your online bank to activate it.':
    'Nå har du opprettet en spareavtale, og det siste som gjenstår er å logge inn i nettbanken din for å aktivere denne.',
  'You have reached the withdrawal limit': 'Du har nådd uttaksgrensen',
  'You have received': 'Du har mottatt',
  'You have received <0/>!': 'Du har fått <0/>!',
  'You have received a gift card. Solve it in the Firi app!':
    'Du har fått et gavekort. Løs det inn i Firi-appen!',
  'You have received rewards from staking! This is what you have earned the last week.':
    'Du har mottatt belønning fra staking! Dette er det du har tjent den siste uken.',
  'You have registered with Firi and verified your identity using BankID':
    'Du har registrert deg hos Firi og bekreftet din identitet med BankID',
  'You have {0} crypto deposits that are missing purchase price.': [
    'Du har ',
    ['0'],
    ' kryptoinnskudd som mangler innkjøpspris (anskaffelsesverdi).',
  ],
  'You have {0} crypto deposits that have not been purchased from Firi. Click here to change the purchase prices.':
    [
      'Du har ',
      ['0'],
      ' kryptoinnskudd som ikke er kjøpt hos Firi. Trykk her for å endre innkjøpsprisene.',
    ],
  'You have {0} recent crypto deposits that are missing purchase price (acquisition value). We recommend that you add the purchase price so that your tax calculation to be correct.':
    [
      'Du har ',
      ['0'],
      ' nye kryptoinnskudd som mangler innkjøpspris (anskaffelsesverdi). Vi anbefaler at du legger til innkjøpsprisen for at skatteberegningen skal bli korrekt.',
    ],
  'You have {count} crypto deposits that are missing the purchase price (acquisition value). This is cryptocurrency that has not been purchased from Firi. We need this information to be able to give you the correct tax calculation.':
    'Du har 3 kryptoinnskudd som mangler innkjøpspris (anskaffelsesverdi). Dette er kryptovaluta som ikke er kjøpt hos Firi. Vi behøver denne informasjonen for å kunne gi deg korrekt skatteberegning.',
  'You have {count} deposits of crypto currency that was not purchased at Firi': [
    'Du har ',
    ['0'],
    ' innskudd med kryptovaluta som du ikke har kjøpt hos Firi.',
  ],
  'You have {remainingPercent}% to distribute': [
    'Du har ',
    ['remainingPercent'],
    '% igjen å fordele',
  ],
  'You might see an error indicating the distribution for some cryptocurrencies is too low. This happens when one distribution is less than {SAVING_AGREEMENT_DETAIL_MIN_AMOUNT} {0}':
    [
      'Du kan se en feil som indikerer at fordelingen for noen kryptovalutaer er for lav. Dette skjer når én fordeling er mindre enn ',
      ['SAVING_AGREEMENT_DETAIL_MIN_AMOUNT'],
      ' ',
      ['0'],
    ],
  'You must be over 18 years old': 'Du må være over 18 år',
  'You must have a valid BankID': 'Du må ha et gyldig norsk personnummer',
  'You must have a valid Norwegian national identity number': 'Du må ha BankID',
  'You must verify your email to continue to use Firi.':
    'Du må bekrefte e-postadressen din for å fortsette å bruke Firi.',
  'You need a 2FA app to generate codes that, in addition to your password, are used to unlock your account.':
    'Du trenger en 2FA-app for å generere koder som, i tillegg til passordet ditt, brukes til å låse opp kontoen din.',
  'You need more {0}': ['Du trenger mer ', ['0']],
  'You need to activate staking to earn up to {0}% expected annual yield on your crypto.': [
    'Du må aktivere staking for å tjene opptil ',
    ['0'],
    '% forventet årlig avkastning på kryptoen din.',
  ],
  'You need to enable notifications in device setting to receive notifications from Firi':
    'Du må skru på varslinger i innstillinger på enheten din for å motta varslinger fra Firi',
  'You need to give access to the camera in device setting to scan QR code. Reload the app after enabling permissions.':
    'Du må gi tilgang til kamera i innstillinger for å skanne QR-kode. Restart appen ved å lukke og åpne den på nytt, etter at du har gitt tillatelse.',
  'You need to give access to the camera in device setting to take a profile picture':
    'Du må gi tilgang til kamera i instillinger for å ta et profilbilde',
  'You need to update your account number and message for your recurring transfer in online banking':
    'Du må oppdatere kontonummer og melding for din faste overføring i nettbanken',
  'You now get {0} {1} in {2} for each friend you invite to Firi and becomes a customer with us.': [
    'Nå får du ',
    ['0'],
    ' ',
    ['1'],
    ' i ',
    ['2'],
    ' for hver venn du inviterer til Firi som blir kunde av oss!',
  ],
  'You now have a staking account.': 'Du har nå en staking-konto.',
  'You password is changed! You will be logged out in a few seconds.':
    'Passordet ditt er endret! Du vil bli logget ut om noen sekunder.',
  'You privacy information are always safely stored with us.':
    'Din personverninformasjon er alltid trygt lagret hos oss.',
  'You received': 'Du mottok',
  'You received a currency': 'Du mottok en valuta',
  'You sent a currency': 'Du sendte en valuta',
  'You session is invalid. Please log out and try again.':
    'Din sesjon er ugyldig. Logg ut og prøv igjen',
  'You should not be here yet.': 'Du burde ikke være her enda.',
  'You supplied your name and address.': 'Du oppgav ditt navn og bostedsadresse.',
  'You will find the code on your gift card itself. In case of problems, contact our support at support@firi.com or in the chat.':
    'Du finner koden på selve gavekortet ditt. Ved problemer, kontakt vår support på support@firi.com eller i chatten vår.',
  'You will find the cryptocurrency on your main account.':
    'Nå finner du kryptovalutaen på hovedkontoen din.',
  'You will hear from us!': 'Du hører fra oss!',
  'You will now be sent to your bank': 'Du blir nå sendt til din bank',
  'You will now be sent to your payment provider':
    'Du vil nå bli sendt til betalingsleverandøren din',
  'You will now find the gift in your main account.': 'Du finner nå gaven på hovedkontoen din.',
  'You will receive the referral bonus once the person you referred has registered using your code and verified their account.':
    'Du mottar vervebonusen når den du verver har registrert seg med koden din og verifisert seg med BankID',
  "You're a bit too early...": 'Her var du litt tidlig ute...',
  "You're almost there!": 'Du er nesten i mål!',
  "You're almost there! To meet the authorities requirements, we also need to verify your identity through facial recognition. Thank you for helping us secure your account!":
    'Du er nesten i mål! For å oppfylle myndighetenes krav, må vi også verifisere din identitet gjennom ansiktsgjenkjenning. Takk for at du hjelper oss med å sikre kontoen din!',
  "You're on the home stretch!": 'Du er snart i mål!',
  "You're staking": 'Du staker',
  "You're up to date!": 'Du er oppdatert!',
  'Your account is linked with Vipps': 'Din konto er koblet til Vipps',
  'Your answers are now registered': 'Dine svar er nå registrert!',
  'Your balance': 'Din beholdning',
  'Your bank account': 'Din bankkonto',
  'Your bank does not allow higher amounts then 15000 NOK per transfer':
    'Din bank støtter ikke mer enn 15000 NOK per overføring',
  'Your choosen price is lower than the best available price in our market.':
    'Din valgte kurs er lavere enn beste tilgjengelige pris i markedet vårt.',
  'Your chosen bank has a maximum amount of {0} {shortName} per deposit.': [
    'Din valgte bank har et maksbeløp på ',
    ['0'],
    ' ',
    ['shortName'],
    ' per innskudd.',
  ],
  'Your configuration key': 'Din konfigureringsnøkkel',
  'Your crypto inventory is empty or it is too low to make a withdrawal. You can either buy crypto, or make an internal transfer from your savings account.':
    'Din kryptobeholdning er tom, eller så er den for lav til å gjøre et uttak. Du kan enten kjøpe krypto, eller gjøre en intern overføring fra din sparekonto.',
  'Your crypto inventory is empty or it is too low to make a withdrawal. You can solve this easily by buying crypto.':
    'Din kryptobeholdning er tom, eller så er den for lav til å gjøre et uttak. Dette løser du enkelt ved å kjøpe krypto.',
  'Your customer relationship will be transferred to Firi ApS on December 31, 2024, in accordance with the new EU regulation (MiCA). To maintain full access to your account after this date, you must accept this change.':
    'Your customer relationship will be transferred to Firi ApS on December 31, 2024, in accordance with the new EU regulation (MiCA). To maintain full access to your account after this date, you must accept this change.',
  'Your email is verified!': 'E-posten din er verifisert!',
  "Your friends don't have to spend a single penny on this - they just invest their welcome bonus.":
    'Vennene dine trenger ikke bruke en eneste krone på dette - det holder at de investerer velkomstbonusen sin.',
  'Your gift card could not be created': 'Ditt gavekort kunne ikke bli opprettet',
  'Your gift card from Firi | Norwegian Stock Exchange for trading cryptocurrency':
    'Ditt gavekort fra Firi | Norsk børs for handel av kryptovaluta',
  "Your gift card have been created and you will soon receive an email where you can download your gift card. We are experiencing some technical issue so you can't see it right here":
    'Gavekortet ditt er opprettet og du vil snart motta en e-post hvor du kan laste ned gavekortet ditt. Vi har et teknisk problem, så du kan ikke se det her',
  'Your gift card!': 'Ditt gavekort!',
  'Your information is handled securely at every step.':
    'Din informasjon håndteres trygt i hvert trinn.',
  'Your information has now been updated, and the changes will automatically take effect on January 1, 2025.':
    'Your information has now been updated, and the changes will automatically take effect on January 1, 2025.',
  'Your latest': 'Dine siste',
  'Your lightning invoice': 'Din lightning faktura',
  'Your money will usually be withdrawn from your bank account immediately, but it takes some time before we receive all required information and can approve your transaction. You will get an e-mail when the money is credited.':
    'Pengene dine vil vanligvis bli trukket fra bankkontoen din umiddelbart, men det tar litt tid før vi mottar all nødvendig informasjon og kan godkjenne transaksjonen din. Du får en e-post når pengene er kreditert.',
  'Your personal information is always stored securely with us.':
    'Dine personopplysninger er alltid trygt oppbevart hos oss.',
  'Your profile is not visible to other users.':
    'Din profil vil ikke være synlig for andre brukere.',
  'Your profile will be automatically completed when using Vipps. Sign in with bankID to get started, safe and simple!':
    'Bruker du Vipps blir din profil automatisk utfylt. Signer så med BankID og du er i gang, trygt og enkelt!',
  'Your referral code:': 'Din vervekode',
  'Your referral codes': 'Dine vervekoder',
  'Your reward of <0/> in {0} has been paid to your main account.': [
    'Belønningen din på <0/> i ',
    ['0'],
    ' er utbetalt til din hovedkonto.',
  ],
  'Your reward of {0} {name} in {1} has been paid to your main account.': [
    'Belønningen din på ',
    ['0'],
    ' ',
    ['name'],
    ' i ',
    ['1'],
    ' er utbetalt til din hovedkonto',
  ],
  'Your safety is important': 'Din sikkerhet er viktig',
  'Your session has either expired or is invalid':
    'Din bruker sesjon er enten ugyldig eller utgått',
  'Your session has expired, please login again.':
    'Din sesjon er utgått, vennligst logg inn på nytt.',
  'Your text exceeds 20 characters': 'Teksten din overstiger 20 tegn',
  'Your wallet address is currently unavailable':
    'Wallet adressen din er for øyeblikket utilgjengelig',
  'Your withdrawal and transaction fees total is {0} {1}. You can enter this amount in your tax return under the topic "Finance", the item "Management costs" and in the field "Securities management"':
    [
      'Uttak- og transaksjonsgebyrene dine er på totalt ',
      ['0'],
      ' ',
      ['1'],
      '. Dette beløpet kan du føre opp i skattemeldingen din under temaet "Finans", punktet "Forvaltningskostnader" og i feltet "Verdipapirforvaltning"',
    ],
  'Your withdrawal limit is <0/> per day. The amount count for a total value for every currency.':
    'Din uttaksgrense er <0/> per døgn. Beløpet gjelder som en samlet verdi for alle valutaer.',
  'Your withdrawal limit is {0} per day. The amount count for a total value for every currency.': [
    'Din uttaksgrense er ',
    ['0'],
    ' per døgn. Beløpet gjelder som en samlet verdi for alle valutaer.',
  ],
  'Your withdrawal limit is {0}{1} per day. The amount count for a total value for every currency.':
    [
      'Din uttaksgrense er ',
      ['0'],
      ' ',
      ['1'],
      ' per døgn. Beløpet gjelder som en samlet verdi for alle valutaer.',
    ],
  'Your withdrawal was not successful. It may have been a hiccup, so please try again.':
    'Ditt uttak gikk ikke gjennom. Det kan ha vært et mindre problem, så prøv igjen.',
  'Zip code': 'Postnummer',
  about: 'om',
  'app-theme': 'app-theme',
  available: 'tilgjengelig',
  'change <0/>': 'utvikling <0/>',
  collected: 'innsamlet',
  cryptocurrencies: 'kryptovalutaer',
  cryptocurrency: 'kryptovaluta',
  'danish kroner': 'danske kroner',
  'deposit-2': 'Sett inn',
  dollars: 'dollar',
  'enter the value': 'fører du opp beløpet',
  'every day': 'hver dag',
  'every month': 'hver måned',
  'every other week': 'annenhver uke',
  'every two weeks': 'hver andre uke',
  'every week': 'hver uke',
  'every {0} days': ['hver ', ['0'], ' dag'],
  'expected yearly earn': 'forventet årlig avkastning',
  for: 'for',
  friends: 'venner',
  'from {0} to {1}': ['fra ', ['0'], ' til ', ['1']],
  'gift card': 'gavekort',
  high: 'høy',
  immediately: 'umiddelbart',
  in: 'i',
  'in expected yield per year': 'i forventet avkastning per år ',
  'in fees': 'i gebyr',
  'in rewards': 'i belønning',
  'invite you to Firi!': 'inviterer deg til Firi!',
  kr: 'kr',
  kroner: 'kroner',
  left: 'gjenstår',
  low: 'lav',
  'norwegian kroner': 'norske kroner',
  'not verified': 'ikke verifisert',
  'on the way': 'på vei',
  'on {0}': ['på ', ['0']],
  or: 'eller',
  'or copy code': 'eller kopier kode',
  'or remote': 'eller remote',
  'people have donated': 'personer har donert',
  'privacy policy': 'personvernerklæring',
  'read-past-tense': 'Lest',
  'savings account': 'sparekonto',
  'select files': 'velg filer',
  'swedish kroner': 'svenska kronor',
  'terms of services': 'brukervilkårene',
  'the App Store': 'App Store',
  today: 'i dag',
  unknown: 'ukjent',
  value: 'verdi',
  vol: 'vol',
  'with email': 'med e-post',
  'withdraw-2': 'Ta ut',
  'yearly yield': 'årlig avkastning',
  yesterday: 'i går',
  '{0, select, en {https://www.firi.com} no {https://www.firi.com/no} dk {https://www.firi.com/dk}}':
    [
      [
        '0',
        'select',
        {
          en: 'https://www.firi.com',
          no: 'https://www.firi.com/no',
          dk: 'https://www.firi.com/dk',
        },
      ],
    ],
  '{0}': [['0']],
  '{0} articles': [['0'], ' innlegg'],
  '{0} in {1}': [['0'], ' i ', ['1']],
  '{0} is {depositFee} percent.': [['0'], ' er på ', ['depositFee'], ' prosent.'],
  '{0} {1}': [['0'], ' ', ['1']],
  '{0} {currency} has been transferred to your main account': [
    ['0'],
    ' ',
    ['currency'],
    ' har blitt overført til din hovedkonto',
  ],
  '{0}% expected annual yield': [['0'], '% forventet årlig avkastning'],
  '{0}-value now': [['0'], '-verdi nå'],
  '{amountTickets} tickets!': [['amountTickets'], ' lodd'],
  '{currency} holdings': [['currency'], ' beholdning'],
  '{currency} is sent over <0>{0}</0>': [['currency'], ' overføres på <0>', ['0'], '</0>'],
  '{currency}-address': [['currency'], '-adresse'],
  '{currency}-value now': [['currency'], '-verdi nå'],
  '{days} days': [['days'], ' dager'],
  '{referralOwnerName} welcomes you to Firi!': [
    ['referralOwnerName'],
    ' ønsker deg velkommen til Firi!',
  ],
  '☑️ Choose the amount you want to stake and confirm.':
    '☑️ Velg hvilket beløp du ønsker å stake og bekreft.',
  '☑️ Choose the amount you want to unstake, i.e., deactivate staking.':
    '☑️ Velg hvilket beløp du ønsker å unstake, altså deaktivere staking på.',
  '⚙️ We give you an estimate of the processing time of the withdrawal. This is usually very quick.':
    '⚙️ Vi gir deg et estimat på behandlingstid av uttaket. Dette går vanligvis veldig raskt.',
  '📈 Statistics shows that long term investments give better yield than buying and selling cryptocurrency based on price.':
    '📈 Statistikken viser at langsiktig sparing gir bedre avkastning enn å kjøpe og slege basert på pris.',
  '📩 The funds you stake will be automatically transferred to your staking account.':
    '📩 Midlene du staker blir automatisk overført til staking-kontoen din.',
  '🕒 Invest a fixed amount at a fixed time with automatic deposits from your bank. This is also know as Dollar-Cost Averaging (DCA)':
    '🕒 Invester et fast beløp til fast tid med autotrekk fra banken din. Dette er også kjent som Dollar-Cost Averaging (DCA)',
  '🙌 Once your withdrawal is processed, you will receive it in your main account.':
    '🙌 Når uttaket ditt er prosessert vil du få det utbetalt til hovedkontoen din.',
  '🙌 You will receive the reward on your staking account every Friday going forward. You will get the first reward the week after starting.':
    '🙌 Du vil motta belønning på staking-kontoen din hver fredag fremover. Første belønning får du uken etter start.',
  '🤝 We have made it simple to make long term investments in cryptocurrency.':
    '🤝 Vi har gjor den enkelt å investere langsiktig i krypto',
  '🧘 Less risk, less stress': '🧘 Lavere risiko, mindre stress',
};
